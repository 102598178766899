import _ from 'lodash';
import { FACILITIES_LOADING, FACILITIES_LOADED, FACILITY_REMOVED, FACILITIES_PURGE } from '../actions/types';

const INITIAL_STATE = {
  facilities: [],
  facilitiesLoading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FACILITIES_LOADING:
      return { ...state, facilitiesLoading: true };
    case FACILITIES_LOADED:
      let facilities = _.unionWith(action.payload, state.facilities, (v1, v2) => {
        return v1 != null && v2 != null && v1.RowId === v2.RowId;
      });
      facilities = _.filter(facilities, (facility) => {
        return !facility.Deleted;
      });
      return {
        ...state,
        facilities,
        facilitiesLoading: false,
      };
    case FACILITY_REMOVED:
      const index = _.findIndex(state.facilities, (facility) => {
        return facility != null && facility.RowId === action.payload;
      });
      if (index > -1) {
        const facilities = [...state.facilities];
        facilities.splice(index, 1);
        return { ...state, facilities };
      }
      return state;
    case FACILITIES_PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
