import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

class MainMenuItem extends Component {
    goTo() {
        this.props.history.push(this.props.Link);
    }
    render() {
        return (
            <div className="homeGroup-item" onClick={this.goTo.bind(this)}>
                {this.props.Text}
            </div>
            // <div style={{ marginBottom: 5 }}>
            //     <Link to={this.props.Link}>
            //         <FontAwesome name={this.props.Icon} style={styles.Icon} />{this.props.Text}
            //     </Link>
            // </div>
        );
    }
}

let exportObj = connect(null, {})(withRouter(MainMenuItem));

export { exportObj as MainMenuItem };