import React, { Component } from 'react';
import _ from 'lodash';
import { defaultProfileImage } from '../config';

class ProfilePic extends Component {
  render() {
    return (
      <div
        className={this.props.className}
        style={{
          width: this.props.size || 30,
          height: this.props.size || 30,
          borderRadius: this.props.size / 2 || 15,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundImage: `url(${!_.isEmpty(this.props.image) ? this.props.image : defaultProfileImage})`,
          ...this.props.style,
        }}
        alt="Profile Pic"
      ></div>
    );
  }
}

export { ProfilePic };
