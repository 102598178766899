import axios from 'axios';
import { getUrl } from './helper';
import { authedFunction } from '../session';

export const authActions = {
  checkUserAuth: (userId) => {
    let url = getUrl('auth', 'checkUserAuth');
    return authedFunction({
      method: 'POST',
      url,
      data: {
        userId,
      },
    });
  },
  fetchForgotPasswordId: (input) => {
    return axios({
      method: 'POST',
      url: getUrl('auth', 'checkForgotUser'),
      data: {
        user: {
          email: input,
          phoneNumber: input,
        },
      },
      headers: { authorization: 'GwegChicken' },
    });
  },
  disableUser: (username, site) => {
    let url = getUrl('users', 'testUserDisbale');
    const request = {
      method: 'POST',
      url,
      data: {
        username,
        site,
      },
    };
    return authedFunction(request);
  },
  getUserSignin: async () => {
    return authedFunction({
      method: 'POST',
      url: getUrl('auth', 'getUserSignin'),
    });
  },
  copyDummy: (site) => {
    let url = getUrl('auth', 'copyDummy');
    const request = {
      method: 'POST',
      url,
      data: {
        site,
      },
    };
    return authedFunction(request);
  },
};
