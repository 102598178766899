import React, { Component } from 'react';
import { connect } from 'react-redux';
import { keysUpdate } from '../../actions';
import { Button, GenericInput, OverlayPage, OverlayPageContents, OverlayPageSection, SuccessPopup } from '../../components';
import { keyActions } from '../../webapi';
import { safeReadParams } from '../../helper';
import { checkLoggedIn, getSessionTokenAWS } from '../../session';

class AddKey extends Component {
    initialState = {
        infoId: safeReadParams(this.props, 'infoId'),
        nameInput: '',
        showWarnings: false,
        success: false,
        submitting: false
    }

    state = { ...this.initialState };

    UNSAFE_componentWillReceiveProps(nextProps) {
    }

    UNSAFE_componentWillMount() {
        checkLoggedIn(this, this.props.auth);
        if (this.state.infoId) {
            this.getData();
        }
    }

    getData() {
        keyActions.getKey(this.state.infoId)
            .then((res) => {
                this.parseUpdate(res.data);
            });
    }

    parseUpdate(update) {
        this.setState({
            nameInput: update.name
        });
    }

    isEmpty(text) {
        return text === '';
    }

    handleChange(event) {
        var stateChange = {};
        stateChange[event.target.getAttribute('id')] = event.target.value;
        this.setState(stateChange);
    }

    validateLoading() {
        if (this.state.submitting) {
            return false;
        }
        return true;
    }

    validateCompulsoryText() {
        if (this.isEmpty(this.state.nameInput)) {
            return false;
        }
        return true;
    }

    validateForm() {
        if (!this.validateCompulsoryText()) {
            return false;
        }
        if (!this.validateLoading()) {
            return false;
        }
        return true;
    }

    handleSubmit() {
        if (!this.validateForm()) {
            this.setState({ showWarnings: true });
            return;
        }
        this.setState({ submitting: true });

        getSessionTokenAWS()
            .then((idToken) => {
                if (this.state.infoId) {
                    keyActions.editKey(this.props.auth.site, this.state.infoId, this.state.nameInput, this.props.auth.user.uid, idToken)
                        .then((res) => {
                            this.setState({
                                success: true,
                                submitting: false
                            });
                            this.props.keysUpdate(this.props.auth.site);
                        }).catch((res) => {
                            this.setState({ submitting: false });
                            alert("Something went wrong with the request. Please try again.")
                        });
                } else {
                    keyActions.addKey(this.props.auth.site, this.state.nameInput, this.props.auth.user.uid, idToken)
                        .then((res) => {
                            this.setState({
                                success: true,
                                submitting: false
                            });
                            this.props.keysUpdate(this.props.auth.site);
                        }).catch((res) => {
                            this.setState({ submitting: false });
                            alert("Something went wrong with the request. Please try again.")
                        });
                }
            }).catch((error2) => {
                this.setState({ submitting: false });
            });
    }

    clearForm() {
        this.setState(this.initialState);
    }

    clearSuccess() {
        this.setState({
            success: false,
            submitting: false,
            showWarnings: false
        });
    }

    renderSuccess() {
        if (!this.state.success) {
            return null;
        }
        const buttons = [];
        if (this.state.infoId == null) {
            buttons.push({
                type: 'outlined',
                onClick: () => this.clearForm(),
                text: 'Clear form'
            });
        }
        buttons.push({
            type: 'outlined',
            onClick: () => { window.history.back() },
            text: 'Go to home'
        });
        return (<SuccessPopup
            text={`Key has been ${this.state.infoId != null ? 'edited' : 'added'}`}
            buttons={buttons}
        />);
    }

    renderSubmit() {
        if (this.state.submitting) {
            return <Button buttonType='secondary'>Saving...</Button>;
        }
        return (
            <Button
                buttonType='primary'
                onClick={this.handleSubmit.bind(this)}
                isActive={this.validateForm()}
            >
                Save
            </Button>
        );
    }

    renderForm() {
        if (this.state.success) {
            return null;
        }
        return (
            <div className="padding-60 paddingVertical-40">
                <p className="text-sectionTitle">
                    {this.state.infoId == null ? 'NEW' : 'EDIT'} KEY
                </p>
                {/* name */}
                <GenericInput
                    id="nameInput"
                    type="text"
                    label="Name"
                    placeholder="Eg. Gymnasium Key, Access Card 1234, etc."
                    value={this.state.nameInput}
                    onChange={(e) => this.handleChange(e)}
                    style={{ paddingBottom: 16, paddingTop: 16 }}
                    isRequired
                    isValid={() => { return !this.isEmpty(this.state.nameInput) }}
                    showError={() => { return this.state.showWarnings && this.isEmpty(this.state.nameInput) }}
                />
                {this.renderSubmit()}
            </div>
        );
    }

    render() {
        return (
            <OverlayPage>
                <OverlayPageContents noBottomButtons>
                    <OverlayPageSection className='pageSectionWrapper--newPopup'>
                        {this.renderForm()}
                        {this.renderSuccess()}
                    </OverlayPageSection>
                </OverlayPageContents>
            </OverlayPage>
        );
    }
}


const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
};

export default connect(mapStateToProps, { keysUpdate })(AddKey);
