import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popup } from '../Popup';
import { setNavData } from '../../actions';
import { androidLink, iOSLink } from '../../config';
import { Button } from '../Button';

class IntroComplete extends Component {
  onClose = () => {
    this.props.setNavData({
      introComplete: null,
    });
  };

  render() {
    if (!this.props.introComplete) {
      return null;
    }
    return (
      <Popup className="introComplete" onClose={this.onClose}>
        <img
          src="https://pluss-prd-media.s3-ap-southeast-2.amazonaws.com/assets/phone-asset.png"
          alt="phone"
          className="introComplete_phone"
        />
        <div className="introComplete_right">
          <p className="popup_title">Thanks for taking a tour!</p>
          <p className="popup_subtitle">Download the Pluss Communities app in App Store and Play Store to see it live</p>

          <div className="flex flex-center marginTop-20">
            <a className="marginRight-10" href={iOSLink} target="_blank">
              <img
                src="https://pluss-prd-media.s3-ap-southeast-2.amazonaws.com/assets/app-store.svg"
                className="introComplete_appStoreImage"
                alt="App Store"
              />
            </a>
            <a href={androidLink} target="_blank">
              <img
                src="https://pluss-prd-media.s3-ap-southeast-2.amazonaws.com/assets/google-play.svg"
                className="introComplete_appStoreImage"
                alt="Play Store"
              />
            </a>
          </div>
          <Button buttonType="primaryAction" className="button--signup marginTop-40" onClick={this.onClose} isActive={true}>
            Go to Dashboard
          </Button>
        </div>
      </Popup>
    );
  }
}

const mapStateToProps = (state) => {
  const { nav } = state;
  return { introComplete: nav.introComplete };
};

const introComplete = connect(mapStateToProps, { setNavData })(IntroComplete);
export { introComplete as IntroComplete };
