import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import { isTheBest, getApiError } from '../../session';
import { DropdownInput, Button, GenericInput } from '../../components';
import { automationActions } from '../../webapi';

const REPO_AWS = 'minuss-aws';
const REPO_WEB = 'minuss-admin';
const REPO_APP = 'minuss-expo';
const REPO_ALL = 'all';

class Extensions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      environmentLoading: {},
      environments: {},
      deployMessage: {},
      selected: {},
      extensions: {},
      packageInfo: {},
    };
  }

  componentDidMount() {
    this.loadEnvironments(REPO_AWS);
    this.loadEnvironments(REPO_WEB);
    this.loadEnvironments(REPO_APP);
  }

  onEnvironmentChanged = (key, repo = '') => {
    const { selected, environments } = this.state;
    if (repo) {
      selected[repo] = environments[repo].find((item) => item.Key === key);
      this.loadExtensions(repo, key);
    } else {
      selected[REPO_ALL] = environments[REPO_ALL].find((item) => item.Key === key);
      selected[REPO_AWS] = environments[REPO_AWS].find((item) => item.Key === key);
      selected[REPO_WEB] = environments[REPO_WEB].find((item) => item.Key === key);
      selected[REPO_APP] = environments[REPO_APP].find((item) => item.Key === key);

      this.loadExtensions(REPO_AWS, key);
      this.loadExtensions(REPO_WEB, key);
      this.loadExtensions(REPO_APP, key);
    }
    this.setState({ selected });
  };

  onChangePackageInfo = (event, repo, field) => {
    const { packageInfo } = this.state;
    if (!packageInfo[repo]) packageInfo[repo] = {};
    packageInfo[repo][field] = event.target.value;
    if (field === 'name') {
      const nameParts = event.target.value.split('/');
      const aliasParts = nameParts[nameParts.length - 1].split('-').map((p) => _.upperFirst(p));
      const alias = _.upperFirst(aliasParts.join().replace(/[^a-zA-Z0-9]/g, ''));
      packageInfo[repo]['alias'] = alias;
    }
    this.setState({ packageInfo });
  };

  onAddExtension = (repo, environment, packageInfo) => {
    if (!this.canAddExtension(repo)) return;
    if (
      !window.confirm(`Are you sure you want to add ${packageInfo.name}@${packageInfo.version || 'latest'} into ${environment} in ${repo}?`)
    )
      return;

    const { environmentLoading, deployMessage } = this.state;
    environmentLoading[repo] = true;
    deployMessage[repo] = `Adding ${packageInfo.name}@${packageInfo.version || 'latest'} into ${environment} in ${repo}...`;
    this.setState({ environmentLoading, deployMessage }, async () => {
      try {
        await automationActions.addExtension(repo, environment, packageInfo.name, packageInfo.version, packageInfo.alias);
        environmentLoading[repo] = false;
        deployMessage[repo] = '';
        this.setState({ environmentLoading, deployMessage }, () => this.loadExtensions(repo, environment));
      } catch (error) {
        environmentLoading[repo] = false;
        deployMessage[repo] = getApiError(error).message;
        this.setState({ environmentLoading, deployMessage });
        console.log('onAddExtension', repo, error);
      }
    });
  };

  onRemoveExtension = (extension) => {
    const { environmentLoading, deployMessage } = this.state;
    const { repo, branch, packageName, alias } = extension;

    if (environmentLoading[repo]) return;
    if (!window.confirm(`Are you sure you want to remove ${packageName} from ${branch} in ${repo}?`)) return;

    environmentLoading[repo] = true;
    deployMessage[repo] = `Removing ${packageName} from  ${branch} in ${repo}...`;
    this.setState({ environmentLoading, deployMessage }, async () => {
      try {
        await automationActions.removeExtension(repo, branch, packageName, alias);
        environmentLoading[repo] = false;
        deployMessage[repo] = '';
        this.setState({ environmentLoading, deployMessage }, () => this.loadExtensions(repo, branch));
      } catch (error) {
        environmentLoading[repo] = false;
        deployMessage[repo] = getApiError(error).message;
        this.setState({ environmentLoading, deployMessage });
        console.log('onRemoveExtension', repo, error);
      }
    });
  };

  hasEnvironmentSelected = (repo) => {
    const { environmentLoading } = this.state;
    const isLoading = environmentLoading[repo];
    const selectedEnv = this.getSelected(repo);
    return !_.isEmpty(selectedEnv) && !isLoading;
  };

  hasPackageInfo = (repo, field) => {
    const { packageInfo } = this.state;
    return !_.isNil(packageInfo[repo]) && !_.isEmpty(packageInfo[repo][field]);
  };

  getPackageInfo = (repo, field) => {
    const { packageInfo } = this.state;
    return _.isNil(packageInfo[repo]) || _.isNil(packageInfo[repo][field]) ? '' : packageInfo[repo][field];
  };

  canAddExtension = (repo) => {
    return this.hasEnvironmentSelected(repo) && this.hasPackageInfo(repo, 'name') && this.hasPackageInfo(repo, 'alias');
  };

  loadEnvironments = (repo) => {
    const { environmentLoading, environments, deployMessage } = this.state;
    environmentLoading[repo] = true;
    deployMessage[repo] = 'Loading environments...';
    this.setState({ environmentLoading, deployMessage }, async () => {
      try {
        const { data } = await automationActions.getEnvironments(repo);
        const envs = data.map((env) => {
          return { Id: env.uuid, Title: env.name, Key: env.slug };
        });
        environments[repo] = envs;
        // console.log('loadEnvironments', repo, environments[repo]);
        environments[REPO_ALL] = this.getAllEnvironments();
        environmentLoading[repo] = false;
        deployMessage[repo] = '';
        this.setState({ environmentLoading, deployMessage, environments });
      } catch (error) {
        environmentLoading[repo] = false;
        deployMessage[repo] = getApiError(error).message;
        this.setState({ environmentLoading, deployMessage });
        console.log('loadEnvironments', repo, error);
      }
    });
  };

  loadExtensions = (repo, branch) => {
    const { deployMessage, extensions } = this.state;
    deployMessage[repo] = 'Loading extensions...';
    this.setState({ deployMessage }, async () => {
      try {
        const { data } = await automationActions.listExtensions(repo, branch);
        extensions[repo] = data;
        // console.log('loadExtensions', repo, extensions[repo]);
        deployMessage[repo] = '';
        this.setState({ deployMessage, extensions });
      } catch (error) {
        deployMessage[repo] = getApiError(error).message;
        this.setState({ deployMessage });
        console.log('loadExtensions', repo, error);
      }
    });
  };

  getAllEnvironments = () => {
    const { environments } = this.state;
    return _.orderBy(
      _.unionWith(environments[REPO_APP], environments[REPO_WEB], (a, b) => a.Key === b.Key),
      'Title',
    );
  };

  getSelected = (repo) => {
    const { selected } = this.state;
    return selected[repo] ? selected[repo].Key : '';
  };

  getSelectedName = (repo) => {
    const { selected } = this.state;
    return selected[repo] ? selected[repo].Title : '';
  };

  renderSelectorAll() {
    const { environmentLoading, environments, deployMessage } = this.state;
    const isLoading = environmentLoading[REPO_APP] || environmentLoading[REPO_WEB] || environmentLoading[REPO_ALL];
    return (
      <div>
        <p className="fontRegular fontSize-20 text-dark">Select Environment</p>
        <div className="flex flex-row">
          <DropdownInput
            id="branchAll"
            style={{ width: 250, marginBottom: 10, marginRight: 45 }}
            placeholder="Select Environment (All)"
            value={this.getSelectedName(REPO_ALL)}
            options={environments[REPO_ALL]}
            onSelect={(key) => this.onEnvironmentChanged(key)}
            disabled={isLoading}
          />
          <div className="marginTop-8 marginLeft-10" style={{ fontSize: 11 }}>
            {deployMessage[REPO_ALL] || (isLoading ? 'Loading environments...' : '')}
          </div>
        </div>
      </div>
    );
  }

  renderSelectorRepo(repo, title) {
    const { environmentLoading, environments, deployMessage, packageInfo } = this.state;

    const isLoading = environmentLoading[repo];
    const selectedEnv = this.getSelected(repo);
    const selectedEnvName = this.getSelectedName(repo);
    const hasSelectedEnv = this.hasEnvironmentSelected(repo);
    return (
      <div>
        <div className="fontRegular fontSize-20 text-dark marginBottom-8">{title}</div>
        <div className="flex flex-column" style={{ height: 300 }}>
          <DropdownInput
            id={`branch-${repo}`}
            style={{ width: 250, marginBottom: 'unset' }}
            placeholder="Select Branch"
            value={selectedEnvName}
            options={environments[repo]}
            onSelect={(key) => this.onEnvironmentChanged(key, repo)}
            disabled={isLoading}
          />
          <GenericInput
            id={`package-${repo}`}
            className="marginTop-10"
            style={{ width: 250 }}
            type="text"
            alwaysShowLabel
            label="Package"
            placeholder="Enter package name"
            isValid={() => this.hasPackageInfo(repo, 'name')}
            value={this.getPackageInfo(repo, 'name')}
            onChange={(e) => this.onChangePackageInfo(e, repo, 'name')}
            disabled={!hasSelectedEnv}
          />
          <GenericInput
            id={`version-${repo}`}
            style={{ width: 200 }}
            type="text"
            alwaysShowLabel
            label="Version (optional)"
            placeholder="Enter package version"
            isValid={() => this.hasPackageInfo(repo, 'version')}
            value={this.getPackageInfo(repo, 'version')}
            onChange={(e) => this.onChangePackageInfo(e, repo, 'version')}
            disabled={!hasSelectedEnv}
          />
          <GenericInput
            id={`alias-${repo}`}
            style={{ width: 250 }}
            type="text"
            alwaysShowLabel
            label="Package Alias"
            placeholder="Enter package alias"
            isValid={() => this.hasPackageInfo(repo, 'alias')}
            value={this.getPackageInfo(repo, 'alias')}
            onChange={(e) => this.onChangePackageInfo(e, repo, 'alias')}
            disabled={!hasSelectedEnv}
          />
          <Button
            style={{ width: 150 }}
            inline
            buttonType="primary"
            onClick={() => this.onAddExtension(repo, selectedEnv, packageInfo[repo])}
            isActive={this.canAddExtension(repo)}
          >
            {isLoading ? <FontAwesome name="spinner fa-pulse fa-fw" /> : 'Add Extension'}
          </Button>
          <div className="flex-1 marginTop-8" style={{ fontSize: 11 }}>
            {deployMessage[repo]}
          </div>
        </div>
      </div>
    );
  }

  renderExtensions(repo) {
    const { environmentLoading, extensions } = this.state;

    const isLoading = environmentLoading[repo];
    return (
      <Table className="plussTable" striped bordered condensed hover>
        <tbody>
          {extensions[repo] &&
            extensions[repo].map((ext) => {
              return (
                <tr key={ext.packageName} style={{ fontSize: 10 }}>
                  <td>
                    <b>{`${ext.packageName}`}</b>
                    <br />
                    {`${ext.version}`}
                    <br />
                    {`${ext.alias}`}
                  </td>
                  <td>
                    <Button inline buttonType="outlined" onClick={() => this.onRemoveExtension(ext)} compact isActive={!isLoading}>
                      {isLoading ? <FontAwesome name="spinner fa-pulse fa-fw" /> : 'Remove'}
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  }

  render() {
    if (!isTheBest(this.props.auth, true)) return null;

    return (
      <div className="flex-1 automation">
        <div className="flex flex-column marginBottom-20">{this.renderSelectorAll()}</div>
        <div className="flex flex-row" style={{ paddingBottom: 160 }}>
          <div className="paddingRight-32" style={{ width: '33%' }}>
            {this.renderSelectorRepo(REPO_AWS, 'Extend AWS')}
            {this.renderExtensions(REPO_AWS)}
          </div>
          <div className="paddingRight-32" style={{ width: '33%' }}>
            {this.renderSelectorRepo(REPO_WEB, 'Extend Web')}
            {this.renderExtensions(REPO_WEB)}
          </div>
          <div className="paddingRight-32" style={{ width: '33%' }}>
            {this.renderSelectorRepo(REPO_APP, 'Extend App')}
            {this.renderExtensions(REPO_APP)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(Extensions);
