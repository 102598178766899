import React, { Component } from 'react';
import { connect } from 'react-redux';

class OnboardingBlocker extends Component {
  render() {
    if (!this.props.onboardingStep) {
      return null;
    }
    return <div className="onboardingBlocker"></div>;
  }
}

const mapStateToProps = (state) => {
  const { nav } = state;
  return { onboardingStep: nav.onboardingStep };
};

const onboardingBlocker = connect(mapStateToProps, {})(OnboardingBlocker);
export { onboardingBlocker as OnboardingBlocker };
