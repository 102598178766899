import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, GenericInput, ImageInput } from '../../../components';
import { checkLoggedIn, isTheBest } from '../../../session';
import { typeActions } from '../../../webapi';
import ColourDisplay1 from './ColourDisplay1';
import ColourDisplay2 from './ColourDisplay2';
import { Text } from '../../../components/text';

class SiteBrandingConfig extends Component {
  state = {
    colour1: '',
    colour2: '',
    colour3: '',
    colour4: '',
    showWarnings: false,
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    if (!isTheBest(this.props.auth)) {
      this.props.history.push('/mastermenu');
    }
    this.getSiteBranding();
  }

  getSiteBranding() {
    typeActions.getSite(this.props.auth.site).then((res) => {
      if (res && res.data && res.data.Branding) {
        this.setState({
          colour1: res.data.Branding.MainBrandingColour,
          colour2: res.data.Branding.LightBrandingColour,
          colour3: res.data.Branding.DarkBrandingColour,
          colour4: res.data.Branding.HeaderBrandingColour,
        });

        if (res.data.Branding.Logo) {
          this.checkSetImage(res.data.Branding.Logo);
        }
      }
    });
  }

  checkSetImage(url) {
    if (this.refs.imageInput) {
      this.refs.imageInput.getWrappedInstance().setValue(url);
    } else {
      setTimeout(() => {
        this.checkSetImage(url);
      }, 100);
    }
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  validateForm() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({
      submitting: true,
      showWarnings: false,
    });
    const brandingData = {};
    if (!_.isEmpty(this.state.colour1)) {
      brandingData.MainBrandingColour = this.state.colour1;
    }
    if (!_.isEmpty(this.state.colour2)) {
      brandingData.LightBrandingColour = this.state.colour2;
    }
    if (!_.isEmpty(this.state.colour3)) {
      brandingData.DarkBrandingColour = this.state.colour3;
    }
    if (!_.isEmpty(this.state.colour4)) {
      brandingData.HeaderBrandingColour = this.state.colour4;
    }
    const logo = this.refs.imageInput.getWrappedInstance().getValue();
    if (!_.isEmpty(logo)) {
      brandingData.Logo = logo;
    }

    console.log(brandingData);

    typeActions
      .editSiteBranding(this.props.auth.site, brandingData)
      .then((res) => {
        console.log('success');
        this.setState({
          submitting: false,
          success: true,
        });
      })
      .catch((error) => {
        console.log('error', error);
        this.setState({
          submitting: false,
          success: false,
        });
      });
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <Button buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateForm()}>
        Save
      </Button>
    );
  }

  displayExample() {
    return !_.isEmpty(this.state.colour1) || !_.isEmpty(this.state.colour2);
  }

  render() {
    return (
      <div style={{ width: '100%', maxWidth: 700 }}>
        <div>
          <Text type="formTitleSmall" className="marginBottom-16">
            Logo
          </Text>
          <ImageInput ref="imageInput" noCompress noMenu />
          <div className="genericInput-help">Logo should be .png, have transparent background, and have a width of 150px</div>
        </div>
        <div className="paddingVertical-40">
          <GenericInput
            id="colour1"
            type="text"
            label="Main Branding Colour"
            placeholder="#DD0000"
            value={this.state.colour1}
            onChange={(e) => this.handleChange(e)}
            alwaysShowLabel
          />
          <GenericInput
            id="colour2"
            type="text"
            label="Light Branding Colour"
            placeholder="#FF0000"
            value={this.state.colour2}
            onChange={(e) => this.handleChange(e)}
            alwaysShowLabel
          />
          <GenericInput
            id="colour3"
            type="text"
            label="Dark Branding Colour (Used for text input cursor and text high-light in app)"
            placeholder="#BB0000"
            value={this.state.colour3}
            onChange={(e) => this.handleChange(e)}
            alwaysShowLabel
          />
          <GenericInput
            id="colour4"
            type="text"
            label="Home Screen header background (warning)"
            placeholder="#BB0000"
            value={this.state.colour4}
            onChange={(e) => this.handleChange(e)}
            alwaysShowLabel
          />
          {this.displayExample() && (
            <div>
              <div className={`fontSize-14 pointer text-dark`} style={{ height: '20px', marginBottom: 4 }}>
                Example App display
              </div>
              <div className="flex flex-around" style={{ padding: 16, backgroundColor: '#888' }}>
                <ColourDisplay2
                  colour1={this.state.colour1}
                  colour2={this.state.colour2}
                  colour3={this.state.colour3}
                  colour4={this.state.colour4}
                />
                <ColourDisplay1 colour1={this.state.colour1} colour2={this.state.colour2} colour3={this.state.colour3} />
              </div>
            </div>
          )}
        </div>
        <div style={{ paddingBottom: 40 }}>{this.renderSubmit()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(SiteBrandingConfig);
