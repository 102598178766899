import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import _ from 'lodash';
import { GenericInput, Button, AddButton } from '../../../components';
import { checkLoggedIn, isTheBest } from '../../../session';
import { typeActions } from '../../../webapi';

class EditSites extends Component {
  state = {
    site: null,
    locations: [],

    viewCreator: false,
    submitting: false,

    newInput: '',
    siteName: '',
    showWarnings: false,
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    if (!isTheBest(this.props.auth)) {
      this.props.history.push('/mastermenu');
    }
    this.getSites();
  }

  openNewSite() {
    this.props.history.push('/newsite');
    // this.props.history.push('/newsitefromtemplate');
    //this.setState({ viewCreator: true });
  }
  closePopup() {
    this.setState({ viewCreator: false });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  getSites() {
    var self = this;
    typeActions
      .getSites()
      .then((res) => {
        self.setState({
          locations: res.data,
        });
      })
      .catch((error) => {});
  }

  renderUserTypes() {
    if (_.isEmpty(this.state.locations)) {
      return null;
    }
    let source = this.state.locations;

    return source.map((ev, index) => {
      return (
        <ListGroup key={ev.Id}>
          <ListGroupItem style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>{ev.siteName}</ListGroupItem>
        </ListGroup>
      );
    });
  }

  handleSubmit() {
    if (this.state.submitting || this.state.siteName.length < 2) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ error: false, submitting: true });
    var self = this;
    typeActions
      .createNewSite(this.state.siteName)
      .then((res) => {
        self.setState({
          submitting: false,
          siteName: '',
          viewCreator: false,
        });
        self.getSites();
      })
      .catch((error) => {
        self.setState({
          submitting: false,
          error: true,
        });
      });
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="primary">Saving...</Button>;
    }
    return (
      <Button buttonType="primary" onClick={() => this.handleSubmit()} isActive>
        Save
      </Button>
    );
  }

  renderForm() {
    return (
      <div style={{}}>
        <div style={{ textAlign: 'center', marginBottom: 10, fontSize: 30, fontWeight: 'bold' }}>Create new site</div>
        {this.state.error && <div style={{ color: 'red' }}>Something went wrong</div>}
        <GenericInput
          id="siteName"
          type="text"
          label="New site name"
          placeholder="Site name"
          value={this.state.siteName}
          onChange={(e) => this.handleChange(e)}
          isRequired
          isValid={() => {
            return this.state.siteName.length > 1;
          }}
          showError={() => {
            return this.state.showWarnings && this.state.siteName.length < 2;
          }}
        />
        <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', paddingTop: 15 }}>
          <Button
            buttonType="tertiary"
            onClick={() => {
              this.closePopup();
            }}
            isActive
            style={{ marginRight: 16 }}
          >
            Cancel
          </Button>
          {this.renderSubmit()}
        </div>
      </div>
    );
  }

  renderPopup() {
    if (!this.state.viewCreator) {
      return null;
    }
    return (
      <div style={styles.popUpOuter}>
        <div style={styles.whiteBox}>
          <div style={{ borderRadius: 15, backgroundColor: 'white', padding: 20, marginBottom: 30 }}>{this.renderForm()}</div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ width: '100%', maxWidth: 600 }}>
        {this.renderPopup()}
        <AddButton onClick={this.openNewSite.bind(this)} text="NEW SITE" style={{ marginBottom: 32 }} />
        {this.state.locations.length > 0 && (
          <div>
            <div style={{ marginBottom: 15, fontWeight: 600, fontSize: 15 }}>Existing sites</div>
            {this.renderUserTypes()}
          </div>
        )}
      </div>
    );
  }
}

const styles = {
  popUpOuter: {
    zIndex: 100,
    width: '100%',
    height: '100%',
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.7)',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 30,
    overflow: 'auto',
    flex: '0 1 auto',
    top: 0,
    left: 0,
    //alignItems: 'center'
  },
  whiteBox: {
    minWidth: '60%',
    maxWidth: '80%',
    //maxHeight: '90%',
    //overflow: 'auto',
  },
};

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(withRouter(EditSites));
