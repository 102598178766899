import _ from 'lodash';

import { JOBS_LOADED, JOBS_PURGE, JOBS_LOADING, TICKETS_LOADED } from '../actions/types';

const INITIAL_STATE = {
  jobs: [],
  jobtypes: [],
  loading: false,
  tickets: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case JOBS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case JOBS_LOADED:
      const result = _.unionWith(action.payload, state.jobs, (v1, v2) => {
        return v1 != null && v2 != null && v1.id === v2.id;
      });
      return {
        ...state,
        jobs: result.map((job) => ({ title: job.title || job.description, ...job })),
        loading: false,
      };
    case TICKETS_LOADED:
      const ticketResult = _.unionWith(action.payload, state.tickets, (v1, v2) => {
        return v1 != null && v2 != null && v1.Id === v2.Id;
      });
      return {
        ...state,
        tickets: ticketResult,
      };
    case JOBS_PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
