import { MAKE_API_KEY } from '../config';
import { authedFunction, unauthedFunction } from '../session';
import { getUrl } from './helper';

export const utilityActions = {
  getUpvotyToken: () => {
    return authedFunction({
      method: 'GET',
      url: getUrl('utility', 'upvoty/usertoken'),
    });
  },
  getBoardHash: () => {
    return authedFunction({
      method: 'GET',
      url: getUrl('utility', 'upvoty/boardhash'),
    });
  },
  getRoadmapHash: () => {
    return authedFunction({
      method: 'GET',
      url: getUrl('utility', 'upvoty/roadmap'),
    });
  },
  generateMake: (templateId, data) => {
    return unauthedFunction({
      method: 'POST',
      url: `https://api.make.cm/make/t/${templateId}/sync`,
      data,
      headers: {
        'Content-Type': 'application/json',
        'X-MAKE-API-KEY': MAKE_API_KEY,
      },
    });
  },
};
