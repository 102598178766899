import { getUrl } from './helper';
import { authedFunction } from '../session';

export const contactActions = {
  getContacts: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('importantContacts', 'get', { site }),
    });
  },
  getContact: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('importantContacts', `get/${id}`),
    });
  },
  addContact: (site, title, email, phone, image, text, shouldNotify) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('importantContacts', 'add'),
      data: {
        site,
        title,
        email,
        phone,
        image,
        text,
        shouldNotify,
      },
    });
  },
  editContact: (site, id, title, email, phone, image, text) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('importantContacts', 'edit'),
      data: {
        site,
        id,
        title,
        email,
        phone,
        image,
        text,
      },
    });
  },
  changeContactOrder: (site, changes) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('importantContacts', 'order'),
      data: {
        site,
        changes,
      },
    });
  },
  deleteContact: (site, id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('importantContacts', 'remove'),
      data: {
        site,
        id,
      },
    });
  },
};
