import { authedFunction } from '../session';
import { getUrl } from './helper';

export const marketActions = {
  getOffers: (site, tvOnly = false) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('marketplace', 'offers/get', { site, tvOnly }),
    });
  },
  getOffer: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('marketplace', `offers/get/${id}`),
    });
  },
  addOffer: (site, data) => {
    data.site = site;
    return authedFunction({
      method: 'POST',
      url: getUrl('marketplace', 'offers/add'),
      data,
    });
  },
  editOffer: (site, id, data) => {
    data.site = site;
    data.id = id;
    return authedFunction({
      method: 'POST',
      url: getUrl('marketplace', 'offers/edit'),
      data,
    });
  },
  deleteOffer: (site, id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('marketplace', 'offers/remove'),
      data: {
        site,
        id,
      },
    });
  },
  getOfferFields: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('marketplace', 'offers/fields', { site }),
    });
  },
  getGeneralTags: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('marketplace', `tags/general`, { site }),
    });
  },
  deleteGeneralTag: (site, tag) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('marketplace', `tags/delete`),
      data: { site, tag, type: 'general' },
    });
  },
};
