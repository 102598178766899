import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { marketActions } from '../webapi';
import { offersLoaded } from '../actions';
import { getLinkedContentTypes } from '../helper';
import { DropdownInput } from './DropdownInput';
import { GenericInput } from './GenericInput';
import { Text } from './text';
import FontAwesome from 'react-fontawesome';

class LinkedContentSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: null,
      filterInput: '',
      titleInput: '',
      linkedItems: [],
    };
  }

  updateLinkedContentValidation() {
    const isValid = _.isEmpty(this.state.linkedItems) || !_.isEmpty(this.state.titleInput);
    this.props.updateValidation(isValid);
  }

  getOffers() {
    marketActions
      .getOffers(this.props.auth.site)
      .then((res) => {
        this.setState({
          loadingOffers: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.offersLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loadingOffers: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  getValidContentTypes() {
    return getLinkedContentTypes(this.props.auth.features || []);
  }

  getData = () => {
    if (_.isEmpty(this.state.linkedItems)) {
      return null;
    }
    return [
      {
        Title: this.state.titleInput,
        Items: [...this.state.linkedItems],
      },
    ];
  };

  setData = (data) => {
    if (_.isEmpty(data)) {
      return;
    }
    this.setState({
      titleInput: data[0].Title,
      linkedItems: [...data[0].Items],
    });
  };

  linkItem = (item, type) => {
    this.setState({
      linkedItems: [...this.state.linkedItems, { Type: type, Item: item }],
    });
    setTimeout(() => {
      this.updateLinkedContentValidation();
    }, 100);
  };

  unlinkItem = (index) => {
    const newLinked = [...this.state.linkedItems];
    newLinked.splice(index, 1);
    this.setState({
      linkedItems: newLinked,
    });
    setTimeout(() => {
      this.updateLinkedContentValidation();
    }, 100);
  };

  moveUp = (index) => {
    if (index === 0) return;
    const item = this.state.linkedItems[index];
    let newLinked = [...this.state.linkedItems];
    newLinked.splice(index, 1);
    newLinked.splice(index - 1, 0, item);
    this.setState({
      linkedItems: newLinked,
    });
  };

  moveDown = (index) => {
    if (index === this.state.linkedItems.length - 1) return;
    const item = this.state.linkedItems[index];
    let newLinked = [...this.state.linkedItems];
    newLinked.splice(index + 2, 0, item);
    newLinked.splice(index, 1);
    this.setState({
      linkedItems: newLinked,
    });
  };

  selectType = (type) => {
    this.setState({
      selectedType: type,
    });
    if (type === 'offers') {
      this.getOffers();
    }
  };

  getSelectedType() {
    const selectedType = _.find(this.getValidContentTypes(), (t) => {
      return t.Key === this.state.selectedType;
    });
    if (selectedType) {
      return selectedType;
    }
    return {
      Title: 'Select',
    };
  }

  renderTitleInput() {
    if (_.isEmpty(this.state.linkedItems)) {
      return null;
    }
    return (
      <GenericInput
        value={this.state.titleInput}
        id="titleInput"
        label="Title"
        placeholder="Enter a title for the section (i.e. Linked Offers)"
        onChange={(e) => {
          this.setState({ titleInput: e.target.value });
          setTimeout(() => {
            this.updateLinkedContentValidation();
          }, 100);
        }}
        alwaysShowLabel
      />
    );
  }

  renderOffer(o, isSelected, index) {
    return (
      <div
        key={o.Id}
        className={`linkedContentItem${isSelected ? ' linkedContentItem-selected' : ''}`}
        onClick={() => {
          if (!isSelected) this.linkItem(o, 'offer');
        }}
      >
        <div className="linkedContentItem_image" style={{ backgroundImage: `url(${o.Thumbnail})` }} />
        <p className="linkedContentItem_title">
          {o.Title}
          {isSelected ? ' (offer)' : ''}
        </p>
        <div className="linkedContentItem_buttons">
          {isSelected && (
            <FontAwesome
              style={{ visibility: index > 0 ? 'visible' : 'hidden' }}
              className="linkedContentItem_buttons_icon"
              name={'arrow-up'}
              onClick={() => {
                this.moveUp(index);
              }}
            />
          )}
          {isSelected && (
            <FontAwesome
              style={{ visibility: index < this.state.linkedItems.length - 1 ? 'visible' : 'hidden' }}
              className="linkedContentItem_buttons_icon"
              name={'arrow-down'}
              onClick={() => {
                this.moveDown(index);
              }}
            />
          )}
          {isSelected && (
            <FontAwesome
              className="linkedContentItem_buttons_icon"
              name={'trash'}
              onClick={() => {
                this.unlinkItem(index);
              }}
            />
          )}
        </div>
      </div>
    );
  }

  renderOffers() {
    const offersToShow = _.filter(this.props.offers, (o) => {
      if (
        _.some(this.state.linkedItems, (item) => {
          return item.Type === 'offer' && item.Item.Id === o.Id;
        })
      ) {
        //already linked
        return false;
      }
      return o.Title.toLowerCase().indexOf(this.state.filterInput.toLowerCase()) > -1;
    });
    return (
      <div>
        <GenericInput
          value={this.state.filterInput}
          id="filterInput"
          label="Filter"
          placeholder="Filter offers"
          onChange={(e) => {
            this.setState({ filterInput: e.target.value });
          }}
          alwaysShowLabel
        />

        {offersToShow.map((o) => {
          return this.renderOffer(o);
        })}
      </div>
    );
  }

  renderContentOptions() {
    if (!this.state.selectedType) {
      return null;
    }

    switch (this.state.selectedType) {
      case 'offers':
        return this.renderOffers();
      default:
        return null;
    }
  }

  renderSelectedItems() {
    if (_.isEmpty(this.state.linkedItems)) {
      return null;
    }
    return (
      <div className="marginBottom-20">
        <Text type="formTitleSmall">Selected Content</Text>
        {this.state.linkedItems.map((l, i) => {
          switch (l.Type) {
            case 'offer':
              return this.renderOffer(l.Item, true, i);
          }
        })}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderTitleInput()}
        {this.renderSelectedItems()}
        <div className={_.isEmpty(this.state.linkedItems) ? '' : 'marginTop-40'}>
          <DropdownInput
            id="contentTypeSelector"
            label="Select a content type"
            placeholder="Content Type"
            value={this.getSelectedType().Title}
            options={this.getValidContentTypes()}
            onSelect={this.selectType.bind(this)}
          />
          {this.renderContentOptions()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    offers: state.market.offers,
  };
};

const toExport = connect(mapStateToProps, { offersLoaded }, null, { withRef: true })(LinkedContentSelector);

export { toExport as LinkedContentSelector };
