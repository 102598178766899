import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import PhoneInput from 'react-phone-input-2';
import QRCode from 'qrcode.react';
import {
  Button,
  GenericInput,
  ImageInput,
  OverlayPage,
  OverlayPageBottomButtons,
  OverlayPageContents,
  OverlayPageSection,
  Popup,
  Spinner,
  SuccessPopup,
} from '../../components';
import { setNavData, updateProfile } from '../../actions';
import { Text } from '../../components/text';
import { get1400, isEmail } from '../../helper';
import { clientName, PHONE_COUNTRY, PHONE_COUNTRY_CODE, PHONE_NUMBER_MINLENGTH, requiresEmail } from '../../config';
import { userActions } from '../../webapi';
import { generateSoftwareToken, getCurrentUser } from '../../session';
import FontAwesome from 'react-fontawesome';
import 'react-phone-input-2/lib/style.css';

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: '',
      displayName: '',
      phoneNumber: '',
      email: '',
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    };
  }

  componentDidMount() {
    this.setInitialState(null, true);
    this.getAuthUser();
  }

  componentDidUpdate(prevProps) {
    this.setInitialState(prevProps);
  }

  getAuthUser = async () => {
    console.log('getting user');
    const user = await getCurrentUser();
    console.log(user);
    this.setState({
      authUser: user,
    });
  };

  getInitialUser() {
    const user = this.props && this.props.auth && this.props.auth.user ? this.props.auth.user : {};
    return user;
  }

  setInitialState(prevProps, force) {
    const user = this.getInitialUser();
    const prevUser = prevProps && prevProps.auth && prevProps.auth.user ? prevProps.auth.user : {};
    const newState = {
      loaded: true,
    };

    let anythingChanged = false;

    if (user.profilePic !== prevUser.profilePic || force) {
      newState.profilePic = user.profilePic;
      anythingChanged = true;
      this.checkSetImage(newState.profilePic);
    }

    if (user.displayName !== prevUser.displayName || force) {
      newState.displayName = user.displayName;
      anythingChanged = true;
    }

    if (user.email !== prevUser.email || force) {
      newState.email = user.email;
      anythingChanged = true;
    }

    if (user.phoneNumber !== prevUser.phoneNumber || force) {
      newState.phoneNumber = user.phoneNumber || '';
      anythingChanged = true;
    }

    if (anythingChanged) {
      this.setState(newState);
    }
  }

  checkSetImage(image) {
    if (this.refs.imageInput) {
      this.refs.imageInput.getWrappedInstance().setValue(image);
    } else {
      setTimeout(() => {
        this.checkSetImage(image);
      }, 100);
    }
  }

  setImage(image) {
    this.setState({
      profilePic: get1400(image),
    });
  }

  handleChange(event) {
    var newState = {};
    newState[event.target.getAttribute('id')] = event.target.value;
    this.setState(newState);
  }

  handleOnPhoneChange(value) {
    this.setState({ phoneNumber: `+${value}` });
  }

  validateCurrentPassword() {
    return this.state.currentPassword.length >= 6;
  }

  validateNewPassword() {
    return this.state.newPassword.length >= 6;
  }

  validateNewPasswordConfirm() {
    return this.state.newPasswordConfirm.length >= 6 && this.state.newPassword === this.state.newPasswordConfirm;
  }

  validatePasswordForm(showError) {
    if (this.state.submittingPassword) {
      return false;
    }
    if (!this.validateCurrentPassword()) {
      if (showError) this.setState({ passwordError: 'Your current password should be at least 6 characters.' });
      return false;
    }
    if (!this.validateNewPassword()) {
      if (showError) this.setState({ passwordError: 'Your new password should be at least 6 characters.' });
      return false;
    }
    if (!this.validateNewPasswordConfirm()) {
      if (showError) this.setState({ passwordError: 'You entered two different new passwords.' });
      return false;
    }
    if (showError) this.setState({ passwordError: null });
    return true;
  }

  handlePasswordSubmit = () => {
    if (!this.validatePasswordForm(true)) {
      return;
    }
    this.setState({
      submittingPassword: true,
      passwordError: '',
    });

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, this.state.currentPassword, this.state.newPassword);
      })
      .then((data) => {
        this.setState({
          submittingPassword: false,
          showPasswordSuccess: true,
          currentPassword: '',
          newPassword: '',
          newPasswordConfirm: '',
        });
      })
      .catch((err) => {
        console.log('password update fail');
        console.log(err);
        this.setState({ submittingPassword: false, passwordError: err.message });
      });
  };

  validateForm() {
    if (this.state.submitting) {
      return false;
    }
    if (_.isEmpty(this.state.displayName)) {
      return false;
    }
    if (requiresEmail && _.isEmpty(this.state.email)) {
      return false;
    }
    if (!_.isEmpty(this.state.email) && !isEmail(this.state.email)) {
      return false;
    }
    return true;
  }

  handleSubmit = () => {
    if (!this.validateForm()) {
      return;
    }
    this.setState({ submitting: true });

    const user = this.getInitialUser();

    const changes = {
      displayName: this.state.displayName,
    };

    if (!_.isEmpty(this.state.profilePic)) {
      changes.profilePic = this.state.profilePic;
    }

    changes.phoneNumber = this.state.phoneNumber;

    changes.email = this.state.email.toLowerCase();

    if (changes.email === user.email) {
      delete changes.email;
    } else if (_.isEmpty(changes.email) && _.isEmpty(user.email)) {
      delete changes.email;
    } else if (_.isEmpty(changes.email)) {
      changes.email = null;
    }

    // check if removing phoneNumber entirely.
    if (this.state.phoneNumber.length <= 3 && !_.isEmpty(user.phoneNumber)) {
      changes.phoneNumber = null;
    } else if (
      _.isEmpty(changes.phoneNumber) ||
      changes.phoneNumber.length < PHONE_NUMBER_MINLENGTH ||
      changes.phoneNumber === user.phoneNumber
    ) {
      delete changes.phoneNumber;
    } else if (!_.isEmpty(changes.phoneNumber)) {
      changes.phoneNumber = String(changes.phoneNumber).replace(/[^\d]/g, '');
      if (changes.phoneNumber.charAt(0) !== '+') {
        changes.phoneNumber = '+' + changes.phoneNumber;
      }
    }

    userActions
      .updateProfile(changes)
      .then((res) => {
        if (res.data.userCreationFail) {
          this.setState({
            error: res.data.message,
            submitting: false,
          });
          return;
        }
        this.setState({ submitting: false, success: true });
        this.props.updateProfile(changes);
      })
      .catch((err) => {
        console.log('error');
        console.log(err);
        this.setState({
          error: 'Something went wrong. Please try again.',
          submitting: false,
        });
      });
  };

  openPasswordPopup = () => {
    this.setState({
      passwordPopupOpen: true,
    });
  };

  closePasswordPopup = () => {
    this.setState({
      passwordPopupOpen: false,
      submittingPassword: false,
      showPasswordSuccess: false,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      passwordError: '',
    });
  };

  openMFAPopup = async () => {
    this.setState({
      mfaPopupOpen: true,
      loadingMFA: true,
      showMFASuccess: false,
      mfaInput: '',
    });

    const code = await generateSoftwareToken(this.state.authUser);

    this.setState({
      loadingMFA: false,
      mfaCode: code,
      mfaString: `otpauth://totp/${clientName} App:${
        this.state.authUser.attributes.email || this.state.authUser.username
      }?secret=${code}&issuer=${clientName} App`,
    });
  };

  closeMFAPopup = () => {
    this.setState({
      mfaPopupOpen: false,
      loadingMFA: false,
      showMFASuccess: false,
      mfaCode: null,
      mfaString: null,
      mfaInput: '',
    });
  };

  openRemoveMFA = async () => {
    if (window.confirm('Are you sure you want to remove two-factor authentication?')) {
      const user = this.state.authUser;
      this.setState({
        authUser: null,
      });
      await Auth.setPreferredMFA(user, 'NOMFA');

      userActions.updateProfile({ isMFAEnabled: false });

      this.getAuthUser();
    }
  };

  validateMFAForm = () => {
    if (!this.state.mfaCode) {
      return false;
    }
    if (_.isEmpty(this.state.mfaInput)) {
      return false;
    }
    return true;
  };

  handleMFASubmit = async () => {
    if (!this.validateMFAForm()) {
      return;
    }
    this.setState({
      loadingMFA: true,
    });
    try {
      // verify token
      const verificationResponse = await Auth.verifyTotpToken(this.state.authUser, this.state.mfaInput);
      if (verificationResponse && verificationResponse.Status && verificationResponse.Status === 'SUCCESS') {
        // update MFA
        const mfaResponse = await Auth.setPreferredMFA(this.state.authUser, 'TOTP');
        if (mfaResponse && verificationResponse.Status && verificationResponse.Status === 'SUCCESS') {
          this.setState({
            showMFASuccess: true,
            loadingMFA: false,
          });

          userActions.updateProfile({ isMFAEnabled: true });
          return;
        }
      }

      const error = verificationResponse && verificationResponse.message ? verificationResponse.message : 'Unknown error';
      this.setState({
        mfaError: error,
        loadingMFA: false,
      });
      return;
    } catch (e) {
      console.log(e);
      const error = e && e.message ? e.message : 'Unknown error';
      this.setState({
        mfaError: error,
        loadingMFA: false,
      });
      return;
    }
  };

  renderMFA() {
    if (!this.state.authUser) {
      return;
    }

    let content;

    switch (this.state.authUser.preferredMFA) {
      case 'SOFTWARE_TOKEN_MFA':
        content = (
          <>
            <Text type="body">
              <FontAwesome className="text-green" name="check-circle" /> Enabled
            </Text>
            <Text type="body">
              <span className="text-link font-semibold" onClick={this.openRemoveMFA}>
                Click here
              </span>{' '}
              to remove.
            </Text>
          </>
        );
        break;
      default:
        content = (
          <>
            <Text type="body">
              <FontAwesome className="text-red" name="check-circle" /> Disabled
            </Text>
            <Text type="body">
              <span className="text-link font-semibold" onClick={this.openMFAPopup}>
                Click here
              </span>{' '}
              to set up multi-factor authentication.
            </Text>
          </>
        );
        break;
    }

    return (
      <div style={{ marginBottom: 24 }}>
        <div className="fieldLabel marginBottom-5">Multi-Factor Authentication</div>
        {content}
      </div>
    );
  }

  renderAccountInfo() {
    return (
      <div className="paddingVertical-40">
        <Text type="formTitleSmall" className="marginBottom-16">
          Login Details
        </Text>
        <Text type="body" className="marginBottom-16">
          Changing this information will affect your login.
        </Text>

        <div style={{ marginBottom: 24 }}>
          <div className="fieldLabel marginBottom-5">Phone number</div>
          <PhoneInput
            id="phoneNumber"
            country={PHONE_COUNTRY}
            onlyCountries={[PHONE_COUNTRY]}
            value={this.state.phoneNumber}
            onChange={(e) => this.handleOnPhoneChange(e)}
            countryCodeEditable={false}
            disableDropdown
            masks={{ au: '... ... ...', nz: '.. ... ....' }}
          />
          <div className="genericInput-help marginTop-4">Must use {PHONE_COUNTRY_CODE} format</div>
        </div>
        <GenericInput
          id="email"
          type="text"
          label="Email"
          placeholder="user@example.com"
          value={this.state.email}
          onChange={(e) => this.handleChange(e)}
          alwaysShowLabel
        />
        <div style={{ marginBottom: 24 }}>
          <div className="fieldLabel marginBottom-5">Password</div>
          <Text type="body">
            <span className="text-link font-semibold" onClick={this.openPasswordPopup}>
              Click here
            </span>{' '}
            to change your password.
          </Text>
        </div>
        {this.renderMFA()}
      </div>
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div className="flex flex-center">
        {this.state.error != null && (
          <Text type="error" className="marginRight-24">
            {this.state.error}
          </Text>
        )}
        <div>
          <Button
            inline
            buttonType="tertiary"
            onClick={() => {
              window.history.back();
            }}
            isActive
            style={{ marginRight: 16 }}
          >
            Back
          </Button>
          <Button inline buttonType="primary" onClick={this.handleSubmit} isActive={this.validateForm()}>
            Save
          </Button>
        </div>
      </div>
    );
  }

  renderForm() {
    if (!this.state.loaded) {
      return <Spinner />;
    }
    return (
      <div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <Text type="formTitleLarge" className="marginBottom-24">
            Edit Profile
          </Text>
          <div className="flex">
            <ImageInput
              ref="imageInput"
              hasDefault={this.state.profilePic}
              containerStyle={{ width: 200, height: 200 }}
              style={{ width: 200, height: 200, borderRadius: 100, overflow: 'hidden' }}
              refreshCallback={this.setImage.bind(this)}
              simpleStyle
              noMenu
              noDownload
              disableRemove
              className="marginRight-40"
            />
            <div className="flex-1">
              <GenericInput
                id="displayName"
                type="text"
                label="Name"
                placeholder="John Doe"
                value={this.state.displayName}
                onChange={(e) => this.handleChange(e)}
                isRequired
                large
                alwaysShowLabel
              />
            </div>
          </div>
          {this.renderAccountInfo()}
        </div>
      </div>
    );
  }

  renderMFAPopup() {
    if (!this.state.mfaPopupOpen) {
      return null;
    }
    if (this.state.loadingMFA) {
      return (
        <Popup hasPadding minWidth={500}>
          <Spinner />
        </Popup>
      );
    }
    if (this.state.showMFASuccess) {
      return (
        <Popup
          title="MFA confirmed"
          hasPadding
          minWidth={500}
          buttons={[
            {
              type: 'primaryAction',
              onClick: this.closeMFAPopup,
              isActive: true,
              text: 'Done',
            },
          ]}
        />
      );
    }
    return (
      <Popup
        title="Set up MFA"
        onClose={this.closeMFAPopup}
        hasPadding
        minWidth={500}
        buttons={[
          {
            type: 'outlined',
            onClick: this.closeMFAPopup,
            isActive: true,
            text: 'Cancel',
          },
          {
            type: 'primary',
            onClick: this.handleMFASubmit,
            isActive: this.validateMFAForm(),
            text: 'Save',
          },
        ]}
      >
        {this.state.mfaString && (
          <div>
            <Text type="help">Scan the QR code using your Authenticator app. Then enter your 6 digit code to verify the connection.</Text>
            <div className="marginTop-40">
              <QRCode renderAs={'svg'} size={150} value={this.state.mfaString} />
            </div>
            <GenericInput
              id="mfaInput"
              type="text"
              label="Confirm Successful Pairing"
              placeholder="123456"
              value={this.state.mfaInput}
              onChange={(e) => this.handleChange(e)}
              className="marginTop-40"
              maxlength="6"
              isRequired
              isValid={this.validateMFAForm()}
              alwaysShowLabel
              showError={!!this.state.mfaError}
            />
          </div>
        )}
        {this.state.mfaError && <Text type="error">{this.state.mfaError}</Text>}
      </Popup>
    );
  }

  renderPasswordPopup() {
    if (!this.state.passwordPopupOpen) {
      return null;
    }
    if (this.state.submittingPassword) {
      return (
        <Popup title="Changing password" hasPadding minWidth={500}>
          <Spinner />
        </Popup>
      );
    }
    if (this.state.showPasswordSuccess) {
      return (
        <Popup
          title="Password changed"
          hasPadding
          minWidth={500}
          buttons={[
            {
              type: 'primaryAction',
              onClick: this.closePasswordPopup,
              isActive: true,
              text: 'Done',
            },
          ]}
        />
      );
    }
    return (
      <Popup
        title="Change password"
        onClose={this.closePasswordPopup}
        hasPadding
        minWidth={500}
        buttons={[
          {
            type: 'outlined',
            onClick: this.closePasswordPopup,
            isActive: true,
            text: 'Cancel',
          },
          {
            type: 'primary',
            onClick: this.handlePasswordSubmit,
            isActive: this.validatePasswordForm(),
            text: 'Save',
          },
        ]}
      >
        {this.state.passwordError && <Text type="error">{this.state.passwordError}</Text>}
        <GenericInput
          id="currentPassword"
          type="password"
          label="Current Password"
          placeholder="******"
          value={this.state.currentPassword}
          onChange={(e) => this.handleChange(e)}
          className="marginTop-40"
          isRequired
          isValid={this.validateCurrentPassword()}
          alwaysShowLabel
          showError={!!this.state.passwordError}
        />
        <GenericInput
          id="newPassword"
          type="password"
          label="New Password"
          placeholder="******"
          value={this.state.newPassword}
          onChange={(e) => this.handleChange(e)}
          className="marginTop-40"
          isRequired
          isValid={this.validateNewPassword()}
          alwaysShowLabel
          showError={!!this.state.passwordError}
        />
        <GenericInput
          id="newPasswordConfirm"
          type="password"
          label="Confirm Password"
          placeholder="******"
          value={this.state.newPasswordConfirm}
          onChange={(e) => this.handleChange(e)}
          isRequired
          isValid={this.validateNewPasswordConfirm()}
          alwaysShowLabel
          showError={!!this.state.passwordError}
        />
      </Popup>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text="Profile has been updated"
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go back',
          },
        ]}
      />
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
            {this.renderPasswordPopup()}
            {this.renderMFAPopup()}
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth, navData: state.nav };
};

export default connect(mapStateToProps, { setNavData, updateProfile })(withRouter(Profile));
