import {
    CONTACTS_LOADED,
    CONTACT_REMOVED,
    CONTACTS_PURGE
} from './types';
import { contactActions } from '../webapi';

export const contactsUpdate = (site) => {
    return (dispatch) => {
        contactActions.getContacts(site).then(
            (res) => {
                if (res.data != null) {
                    dispatch({
                        type: CONTACTS_LOADED,
                        payload: res.data
                    })
                }
            }
        );
    }
}

export const contactsLoaded = (events) => {
    return {
        type: CONTACTS_LOADED,
        payload: events
    };
};

export const removeContact = (id) => {
    return {
        type: CONTACT_REMOVED,
        payload: id
    };
};

export const clearContacts = () => {
    return {
        type: CONTACTS_PURGE
    };
};