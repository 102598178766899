import _ from 'lodash';

import {
    CONTACTS_LOADED,
    CONTACT_REMOVED,
    CONTACTS_PURGE
} from '../actions/types';

const INITIAL_STATE = {
    contacts: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CONTACTS_LOADED:
            var result = _.unionWith(action.payload, state.contacts, (v1, v2) => {
                return v1 != null && v2 != null && v1.RowId === v2.RowId;
            });
            return {
                ...state,
                contacts: result
            };
        case CONTACT_REMOVED:
            const index = _.findIndex(state.contacts, (event) => { return event != null && event.RowId === action.payload });
            if (index > -1) {
                const newEvents = [...state.contacts];
                newEvents.splice(index, 1);
                return { ...state, contacts: newEvents };
            }
            return state;
        case CONTACTS_PURGE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
