import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Button } from '../../../components';
import { capitalize } from '../../../helper';
import { checkLoggedIn, isTheBest } from '../../../session';
import { typeActions, newsletterActions, userActions, facilityActions, eventActions, serviceActions, authActions } from '../../../webapi';
import { COLOUR_GREEN } from '../../../js';
import { canCopyDummy } from '../../../config';

class PrepareDummy extends Component {
  state = {
    goowoos: null,
    news: [],
    users: 0,
    events: [],
    facilities: [],
    services: [],
    locations: [],
    site: 'Select target site',
    submitting: false,
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    this.prepareDummyStats();
    this.getSites();
  }

  getSites() {
    var self = this;
    typeActions
      .getSites()
      .then((res) => {
        self.setState({
          locations: res.data,
        });
      })
      .catch((error) => {});
  }

  selectTargetSite(key, e) {
    this.setState({
      site: key,
    });
  }

  getSiteName() {
    if (this.state.site) {
      const site = _.find(this.state.locations, (l) => {
        return l.Id === this.state.site;
      });
      if (site) {
        return capitalize(site.siteName);
      }
      return capitalize(this.state.site);
    }
    return '';
  }

  prepareDummyStats() {
    this.getNewStats();
    this.getUserStats();
    this.getEventStats();
    // this.getFacilityStats();
    this.getServicesStats();
  }

  getNewStats() {
    newsletterActions
      .getNewsletterEntries('Dummy')
      .then((res) => {
        // console.log('get news success');
        // console.log(res.data);
        this.setState({ news: res.data != null ? res.data : [] });
      })
      .catch((error) => {
        console.log(error);
        console.log('get news fail');
      });
  }
  getUserStats() {
    userActions
      .getSiteResidentsCount('Dummy')
      .then((res) => {
        // console.log('get users success');
        // console.log(res.data.results);
        this.setState({ users: res.data.results != null ? res.data.results : [] });
      })
      .catch((error) => {
        console.log(error);
        console.log('get users fail');
      });
  }
  getEventStats() {
    eventActions
      .getEvents('Dummy', 0, true)
      .then((res) => {
        // console.log('get events success');
        // console.log(res.data);
        this.setState({ events: res.data != null ? res.data : [] });
      })
      .catch((error) => {
        console.log(error);
        console.log('get events fail');
      });
  }
  getFacilityStats() {
    facilityActions
      .getFacilities('Dummy')
      .then((res) => {
        // console.log('get facilities success');
        // console.log(res.data);
        this.setState({ facilities: res.data != null ? res.data : [] });
      })
      .catch((error) => {
        console.log(error);
        console.log('get facilities fail');
      });
  }
  getServicesStats() {
    serviceActions
      .getServices('Dummy')
      .then((res) => {
        // console.log('get services success');
        // console.log(res.data);
        this.setState({ services: res.data != null ? res.data : [] });
      })
      .catch((error) => {
        console.log(error);
        console.log('get services fail');
      });
  }

  renderDummyStats() {
    return (
      <div style={{ alignItems: 'center', minWidth: 200 }}>
        <div className="fontMedium fontSize-18 text-dark" style={{ marginBottom: 8, textAlign: 'center' }}>
          Dummy Site
        </div>
        <div
          style={{
            padding: 16,
            backgroundColor: '#f4f7f9',
            borderRadius: 6,
          }}
        >
          <div>News:</div>
          <div style={{ marginBottom: 16 }}>{this.state.news.length}</div>
          <div>Users:</div>
          <div style={{ marginBottom: 16 }}>{this.state.users}</div>
          <div>Events:</div>
          <div style={{ marginBottom: 16 }}>{this.state.events.length}</div>
          {/* <div>Facilities:</div>
                    <div style={{ marginBottom: 16 }}>{this.state.facilities.length}</div> */}
          <div>Services:</div>
          <div>{this.state.services.length}</div>
        </div>
      </div>
    );
  }

  renderOptions() {
    let source = this.state.locations;
    return source.map((ev) => {
      if (ev != null && ev.active && ev.Id !== 'Dummy') {
        return (
          <MenuItem key={ev.Id} eventKey={ev.Id} active={this.state.site === ev.Id}>
            {capitalize(ev.siteName)}
          </MenuItem>
        );
      }
      return null;
    });
  }

  renderRight() {
    return (
      <div style={{ alignItems: 'center', minWidth: 200, display: 'flex', flexDirection: 'column' }}>
        <div className="fontMedium fontSize-18 text-dark" style={{ marginBottom: 8, textAlign: 'center' }}>
          Target site
        </div>
        <div
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 30,
          }}
        >
          <div
            style={{
              padding: 16,
              backgroundColor: '#f4f7f9',
              borderRadius: 6,
              alignSelf: 'center',
              width: '100%',
            }}
          >
            <div className="plussDropdown" style={{ width: '100%' }}>
              <DropdownButton
                id="secondLevelSort"
                // bsSize="xsmall"
                className="fontRegular"
                title={this.getSiteName()}
                style={{
                  width: 168,
                  backgroundColor: 'white',
                  border: 'none',
                  fontSize: 16,
                  // height: 20,
                  padding: 0,
                  margin: 0,
                  lineHeight: '20px',
                  textAlign: 'center',
                }}
                onSelect={this.selectTargetSite.bind(this)}
              >
                {this.renderOptions()}
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleSubmit() {
    if (this.state.submitting || this.state.site === 'Select target site') {
      return;
    }
    if (!isTheBest(this.props.auth, true)) {
      return;
    }
    if (window.confirm(`Are you sure you want to duplicate 'Dummy' data to '${this.state.site}'?`)) {
      this.setState({ submitting: true });
      authActions
        .copyDummy(this.state.site)
        .then((res) => {
          console.log(res.data);
          this.setState({ submitting: false, goowoos: res.data });
          console.log('^^^^^^^^^^^^^^^^^^');
        })
        .catch((res) => {
          console.log('fail gweg');
          this.setState({ submitting: false });
        });
    }
  }

  renderSubmit() {
    if (this.state.goowoos != null && this.state.goowoos.finishedSuccessfully) {
      return null;
    }
    if (this.state.submitting) {
      return <Button buttonType="primary">Working...</Button>;
    }
    return (
      <Button buttonType="primary" onClick={() => this.handleSubmit()} isActive>
        Go
      </Button>
    );
  }

  renderResponses() {
    if (this.state.goowoos == null || !this.state.goowoos.finishedSuccessfully) {
      return null;
    }

    return this.state.goowoos.results.map((ev) => {
      if (ev != null) {
        return (
          <div style={{ padding: 10, marginBottom: 10, backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: 10 }}>
            {_.map(Object.keys(ev), (key, index) => {
              return (
                <div>
                  <span>{key}:</span>
                  <span
                    style={{
                      marginLeft: 5,
                      fontWeight: 800,
                      color: typeof ev[key] === 'boolean' ? (ev[key] ? 'green' : 'red') : 'black',
                    }}
                  >
                    {ev[key].toString()}
                  </span>
                </div>
              );
            })}
          </div>
        );
      }
      return null;
    });
  }

  render() {
    if (!canCopyDummy) {
      return null;
    }
    return (
      <div style={{ width: '100%', maxWidth: 600 }}>
        <div className="fontMedium fontSize-26 text-dark" style={{ marginBottom: 8 }}>
          Copy 'Dummy' site data to new site
        </div>
        <div className="fontRegular fontSize-18 text-dark" style={{ marginBottom: 32 }}>
          This will copy the current data within the Dummy site to a new target site.
        </div>
        <div className="flex flex-between">
          {this.renderDummyStats()}
          <div
            style={{
              paddingBottom: 2,
              display: 'flex',
              alignSelf: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              height: 60,
              width: 60,
              borderRadius: 30,
              backgroundColor: '#f4f7f9',
            }}
          >
            <FontAwesome style={{ fontSize: 24, color: COLOUR_GREEN }} name={'arrow-right'} />
          </div>
          {this.renderRight()}
        </div>
        {this.state.goowoos == null && (
          <div style={{ fontSize: 16, marginTop: 24, textAlign: 'center' }}>
            Note: Users copied will be pure data copies. You will not be able to log in with these accounts. Any resident/family
            relationships will not be copied accross and will need to be re-linked.
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: 24 }}>{this.renderSubmit()}</div>
        {this.renderResponses()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(PrepareDummy);
