import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, OverlayPage, OverlayPageContents, OverlayPageSection, OverlayPageBottomButtons } from '../../components';
import { analyticsActions, eventActions } from '../../webapi';
import { checkLoggedIn, validateAccess } from '../../session';
import { safeReadParams, getPluralS } from '../../helper';
import FontAwesome from 'react-fontawesome';
import { Text } from '../../components/text';
import { CONST_STRINGS } from '../../config';

class EventAnalytics extends Component {
  initialState = {
    eventType: window.location.pathname.indexOf('/facilities') === 0 ? 'FACILITY' : 'EVENT',
    infoId: safeReadParams(this.props, 'infoId'),
    event: {},
    PageView: 0,
    ReturningPageView: 0,
    UniquePageView: 0,
    EventRegistration: 0,
    EventRegistrationFromAdmin: 0,
    EventRegistrationFromApp: 0,
    UniqueEventRegistration: 0,
    ReturningEventRegistration: 0,
    EventDeregistration: 0,
    EventDeregistrationFromAdmin: 0,
    EventDeregistrationFromApp: 0,
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!validateAccess(this.props.auth.site, 'events', this.props.auth, true)) {
        window.history.back();
      } else {
        this.getEvent();
        this.getStats();
      }
    }, 500);
  }

  getEvent() {
    const idSplit = this.state.infoId.split('_');
    eventActions
      .getEvent(idSplit[0], idSplit[1])
      .then((res) => {
        this.setState({
          event: res.data,
        });
      })
      .catch((error) => {});
  }

  getStats() {
    analyticsActions.getEntityStats(this.state.infoId, 'event').then((res) => {
      console.log(res.data);
      this.setState({ ...res.data, statsLoaded: true });
    });
  }

  renderEventSpecificStats() {
    if (this.state.eventType === 'FACILITY') {
      return null;
    }
    return (
      <div>
        <Text type="formTitleSmall" className="marginTop-32 marginBottom-16">
          Event Attendance
        </Text>
        <div className="polloverview">
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="calendar-check-o" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.EventRegistration}</p>
              <p className="polloverview_text">
                Event
                <br />
                Registration{getPluralS(this.state.EventRegistration)}
              </p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="calendar-times-o" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.EventDeregistration}</p>
              <p className="polloverview_text">Cancelled Event Registration{getPluralS(this.state.EventDeregistration)}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="user" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.UniqueEventRegistration}</p>
              <p className="polloverview_text">First-time Event Registrant{getPluralS(this.state.UniqueEventRegistration)}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="repeat" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.ReturningEventRegistration}</p>
              <p className="polloverview_text">Returning Event Registrant{getPluralS(this.state.ReturningEventRegistration)}</p>
            </div>
          </div>
        </div>
        <Text type="formTitleSmall" className="marginTop-32 marginBottom-16">
          App vs Community Manager
        </Text>
        <div className="polloverview">
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="mobile" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.EventRegistrationFromApp}</p>
              <p className="polloverview_text">
                Registration{getPluralS(this.state.EventRegistrationFromApp)}
                <br />
                From App
              </p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="laptop" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.EventRegistrationFromAdmin}</p>
              <p className="polloverview_text">
                Registration{getPluralS(this.state.EventRegistrationFromAdmin)}
                <br />
                From Web
              </p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="mobile" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.EventDeregistrationFromApp}</p>
              <p className="polloverview_text">
                Cancellation{getPluralS(this.state.EventDeregistrationFromApp)}
                <br />
                From App
              </p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="laptop" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.EventDeregistrationFromAdmin}</p>
              <p className="polloverview_text">
                Cancellation{getPluralS(this.state.EventDeregistrationFromAdmin)}
                <br />
                From Web
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOverview() {
    if (!this.state.statsLoaded) {
      return null;
    }
    return (
      <div>
        <Text type="formTitleSmall" className="marginBottom-16">
          Page Views
        </Text>
        <div className="polloverview">
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="eye" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.PageView}</p>
              <p className="polloverview_text">Page View{getPluralS(this.state.PageView)}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="user" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.UniquePageView}</p>
              <p className="polloverview_text">Unique Visitor{getPluralS(this.state.UniquePageView)}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="repeat" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.ReturningPageView}</p>
              <p className="polloverview_text">Returning Visitor{getPluralS(this.state.ReturningPageView)}</p>
            </div>
          </div>
        </div>
        {this.renderEventSpecificStats()}
      </div>
    );
  }

  renderBack() {
    return (
      <div>
        <Button
          inline
          buttonType="primary"
          onClick={() => {
            window.history.back();
          }}
          isActive
        >
          Back to {this.state.eventType === 'EVENT' ? 'Events' : CONST_STRINGS.FACILITIES}
        </Button>
      </div>
    );
  }

  renderMain() {
    return (
      <div className="padding-60 paddingVertical-40">
        <Text type="formTitleLarge" className="marginBottom-24">
          {this.state.event.Title}
        </Text>
        {this.renderOverview()}
      </div>
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents id="EventContainer" noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--fixedPopupSize">{this.renderMain()}</OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderBack()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(EventAnalytics);
