import _ from 'lodash';
import { FORMS_LOADED, FORM_REMOVED, FORM_UPDATED, FORM_SUBMISSIONS_LOADED } from '../actions/types';

const INITIAL_STATE = {
  list: [],
  submissions: [],
};

export default (state = INITIAL_STATE, action) => {
  let index, list, submissions;
  switch (action.type) {
    case FORMS_LOADED:
      list = _.unionWith(action.payload, state.list, (v1, v2) => {
        return v1 != null && v2 != null && v1.Id === v2.Id;
      }).filter((form) => !form.Deleted);

      // Order forms by order number
      list = _.orderBy(list, 'Order');
      if (list.some((item) => !_.isNil(item.Order))) {
        list = list.map((item, index) => {
          return { ...item, Order: index };
        });
      }

      return { ...state, list };
    case FORM_REMOVED:
      index = _.findIndex(state.list, (form) => {
        return form != null && form.RowId === action.payload;
      });
      if (index > -1) {
        list = [...state.list];
        list.splice(index, 1);
        return { ...state, list };
      }
      return state;
    case FORM_UPDATED:
      list = _.unionWith([action.payload], state.list, (v1, v2) => {
        return v1 != null && v2 != null && v1.Id === v2.Id;
      });
      return { ...state, list };
    case FORM_SUBMISSIONS_LOADED:
      submissions = _.unionWith(action.payload, state.submissions, (v1, v2) => {
        return v1 != null && v2 != null && v1.Id === v2.Id;
      });
      return { ...state, submissions };
    default:
      return state;
  }
};
