import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Lottie } from '@crello/react-lottie';
import { Button, CheckBox, Header, Popup, SVGIcon } from '../../../components';
import { isEmail } from '../../../helper';
import { COLOUR_BRANDING_ACTION } from '../../../js';
import { authActions, typeActions } from '../../../webapi';
import { setAuthUser, setNavData } from '../../../actions';
import communityTemplates from '../../../json/site/communityTemplates.json';
import { unauthedFunction } from '../../../session';

class SiteSignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      showPageErrors: false,
      fullNameInput: '',
      orgInput: '',
      emailInput: '',
      passwordInput: '',
      submitting: false,
      selectedTemplate: null,
      lottieConfig: {
        path: 'https://pluss-prd-media.s3-ap-southeast-2.amazonaws.com/assets/config-lottie.json',
        loop: true,
        name: 'config-lottie',
      },
    };
  }

  componentDidMount() {
    this.getLottieConfig();
  }

  getLottieConfig() {
    unauthedFunction({
      method: 'GET',
      url: this.state.lottieConfig.path,
    }).then((res) => {
      this.setState({
        lottieConfig: {
          ...this.state.lottieConfig,
          path: undefined,
          animationData: res.data,
        },
      });
    });
  }

  nextPage = () => {
    if (!this.validateAccountPage()) {
      this.setState({
        showPageErrors: true,
      });
      return;
    }
    this.setState({
      page: 1,
      showPageErrors: false,
    });
  };

  closeErrorPopup = () => {
    this.setState({
      errorMessage: null,
    });
  };

  fakeSubmit = () => {
    this.setState({
      submitting: true,
    });
    setTimeout(() => {
      this.setState({
        signingIn: true,
      });
      Auth.signIn('suttr@muspelheim.com', 'testing').then((result) => {
        const userId = result.username;

        authActions.checkUserAuth(userId).then((res) => {
          authActions.getUserSignin().then((userRes) => {
            if (res.data.type != null) {
              this.props.setAuthUser(res.data.type, { ...userRes.data, uid: userRes.data.Id }, res.data.site);
              this.props.setNavData({ signUpSuccess: true });
              setTimeout(() => {
                this.props.history.push(`/`);
              }, 50);
            }
          });
        });
      });
    }, 2000);
  };

  submit = () => {
    // return this.fakeSubmit();
    if (!this.validateTemplatePage()) {
      this.setState({
        showPageErrors: true,
      });
      return;
    }
    const site = {
      siteName: this.state.orgInput,
      Info: {
        Size: this.state.orgSize,
        Type: this.state.selectedTemplate.key,
      },
    };
    const lastName = _.last(this.state.fullNameInput.split(' '));
    const user = {
      email: this.state.emailInput.toLowerCase(),
      password: this.state.passwordInput,
      displayName: this.state.fullNameInput,
      firstName:
        this.state.fullNameInput.length === lastName.length
          ? this.state.fullNameInput
          : this.state.fullNameInput.substring(0, this.state.fullNameInput.length - lastName.length),
      lastName: lastName,
    };
    this.setState({
      submitting: true,
    });
    typeActions
      .signUpNewSite(site, user)
      .then((res) => {
        if (res && res.data && res.data.userCreationFail) {
          this.setState({
            submitting: false,
            errorMessage: res.data.message,
            page: 0,
            wrongEmail: res.data.email ? user.email : null,
            showPageErrors: true,
          });
          return;
        }
        this.setState({
          signingIn: true,
        });
        Auth.signIn(user.email, user.password).then((result) => {
          const userId = result.username;

          authActions.checkUserAuth(userId).then((res) => {
            authActions.getUserSignin().then((userRes) => {
              if (res.data.type != null) {
                this.props.setAuthUser(res.data.type, { ...userRes.data, uid: userRes.data.Id }, res.data.site);
                this.props.setNavData({ signUpSuccess: true });
                setTimeout(() => {
                  this.props.history.push(`/`);
                }, 50);
              }
            });
          });
        });
      })
      .catch((err) => {
        this.setState({ submitting: false });
      });
  };

  handleTextChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  selectTemplate = (t) => {
    this.setState({
      selectedTemplate: t,
    });
  };

  validateAccountPage() {
    return (
      this.validateInput('passwordInput') &&
      this.validateInput('emailInput') &&
      this.validateInput('fullNameInput') &&
      this.validateInput('orgInput') &&
      this.validateInput('orgSize')
    );
  }

  validateTemplatePage() {
    return !!this.state.selectedTemplate && !this.state.submitting;
  }

  validateMultiChoice(id) {
    return !_.isEmpty(this.state[id]);
  }

  validateInput(key) {
    switch (key) {
      case 'passwordInput':
        return this.state.passwordInput.length >= 6;
      case 'emailInput':
        if (this.state.wrongEmail && this.state.wrongEmail === this.state.emailInput) {
          return false;
        }
        return isEmail(this.state.emailInput);
      default:
        return !_.isEmpty(this.state[key]);
    }
  }

  getValidationClass(key) {
    if (this.validateInput(key)) {
      return 'signUp_input-valid';
    }
    if (this.state.showPageErrors) {
      return 'signUp_input-error';
    }
    return '';
  }

  renderInput(id, labelText, type, disabled, containerClass) {
    return (
      <div key={id} className={`signUp_inputContainer ${containerClass} ${disabled ? 'signUp_inputContainer-disabled' : ''}`}>
        <label className="signUp_inputLabel" htmlFor={id}>
          {labelText}
        </label>
        <div className={`signUp_input ${this.getValidationClass(id)}`}>
          <div className="signUp_input_validation"></div>
          <input
            className="signUp_input_text"
            onChange={(e) => {
              this.handleTextChange(e);
            }}
            value={this.state[id]}
            id={id}
            type={type || 'text'}
            autoComplete="new-password"
            maxLength="50"
            disabled={disabled}
          />
        </div>
      </div>
    );
  }

  renderMultiChoice(id, labelText, options) {
    return (
      <div className="signUp_inputContainer">
        <label className="signUp_inputLabel signUp_inputLabel-dropdown" htmlFor={id}>
          {labelText}
        </label>
        <div className="signUp_multi">
          {options.map((o) => {
            return (
              <CheckBox
                className="signUp_multi_option checkbox-updated"
                label={o}
                isActive={this.state[id] === o}
                onChange={() => {
                  this.setState({ [id]: o });
                }}
                showWarning={this.state.showPageErrors && !this.validateMultiChoice(id)}
              />
            );
          })}
        </div>
      </div>
    );
  }

  renderAccountPage() {
    return (
      <div className="flex flex-center-row relative paddingTop-header">
        <div className="container600">
          <div className="signUp_account">
            <p className="signUp_title">You're the community builder - not us!</p>
            <p className="signUp_subtitle">A bit of info</p>
            <div className="paddingVertical-20">
              {this.renderInput('fullNameInput', 'Full Name')}
              {this.renderInput('emailInput', 'E-mail')}
              {this.renderInput('passwordInput', 'Password', 'password')}
              {this.renderInput('orgInput', 'Organisation Name')}
              {this.renderMultiChoice('orgSize', 'How many people do you see using this app?', ['1-10', '11-50', '51-200', '>200'])}
            </div>
          </div>
          <div className="signUp_bottom">
            <Button buttonType="primary" className="button--signup" onClick={this.nextPage} isActive={this.validateAccountPage()}>
              Next step
            </Button>
          </div>
        </div>
      </div>
    );
  }

  renderTemplate(t, i) {
    const hasSelection = !!this.state.selectedTemplate;
    let left = (i % 3) * 160;
    let top = Math.floor(i / 3) * 160;

    let extraClasses = '';
    if (hasSelection) {
      extraClasses += ' signUp_template-hasSelection';

      const isSelected = this.state.selectedTemplate === t;
      if (isSelected) {
        extraClasses += ' signUp_template-isSelected';
        left = 0;
        top = 0;
      }
    }
    return (
      <div
        className={`signUp_template signUp_template-inGrid${extraClasses}`}
        key={t.key}
        onClick={() => {
          if (hasSelection) return;
          this.selectTemplate(t);
        }}
        style={{ transform: `translate(${left}px, ${top}px)` }}
      >
        <div className="signUp_template_hover"></div>
        <div className="signUp_template_inner">
          <SVGIcon className="signUp_template_icon" icon={t.icon} colour={COLOUR_BRANDING_ACTION} />
          <p className="signUp_template_title">{t.title}</p>
          {t.hasAward && <p className="signUp_template_award">Award Winning</p>}
        </div>
      </div>
    );
  }

  renderFeature(f) {
    return (
      <div className="signUp_feature" key={f.key}>
        {f.title}
      </div>
    );
  }

  renderSelectedTemplatePage() {
    return (
      <div className="flex flex-center-row relative">
        <div className="container1200 paddingTop-header marginTop-60 flex">
          {this.renderPreview()}
          <div className="container600">
            <div className="signUp_account signUp_account-noTop">
              <p className="signUp_title">A great example</p>
              <p className="signUp_subtitle">&nbsp;</p>
              {this.renderTemplates()}
              <div className="paddingVertical-20">
                <p className="signUp_featureTitle">Features including</p>
                {this.state.selectedTemplate.features.map((f) => {
                  return this.renderFeature(f);
                })}
              </div>
            </div>
            <div className="signUp_bottom">
              <Button buttonType="primary" className="button--signup" onClick={this.submit} isActive={this.validateTemplatePage()}>
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTemplates() {
    const height = !!this.state.selectedTemplate ? 160 : Math.ceil(communityTemplates.length / 3) * 160;
    return (
      <div className="paddingVertical-20">
        <div style={{ height }}>
          {communityTemplates.map((t, i) => {
            return this.renderTemplate(t, i);
          })}
        </div>
        {this.state.selectedTemplate && (
          <p
            className="signUp_changeTemplate"
            onClick={() => {
              this.selectTemplate();
            }}
          >
            Change Selection
          </p>
        )}
      </div>
    );
  }

  renderTemplatesPage() {
    return (
      <div className="flex flex-center-row relative">
        <div className="container1200 paddingTop-header marginTop-60 flex">
          {this.renderPreview()}
          <div className="container600">
            <div className="signUp_account signUp_account-noTop">
              <p className="signUp_title">You can still use one of our templates</p>
              <p className="signUp_subtitle">Pre built for speed</p>
              {this.renderTemplates()}
              <div className="paddingVertical-20">
                <p className="signUp_title">or</p>
                <div
                  className="signUp_template signUp_template-byo"
                  onClick={() => {
                    console.log('TODO');
                  }}
                >
                  <div className="signUp_template_background"></div>
                  <div className="signUp_template_inner">
                    <SVGIcon className="signUp_template_icon" icon="services6" colour="#fff" />
                    <p className="signUp_template_title">Build your own</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPreview() {
    return (
      <div className="featurePickerPreview">
        <div className="featurePickerPreview_inner">
          {this.state.selectedTemplate ? <p>{this.state.selectedTemplate.title} template goes here</p> : <p>Select a template</p>}
        </div>
      </div>
    );
  }

  renderPage() {
    switch (this.state.page) {
      case 0:
        return this.renderAccountPage();
      case 1:
        if (this.state.selectedTemplate) {
          return this.renderSelectedTemplatePage();
        }
        return this.renderTemplatesPage();
      default:
        return null;
    }
  }

  renderError() {
    if (_.isEmpty(this.state.errorMessage)) {
      return null;
    }
    return (
      <div className="popup popup-error">
        <div className="popup_box flex flex-column flex-center">
          <p className="signUp_subtitle">{this.state.errorMessage}</p>
          <Button buttonType="outlinedAction" className="button--signup marginTop-30" onClick={this.closeErrorPopup} isActive={true}>
            Close
          </Button>
        </div>
      </div>
    );
  }

  renderSubmitting() {
    if (!this.state.submitting) {
      return null;
    }
    return (
      <Popup>
        <Lottie key="config-lottie" height="400px" width="700px" config={this.state.lottieConfig} />
        <p className="signUp_lottieText">{this.state.signingIn ? 'Configuring app' : 'Creating profile'}</p>
      </Popup>
    );
  }

  render() {
    return (
      <div className="pageContainer signUpContainer" onClick={this.closeColourPicker}>
        <Header fixed simple />
        {this.renderPage()}
        {this.renderError()}
        {this.renderSubmitting()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, { setAuthUser, setNavData })(withRouter(SiteSignUp));
