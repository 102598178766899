import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Lottie } from '@crello/react-lottie';
import _ from 'lodash';
import { unauthedFunction } from '../session';
import { Popup } from './Popup';
import { GenericInput } from './GenericInput';
import { Text } from './text';
import { Button } from './Button';
import { DropdownInput } from './DropdownInput';
import { typeActions, utilityActions } from '../webapi';
import { logo } from '../config';
import { COLOUR_BRANDING_APP } from '../js';
import { getPluralOptions } from '../helper';

class MakerPopup extends Component {
  constructor(props) {
    super(props);

    this.sizes = [
      { Key: 'A3', Title: 'A3' },
      { Key: 'A4', Title: 'A4' },
      { Key: 'A5', Title: 'A5' },
    ];
    this.formats = [
      { Key: 'pdf', Title: 'PDF' },
      { Key: 'png', Title: 'PNG' },
      { Key: 'jpg', Title: 'JPEG' },
    ];

    this.state = {
      ...this.props.initialData,
      logo: null,
      colour: null,
      submitting: false,
      loadingBranding: true,
      asset: null,
      selectedSize: 'A4',
      selectedFormat: 'pdf',
      lottieConfig: {
        path: 'https://pluss-prd-media.s3-ap-southeast-2.amazonaws.com/assets/lottie-layout.json',
        loop: true,
        name: 'lottie-layout',
      },
    };
  }

  componentDidMount() {
    this.getBranding();
    this.getLottieConfig();
  }

  getBranding() {
    typeActions.getSiteBranding(this.props.auth.site).then((res) => {
      console.log(res.data);
      this.setState({
        loadingBranding: false,
        siteName: res.data.Name,
        colour: res.data.MainBrandingColour || COLOUR_BRANDING_APP,
        logo: res.data.Logo || logo,
        headerType: res.data.HeaderType || 'white',
      });
    });
  }

  getLottieConfig() {
    unauthedFunction({
      method: 'GET',
      url: this.state.lottieConfig.path,
    }).then((res) => {
      this.setState({
        lottieConfig: {
          ...this.state.lottieConfig,
          path: undefined,
          animationData: res.data,
        },
      });
    });
  }

  handleChange(key, value) {
    this.setState({ [key]: value });
  }

  selectSize = (size) => {
    this.setState({
      selectedSize: size,
    });
  };

  getSelectedSizeTitle = () => {
    return _.find(this.sizes, (f) => {
      return f.Key === this.state.selectedSize;
    }).Title;
  };

  selectFormat = (format) => {
    this.setState({
      selectedFormat: format,
    });
  };

  getSelectedFormatTitle = () => {
    return _.find(this.formats, (f) => {
      return f.Key === this.state.selectedFormat;
    }).Title;
  };

  compileData = () => {
    const result = {};
    this.props.inputs.forEach((input) => {
      result[input.key] = this.state[input.key];
    });
    result.colour = this.state.colour;
    result.logo = this.state.logo;
    result.headerType = this.state.headerType;
    return { ...result, ...this.props.requestData };
  };

  compileRequestData = () => {
    const result = {};

    if (!this.props.hideFormat) {
      result.format = this.state.selectedFormat;
    }
    if (!this.props.hideSize) {
      result.size = this.state.selectedSize;
    }
    return { ...result, ...this.props.requestData, data: this.compileData() };
  };

  isReadyToSubmit = () => {
    if (this.state.loadingBranding) {
      return false;
    }
    if (this.state.submitting) {
      return false;
    }
    return true;
  };

  onGenerateAsset = () => {
    if (!this.isReadyToSubmit()) {
      return;
    }
    const data = this.compileRequestData();
    this.setState({
      submitting: true,
    });

    utilityActions
      .generateMake(this.props.templateId, data)
      .then((res) => {
        this.setState({
          submitting: false,
          asset: res.data.resultUrl,
        });
      })
      .catch((err) => {
        this.setState({
          submitting: false,
        });
      });
  };

  downloadAsset = () => {
    window.open(this.state.asset, '_blank');
  };

  getButtons() {
    if (this.state.submitting) {
      return [];
    }
    if (this.state.asset) {
      return [
        {
          type: 'tertiary',
          onClick: this.props.onClose,
          isActive: true,
          text: 'Done',
        },
      ];
    }
    return [
      {
        type: 'primary',
        onClick: this.onGenerateAsset,
        isActive: this.isReadyToSubmit(),
        text: 'Create',
      },
      {
        type: 'tertiary',
        onClick: this.props.onClose,
        isActive: true,
        text: 'Cancel',
      },
    ];
  }

  renderInput(input) {
    if (input.type === 'array') {
      return (
        <div className="marginTop-16">
          <Text type="formLabel">{input.title}</Text>
          <Text type="body">
            {this.state[input.key].length} {getPluralOptions(this.state[input.key].length, 'entry', 'entries')}
          </Text>
        </div>
      );
    }
    return (
      <GenericInput
        key={input.key}
        id={`maker_${input.key}`}
        type={input.type}
        label={input.title}
        value={this.state[input.key]}
        onChange={(e) => this.setState({ [input.key]: e.target.value })}
        alwaysShowLabel
        disabled={input.readOnly}
        placeholder={input.placeholder}
      />
    );
  }

  renderContent() {
    if (this.state.submitting) {
      return (
        <div className="flex flex-column flex-center">
          <Lottie key="lottie-layout" height="260px" width="260px" config={this.state.lottieConfig} />
          <Text type="h3" className="marginTop-16">
            Generating
          </Text>
        </div>
      );
    }
    if (this.state.asset) {
      return (
        <div>
          <Text type="h4">Your file is ready</Text>
          <Button leftIcon="download" buttonType="primary" onClick={this.downloadAsset} isActive className="marginTop-16">
            Download
          </Button>
        </div>
      );
    }
    return (
      <div>
        {!this.props.hideSize && (
          <DropdownInput
            id="size"
            label="Size"
            placeholder="Size"
            value={this.getSelectedSizeTitle()}
            options={this.sizes}
            onSelect={this.selectSize}
          />
        )}
        {!this.props.hideFormat && (
          <DropdownInput
            id="format"
            label="Format"
            placeholder="Format"
            value={this.getSelectedFormatTitle()}
            options={this.formats}
            onSelect={this.selectFormat}
          />
        )}
        {this.props.inputs.map((input) => {
          return this.renderInput(input);
        })}
      </div>
    );
  }

  render() {
    return (
      <Popup
        title={this.props.title}
        buttons={this.getButtons()}
        onClose={this.props.onClose}
        hasPadding
        minWidth={this.props.minWidth}
        maxWidth={this.props.maxWidth}
        minHeight={this.props.minHeight}
        maxHeight={this.props.maxHeight}
      >
        {this.renderContent()}
      </Popup>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

let exportObj = connect(mapStateToProps, {})(MakerPopup);

export { exportObj as MakerPopup };
