import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { usersUpdate } from '../../actions';
import {
  GenericInput,
  Button,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  SuccessPopup,
} from '../../components';
import { userActions } from '../../webapi';
import { checkLoggedIn, validateAccess } from '../../session';
import { safeReadParams } from '../../helper';

class Kiosk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: '',

      uploadingImage: false,

      submitting: false,
      success: false,

      PageError: null,

      showWarnings: false,

      tempUser: {},
    };
  }

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    if (!validateAccess(this.props.auth.site, 'userManagement', this.props.auth, true)) {
      this.props.history.push('/mastermenu');
    } else {
      const userId = decodeURIComponent(safeReadParams(this.props, 'userId'));
      if (userId != null) {
        this.setState({ userId });
        this.fetchUser(userId);
      }
    }
  }

  fetchUser(userId) {
    this.setState({ loading: true, loadError: false });
    userActions
      .fetchUser(this.props.auth.site, userId)
      .then((res) => {
        this.setState({
          userEmail: res.data.user.email,
          loading: false,
          loadError: false,
        });
      })
      .catch((error) => {
        console.log(error);
        console.log('fail on action call');
        this.setState({ loading: false, loadError: true });
      });
  }

  isEmail(email) {
    const atpos = email.indexOf('@');
    const dotpos = email.lastIndexOf('.');
    if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= email.length) {
      return false;
    }
    return true;
  }

  validateEmail() {
    return !_.isEmpty(this.state.userEmail) && this.isEmail(this.state.userEmail);
  }

  validateForm() {
    if (!this.validateEmail()) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    if (this.state.submitting) {
      return;
    }

    this.setState({ showWarnings: false, submitting: true, PageError: null });

    var obj = {
      email: this.state.userEmail,
      displayName: this.state.userEmail,
    };
    userActions
      .updateUser(this.props.auth.site, this.state.userId, obj)
      .then((res) => {
        if ((!_.isUndefined(res.data.success) && !res.data.success) || res.data.userCreationFail) {
          console.log('FAIL');
          console.log(res.data);
          if (res.data.message) {
            this.setState({ submitting: false, PageError: res.data.message });
          } else {
            this.setState({ submitting: false, PageError: 'Error on user edit' });
          }
        } else {
          this.setState({
            success: true,
            submitting: false,
            tempUser: res.data.user,
          });
          this.props.usersUpdate(this.props.auth.site);
        }
      })
      .catch((error) => {
        this.setState({ submitting: false, PageError: 'Something went wrong. Please try again.' });
        console.log(error);
      });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  renderForm() {
    return (
      <div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <p className="text-sectionTitle">EDIT KIOSK LOGIN</p>
          <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
          <GenericInput
            id="userEmail"
            type="text"
            label="Email"
            placeholder="Email"
            value={this.state.userEmail}
            onChange={(e) => this.handleChange(e)}
            showError={() => {
              return this.state.showWarnings && !this.validateEmail();
            }}
            errorMessage="Not a valid email"
            autoComplete={false}
          />
        </div>
      </div>
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateForm()}>
          Save
        </Button>
      </div>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text="Kiosk login has been edited"
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  onBackPress() {
    if (this.state.submitting) {
      return;
    }
    this.props.onBack(null);
  }

  renderError() {
    if (this.state.PageError) {
      return (
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <div style={{ fontWeight: 'bold', color: 'red', fontSize: 20 }}>{this.state.PageError}</div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <OverlayPage>
        {this.renderSuccess()}
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            <div style={{ marginBottom: 60 }}>
              {!this.state.success && this.renderForm()}
              {this.renderError()}
            </div>
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { usersUpdate })(withRouter(Kiosk));
