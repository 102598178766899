import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { utilityActions } from '../../webapi';
import UpvotyWidget from '../../components/upvoty';
import { Header, Tabs } from '../../components';

class Upvoty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      upvotyToken: null,
      boardhash: undefined,
      roadmapHash: undefined,
      hasLoadedBoard: false,
      hasBoard: true,
      loading: true,
    };
  }

  componentDidMount() {
    this.loadToken();
    this.loadBoardHash();
  }

  loadToken() {
    utilityActions.getUpvotyToken().then((res) => {
      this.setState({
        upvotyToken: res.data,
      });
    });
  }

  loadBoardHash() {
    utilityActions.getBoardHash().then((res) => {
      const newState = {
        boardhash: res.data,
        hasLoadedBoard: true,
        hasBoard: !_.isEmpty(res.data),
      };
      if (this.props.embedType === 'board') {
        newState.loading = false;

        if (_.isEmpty(res.data)) {
          //no board for this environment, so redirect
          this.selectTab('roadmap');
        }
      }
      this.setState(newState);
    });

    if (this.props.embedType === 'roadmap') {
      utilityActions.getRoadmapHash().then((res) => {
        this.setState({
          roadmapHash: res.data,
          loading: false,
        });
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  selectTab = (tab) => {
    this.props.history.push(`/${tab === 'board' ? 'improvements' : tab}`);
  };

  getBoardHash() {
    if (this.props.embedType === 'changelog') {
      return undefined;
    }
    if (this.props.embedType === 'roadmap') {
      return this.state.roadmapHash;
    }
    return this.state.boardhash;
  }

  getTabs() {
    const result = [
      {
        value: 'roadmap',
        text: 'Roadmap',
      },
      {
        value: 'changelog',
        text: 'Release Notes',
      },
    ];
    if (this.state.hasBoard) {
      result.unshift({
        value: 'board',
        text: 'Improvements',
      });
    }
    return result;
  }

  renderContent() {
    if (!this.state.upvotyToken || this.state.loading) {
      return (
        <div className="paddingVertical-40 flex flex-row flex-center-row">
          <FontAwesome className="spinner" name="spinner fa-pulse fa-fw" />
        </div>
      );
    }
    return (
      <UpvotyWidget
        ssoToken={this.state.upvotyToken}
        baseUrl="pluss.upvoty.com"
        boardHash={this.getBoardHash()}
        startPage={this.props.startPage}
        fixedLayout={this.props.fixedLayout}
      />
    );
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100%', maxHeight: '100vh', overflow: 'hidden' }}>
        <Header />
        <div className="paddingVertical-20 paddingHorizontal-40" style={{ visibility: this.state.hasLoadedBoard ? 'visible' : 'hidden' }}>
          <Tabs onSelectTab={this.selectTab} selectedTab={this.props.embedType} tabs={this.getTabs()} />
        </div>
        <div className="paddingLeft-40 flex-1 overflowY-auto">{this.renderContent()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(withRouter(Upvoty));
