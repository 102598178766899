import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import FontAwesome from 'react-fontawesome';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { usersLoaded } from '../../actions';
import { checkLoggedIn, validateAccess } from '../../session';
import { analyticsActions } from '../../webapi';
import { getPluralS } from '../../helper';
import { UTC_OFFSET } from '../../config';
import { compileStats, getInsight, getAnalyticsFilterOptions } from '../../js';
import { AnalyticsFilter } from '../../components';
import { Text } from '../../components/text';

class EventHubAnalytics extends Component {
  constructor(props) {
    super(props);
    const filterOptions = getAnalyticsFilterOptions();
    const selectedFilter = filterOptions[1];
    this.state = {
      loading: true,
      stats: {},
      totalStats: {},
      previousStats: null,
      selectedFilter: filterOptions[1],
      currentDayCount: selectedFilter.dayCount,
    };
  }

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    if (!validateAccess(this.props.auth.site, 'events', this.props.auth, true)) {
      this.props.history.push('/mastermenu');
    } else {
      this.getStats();
    }
  }

  getTotalStat(key) {
    if (!this.state.totalStats[key]) {
      return 0;
    }
    if (Array.isArray(this.state.totalStats[key])) {
      return this.state.totalStats[key].length;
    }
    return this.state.totalStats[key];
  }

  getStat(id, key) {
    if (!this.state.stats[id]) {
      return 0;
    }
    if (!this.state.stats[id][key]) {
      return 0;
    }
    if (Array.isArray(this.state.stats[id][key])) {
      return this.state.stats[id][key].length;
    }
    return this.state.stats[id][key];
  }

  getStats(min, max) {
    this.setState({
      loading: true,
    });
    const lastDay = max ? moment(max).utc() : moment.utc();
    const dayCount = min ? moment(max).diff(moment(min), 'days') : this.state.selectedFilter.dayCount;
    console.log(`getStats: lastDay:${moment(lastDay).format('DD/MM/YYYY')}, dayCount:${dayCount}`);

    const startOfLastDay = lastDay.utcOffset(UTC_OFFSET).startOf('d');
    const minTime = moment(startOfLastDay).add(-dayCount, 'd').valueOf() - 1;
    const maxTime = moment(startOfLastDay).valueOf() - 1;

    analyticsActions.getAggregateEntityStats(this.props.auth.site, 'event', minTime, maxTime).then((res) => {
      console.log(res.data);
      console.log(compileStats(res.data));
      this.setState({
        stats: res.data,
        totalStats: compileStats(res.data),
        loading: false,
        currentDayCount: dayCount,
      });
      const prevMinTime =
        moment(startOfLastDay)
          .add(2 * -dayCount, 'd')
          .valueOf() - 1;
      analyticsActions.getAggregateEntityStats(this.props.auth.site, 'event', prevMinTime, minTime).then((oldRes) => {
        console.log(oldRes.data);
        console.log(compileStats(oldRes.data));
        this.setState({
          previousStats: oldRes.data,
          previousTotalStats: compileStats(oldRes.data),
        });
      });
    });
  }

  filterChanged = (selectedFilter) => {
    this.setState({ selectedFilter }, () => {
      this.getStats();
    });
  };

  filterDateRangeChanged = (startDate, endDate) => {
    this.getStats(startDate, endDate);
  };

  renderStats() {
    if (this.state.loading) {
      return null;
    }
    return (
      <div className="eventanalytics--overview paddingTop-40">
        <Text type="formTitleSmall" className="marginBottom-16">
          Overview
        </Text>
        <div className="polloverview polloverview--eventAnalytics">
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="eye" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.getTotalStat('PageView')}</p>
              <p className="polloverview_text">Page View{getPluralS(this.getTotalStat('PageView'))}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="user" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.getTotalStat('UniquePageView')}</p>
              <p className="polloverview_text">Unique Visitor{getPluralS(this.getTotalStat('UniquePageView'))}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="undo" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.getTotalStat('PageView') - this.getTotalStat('UniquePageView')}</p>
              <p className="polloverview_text">
                Returning Visitor{getPluralS(this.getTotalStat('PageView') - this.getTotalStat('UniquePageView'))}
              </p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="calendar-check-o" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.getTotalStat('EventRegistration')}</p>
              <p className="polloverview_text">
                Event
                <br />
                Registration{getPluralS(this.getTotalStat('EventRegistration'))}
              </p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="calendar-times-o" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.getTotalStat('EventDeregistration')}</p>
              <p className="polloverview_text">Cancelled Event Registration{getPluralS(this.getTotalStat('EventDeregistration'))}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInsight(event, title, primaryStat) {
    if (!event || !event.Entity || !event.Stats) {
      return null;
    }
    const currentStat = event.Stats && event.Stats[primaryStat] ? event.Stats[primaryStat] : 0;
    const prevStat = event.PreviousStats && event.PreviousStats[primaryStat] ? event.PreviousStats[primaryStat] : 0;
    const change = currentStat - prevStat;
    const prevText = this.state.selectedFilter.prevText.replace('*', this.state.currentDayCount);

    return (
      <div className="insights">
        <div className="insightstitle">
          <Text type="formTitleSmall">{title}</Text>
          <div className="hrline"></div>
        </div>
        <div className="insights_section">
          <div className="insightsimg" alt="" style={{ backgroundImage: `url('${event.Entity.Image}')` }}></div>
          <div className="insightsright">
            <p className="fontMedium fontSize-26 text-dusk lineClamp-2">{event.Entity.Title}</p>
            {change === 0 ? (
              <p className="fontHeavy fontSize-13 text-brandingAction">No change on {prevText}</p>
            ) : (
              <p className="fontHeavy fontSize-13 text-brandingAction">
                <FontAwesome className="insightsstat" name={`caret-${change > 0 ? 'up' : 'down'}`} />
                <b>
                  {change} on {prevText}
                </b>
              </p>
            )}

            <div className="insightsnumber">
              <div className="insightsnumleft">
                <p className="polloverview_text marginBottom-8 text-mid">Page View{getPluralS(event.Stats.PageView)}</p>
                <p className="polloverview_text marginBottom-8 text-mid">Registration{getPluralS(event.Stats.EventRegistration)}</p>
              </div>
              <div className="insightsnumright">
                <p className="polloverview_num marginBottom-8">{event.Stats.PageView || 0}</p>
                <p className="polloverview_num marginBottom-8">{event.Stats.EventRegistration || 0}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderInsights() {
    if (this.state.loading) {
      return null;
    }
    const mostViewed = getInsight(this.state.stats, this.state.previousStats, this.props.events, 'RowId', 'PageView');
    const mostBooked = getInsight(this.state.stats, this.state.previousStats, this.props.events, 'RowId', 'EventRegistration');
    if (!mostViewed && !mostBooked) {
      return null;
    }
    return (
      <div className="serviceanalytics--insights paddingTop-40">
        <Text type="formTitleSmall" className="marginBottom-16">
          Insights
        </Text>

        <div className="insightscontainer">
          {this.renderInsight(mostViewed, 'Most Viewed', 'PageView')}
          {this.renderInsight(mostBooked, 'Most Registrations', 'EventRegistration')}
        </div>
      </div>
    );
  }

  renderEventList() {
    if (this.state.loading) {
      return null;
    }
    return (
      <div className="servicelisttable paddingTop-40">
        <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
          <thead>
            <tr>
              <th>Title</th>
              <th>Page Views</th>
              <th>Registrations</th>
              <th>Cancellations</th>
            </tr>
          </thead>
          <tbody>
            {_.sortBy(_.sortBy(this.props.events, 'Title'), (event) => {
              return this.getStat(event.RowId, 'PageView');
            })
              .reverse()
              .map((event) => {
                if (!this.state.stats[event.RowId]) {
                  return null;
                }
                return (
                  <tr key={event.RowId}>
                    <td>{event.Title}</td>
                    <td>{this.getStat(event.RowId, 'PageView')}</td>
                    <td>{this.getStat(event.RowId, 'EventRegistration')}</td>
                    <td>{this.getStat(event.RowId, 'EventDeregistration')}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    );
  }

  renderFilter() {
    return (
      <AnalyticsFilter
        defaultFilter={this.state.selectedFilter}
        filterChanged={this.filterChanged}
        filterDateRangeChanged={this.filterDateRangeChanged}
      />
    );
  }

  render() {
    return (
      <div style={{ minWidth: '100%' }}>
        <p className="fontMedium fontSize-36 text-dark">Analytics</p>
        {this.renderFilter()}
        {this.renderStats()}
        {this.renderInsights()}
        {this.renderEventList()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, { usersLoaded })(withRouter(EventHubAnalytics));
