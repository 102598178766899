import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import QRCode from 'qrcode.react';
import { Button, GenericInput, P60Icon, Popup, RadioButton } from '../../components';
import { COLOUR_GREEN, LINEGREY, COLOUR_DUSK } from '../../js';
import { stringActions } from '../../webapi';
import { CONST_STRINGS } from '../../config';

class FormLockoutSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: [],
      successNotification: false,
      failureNotification: false,
      failureText:
        'As you have responded Yes to one or more of our assessment questions, we regret to inform you that we are unable to allow visitation access today. Thank you for your understanding and your cooperation.',
    };
  }

  componentDidMount() {
    this.addQuestion();
    this.getData();
  }

  handleQuestionChange(question, key, event) {
    question[key] = event.target.value;
    this.setState({
      questions: this.state.questions,
    });
  }

  getData() {
    stringActions.getString(this.props.auth.site, 'formlockout', true).then((res) => {
      const result = JSON.parse(res.data);
      const newState = {};

      if (!_.isEmpty(result.questions)) {
        newState.questions = result.questions;
        newState.exists = true;
      }
      if (!_.isEmpty(result.failureText)) {
        newState.failureText = result.failureText;
      }
      if (!_.isUndefined(result.successNoti)) {
        newState.successNotification = result.successNoti;
      }
      if (!_.isUndefined(result.failureNoti)) {
        newState.failureNotification = result.failureNoti;
      }
      this.setState(newState);
    });
  }

  addQuestion() {
    this.state.questions.push({
      Id: this.state.questions.length,
      Text: '',
      Description: '',
    });
    this.setState({
      questions: this.state.questions,
    });
  }

  removeQuestion(index) {
    this.state.questions.splice(index, 1);
    this.setState({
      questions: this.state.questions,
    });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleSubmit() {
    if (!this.validateForm()) {
      return;
    }
    this.setState({ submitting: true });
    stringActions
      .setString(
        this.props.auth.site,
        'formlockout',
        JSON.stringify({
          questions: this.state.questions,
          failureText: this.state.failureText,
          successNoti: this.state.successNotification,
          failureNoti: this.state.failureNotification,
        }),
      )
      .then((res) => {
        this.setState({
          success: true,
          submitting: false,
          exists: true,
        });
      })
      .catch((res) => {
        this.setState({ submitting: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  onSuccess() {
    this.setState({ submitting: false, success: true });
  }

  openQR = () => {
    this.setState({
      showQR: true,
    });
  };

  closeQR = () => {
    this.setState({
      showQR: false,
    });
  };

  validateForm() {
    let allQsValid = true;
    this.state.questions.forEach((q) => {
      if (!this.validateQuestion(q)) {
        allQsValid = false;
      }
    });
    return !_.isEmpty(this.state.questions) && allQsValid;
  }

  validateQuestion(q) {
    return !_.isEmpty(q.Text);
  }

  renderSubmit() {
    if (this.state.submitting) {
      return (
        <Button buttonType="secondary" style={{ marginTop: 32 }}>
          Saving...
        </Button>
      );
    }
    return (
      <Button
        inline
        buttonType="primary"
        style={{ marginTop: 32, marginBottom: 32 }}
        onClick={this.handleSubmit.bind(this)}
        isActive={this.validateForm()}
      >
        Save
      </Button>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return <span style={{ color: COLOUR_GREEN, fontSize: 14, lineHeight: '33px', marginLeft: 30 }}>Saved</span>;
  }

  renderQuestion(question, index) {
    return (
      <div className="questionContainer" key={index}>
        <div className="questionNumber_container">
          <p className="questionNumber">{index + 1}</p>
        </div>
        <div className="question" style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 24 }}>
          <GenericInput
            id={'qTextInput_' + index}
            type="textarea"
            label="Question"
            alwaysShowLabel
            placeholder="Enter your question here"
            showError={() => {
              return this.state.showWarnings && _.isEmpty(question.Text);
            }}
            isValid={this.validateQuestion.bind(this, question)}
            value={question.Text}
            onChange={(e) => this.handleQuestionChange(question, 'Text', e)}
            isRequired
          />
          {this.state.questions.length > 1 && (
            <FontAwesome className="question_remove" name="minus-circle" onClick={this.removeQuestion.bind(this, index)} />
          )}
        </div>
      </div>
    );
  }

  renderQuestions() {
    return this.state.questions.map((q, i) => {
      return this.renderQuestion(q, i);
    });
  }

  renderAddQuestion() {
    return (
      <div className="clearfix addoption marginTop-32 marginLeft-40" onClick={this.addQuestion.bind(this)}>
        <P60Icon className="addoption_plus" icon="add-circle" />
        <div className="fillSpace">
          <p className="addoption_text">Add another question</p>
        </div>
      </div>
    );
  }

  renderFailureInput() {
    return (
      <div style={{ marginTop: 32, marginBottom: 32 }}>
        <GenericInput
          id="failureText"
          type="textarea"
          label="INFECTION RISK MESSAGE"
          placeholder="Enter a message"
          value={this.state.failureText}
          onChange={(e) => this.handleChange(e)}
          help="This message will be displayed if a person answers yes to any of the above questions."
          inputStyle={{ padding: 16, backgroundColor: LINEGREY, borderRadius: 4, color: COLOUR_DUSK, borderBottom: 'none', marginTop: 8 }}
        />
      </div>
    );
  }

  renderFailureNotificationList() {
    if (!this.state.failureNotification) {
      return null;
    }
    //TODO
    return <div></div>;
  }

  renderFailureStaffNotification() {
    return (
      <div>
        <RadioButton
          label="Notify staff when someone is rejected entry?"
          isActive={this.state.failureNotification}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ failureNotification: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ failureNotification: false }) },
          ]}
        />
        <div className="genericInput-help" style={{ marginTop: 4 }}>
          This will send an alert to all staff members when someone answers yes to any of the questions on this form.
        </div>
        {/* {this.renderFailureNotificationList()} */}
      </div>
    );
  }

  renderSuccessStaffNotification() {
    return (
      <div>
        <RadioButton
          label="Notify staff when someone is allowed entry?"
          isActive={this.state.successNotification}
          options={[
            { Label: 'Yes', Value: true, onChange: () => this.setState({ successNotification: true }) },
            { Label: 'No', Value: false, onChange: () => this.setState({ successNotification: false }) },
          ]}
        />
        <div className="genericInput-help" style={{ marginTop: 4 }}>
          This will send an alert to all staff members when someone answers no to all of the questions on this form.
        </div>
        {/* {this.renderSuccessNotificationList()} */}
      </div>
    );
  }

  renderNotificationSettings() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: 32, marginBottom: 32 }}>
        <div style={{ flex: 1, paddingRight: 48 }}>{this.renderFailureStaffNotification()}</div>
        <div style={{ flex: 1 }}>{this.renderSuccessStaffNotification()}</div>
      </div>
    );
  }

  renderQR() {
    return (
      <div>
        <p>
          <a onClick={this.openQR}>Click here</a> to view QR Code
        </p>
        <div className="separator"></div>
      </div>
    );
  }

  renderQRPopup() {
    if (!this.state.showQR) {
      return null;
    }
    return (
      <Popup
        maxWidth="90%"
        maxHeight="90%"
        hasPadding
        buttons={[
          {
            type: 'outlined',
            onClick: this.closeQR,
            isActive: true,
            text: 'Close',
          },
        ]}
      >
        <QRCode value={`${window.location.origin}/signin?site=${this.props.auth.site}`} />
      </Popup>
    );
  }

  render() {
    return (
      <div style={{ minWidth: '100%' }}>
        {this.renderQRPopup()}
        <p className="fontMedium fontSize-36 text-dark">{CONST_STRINGS.KIOSK_FORM_LOCKOUT} Settings</p>
        {this.renderQuestions()}
        {this.renderAddQuestion()}
        <div className="separator"></div>
        {this.renderFailureInput()}
        <div className="separator"></div>
        {this.renderNotificationSettings()}
        <div className="separator"></div>
        {this.renderQR()}
        {this.renderSubmit()}
        {this.renderSuccess()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(FormLockoutSettings);
