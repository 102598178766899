import React, { Component } from 'react';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      open: false,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ open: false });
    }
  }

  getClassNames() {
    let string = this.props.className;
    if (this.props.disabled) {
      string = ' validation-disabled';
    }
    if (this.props.showErrors && !this.props.isValid) {
      return string + ' validation-error';
    }
    if (this.props.isValid) {
      return string + ' validation-valid';
    }
    return string;
  }

  open() {
    if (this.props.disabled) {
      return;
    }
    this.setState({ open: !this.state.open });
  }

  renderOptions() {
    if (!this.state.open) {
      return null;
    }
    return (
      <div className="select_options">
        {_.values(this.props.options).map((o) => {
          return (
            <div
              key={o.Key}
              className="select_option"
              onClick={() => {
                this.props.onSelect(o.Key);
              }}
            >
              {o.Title}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div ref={this.setWrapperRef} onClick={this.open.bind(this)} className={`select ${this.getClassNames()}`}>
        <input
          id={this.props.id}
          placeholder={this.props.placeholder}
          type={'text'}
          value={this.props.value}
          onChange={this.props.onChange}
          disabled
          autoComplete={false}
          className="select_input"
        />
        <FontAwesome className="select_chevron" name={this.state.open ? 'chevron-up' : 'chevron-down'} />
        {this.renderOptions()}
      </div>
    );
  }
}

export { Dropdown };
