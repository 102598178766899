import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { AddButton, WarningPopup, Popup, GenericInput, RadioButton } from '../../../components';
import { checkLoggedIn, validateAccess } from '../../../session';
import { setNavData, setAuth } from '../../../actions';
import { typeActions } from '../../../webapi';
import PermissionType from './PermissionType';
import { PageTitle, Text } from '../../../components/text';

class EditPermissions extends Component {
  state = {
    openView: '',
    types: [],
    showExistingUsersWarning: false,
    interfaceTitleInput: '',
    interfaces: [],
    showNewUserType: false,
    newUserTypeName: '',
    newUserTypeType: '',
    selectedUserType: null,
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    this.getSite();
    this.getUserTypes();
    this.getInterfaces();
  }

  getSite() {
    typeActions.getSite(this.props.auth.site).then((res) => {
      this.setState({
        site: res.data,
      });
    });
  }

  getUserTypes() {
    typeActions
      .getUserTypes(this.props.auth.site)
      .then((res) => {
        this.props.setAuth({ userType: _.cloneDeep(res.data) });
        // console.log('user types', res.data);
        this.setState({
          types: res.data,
        });
      })
      .catch((error) => {});
  }

  getInterfaces() {
    typeActions
      .getInterfaces(this.props.auth.site)
      .then((res) => {
        // console.log('interfaces', res.data);
        this.setState({
          interfaces: res.data,
        });
      })
      .catch((error) => {});
  }

  showExistingUsersOnDelete() {
    this.setState({
      showExistingUsersWarning: true,
    });
  }

  closeExistingUsersOnDelete() {
    this.setState({
      showExistingUsersWarning: false,
    });
  }

  toggleInterfacePopup = (type) => {
    this.setState({
      interfacePopup: type,
    });
  };

  goToCreateInterface = () => {
    this.props.history.push(
      `/interface?title=${encodeURIComponent(this.state.interfaceTitleInput)}&attach=${this.state.interfacePopup.typeName}`,
    );
  };

  canDeleteInterface = (interfaceId) => {
    return !_.some(this.state.types, (t) => {
      return t.Interface === interfaceId;
    });
  };

  editInterface = (event, iFace) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (iFace === null) {
      this.props.history.push(`/featurepicker`);
      return;
    }
    this.props.setNavData({ interface: iFace, site: this.state.site });

    this.props.history.push(`/interface/${encodeURIComponent(iFace.Id)}`);
  };

  deleteInterface = (event, interfaceId) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (window.confirm('Are you sure you want to delete this interface?')) {
      typeActions.deleteInterface(interfaceId).then((res) => {
        console.log('success');
      });
      const index = _.findIndex(this.state.interfaces, (i) => {
        return i.Id === interfaceId;
      });
      if (index !== -1) {
        this.state.interfaces.splice(index, 1);
        this.setState({
          interfaces: [...this.state.interfaces],
        });
      }
    }
  };

  selectInterface = (interfaceId) => {
    typeActions.attachInterface(this.props.auth.site, this.state.interfacePopup.typeName, interfaceId).then((res) => {
      console.log('success');
    });
    const interfacePopup = this.state.interfacePopup;
    interfacePopup.Interface = interfaceId;
    this.setState({
      interfacePopup: this.state.interfacePopup,
    });
  };

  createInterface = () => {
    this.setState({
      creatingNewInterface: true,
    });
  };

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  selectView(id) {
    this.setState({ openView: id });
  }

  viewItem(selected, isNew) {
    this.setState({ selectedUserType: selected === this.state.selectedUserType ? null : { ...selected, isNew } });
  }

  onShowNewUserType = () => {
    this.setState({ showNewUserType: true, newUserTypeName: '', newUserTypeType: '' });
  };

  onHideNewUserType = () => {
    this.setState({ showNewUserType: false });
  };

  isNewUserTypeValid = () => {
    const { newUserTypeName, newUserTypeType, types } = this.state;

    if (_.isEmpty(newUserTypeName)) return false;
    if (_.isEmpty(newUserTypeType)) return false;
    if (types.find((type) => type.typeName === newUserTypeName)) return false;

    return true;
  };

  onCreateNewUserType = () => {
    if (!this.isNewUserTypeValid()) return;

    const { types, newUserTypeName, newUserTypeType } = this.state;
    const newTypes = _.cloneDeep(types);
    const type = {
      active: true,
      displayName: newUserTypeName,
      permissions: [],
      site: this.props.auth.site,
      sortLevel: _.isEmpty(types) ? 0 : _.maxBy(types, 'sortLevel').sortLevel + 1,
      typeName: newUserTypeName.replace(/ /g, ''),
      category: newUserTypeType,
    };
    type.Id = `${type.site}_${type.typeName}`;
    newTypes.push(type);
    this.setState({ types: newTypes }, () => {
      this.onHideNewUserType();
      this.viewItem(type, true);
    });
  };

  onChangeUserTypeOption = (newUserTypeType) => {
    this.setState({ newUserTypeType });
  };

  getUserTypeOptions() {
    return [
      {
        Label: 'Primary user',
        Value: 'resident',
        onChange: () => this.onChangeUserTypeOption('resident'),
      },
      {
        Label: 'Staff',
        Value: 'staff',
        onChange: () => this.onChangeUserTypeOption('staff'),
      },
      {
        Label: 'Linked user',
        Value: 'family',
        onChange: () => this.onChangeUserTypeOption('family'),
      },
    ];
  }

  renderUserTypes() {
    const { types, selectedUserType, interfaces } = this.state;

    if (_.isEmpty(types)) return null;

    let source = _.sortBy(types, 'sortLevel');

    return (
      <div className="userTypesList">
        {source.map((ev, index) => {
          if (ev != null && ev.typeName !== 'master') {
            return (
              <PermissionType
                index={index}
                key={ev.Id}
                userType={ev}
                selectedView={selectedUserType}
                selectView={this.viewItem.bind(this)}
                updateUserType={this.getUserTypes.bind(this)}
                showExistingUsersOnDelete={this.showExistingUsersOnDelete.bind(this)}
                interfaces={interfaces}
                openInterfacePopup={this.toggleInterfacePopup.bind(this)}
              />
            );
          }
          return null;
        })}
      </div>
    );
  }

  renderNewType() {
    if (!validateAccess(this.props.auth.site, 'permissionManagement', this.props.auth)) return null;

    return (
      <div className="addNewContainer">
        <AddButton onClick={this.onShowNewUserType} text="NEW USER TYPE" />
      </div>
    );
  }

  renderSelectType(selectedType) {
    return (
      <div className="selectUserType">
        <p className="selectTypeHeading">SELECT TYPE</p>
        <RadioButton
          isActive={selectedType}
          className="userTypeOptions"
          rowStyle={{ flexDirection: 'row' }}
          buttonStyle={{
            width: '215px',
            height: '46px',
            boxShadow: '0 3px 30px rgba(103, 125, 169, 0.07)',
            alignItems: 'center',
            padding: '0 25px',
          }}
          options={this.getUserTypeOptions()}
        />
      </div>
    );
  }

  renderNewUserTypePopup() {
    const { showNewUserType, newUserTypeName, newUserTypeType } = this.state;
    if (!showNewUserType) return null;

    return (
      <Popup
        maxWidth={800}
        hasPadding
        buttons={[
          {
            type: 'primaryAction',
            onClick: this.onCreateNewUserType,
            isActive: this.isNewUserTypeValid(),
            text: 'Save',
            className: 'popupButton',
          },
          {
            type: 'outlinedAction',
            onClick: this.onHideNewUserType,
            isActive: true,
            text: 'Cancel',
            className: 'popupButton',
          },
        ]}
        onClose={this.onHideNewUserType}
        title="Create New User Type"
      >
        <div className="userTypeNameSection">
          <GenericInput
            inputClass="userTypeNameInput"
            id="newUserTypeName"
            type="text"
            isRequired
            value={newUserTypeName}
            onChange={(e) => this.handleChange(e)}
            maxLength={100}
            placeholder="Enter user type name"
          />
        </div>
        {this.renderSelectType(newUserTypeType)}
      </Popup>
    );
  }

  renderExistingUsersWarning() {
    if (!this.state.showExistingUsersWarning) {
      return null;
    }
    return (
      <WarningPopup
        text={
          <span>
            To delete a user type, you must first ensure there are no current users of that type.
            <br />
            Change the type of any existing user and try again.
          </span>
        }
        smallText
        buttons={[
          {
            type: 'outlined',
            onClick: this.closeExistingUsersOnDelete.bind(this),
            text: 'Close',
          },
        ]}
      />
    );
  }

  render() {
    return (
      <div className="editPermissions">
        <div className="headingSection">
          <PageTitle>User Types</PageTitle>
          {this.renderNewType()}
        </div>
        {this.renderUserTypes()}
        {this.renderExistingUsersWarning()}
        {this.renderNewUserTypePopup()}
        {_.isEmpty(this.state.types) && (
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
            <div className="emptyState" />
            <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
              <Text type="h3">There are no user types</Text>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { setNavData, setAuth })(withRouter(EditPermissions));
