import React, { PureComponent } from 'react';
import { P60Icon } from './P60Icon';

class AddOption extends PureComponent {
  render() {
    return (
      <div className="clearfix addoption" style={this.props.style} onClick={this.props.onClick}>
        <P60Icon className="addoption_plus" icon="add-circle" />
        <div className="fillSpace">
          <p className="addoption_text">{this.props.text}</p>
        </div>
      </div>
    );
  }
}

export { AddOption };
