import React, { Component } from 'react';
import AddEvent from './AddEvent';

class ScrapeEventPage extends Component {
    render() {
        return (
            <AddEvent showScraper />
        );
    }
}

export default ScrapeEventPage;