import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PageContainer } from '../../components';
import { stringActions } from '../../webapi';

class ContractorTerms extends Component {
    state = {
        termsText: 'Loading terms...'
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        stringActions.getString(this.props.auth.site, 'termsofentry')
            .then((res) => {
                this.setState({
                    termsText: res.data
                });
            });
    }

    render() {
        return (
            <div>
                <PageContainer>
                    <div style={{ fontSize: 32, fontWeight: 'bold', marginBottom: 30 }}>
                        Terms of Entry
                    </div>
                    <p style={{ whiteSpace: 'pre-line' }}>
                        {this.state.termsText}
                    </p>
                </PageContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
};

export default connect(mapStateToProps, {})(ContractorTerms);
