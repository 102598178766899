import { DEVICES_LOADED, DEVICE_REMOVED, DEVICE_VIEWS_LOADED, DEVICES_PURGE } from './types';

export const devicesLoaded = (devices) => {
  return {
    type: DEVICES_LOADED,
    payload: devices,
  };
};

export const removeDevice = (deviceId) => {
  return {
    type: DEVICE_REMOVED,
    payload: deviceId,
  };
};

export const viewsLoaded = (views) => {
  return {
    type: DEVICE_VIEWS_LOADED,
    payload: views,
  };
};

export const clearDevices = () => {
  return {
    type: DEVICES_PURGE,
  };
};
