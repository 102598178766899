import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';

class ColourDisplay1 extends Component {
    render() {
        return (
            <div style={styles.card}>
                <div
                    style={{
                        ...styles.eventTop,
                        backgroundImage: `url('https://plusscdn.azureedge.net/uploads1400/461-1/636491722666798942483background3.jpg')`
                    }}>
                    <FontAwesome
                        style={{ ...styles.backButton, backgroundColor: this.props.colour1 }}
                        name={'chevron-left'}
                    />
                </div>
                <div style={{ padding: 12 }}>
                    <div className='fontHeavy text-dark fontSize-20' style={{ paddingBottom: 6 }}>
                        Example Event Title
                    </div>
                    <div className='flex'>
                        <div className='fontMedium' style={{ ...styles.button, ...styles.buttonTag, backgroundColor: this.props.colour2, color: this.props.colour1 }}>
                            Tag 1
                        </div>
                        <div className='fontMedium' style={{ ...styles.button, ...styles.buttonTag, backgroundColor: this.props.colour2, color: this.props.colour1 }}>
                            Tag 2
                        </div>
                        <div className='fontMedium' style={{ ...styles.button, ...styles.buttonTag, backgroundColor: this.props.colour2, color: this.props.colour1 }}>
                            Tag 3
                        </div>
                    </div>
                    <div style={{ marginTop: 8, paddingTop: 8, borderTop: '1px solid #ccc' }}>
                        <div className='fontHeavy text-dark fontSize-13' style={{ paddingBottom: 6 }}>
                            Today - 6:09pm
                        </div>
                        <div className='flex flex-between flex-center'>
                            <div style={{ ...styles.backButton }} />
                            <div className='fontMedium' style={{ ...styles.button, backgroundColor: this.props.colour1 }}>
                                Reserve spot
                            </div>
                        </div>
                        <div className='fontMedium fontSize-13' style={{ color: this.props.colour1 }}>
                            See more times
                        </div>
                    </div>
                    <div style={{ marginTop: 8, paddingTop: 8, borderTop: '1px solid #ccc' }} />
                </div>
            </div>
        );
    }
}

const styles = {
    card: {
        backgroundColor: '#fff', width: 250, height: 370, overflow: 'hidden', borderRadius: 3
    },
    eventTop: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: 8,
        paddingLeft: 12,
        height: 120
    },
    backButton: {
        height: 20,
        width: 20,
        textAlign: 'center',
        borderRadius: 20,
        color: '#fff',
        fontSize: 10,
        lineHeight: '20px',
        paddingRight: 2,
        backgroundColor: '#ccc'
    },
    button: {
        padding: '6px 12px',
        borderRadius: '4px',
        width: 'max-content',
        color: '#fff'
    },
    buttonTag: {
        fontSize: 12,
        marginRight: 8,
        padding: '5px 10px'
    }
}

export default connect(null, {})(ColourDisplay1);
