import React, { Component } from 'react';
import _ from 'lodash';
import { androidApk, clientName, baseStage } from '../../../config';
import { PageContainer } from '../../../components';

class AndroidDownload extends Component {
    componentDidMount() {
        setTimeout(() => {
            if (!_.isUndefined(androidApk) && !_.isEmpty(androidApk)) {
                console.log('redirecting to android download');
                window.location.href = androidApk;
            }
        }, 1500);
    }

    renderAndroid() {
        if (!_.isUndefined(androidApk) && !_.isEmpty(androidApk)) {
            return (
                <div>
                    Downloading android app for {clientName}-{baseStage}
                </div>
            )
        }
        return (
            <div>
                No android demo app for {clientName}-{baseStage}.
            </div>
        )
    }

    render() {
        return (
            <div>
                <PageContainer maxWidth={400}>
                    <div style={{ marginTop: 40, textAlign: 'center' }}>
                        <div className="fontMedium" style={{ fontSize: 16, lineHeight: '40px' }}>
                            {clientName}-{baseStage} android
                        </div>
                        <div className="fontRegular " style={{ fontSize: 14, lineHeight: '20px' }}>
                            {this.renderAndroid()}
                        </div>
                    </div>
                </PageContainer>
            </div>
        );
    }
}

export default AndroidDownload;
