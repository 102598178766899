import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ticketsUpdate } from '../../actions';
import {
  Button,
  ImageInput,
  GenericInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  SuccessPopup,
} from '../../components';
import { maintenanceActions } from '../../webapi';
import { checkLoggedIn } from '../../session';
import { Text } from '../../components/text';

class AddTicket extends Component {
  initialState = {
    titleInput: '',
    textInput: '',
    showWarnings: false,
    success: false,
    submitting: false,
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  isEmpty(text) {
    return text === '';
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  validateLoading() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  validateCompulsoryText() {
    if (this.isEmpty(this.state.titleInput)) {
      return false;
    }
    return true;
  }

  validateForm() {
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (!this.validateLoading()) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true });

    maintenanceActions
      .addTicket(this.props.auth.site, this.state.titleInput, this.state.textInput, this.refs.imageInput.getWrappedInstance().getValue())
      .then((res) => {
        this.setState({
          success: true,
          submitting: false,
        });
        this.props.ticketsUpdate(this.props.auth.site);
      })
      .catch((res) => {
        this.setState({ submitting: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text="Ticket has been logged"
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  inputsDisabled() {
    if (this.state.submitting) {
      return true;
    }
    return false;
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Sending...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {!this.inputsDisabled() ? 'Cancel' : 'Back'}
        </Button>
        {!this.inputsDisabled() && (
          <Button inline buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.validateForm()}>
            Send
          </Button>
        )}
      </div>
    );
  }

  renderForm() {
    if (this.state.success) {
      return null;
    }
    return (
      <div className="padding-60 paddingVertical-40 bottomDivideBorder">
        <Text type="formTitleLarge" className="marginBottom-24">
          New Support Ticket
        </Text>
        {/* title */}
        <GenericInput
          id="titleInput"
          type="text"
          label="Title"
          placeholder="Enter brief summary of the request"
          value={this.state.titleInput}
          onChange={(e) => this.handleChange(e)}
          isRequired
          isValid={() => {
            return !this.isEmpty(this.state.titleInput);
          }}
          showError={() => {
            return this.state.showWarnings && this.isEmpty(this.state.titleInput);
          }}
          alwaysShowLabel
        />
        {/* description */}
        <GenericInput
          id="textInput"
          type="textarea"
          label="Description"
          placeholder="Enter any further description here"
          value={this.state.textInput}
          onChange={(e) => this.handleChange(e)}
          alwaysShowLabel
        />
        {/* image */}
        <div className="paddingBottom-20" />
        <ImageInput
          ref="imageInput"
          label="Images"
          refreshCallback={() => {
            this.setState({ imageUpdate: true });
          }}
          noMenu
          multiple
        />
      </div>
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { ticketsUpdate })(AddTicket);
