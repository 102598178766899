import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import { removeEventSubmission } from '../../actions';
import { Button, OverlayPage, OverlayPageContents, OverlayPageSection, OverlayPageBottomButtons, SuccessPopup } from '../../components';
import { eventActions } from '../../webapi';
import { safeReadParams } from '../../helper';
import { checkLoggedIn } from '../../session';
import { COLOUR_BRANDING_MAIN, COLOUR_BRANDING_OFF } from '../../js';
import EditSubmission from './EditSubmission';

class EventSubmission extends Component {
  initialState = {
    updateId: safeReadParams(this.props, 'updateId'),
    success: false,
    submitting: false,
    update: {},
    action: '',
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);

    if (this.state.updateId) {
      this.getData();
    }
  }

  getData() {
    eventActions.getEventSubmission(this.state.updateId).then((res) => {
      this.setState({ update: res.data, edit: false });
    });
  }

  getDate() {
    if (!this.state.update.Timestamp) {
      return '';
    }
    return moment.utc(this.state.update.Timestamp).local().format('D MMM');
  }

  validateForm() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  handleSubmit(action) {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    if (
      window.confirm(
        action === 'accept'
          ? 'Approving this event will make it visible to everyone in the app. Are you sure you want to continue?'
          : 'Rejecting this event will remove it from the system. Are you sure you want to continue?',
      )
    ) {
      this.setState({ submitting: true, action });

      eventActions
        .handleEventSubmission(this.props.auth.site, this.state.updateId, action)
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          this.props.removeEventSubmission(this.state.updateId);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`Event submission has been ${this.state.action === 'accept' ? 'approved' : 'rejected'}`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  setEditForm() {
    const newUpdate = {
      ...this.state.update,
    };
    // if (newUpdate.Tags != null) {
    //     newUpdate.Tags = this.state.update.Tags.join(',')
    // } else {
    //     newUpdate.Tags = '';
    // }
    this.setState({ edit: true, newUpdate });
    // this.checkSetImage();
  }

  renderEditSubmit() {
    if (this.state.editSubmitting) {
      return <Button buttonType="secondary">Saving changes...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            this.setState({ edit: false });
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          Cancel
        </Button>
        <Button
          inline
          buttonType="primary"
          // onClick={this.handleEditSubmit.bind(this)}
          // isActive={this.validateEditForm()}
        >
          Save changes
        </Button>
      </div>
    );
  }

  renderSubmit() {
    if (this.state.edit) {
      return this.renderEditSubmit();
    }
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div className="flex flex-between" style={{ width: '100%' }}>
        <div>
          <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this, 'accept')} isActive={true}>
            Approve
          </Button>
          <Button inline buttonType="secondary" onClick={this.handleSubmit.bind(this, 'reject')} isActive={true} style={{ marginLeft: 16 }}>
            Reject
          </Button>
        </div>
        <Button inline buttonType="outlined" onClick={() => this.setEditForm()} isActive={true} style={{ marginLeft: 16 }}>
          Edit submission
        </Button>
      </div>
    );
  }

  renderTimeSlots() {
    if (!this.state.update || !this.state.update.RepeatedTimes) {
      return null;
    }
    return (
      <div>
        {this.state.update.RepeatedTimes.map((event) => {
          return (
            <div key={event.Id} style={{ padding: 15, backgroundColor: '#f1f1f19e', marginBottom: 5 }}>
              <p style={{ marginBottom: 0 }}>
                {`${moment.utc(event.Time).local().format('D MMM YY')} ${
                  event.AllDay ? '(All day)' : moment.utc(event.Time).local().format('h:mma')
                }`}
              </p>
              {event.Location && <p style={{ marginBottom: 0 }}>{event.Location}</p>}
              {event.TicketCount > 0 && <p style={{ marginBottom: 0 }}>Limit: {event.TicketCount}</p>}
            </div>
          );
        })}
      </div>
    );
  }

  renderAttachments() {
    if (!this.state.update || _.isEmpty(this.state.update.Attachments)) {
      return null;
    }
    return (
      <div style={{ marginTop: 10 }}>
        <p className="fontHeavy text-dark fontSize-16">Attachments</p>
        <div className="clearfix">
          {this.state.update.Attachments.map((att, index) => {
            return (
              <a
                key={index}
                href={att.Source}
                target="_blank"
                style={{
                  width: 100,
                  height: 100,
                  padding: 5,
                  borderRadius: 4,
                  backgroundColor: COLOUR_BRANDING_MAIN,
                  marginBottom: 5,
                  marginRight: 5,
                  float: 'left',
                }}
              >
                <p
                  style={{
                    marginBottom: 0,
                    fontWeight: 'bold',
                    color: '#fff',
                    textAlign: 'center',
                    width: 90,
                    wordBreak: 'break-all',
                    lineHeight: '20px',
                    maxHeight: 40,
                    overflow: 'hidden',
                  }}
                >
                  {att.Title}
                </p>
                <p style={{ marginBottom: 0, color: '#fff', textAlign: 'center' }}>PDF</p>
              </a>
            );
          })}
        </div>
      </div>
    );
  }

  renderAdditionalInfo(title, value) {
    if (_.isEmpty(value)) {
      return null;
    }
    return (
      <div style={{ marginTop: 10 }}>
        <p className="fontHeavy text-dark fontSize-16" style={{ marginBottom: 4 }}>
          {title}
        </p>
        <p className="fontMedium text-dark fontSisze-16">{value}</p>
      </div>
    );
  }

  renderBookingOptions() {
    if (!this.state.update || !this.state.update.BookingData) {
      return null;
    }
    return (
      <div style={{ marginTop: 10 }}>
        <p className="fontHeavy text-dark fontSize-16" style={{ marginBottom: 4 }}>
          Booking Options
        </p>
        <p className="fontMedium text-dark fontSize-16">
          {this.state.update.BookingData.type === 'EmailRequest'
            ? 'In app booking (with forwarding email)'
            : this.state.update.BookingData.type}{' '}
          -{' '}
          {this.state.update.BookingData.type === 'External'
            ? this.state.update.BookingData.ButtonLink
            : this.state.update.BookingData.RequestEmail}
        </p>
      </div>
    );
  }

  renderForm() {
    if (this.state.success) {
      return null;
    }
    if (_.isEmpty(this.state.update)) {
      return (
        <div className="padding-60 bottomPadding-40" style={{ textAlign: 'center' }}>
          <FontAwesome style={{ fontSize: 32, color: COLOUR_BRANDING_OFF }} name="spinner fa-pulse fa-fw" />
        </div>
      );
    }
    return (
      <div>
        <div className="padding-60 paddingBottom-40 bottomDivideBorder">
          <div className="newTopBar clearfix">
            <p className="newTopBarTitle text-sectionTitle">EVENT SUBMISSION</p>
          </div>
          <div className="newTopBar clearfix">
            <span className="newTopBarTitle text-sectionTitle">
              Submitted by <span className="text-brandingColour">{this.state.update.SubmittedBy.displayName}</span>
            </span>
          </div>
          {!_.isUndefined(this.state.update.EditedBy) && (
            <div className="newTopBar clearfix">
              <span className="newTopBarTitle text-sectionTitle">
                Last edited by <span className="text-brandingColour">{this.state.update.EditedBy.displayName}</span>
              </span>
            </div>
          )}
          <div className="marginTop-16 fontMedium text-dark" style={{ fontSize: 26 }}>
            {this.state.update.Title}
          </div>
          <div className="fontMedium marginBottom-8 text-light" style={{ color: COLOUR_BRANDING_MAIN }}>
            {this.state.update.Category} ·{' '}
            {_.map(this.state.update.Tags, (tag, index) => {
              return (
                <span key={index}>
                  {tag}
                  {index !== this.state.update.Tags.length - 1 ? ', ' : ''}
                </span>
              );
            })}
          </div>
          {/* Image */}
          <div
            style={{
              height: 250,
              backgroundImage: `url(${this.state.update.Image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <div className="newTopBar flex marginBottom-8 clearfix">
            <span className="newTopBarTitle text-sectionTitle">EVENT TIME SLOTS</span>
            {this.state.update.IsFeatured && (
              <span className="newTopBarTitle fontMedium fontSize-13 letterSpacing-1 text-brandingColour">
                &nbsp;-&nbsp;IS FEATURED EVENT
              </span>
            )}
          </div>
          {this.renderTimeSlots()}
        </div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder">
          <div className="newTopBar clearfix">
            <p className="newTopBarTitle text-sectionTitle">EVENT DETAILS</p>
          </div>
          {this.renderAdditionalInfo('Description', this.state.update.Description)}
          {this.renderAdditionalInfo('Organiser', this.state.update.Organiser)}
          {this.renderAdditionalInfo('Organiser phone', this.state.update.Phone)}
          {this.renderBookingOptions()}
          {this.renderAttachments()}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.edit) {
      return (
        <EditSubmission
          newUpdate={this.state.newUpdate}
          onCancel={() => {
            this.setState({ edit: false });
          }}
          onSuccess={() => {
            this.getData();
          }}
        />
      );
    }
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
        </OverlayPageContents>
        {!this.state.success && <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>}
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { removeEventSubmission })(EventSubmission);
