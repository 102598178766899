import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { visitorActions } from '../../webapi';
import { checkLoggedIn } from '../../session';
import { COLOUR_GREEN, COLOUR_RED } from '../../js';
import { CONST_STRINGS } from '../../config';
import { SuccessPopup } from '../../components';

class FormLockoutLog extends Component {
  state = {
    log: [],
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    visitorActions.getFormLockoutLog(this.props.auth.site, 0, Number.MAX_SAFE_INTEGER, 100).then(res => {
      this.setState({
        log: res.data,
      });
    });
  }

  showAnswers(answers) {
    this.setState({
      selectedAnswer: answers,
    });
  }

  renderList(source) {
    return source.map(ev => {
      if (ev != null) {
        return (
          <tr key={ev.Id}>
            <td className="table-TitleColumn pointer">
              <a onClick={this.showAnswers.bind(this, ev)}>
                {moment
                  .utc(ev.Timestamp)
                  .local()
                  .format('D MMM YYYY h:mma')}
              </a>
            </td>
            <td>
              {ev.AnyYes ? (
                <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, color: COLOUR_RED }} name="exclamation-triangle" />
              ) : (
                <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, color: COLOUR_GREEN }} name="check" />
              )}
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderAnswersPopup() {
    if (!this.state.selectedAnswer) {
      return null;
    }
    return (
      <SuccessPopup
        noIcon
        text={`Answers at ${moment
          .utc(this.state.selectedAnswer.Timestamp)
          .local()
          .format('D MMM YYYY h:mma')}`}
        closeAction={this.showAnswers.bind(this, undefined)}
      >
        <div style={{ padding: 16 }}>
          {this.state.selectedAnswer.Answers.map(a => {
            return (
              <div key={a.Id} style={{ marginTop: 16 }}>
                <p className="fontRegular fontSize-16">{a.Text}</p>
                <p className="fontHeavy fontSize-20">{a.Answer ? 'Yes' : 'No'}</p>
              </div>
            );
          })}
        </div>
      </SuccessPopup>
    );
  }

  renderContent() {
    return (
      <Table className="plussTable" striped bordered condensed hover>
        <thead>
          <tr>
            <th style={{ width: 240 }}>Time</th>
            <th>Result of form</th>
          </tr>
        </thead>
        <tbody>{this.renderList(this.state.log)}</tbody>
      </Table>
    );
  }

  render() {
    return (
      <div style={{ minWidth: '100%' }}>
        <p className="fontMedium fontSize-36 text-dark">{CONST_STRINGS.KIOSK_FORM_LOCKOUT} Log</p>
        {this.renderContent()}
        {this.renderAnswersPopup()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(FormLockoutLog);
