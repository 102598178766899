import React, { Component } from 'react';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { TEXT_DARK } from '../js';

class MainMenuGroup extends Component {
    renderIcons() {
        if (_.isEmpty(this.props.titleIcons)) {
            return null;
        }
        return this.props.titleIcons.map((ev, index) => {
            if (ev != null) {
                return (
                    <FontAwesome
                        className="homeGroup-icon"
                        key={index}
                        name={ev}
                        style={styles.titleIcon}
                    />
                )
            }
            return null;
        });
    }

    render() {
        return (
            <div style={styles.group} className="homeGroup">
                <div style={styles.titleIconWrapper}>
                    {this.renderIcons()}
                </div>
                <div style={styles.title}>
                    {this.props.title}
                </div>
                <div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const styles = {
    group: {
        width: 380,
        minHeight: 240,
        marginBottom: 32,
        marginLeft: 16,
        marginRight: 16,
        overflow: 'hidden',
        backgroundColor: '#fff',
        boxShadow: "1px 1px 32px rgba(106, 163, 216, 0.2)",
        padding: '18px 18px 18px 32px'
    },
    titleIconWrapper: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    titleIcon: {
        fontSize: '16px',
        width: 25,
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        color: TEXT_DARK,
        fontSize: '32px',
        marginBottom: 16
    }
}

let exportObj = connect(null, {})(withRouter(MainMenuGroup));

export { exportObj as MainMenuGroup };