import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Header, ImageInput } from '../../components';
import { Text } from '../../components/text';
import { MakerPopup } from '../../components/MakerPopup';

class AppStoreAssets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPreset: null,
      includeFrame: true,
    };
  }

  closeMaker = () => {
    this.setState({
      makerData: null,
    });
  };

  handleTextChange = (event) => {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  };

  validateForm() {
    if (_.isEmpty(this.state.image)) {
      return false;
    }
    return true;
  }

  onImageUpdated = (url) => {
    this.setState({
      image: url,
    });
  };

  handleSubmit = (size) => {
    if (!this.validateForm()) {
      return;
    }
    let height = 0;
    let width = 0;
    switch (size) {
      case 'appIcon':
        height = 1024;
        width = 1024;
        break;
      case 'androidIcon':
        height = 512;
        width = 512;
        break;
      case 'splash':
        height = 2668;
        width = 1500;
        break;
      default:
        break;
    }

    this.setState({
      makerData: {
        image: this.state.image,
      },
      customSize: {
        height,
        width,
        unit: 'px',
      },
    });
  };

  renderMakerPopup() {
    if (!this.state.makerData) {
      return null;
    }
    return (
      <MakerPopup
        title="Create App Store Asset"
        onClose={this.closeMaker}
        initialData={this.state.makerData}
        templateId="0fbd95e4-070a-4c7c-b16d-0900b0691cfe"
        minWidth={600}
        hideSize
        hideFormat
        inputs={[
          {
            key: 'image',
            type: 'string',
            title: 'Logo',
          },
        ]}
        requestData={{
          postProcessing: {
            optimize: false,
            targetDPI: 300,
          },
          customSize: this.state.customSize,
          format: 'png',
        }}
      />
    );
  }

  render() {
    return (
      <div>
        {this.renderMakerPopup()}
        <Header />
        <div className="pageContainer container600 paddingVertical-20 paddingHorizontal-40">
          <Text type="h1" className="marginBottom-40">
            App Assets
          </Text>

          <ImageInput
            ref="logoInput"
            containerStyle={{ width: 800, height: 200 }}
            style={{ width: 800, height: 200 }}
            isRequired
            refreshCallback={this.onImageUpdated}
            simpleStyle
            noCompress
            noMenu
            noDownload
          />

          <div className="marginTop-40 flex flex-center">
            <Button
              className="marginRight-20"
              inline
              buttonType="primary"
              onClick={() => {
                this.handleSubmit('appIcon');
              }}
              isActive={this.validateForm()}
            >
              iOS App Icon
            </Button>
            <Button
              className="marginRight-20"
              inline
              buttonType="primary"
              onClick={() => {
                this.handleSubmit('androidIcon');
              }}
              isActive={this.validateForm()}
            >
              Android App Icon
            </Button>
            <Button
              inline
              buttonType="primary"
              onClick={() => {
                this.handleSubmit('splash');
              }}
              isActive={this.validateForm()}
            >
              Splash
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(AppStoreAssets);
