import React, { Component } from 'react';

class PageContainer extends Component {
    render() {
        return (
            <div
                style={{
                    maxWidth: this.props.maxWidth || 500,
                    margin: '0 auto',
                    padding: 15,
                    paddingTop: 85,
                    ...this.props.style
                }}>
                {this.props.children}
            </div>
        );
    }
}

export { PageContainer };
