import React, { Component } from 'react';
import { connect } from 'react-redux';

class OnboardingFade extends Component {
  render() {
    if (this.props.onboardingStep !== this.props.step) {
      return null;
    }
    return <div className="onboardingFade"></div>;
  }
}

const mapStateToProps = (state) => {
  const { nav } = state;
  return { onboardingStep: nav.onboardingStep };
};

const onboardingFade = connect(mapStateToProps, {})(OnboardingFade);
export { onboardingFade as OnboardingFade };
