import React, { Component } from 'react';
import moment from 'moment';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import { OverlayPage, OverlayPageContents, OverlayPageSection } from '../../components';
import { checkLoggedIn } from '../../session';
import { safeReadParams } from '../../helper';
import { keyActions } from '../../webapi';

class KeyHistory extends Component {
    state = {
        infoId: safeReadParams(this.props, 'infoId'),
        keyList: [],
        sortColumn: 'UnixTimestamp',
        sortDesc: true
    }

    UNSAFE_componentWillMount() {
        checkLoggedIn(this, this.props.auth);
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        keyActions.getKeyHistory(this.state.infoId)
            .then((res) => {
                this.setState({
                    keyList: res.data
                });
            });
    }

    sortByCol(col) {
        if (this.state.sortColumn === col) {
            this.setState({
                sortDesc: !this.state.sortDesc
            });
        } else {
            this.setState({
                sortColumn: col,
                sortDesc: false
            });
        }
    }

    renderList() {
        if (_.isEmpty(this.state.keyList)) {
            return null;
        }
        let source = _.sortBy(this.state.keyList, this.state.sortColumn);
        if (this.state.sortDesc) {
            source.reverse();
        }
        source = _.filter(source, (ev) => {
            if (!ev) {
                return false
            }
            return true;
        });

        return (source.map((ev, index) => {
            if (ev != null) {
                return (
                    <tr key={index}>
                        <td>{moment.utc(ev.Timestamp).local().format('D MMM YY - h:mma')}</td>
                        <td>{ev.name}</td>
                        <td>{ev.phone}</td>
                        <td>{ev.email}</td>
                        <td>{ev.action}</td>
                    </tr >
                );
            }
            return null;
        })
        )
    }

    render() {
        return (
            <OverlayPage>
                <OverlayPageContents noBottomButtons>
                    <OverlayPageSection className='pageSectionWrapper--newPopup'>
                        <div className="padding-60 paddingVertical-40">
                            <p className="text-sectionTitle">
                                KEY HISTORY
                            </p>
                            <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
                                <thead>
                                    <tr>
                                        <th style={{ cursor: 'pointer' }} onClick={() => { this.sortByCol('UnixTimestamp'); }}>
                                            Time
                                    </th>
                                        <th style={{ cursor: 'pointer' }} onClick={() => { this.sortByCol('name'); }}>
                                            Name
                                    </th>
                                        <th>
                                            Phone
                                    </th>
                                        <th>
                                            Email
                                    </th>
                                        <th>
                                            Action
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderList()}
                                </tbody>
                            </Table>
                        </div>
                    </OverlayPageSection>
                </OverlayPageContents>
            </OverlayPage>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
};

export default connect(mapStateToProps, {})(KeyHistory);