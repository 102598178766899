import React, { Component } from 'react';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';

class NumberSpinner extends Component {
  canAdd = () => {
    const { value, max, disabled } = this.props;
    return !disabled && (!_.isNumber(max) || max > value);
  };

  canSubtract = () => {
    const { value, min, disabled } = this.props;
    return !disabled && (!_.isNumber(min) || min < value);
  };

  onAdd = () => {
    const { onPlus, value } = this.props;
    if (this.canAdd() && onPlus) onPlus(value + 1);
  };

  onSubtract = () => {
    const { onMinus, value } = this.props;
    if (this.canSubtract() && onMinus) onMinus(value - 1);
  };

  render() {
    const { className, textClassName, buttonClassName, iconClassName, style, buttonStyle, iconStyle, textStyle, value } = this.props;
    const canSubtract = this.canSubtract();
    const canAdd = this.canAdd();
    // console.log('canSubtract', canSubtract, 'canAdd', canAdd);
    return (
      <div className={`numberSpinner ${className}`} style={style}>
        <div
          onClick={this.onSubtract}
          className={`buttonContainer ${canSubtract ? '' : 'disabled'} ${buttonClassName}`}
          style={buttonStyle}
        >
          <FontAwesome name="minus" className={`buttonIcon ${iconClassName}`} style={iconStyle} />
        </div>
        <div className={`numberText ${textClassName}`} style={textStyle}>
          {value || 0}
        </div>
        <div onClick={this.onAdd} className={`buttonContainer ${canAdd ? '' : 'disabled'} ${buttonClassName}`} style={buttonStyle}>
          <FontAwesome name="plus" className={`buttonIcon ${iconClassName}`} style={iconStyle} />
        </div>
      </div>
    );
  }
}

export { NumberSpinner };
