import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Header, CheckBox, GenericInput, ImageInput, DropdownInput } from '../../components';
import { Text } from '../../components/text';
import { MakerPopup } from '../../components/MakerPopup';
import { CONST_STRINGS, logo } from '../../config';
import { onlyAlphanumeric } from '../../helper';
import { typeActions, utilityActions } from '../../webapi';
import { COLOUR_BRANDING_APP } from '../../js';

class AppStoreAssets extends Component {
  constructor(props) {
    super(props);

    this.presetImages = [
      { Key: 'UYO', Title: 'Upload your own' },
      {
        Title: 'Alert (branded)',
        Key: 'alert',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/6713967e4516b0f9d6733d9811/alertsiphonex.png',
      },
      {
        Title: 'Alert (unbranded)',
        Key: 'alert_neutral',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/97819e994631990c56d9469fe5/alertiphone12.png',
      },
      {
        Title: 'Group (branded)',
        Key: 'chat',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/efccb4a34f2d9af3f1f14af30a/chatiphonex.png',
      },
      {
        Title: 'Group (unbranded)',
        Key: 'group',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/ad17e20c4b5ea8cb64b3eb75c7/groupiphone12.png',
      },
      {
        Title: 'Gallery (unbranded)',
        Key: 'gallery',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/8b0506bc431da2626e4041700e/galleryiphone12.png',
      },
      {
        Title: 'Live Event (branded)',
        Key: 'liveEvent',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/5e543f36456ca9bbc4729dcd8b/liveeventiphonex.png',
      },
      {
        Title: 'Event 1 (unbranded)',
        Key: 'event_neutral_1',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/11c829db4d7b8d0aadf55eecc8/event1iphone12.png',
      },
      {
        Title: 'Event 2 (unbranded)',
        Key: 'event_neutral_2',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/c3b1877c42c3bd83f512334fbc/event2iphone12.png',
      },
      {
        Title: 'Event 3 (unbranded)',
        Key: 'event_neutral_3',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/c522df6948c5b59021cc8f97f5/event3iphone12.png',
      },
      {
        Title: 'News 1 (unbranded)',
        Key: 'news_neutral_1',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/ca277d9f45f3a62a81c74aa8ce/news1iphone12.png',
      },
      {
        Title: 'News 2 (unbranded)',
        Key: 'news_neutral_2',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/e2abcd7b4b498ba48a444e4cbf/news2iphone12.png',
      },
      {
        Title: 'News 3 (unbranded)',
        Key: 'news_neutral_3',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/fe12f2b84785b9d6d482ccee3e/news3iphone12.png',
      },
      {
        Title: 'News 4 (unbranded)',
        Key: 'news_neutral_4',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/4a2fc18644a58267d6034d775d/news4iphone12.png',
      },
      {
        Title: 'News 5 (unbranded)',
        Key: 'news_neutral_5',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/a06744a84773a8a5771df4b5b3/news5iphone12.png',
      },
      {
        Title: 'News 6 (unbranded)',
        Key: 'news_neutral_6',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/f9d003c8416bb6de0df0344d19/news6iphone12.png',
      },
      {
        Title: 'Services (branded)',
        Key: 'services',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/b18916cc432e818baf2feeff22/servicesiphonex.png',
      },
      {
        Title: 'Offer (branded)',
        Key: 'offer',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/7dd4443b44afa9e1e78d87fb3b/offeriphonex.png',
      },
      {
        Title: 'News (branded)',
        Key: 'news',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/0c7982454af2bafa0772e3a09e/newsiphonex.png',
      },
      {
        Title: `${CONST_STRINGS.REQUEST} (branded)`,
        Key: 'maintenance',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/072586f44e73b01e508e478c02/maintenanceiphonex.png',
      },
      {
        Title: 'Surveys (branded)',
        Key: 'surveys',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/d8516eca44f88928c01caee3c9/surveysiphonex.png',
      },
      {
        Title: 'Survey (unbranded)',
        Key: 'survey_neutral',
        Url: 'https://pluss-prd-uploads.s3.ap-southeast-2.amazonaws.com/uploads/users/ap-southeast-2:80aecdcb-9955-493e-a341-2f2263f64777/public/92e0849d4339bd65462b2513d2/surveyiphone12.png',
      },
    ];

    this.state = {
      selectedPreset: null,
      includeFrame: true,
    };
  }

  componentDidMount() {
    this.getBranding();
  }

  getBranding() {
    typeActions.getSiteBranding(this.props.auth.site).then((res) => {
      console.log(res.data);
      this.setState({
        loadingBranding: false,
        siteName: res.data.Name,
        colour: res.data.MainBrandingColour || COLOUR_BRANDING_APP,
        logo: res.data.Logo || logo,
        headerType: res.data.HeaderType || 'white',
      });
    });
  }

  handleTextChange = (event) => {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  };

  validateForm() {
    if (!this.state.selectedPreset) {
      return false;
    }
    if (this.state.selectedPreset === 'UYO' && _.isEmpty(this.state.image)) {
      return false;
    }
    if (_.isEmpty(this.state.screenshotText)) {
      return false;
    }
    return true;
  }

  onImageUpdated = (url) => {
    this.setState({
      image: url,
    });
  };

  selectPreset = (preset) => {
    this.setState({
      selectedPreset: preset,
    });
  };

  getSelectedPresetTitle = () => {
    if (!this.state.selectedPreset) {
      return undefined;
    }
    return _.find(this.presetImages, (f) => {
      return f.Key === this.state.selectedPreset;
    }).Title;
  };

  getSelectedPresetUrl = () => {
    if (!this.state.selectedPreset) {
      return undefined;
    }
    return _.find(this.presetImages, (f) => {
      return f.Key === this.state.selectedPreset;
    }).Url;
  };

  toggleIncludeFrame = () => {
    this.setState({
      includeFrame: !this.state.includeFrame,
    });
  };

  handleSubmit = (sizes) => {
    if (!this.validateForm()) {
      return;
    }

    const data = {
      postProcessing: {
        optimize: false,
        targetDPI: 300,
      },
      format: 'png',
      data: {
        text: this.state.screenshotText,
        image: this.state.selectedPreset === 'UYO' ? this.state.image : undefined,
        includeFrame: this.state.selectedPreset === 'UYO' ? this.state.includeFrame : true,
        presetImage: this.state.selectedPreset === 'UYO' ? undefined : this.state.selectedPreset,
        colour: this.state.colour,
        logo: this.state.logo,
        headerType: this.state.headerType,
      },
    };

    sizes.forEach((device) => {
      let height = 0;
      let width = 0;
      switch (device) {
        case 'iPhoneX':
          height = 2778;
          width = 1284;
          break;
        case 'iPhone8':
          height = 2208;
          width = 1242;
          break;
        case 'iPad':
          height = 2732;
          width = 2048;
          break;
        case 'androidFeature':
          height = 500;
          width = 1024;
          break;
        default:
          break;
      }

      const fileName = `${onlyAlphanumeric(this.props.auth.site)}_${device}_${onlyAlphanumeric(this.state.selectedPreset)}`;

      const dataToSubmit = {
        ...data,
        customSize: {
          height,
          width,
          unit: 'px',
        },
        fileName,
      };
      dataToSubmit.data.frame = device;

      this.setState({
        anySubmitted: true,
        [`${device}Submitting`]: true,
        [`${device}Url`]: null,
        [`${device}FileName`]: fileName,
      });

      utilityActions
        .generateMake('d98af1d8-ad56-4c7c-a5ac-655704ddfbab', dataToSubmit)
        .then((res) => {
          this.setState({
            [`${device}Submitting`]: false,
            [`${device}Url`]: res.data.resultUrl,
          });
        })
        .catch((err) => {
          this.setState({
            [`${device}Submitting`]: false,
            [`${device}Url`]: null,
          });
        });
    });
  };

  render() {
    return (
      <div>
        <Header />
        <div className="pageContainer container600 paddingVertical-20 paddingHorizontal-40">
          <Text type="h1" className="marginBottom-40">
            App Store Screenshots
          </Text>
          <Text type="subtitle" className="marginBottom-20">
            The App Store and Play Store require multiple screenshots of what your app will look like. It's also a chance to show off the
            great features to your audience. We will walk you through the requirements:
          </Text>

          <GenericInput
            className="marginTop-10"
            id="screenshotText"
            label="Store Image Description"
            alwaysShowLabel
            value={this.state.screenshotText}
            onChange={this.handleTextChange}
            help='For each of your store images a description is needed (ie "The app for Community conversation")'
          />
          {/* <Text type="formLabel" className="marginTop-10">
            Feature Images
          </Text> */}
          <DropdownInput
            id="presetImage"
            label="Images of Features"
            placeholder="Upload your own or choose from ours"
            value={this.getSelectedPresetTitle()}
            options={this.presetImages}
            onSelect={this.selectPreset}
            className="marginTop-8"
            alwaysShowLabel
          />
          <ImageInput
            ref="screenshotInput"
            containerStyle={{ width: 250, height: 350, display: this.state.selectedPreset === 'UYO' ? 'block' : 'none' }}
            style={{ width: 250, height: 350 }}
            isRequired
            refreshCallback={this.onImageUpdated}
            simpleStyle
            noCompress
            noMenu
            noDownload
          />
          {!_.isEmpty(this.state.selectedPreset) && this.state.selectedPreset !== 'UYO' && (
            <img style={{ height: 350 }} src={this.getSelectedPresetUrl()} />
          )}
          <CheckBox
            style={{ display: this.state.selectedPreset === 'UYO' ? 'flex' : 'none' }}
            className="marginTop-10"
            id={`includeFrame`}
            label="Include device frame"
            isActive={this.state.includeFrame}
            onChange={this.toggleIncludeFrame}
          />
          {this.state.anySubmitted && (
            <div className="marginTop-40">
              <Text type="formTitleMedium">Results</Text>
              <div className="flex flex-center">
                <div className="marginRight-40 genericBox padding-10 marginTop-10">
                  <Text type="formTitleSmall">iPhone X</Text>
                  <Text type="body">
                    {this.state.iPhoneXSubmitting ? (
                      'Generating'
                    ) : _.isEmpty(this.state.iPhoneXUrl) ? (
                      'Nothing yet'
                    ) : (
                      <a download={this.state.iPhoneXFileName} href={this.state.iPhoneXUrl} target="_blank">
                        Download
                      </a>
                    )}
                  </Text>
                </div>
                <div className="marginRight-40 genericBox padding-10 marginTop-10">
                  <Text type="formTitleSmall">iPad</Text>
                  <Text type="body">
                    {this.state.iPadSubmitting ? (
                      'Generating'
                    ) : _.isEmpty(this.state.iPadUrl) ? (
                      'Nothing yet'
                    ) : (
                      <a download={this.state.iPadFileName} href={this.state.iPadUrl} target="_blank">
                        Download
                      </a>
                    )}
                  </Text>
                </div>
                <div className="marginRight-40 genericBox padding-10 marginTop-10">
                  <Text type="formTitleSmall">iPhone 8</Text>
                  <Text type="body">
                    {this.state.iPhone8Submitting ? (
                      'Generating'
                    ) : _.isEmpty(this.state.iPhone8Url) ? (
                      'Nothing yet'
                    ) : (
                      <a download={this.state.iPhone8FileName} href={this.state.iPhone8Url} target="_blank">
                        Download
                      </a>
                    )}
                  </Text>
                </div>
                <div className="genericBox padding-10 marginTop-10">
                  <Text type="formTitleSmall">Android Feature</Text>
                  <Text type="body">
                    {this.state.androidFeatureSubmitting ? (
                      'Generating'
                    ) : _.isEmpty(this.state.androidFeatureUrl) ? (
                      'Nothing yet'
                    ) : (
                      <a download={this.state.androidFeatureFileName} href={this.state.androidFeatureUrl}>
                        Download
                      </a>
                    )}
                  </Text>
                </div>
              </div>
            </div>
          )}
          <div className="marginTop-40 flex flex-center">
            <Button
              className="marginRight-20"
              inline
              buttonType="primary"
              onClick={() => {
                this.handleSubmit(['iPhoneX', 'iPad', 'iPhone8']);
              }}
              isActive={this.validateForm()}
            >
              All
            </Button>
            <Button
              className="marginRight-20"
              inline
              buttonType="primary"
              onClick={() => {
                this.handleSubmit(['iPhoneX']);
              }}
              isActive={this.validateForm()}
            >
              iPhone X
            </Button>
            <Button
              className="marginRight-20"
              inline
              buttonType="primary"
              onClick={() => {
                this.handleSubmit(['iPad']);
              }}
              isActive={this.validateForm()}
            >
              iPad
            </Button>
            <Button
              className="marginRight-20"
              inline
              buttonType="primary"
              onClick={() => {
                this.handleSubmit(['iPhone8']);
              }}
              isActive={this.validateForm()}
            >
              iPhone 8 / Android
            </Button>
            <Button
              inline
              buttonType="primary"
              onClick={() => {
                this.handleSubmit(['androidFeature']);
              }}
              isActive={this.validateForm()}
            >
              Android Feature Graphic
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(AppStoreAssets);
