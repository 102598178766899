import React, { Component } from 'react';
import icons from '../assets/fonts/svg-icons.json';
import _ from 'lodash';

class SVGIcon extends Component {
  renderPaths() {
    const { icon, colour } = this.props;

    return icons[icon].paths.map((p, i) => {
      let pathProps = {};
      if (icons[icon].pathRules && icons[icon].pathRules[i]) {
        pathProps = icons[icon].pathRules[i];
      }
      return <path key={p.substring(0, 20)} d={p} fill={colour} {...pathProps} />;
    });
  }

  render() {
    const icon = icons[this.props.icon];
    if (!icon) {
      return null;
    }
    const viewBox = icons[this.props.icon].viewBox;

    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} {..._.omit(this.props, ['colour'])}>
        {this.renderPaths()}
      </svg>
    );
  }
}

export { SVGIcon };
