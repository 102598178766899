import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { GenericInput, DatePicker, Popup } from '../../components';
import { Text } from '../../components/text';
import { alertsUpdate } from '../../actions';
import { alertActions } from '../../webapi';

const TO_ALL = 'ALL';
class SendAlertPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showWarnings: false,
      saving: false,
      sendAlertTimeSlot: null,
      alertTitle: '',
      alertText: '',
      alertExpiry: moment().add(7, 'days').format('YYYY-MM-DD'),
      alertExpiryText: moment().add(7, 'days').format('DD/MM/YYYY'),
      showAlertExpiryDate: false,
      audiences: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loadingAttendees && !this.props.loadingAttendees) {
      const attendedReps = this.getAttendedReps();
      if (attendedReps && attendedReps.length === 1) this.onSelectSlot(attendedReps[0].Id);
    }
  }

  getAttendedReps = () => {
    const { reps, attendees } = this.props;
    if (!reps || !attendees) return [];

    return reps.filter((rep) => attendees.find((att) => att.RepId === rep.Id && att.Attendees && att.Attendees.length > 0));
  };

  isTimeSlotSelected = () => !_.isNull(this.state.sendAlertTimeSlot);

  canSave = () => !_.isEmpty(this.props.eventId) && !_.isEmpty(this.state.alertTitle);

  onSelectSlot = (repId) => {
    let attendees = [];
    if (repId === TO_ALL) {
      attendees = _.uniqBy(this.props.attendees.map((a) => a.Attendees).flat(), 'id');
    } else {
      const repAttendees = this.props.attendees.find((att) => att.RepId === repId);
      attendees = repAttendees && repAttendees.Attendees ? repAttendees.Attendees : [];
    }

    const audiences = attendees
      .filter((att) => !att.isGuest)
      .map((att) => {
        return { Id: att.id, displayName: att.displayName, profilePic: att.profilePic };
      });

    this.setState({ sendAlertTimeSlot: repId, audiences });
  };

  onClose = () => {
    this.setState({ sendAlertTimeSlot: null }, () => {
      if (this.props.onClose) this.props.onClose();
    });
  };

  onSave = () => {
    if (!this.canSave()) {
      this.setState({ showWarnings: true });
      return;
    }

    const { eventId, eventTitle } = this.props;
    const { alertTitle, alertText, alertExpiry, audiences, sendAlertTimeSlot } = this.state;
    const obj = {
      Title: eventTitle ? `${eventTitle}: ${alertTitle}` : alertTitle,
      Description: alertText,
      Expiry: alertExpiry,
      AudienceType: 'User',
      AudienceTypeSelection: '',
      IsImportant: false,
      Site: this.props.auth.site,
      TVMode: false,
      Include: audiences,
      EntityType: 'event',
      EntityInfo: { id: eventId, repId: sendAlertTimeSlot === TO_ALL ? null : sendAlertTimeSlot },
    };

    this.setState({ saving: true, showWarnings: false }, async () => {
      try {
        alertActions.addAlert(obj);
        this.props.alertsUpdate(this.props.auth.site);
        this.setState({ saving: false }, this.onClose);
      } catch (error) {
        this.setState({ saving: false });
        console.log(error);
        alert('Something went wrong with the request. Please try again.');
      }
    });
  };

  handleChange(event) {
    var newState = {};
    newState[event.target.getAttribute('id')] = event.target.value;
    this.setState(newState);
  }

  handleDateTextChange = (value) => {
    const newState = { alertExpiryText: value };
    const m = moment(value, 'DD/MM/YYYY');

    if (m.isValid() && m.year() > 1900) newState.alertExpiryText = m.format('YYYY-MM-DD');
    this.setState(newState);
  };

  handleDateChange = (date) => {
    var newState = {
      alertExpiry: date,
      alertExpiryText: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      showAlertExpiryDate: false,
    };
    this.setState(newState);
  };

  renderContent = () => {
    const { loadingAttendees } = this.props;
    const { saving, showWarnings, alertTitle, alertText, alertExpiryText, alertExpiry, showAlertExpiryDate } = this.state;

    if (this.isTimeSlotSelected()) {
      if (saving) {
        return (
          <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
            <FontAwesome style={{ fontSize: 30 }} name="spinner fa-pulse fa-fw" />
          </div>
        );
      }

      return (
        <div>
          <GenericInput
            id="alertTitle"
            type="text"
            label="Title"
            placeholder="Insert title here"
            showError={() => showWarnings && _.isEmpty(alertTitle)}
            value={alertTitle}
            onChange={(e) => this.handleChange(e)}
            isRequired
            isValid={() => !_.isEmpty(alertTitle)}
            alwaysShowLabel
          />
          <GenericInput
            id="alertText"
            label="Text"
            type="textarea"
            componentClass="textarea"
            placeholder="Insert text here"
            value={alertText}
            onChange={(e) => this.handleChange(e)}
            inputStyle={{ height: 120 }}
            alwaysShowLabel
          />
          <GenericInput
            id="alertExpiryText"
            label="Expiry Date"
            alwaysShowLabel
            placeholder={alertExpiryText}
            value={alertExpiryText}
            onChange={(e) => this.handleDateTextChange(e.target.value)}
            onClick={(e) => this.setState({ showAlertExpiryDate: !showAlertExpiryDate })}
            style={{ marginTop: 24 }}
            help="Alerts by default will be removed from users' notifications after one week."
          />
          {showAlertExpiryDate && <DatePicker selectedDate={alertExpiry} selectDate={this.handleDateChange} />}
        </div>
      );
    }

    const attendedReps = this.getAttendedReps();
    if (attendedReps.length === 0) {
      return (
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          {loadingAttendees ? (
            <FontAwesome style={{ fontSize: 30 }} name="spinner fa-pulse fa-fw" />
          ) : (
            <Text type="h3">There are no attendees</Text>
          )}
        </div>
      );
    }

    return (
      <div style={{ overflow: 'scroll' }}>
        <p onClick={() => this.onSelectSlot(TO_ALL)} className="attendanceEntry">
          Send to all
        </p>
        <div style={{ marginLeft: 30, marginBottom: 8 }}>or</div>
        {attendedReps.map((rep) => {
          const repText = `${moment(rep.Date, 'YYYY-MM-DD').format('D MMMM')} • ${moment(rep.Time, 'HH:mm').format('h:mma')}`;
          return (
            <p key={rep.Id} onClick={() => this.onSelectSlot(rep.Id)} className="attendanceEntry">
              {repText}
            </p>
          );
        })}
      </div>
    );
  };

  render() {
    const title = this.isTimeSlotSelected() ? 'Send Alert' : 'Select Time Slot';
    const buttons = (() => {
      const result = [
        {
          type: 'tertiary',
          onClick: this.onClose,
          isActive: true,
          text: 'Cancel',
        },
      ];
      if (this.isTimeSlotSelected()) {
        result.unshift({
          type: 'primary',
          onClick: this.onSave,
          isActive: this.canSave(),
          text: 'Save',
        });
      }
      return result;
    })();

    return (
      <Popup
        boxClasses="flex flex-column"
        innerClasses="flex flex-column fillSpace flex-1"
        minWidth={500}
        maxWidth={500}
        minHeight={'50vh'}
        maxHeight={'100vh'}
        title={title}
        hasPadding
        onClose={this.onClose}
        buttons={buttons}
      >
        {this.renderContent()}
      </Popup>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { alertsUpdate })(SendAlertPopup);
