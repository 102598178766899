import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router';
import { setNavData } from '../../actions';
import onboardingSteps from '../../json/onboarding/onboarding.json';
import { Button } from '../Button';

class OnboardingStep extends Component {
  onClose = () => {
    const stepConfig = onboardingSteps[this.props.step];
    this.props.setNavData({
      onboardingStep: null,
    });
    if (stepConfig.redirectOnClose) {
      setTimeout(() => {
        this.props.history.push(stepConfig.redirectOnClose);
      }, 50);
    }
  };

  onNext = () => {
    const stepConfig = onboardingSteps[this.props.step];
    const next = onboardingSteps[stepConfig.next];
    this.props.setNavData({
      onboardingStep: stepConfig.next,
      ...stepConfig.nextData,
    });
    if (next.route !== stepConfig.route) {
      setTimeout(() => {
        this.props.history.push(next.route);
      }, 50);
    }
  };

  onPrev = () => {
    const stepConfig = onboardingSteps[this.props.step];
    const prev = onboardingSteps[stepConfig.prev];
    this.props.setNavData({
      onboardingStep: stepConfig.prev,
      ...stepConfig.prevData,
    });
    if (prev.route !== stepConfig.route) {
      setTimeout(() => {
        this.props.history.push(prev.route);
      }, 50);
    }
  };

  renderPrev(stepConfig) {
    if (!stepConfig.back) {
      return null;
    }
    return (
      <Button buttonType="tertiary" onClick={this.onPrev} isActive>
        Back
      </Button>
    );
  }

  renderNext(stepConfig) {
    if (!stepConfig.next) {
      return null;
    }
    return (
      <Button buttonType="tertiary" style={stepConfig.back && { marginLeft: 16 }} onClick={this.onNext} isActive>
        Next
      </Button>
    );
  }

  renderClose(stepConfig) {
    if (!stepConfig.hasClose) {
      return null;
    }
    return <FontAwesome className="onboardingContainer_close" name="times-circle" onClick={this.onClose} />;
  }

  render() {
    if (this.props.step !== this.props.onboardingStep) {
      return null;
    }
    const stepConfig = onboardingSteps[this.props.step];
    return (
      <div className={`onboardingContainer onboardingContainer-${stepConfig.colourClass} onboardingContainer-${this.props.step}`}>
        {this.renderClose(stepConfig)}
        <p className="onboardingContainer_title">{stepConfig.title}</p>
        <div className="onboardingContainer_bottom">
          <img
            src="https://pluss-prd-media.s3-ap-southeast-2.amazonaws.com/assets/laptop-asset.svg"
            alt="laptop"
            className="onboardingContainer_laptop"
          />
          <div className="onboardingContainer_bottomLeft">
            <p className="onboardingContainer_text">{stepConfig.text}</p>
            <div className="flex">
              {this.renderPrev(stepConfig)}
              {this.renderNext(stepConfig)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { nav } = state;
  return { onboardingStep: nav.onboardingStep };
};

const onboardingStep = connect(mapStateToProps, { setNavData })(withRouter(OnboardingStep));
export { onboardingStep as OnboardingStep };
