import _ from 'lodash';
import {
  EVENTS_LOADED,
  EVENTS_UPDATED,
  EVENT_REMOVED,
  EVENT_SUBMISSIONS_LOADED,
  EVENT_SUBMISSION_REMOVED,
  EVENTS_PURGE,
  EVENT_DASHBOARD_LOADING,
  EVENT_SUBMISSIONS_DASHBOARD_LOADING,
  EVENTS_MANAGED_LOADED,
  AVAILABLE_EVENTS_LOADED,
} from '../actions/types';

const INITIAL_STATE = {
  events: [],
  loading: false,
  submissions: [],
  submissionsLoading: false,
  managed: [],
  templates: [],
  globals: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //////////////////////
    // EVENTS
    //////////////////////
    case EVENT_DASHBOARD_LOADING:
      return {
        ...state,
        loading: true,
      };
    case EVENTS_LOADED:
      var result = _.unionWith(action.payload, state.events, (v1, v2) => {
        return v1 != null && v2 != null && v1.RowId === v2.RowId;
      });
      result = _.filter(result, (ev) => {
        return !ev.IsEveryday;
      });
      return {
        ...state,
        events: result,
        loading: false,
      };
    case EVENTS_UPDATED:
      var resultU = _.unionWith(action.payload, state.events, (v1, v2) => {
        return v1 != null && v2 != null && v1.RowId === v2.RowId;
      });
      resultU = _.filter(resultU, (ev) => {
        return !ev.IsEveryday;
      });
      return {
        ...state,
        events: resultU,
        loading: false,
      };
    case EVENT_REMOVED:
      const index = _.findIndex(state.events, (event) => {
        return event != null && event.RowId === action.payload;
      });
      if (index > -1) {
        const newEvents = [...state.events];
        newEvents.splice(index, 1);
        return { ...state, events: newEvents };
      }
      return state;
    //////////////////////
    // SUBMISSIONS
    //////////////////////
    case EVENT_SUBMISSIONS_DASHBOARD_LOADING:
      return {
        ...state,
        submissionsLoading: true,
      };
    case EVENT_SUBMISSIONS_LOADED:
      var resultSub = _.unionWith(action.payload, state.submissions, (v1, v2) => {
        return v1 != null && v2 != null && v1.RowId === v2.RowId;
      });
      return {
        ...state,
        submissions: resultSub,
        submissionsLoading: false,
      };
    case EVENTS_MANAGED_LOADED:
      return {
        ...state,
        managed: action.payload,
      };
    case AVAILABLE_EVENTS_LOADED:
      if (action.payload.type === 'global') {
        return {
          ...state,
          globals: action.payload.list,
        };
      }
      if (action.payload.type === 'template') {
        return {
          ...state,
          templates: action.payload.list,
        };
      }
      return state;
    case EVENT_SUBMISSION_REMOVED:
      const indexSub = _.findIndex(state.submissions, (event) => {
        return event != null && event.RowId === action.payload;
      });
      if (indexSub > -1) {
        const newEventsSub = [...state.submissions];
        newEventsSub.splice(indexSub, 1);
        return { ...state, submissions: newEventsSub };
      }
      return state;
    case EVENTS_PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
