import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';

class ConfirmActions extends Component {
    generateClassNames() {
        let string = '';
        if (this.props.isActive) {
            string = string + ' aCheck--active'
        }
        return string;
    }

    render() {
        return (
            <div>
                <div style={styles.row}>
                    {/* Decline Button */}
                    {!this.props.confirmOnly &&
                        <div
                            className="aCheck marginRight-8"
                            onClick={this.props.onDecline}
                        >
                            <div className="aCheck-Tick">
                                <FontAwesome
                                    className="aCheck-font"
                                    name={'times'}
                                />
                            </div>
                        </div>
                    }
                    {/* Confirm Button */}
                    {!this.props.declineOnly &&
                        <div
                            className={`aCheck ${this.generateClassNames()}`}
                            onClick={this.props.onConfirm}
                        >
                            <div className="aCheck-Tick">
                                <FontAwesome
                                    className="aCheck-font"
                                    name={'check'}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const styles = {
    row: {
        display: 'flex',
        flexDirection: 'row'
    }
};

export { ConfirmActions };
