import { Component } from 'react';
import { connect } from 'react-redux';
import { refreshAuthUser } from '../actions';

class UserRefresh extends Component {
  componentDidMount() {
    this.props.refreshAuthUser(this.props.auth.site);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.site !== this.props.auth.site) {
      this.props.refreshAuthUser(nextProps.auth.site);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

const userRefresh = connect(mapStateToProps, { refreshAuthUser })(UserRefresh);
export { userRefresh as UserRefresh };
