export const CONST_STRINGS = {
  REQUEST: 'Request',
  MAINTENANCE_REQUEST: 'Request',
  MAINTENANCE: 'Requests',
  MAINTENANCE_HOME: 'Must be home',
  JOB_TYPE: 'Request Type',
  PERMISSION_MAINTENANCETYPES: 'Manage Request Types',
  PERMISSION_MAINTENANCETRACKING: 'Request Tracking',
  PERMISSION_FAQS: 'Manage Information',
  FAQS: 'Information',
  FAQ_ENTRY: 'info page',
  FEEDBACK_ANONYMOUS: 'Anonymous',
  KIOSK_FORM_LOCKOUT: 'Lockout Form',
  KIOSK_FORM_SIGNIN: 'Sign In Form',
  FACILITY: 'Facility',
  FACILITIES: 'Facilities',
  FACILITIES_MANAGEMENT: 'Facilities Management',
};
