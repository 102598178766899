import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { TEXT_LIGHT } from '../js';

class FilterMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={styles.wrapper}>
        {this.props.children}
        {!this.props.hideViewChange && this.props.showCalendar && (
          <a
            style={{ marginLeft: 16 }}
            onClick={() => {
              this.props.updateView('week');
            }}
            className={`hubOptions${this.props.viewType === 'week' ? ' hubOptions--active' : ''}`}
          >
            <FontAwesome name="columns" style={styles.iconStyle} />
          </a>
        )}
        {!this.props.hideViewChange && this.props.showCalendar && (
          <a
            style={{ marginLeft: 16 }}
            onClick={() => {
              this.props.updateView('calendar');
            }}
            className={`hubOptions${this.props.viewType === 'calendar' ? ' hubOptions--active' : ''}`}
          >
            <FontAwesome name="calendar-o" style={styles.iconStyle} />
          </a>
        )}
        {!this.props.hideViewChange && (
          <a
            style={{ marginLeft: 16 }}
            onClick={() => {
              this.props.updateView('list');
            }}
            className={`hubOptions${this.props.viewType === 'list' ? ' hubOptions--active' : ''}`}
          >
            <FontAwesome name="bars" style={styles.iconStyle} />
          </a>
        )}
      </div>
    );
  }
}

const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 16,
  },
  iconStyle: {
    lineHeight: '19px',
    fontSize: 20,
    cursor: 'pointer',
    color: TEXT_LIGHT,
  },
  iconStyle_alpha: {
    fontSize: 17,
  },
  iconStyle_arrow: {
    width: 9,
  },
};

export { FilterMenu };
