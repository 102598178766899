import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { Button } from './Button';

class WarningPopup extends Component {
    getBackgroundClasses() {
        if (this.props.backgroundClose) {
            return 'successPopupBackground pointer';
        }
        return 'successPopupBackground';
    }

    renderButtons() {
        return this.props.buttons.map((b, i) => {
            return <Button
                key={i}
                buttonClassName='successPopup_button'
                buttonType={b.type}
                isActive
                onClick={b.onClick}
            >
                {b.text}
            </Button>
        });
    }

    render() {
        return (
            <div className={this.getBackgroundClasses()}>
                <div className='successPopup'>
                    <div className='successPopup_tick'>
                        <FontAwesome
                            className="successPopup_tick_icon"
                            name={'exclamation-triangle'}
                        />
                    </div>
                    <p className={`successPopup_text ${this.props.smallText ? 'successPopup_text-small' : ''}`}>{this.props.text}</p>
                    {this.props.children}
                    <div className='successPopup_buttons'>
                        {this.renderButtons()}
                    </div>
                </div>
            </div>
        );
    }
}

export { WarningPopup };
