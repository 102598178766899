import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapsUpdate, addRecentlyCreated } from '../../actions';
import {
  Button,
  ImageInput,
  GenericInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  SuccessPopup,
  RadioButton,
} from '../../components';
import { mapActions, fileActions } from '../../webapi';
import { safeReadParams } from '../../helper';
import { checkLoggedIn } from '../../session';
import { Text } from '../../components/text';

class AddMap extends Component {
  initialState = {
    infoId: safeReadParams(this.props, 'infoId'),
    titleInput: '',
    sourceInput: '',
    shouldNotify: false,
    showWarnings: false,
    success: false,
    submitting: false,
  };

  state = { ...this.initialState };

  UNSAFE_componentWillReceiveProps(nextProps) {}

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    if (this.state.infoId) {
      this.getData();
    }
  }

  componentDidMount() {
    this.props.addRecentlyCreated('maps');
  }

  getData() {
    mapActions.getMap(this.state.infoId).then((res) => {
      this.parseUpdate(res.data);
    });
  }

  parseUpdate(update) {
    this.setState({
      titleInput: update.title,
      sourceInput: update.source,
    });
    this.checkSetImage(update.source);
  }

  checkSetImage(url) {
    if (this.refs.imageInput) {
      this.refs.imageInput.getWrappedInstance().setValue(url);
    } else {
      setTimeout(() => {
        this.checkSetImage(url);
      }, 100);
    }
  }

  isEmpty(text) {
    return text === '';
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleFileChange(event) {
    const file = event.target.files[0];
    if (!file || this.state.uploadingImage) {
      return;
    }
    this.setState({
      uploadingImage: true,
    });
    // fileActions.readBase64(file)
    //     .then((res) => {
    fileActions
      .uploadMediaAsync(file, file.name)
      .then((fileRes) => {
        this.setState({
          sourceInput: fileRes,
          uploadingImage: false,
        });
      })
      .catch((uploadErrorRes) => {
        this.setState({
          uploadingImage: false,
        });
      });
    // })
    // .catch((res) => {
    //     this.setState({
    //         uploadingImage: false
    //     });
    // });
  }

  validateLoading() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  validateImage() {
    return (
      this.refs.imageInput &&
      this.refs.imageInput
        .getWrappedInstance()
        .getValue()
        .match(/\.(jpeg|jpg|gif|png|ashx)/) != null
    );
  }

  validateCompulsoryText() {
    if (this.isEmpty(this.state.titleInput)) {
      return false;
    }
    if (!this.validateImage()) {
      return false;
    }
    return true;
  }

  validateForm() {
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (!this.validateLoading()) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true });

    if (this.state.infoId) {
      mapActions
        .editMap(this.props.auth.site, this.state.infoId, this.state.titleInput, this.refs.imageInput.getWrappedInstance().getValue())
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          this.props.mapsUpdate(this.props.auth.site);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    } else {
      mapActions
        .addMap(this.props.auth.site, this.state.titleInput, this.refs.imageInput.getWrappedInstance().getValue(), this.state.shouldNotify)
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          this.props.mapsUpdate(this.props.auth.site);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  clearForm() {
    this.setState(this.initialState);
  }

  clearSuccess() {
    this.setState({
      success: false,
      submitting: false,
      showWarnings: false,
    });
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <SuccessPopup
        text={`Map has been ${this.state.infoId != null ? 'edited' : 'added'}`}
        buttons={[
          {
            type: 'outlined',
            onClick: () => {
              window.history.back();
            },
            text: 'Go to home',
          },
        ]}
      />
    );
  }

  inputsDisabled() {
    if (this.state.submitting) {
      return true;
    }
    return false;
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {!this.inputsDisabled() ? 'Cancel' : 'Back'}
        </Button>
        {!this.inputsDisabled() && (
          <Button inline buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.validateForm()}>
            Save
          </Button>
        )}
      </div>
    );
  }

  renderSideForm() {
    if (this.state.success || this.state.infoId) {
      return null;
    }
    return (
      <OverlayPageSection className="pageSectionWrapper--newPopupSide">
        <div>
          <div className="padding-60 paddingVertical-40 bottomDivideBorder ">
            <Text type="formTitleSmall" className="marginBottom-16">
              Notification
            </Text>

            <RadioButton
              label="Do you want to send a notification with this map?"
              isActive={this.state.shouldNotify}
              options={[
                { Label: 'Yes', Value: true, onChange: () => this.setState({ shouldNotify: true }) },
                { Label: 'No', Value: false, onChange: () => this.setState({ shouldNotify: false }) },
              ]}
            />
            <div className="genericInput-help" style={{ marginTop: 4 }}>
              This will send an alert to all users who have enabled push notifications.
            </div>
          </div>
        </div>
      </OverlayPageSection>
    );
  }

  renderForm() {
    if (this.state.success) {
      return null;
    }
    return (
      <div className="padding-60 paddingVertical-40 bottomDivideBorder">
        <Text type="formTitleLarge" className="marginBottom-24">
          {this.state.infoId == null ? 'New' : 'Edit'} Map
        </Text>
        {/* title */}
        <GenericInput
          id="titleInput"
          type="text"
          label="Title"
          placeholder="Level 5 Floorplan"
          value={this.state.titleInput}
          onChange={(e) => this.handleChange(e)}
          isRequired
          isValid={() => {
            return !this.isEmpty(this.state.titleInput);
          }}
          showError={() => {
            return this.state.showWarnings && this.isEmpty(this.state.titleInput);
          }}
          alwaysShowLabel
        />
        {/* image */}
        {/* <FormGroup controlId="sourceInput"
                    validationState={(this.state.showWarnings && !this.validateImage()) ? 'error' : null}>
                    <ControlLabel>Map Image</ControlLabel>
                    {this.state.uploadingImage ?
                        (<p>Uploading image...</p>) :
                        (<div>
                            <FormControl
                                type="file"
                                onChange={(e) => this.handleFileChange(e)}
                            />
                            <HelpBlock>or</HelpBlock>
                            <GenericInput
                                id="sourceInput"
                                type="text"
                                placeholder="https://..."
                                value={this.state.sourceInput}
                                onChange={(e) => this.handleChange(e)}
                                autoComplete
                                showError={() => { return this.state.showWarnings && !this.validateImage() }}
                                errorMessage='Image url incorrect'
                            />
                        </div>)}
                </FormGroup> */}
        <div className="paddingBottom-20" />
        <ImageInput
          ref="imageInput"
          label="IMAGE"
          isRequired
          refreshCallback={() => {
            this.setState({ imageUpdate: true });
          }}
          showError={() => {
            return this.state.showWarnings && !this.validateImage();
          }}
          noMenu
          // disableRemove={this.inputsDisabled()}
        />
      </div>
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
          {this.renderSideForm()}
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { mapsUpdate, addRecentlyCreated })(AddMap);
