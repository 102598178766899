import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, CheckBox } from '../../../components';
import { checkLoggedIn, isTheBest, isFeatureEnabled } from '../../../session';
import { typeActions } from '../../../webapi';
import { refreshAuthUser } from '../../../actions';

class ManageSiteSettings extends Component {
  state = {
    visitors: false,
    kioskVisitors: false,
    kioskFooter: true,
    useExpandedFeatured: false,
    useGlobalEvents: false,
    tabTitles: true,
    notificationsForComments: false,
    commentVisibilityLimited: false,
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    if (!isTheBest(this.props.auth)) {
      this.props.history.push('/mastermenu');
    }
    this.getSiteSettings();
  }

  getSiteSettings() {
    typeActions.getSite(this.props.auth.site).then((res) => {
      // split into Hidden and NotHidden to control which default to hidden and which default to showing
      const newState = {
        oldSettings: res.data,
      };
      if (res && res.data && res.data.NotHidden) {
        newState.visitors = _.includes(res.data.NotHidden, 'visitors');
      }
      if (res && res.data && res.data.Settings) {
        newState.kioskVisitors = !!res.data.Settings.KioskVisitors;
        newState.useExpandedFeatured = !!res.data.Settings.UseExpandedFeatured;
        newState.notificationsForComments = !!res.data.Settings.NotificationsForComments;
        newState.commentVisibilityLimited = !!res.data.Settings.CommentVisibilityLimited;
      }
      if (res && res.data && res.data.Hidden) {
        newState.kioskFooter = !_.includes(res.data.Hidden, 'kioskFooter');
        newState.tabTitles = !_.includes(res.data.Hidden, 'tabTitles');
      }
      this.setState(newState);
    });
  }

  validateForm() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  compileData() {
    let hidden = this.state.oldSettings.Hidden || [];
    let notHidden = this.state.oldSettings.NotHidden || [];
    const settings = this.state.oldSettings.Settings || {};
    settings.KioskVisitors = this.state.kioskVisitors;
    settings.UseExpandedFeatured = this.state.useExpandedFeatured;
    settings.UseGlobalEvents = this.state.useGlobalEvents;
    settings.NotificationsForComments = this.state.notificationsForComments;
    settings.CommentVisibilityLimited = this.state.commentVisibilityLimited;

    if (this.state.kioskFooter) {
      hidden = _.filter(hidden, (key) => {
        return key !== 'kioskFooter';
      });
    } else {
      hidden.push('kioskFooter');
    }

    if (this.state.tabTitles) {
      hidden = _.filter(hidden, (key) => {
        return key !== 'tabTitles';
      });
    } else {
      hidden.push('tabTitles');
    }

    if (this.state.visitors) {
      notHidden.push('visitors');
    } else {
      notHidden = _.filter(notHidden, (key) => {
        return key !== 'visitors';
      });
      settings.KioskVisitors = false;
    }
    return { Hidden: _.uniq(hidden), NotHidden: _.uniq(notHidden), Settings: settings };
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({
      submitting: true,
      showWarnings: false,
    });

    typeActions
      .editSiteSettings(this.props.auth.site, this.compileData())
      .then((res) => {
        console.log('success');
        this.setState({
          submitting: false,
          success: true,
        });
        this.props.refreshAuthUser(this.props.auth.site);
      })
      .catch((error) => {
        console.log('error', error);
        this.setState({
          submitting: false,
          success: false,
        });
      });
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <Button buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateForm()}>
        Save
      </Button>
    );
  }

  render() {
    return (
      <div style={{ width: '100%', maxWidth: 700 }}>
        <div className="marginBottom-40">
          <p className="fontMedium fontSize-36 text-dark">Content settings</p>
          {isFeatureEnabled(this.props.auth.features, 'events') && (
            <CheckBox
              label="Automatically import all global events from the Available Events-section"
              isActive={this.state.useGlobalEvents}
              onChange={() => {
                this.setState({ useGlobalEvents: !this.state.useGlobalEvents });
              }}
            />
          )}
          <CheckBox
            label="Let featured items display a description"
            isActive={this.state.useExpandedFeatured}
            onChange={() => {
              this.setState({ useExpandedFeatured: !this.state.useExpandedFeatured });
            }}
          />
        </div>
        <div className="marginBottom-40">
          <p className="fontMedium fontSize-36 text-dark">Enable features</p>
          <p className="genericInput-help">The features with ticked boxes will be enabled in the Community Manager and App.</p>
          <CheckBox
            label="Visitor Sign-in"
            isActive={this.state.visitors}
            onChange={() => {
              this.setState({ visitors: !this.state.visitors });
            }}
          />
          <CheckBox
            label="Titles in the tab bar"
            isActive={this.state.tabTitles}
            onChange={() => {
              this.setState({ tabTitles: !this.state.tabTitles });
            }}
          />
          <CheckBox
            label="Notifications for Comments"
            isActive={this.state.notificationsForComments}
            onChange={() => {
              this.setState({ notificationsForComments: !this.state.notificationsForComments });
            }}
          />
        </div>
        <div className="marginBottom-40">
          <p className="fontMedium fontSize-36 text-dark">Default settings</p>
          <p className="genericInput-help">This controls the default values for certain parts of the environment</p>
          <CheckBox
            label="Limit comment visibility by default"
            isActive={this.state.commentVisibilityLimited}
            onChange={() => {
              this.setState({ commentVisibilityLimited: !this.state.commentVisibilityLimited });
            }}
          />
        </div>
        <p className="fontMedium fontSize-36 text-dark">Kiosk settings</p>
        <p className="genericInput-help">The features with ticked boxes will be enabled in the Kiosk version of the App.</p>
        <CheckBox
          label="Footer on Kiosk"
          isActive={this.state.kioskFooter}
          onChange={() => {
            this.setState({ kioskFooter: !this.state.kioskFooter });
          }}
        />
        {this.state.visitors && (
          <CheckBox
            label="Visitor Sign-in on Kiosk"
            isActive={this.state.kioskVisitors}
            onChange={() => {
              this.setState({ kioskVisitors: !this.state.kioskVisitors });
            }}
          />
        )}
        <div className="marginTop-40" style={{ paddingBottom: 40 }}>
          {this.renderSubmit()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { refreshAuthUser })(ManageSiteSettings);
