import { FEEDBACK_LOADED, FEEDBACK_REMOVED, POLLS_LOADED, POLL_REMOVED, POLL_UPDATED } from './types';

export const feedbackLoaded = feedback => {
  return {
    type: FEEDBACK_LOADED,
    payload: feedback,
  };
};

export const removeFeedback = id => {
  return {
    type: FEEDBACK_REMOVED,
    payload: id,
  };
};

export const pollsLoaded = polls => {
  return {
    type: POLLS_LOADED,
    payload: polls,
  };
};

export const removePoll = id => {
  return {
    type: POLL_REMOVED,
    payload: id,
  };
};

export const updatePoll = poll => {
  return {
    type: POLL_UPDATED,
    payload: poll,
  };
};
