import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import Textarea from 'react-textarea-autosize';
import { Button, OverlayPage, OverlayPageContents, OverlayPageSection, OverlayPageBottomButtons, ProfilePic } from '../../components';
import { maintenanceActions, reactionActions } from '../../webapi';
import { checkLoggedIn, isTheBest } from '../../session';
import { safeReadParams, toParagraphed } from '../../helper';
import StatusTypes from '../../json/supportStatus.json';

class Ticket extends Component {
  initialState = {
    ticketId: safeReadParams(this.props, 'ticketId'),
    ticket: {},
    statusChangerOpen: false,
    comments: [],
    commentInput: '',
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);

    this.checkGetData();
    this.getComments();
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  toggleStatusChanger() {
    this.setState({
      statusChangerOpen: !this.state.statusChangerOpen,
    });
  }

  selectStatus(status) {
    this.setState({
      ticket: {
        ...this.state.ticket,
        Status: status,
      },
      statusChangerOpen: false,
    });
    maintenanceActions.editTicketStatus(this.state.ticket.Id, status).then((res) => {
      console.log(res.data);
      this.setState({
        ticket: res.data.ticket,
      });
    });
  }

  checkGetData() {
    const ticket = _.find(this.props.tickets, (t) => {
      return t.Id === this.state.ticketId;
    });
    if (ticket) {
      this.setState({
        ticket,
      });
    } else {
      maintenanceActions.getTicket(this.state.ticketId).then((res) => {
        this.setState({
          ticket: res.data,
        });
      });
    }
  }

  getComments() {
    reactionActions.getComments(this.state.ticketId, 'supportticket', 0).then((res) => {
      console.log(res.data);
      this.setState({
        comments: res.data,
      });
    });
  }

  addComment() {
    reactionActions
      .addComment(this.state.ticketId, 'supportticket', this.state.ticket.Title, this.state.ticket.Site, this.state.commentInput)
      .then((res) => {
        this.setState({
          comments: [...this.state.comments, res.data],
        });
      });
    this.setState({
      commentInput: '',
    });
  }

  renderBack() {
    return (
      <div>
        <Button
          inline
          buttonType="primary"
          onClick={() => {
            window.history.back();
          }}
          isActive
        >
          Back to Support
        </Button>
      </div>
    );
  }

  renderStatusLabel() {
    if (!this.state.ticket.Status) {
      return null;
    }
    if (isTheBest(this.props.auth, true)) {
      return (
        <div
          className="statusLabel pointer"
          onClick={this.toggleStatusChanger.bind(this)}
          style={{ backgroundColor: StatusTypes[this.state.ticket.Status].color }}
        >
          <span className="statusLabel_text">{StatusTypes[this.state.ticket.Status].text}</span>
        </div>
      );
    }
    return (
      <div className="statusLabel" style={{ backgroundColor: StatusTypes[this.state.ticket.Status].color }}>
        <span className="statusLabel_text">{StatusTypes[this.state.ticket.Status].text}</span>
      </div>
    );
  }

  renderStatusChanger() {
    if (!this.state.statusChangerOpen) {
      return null;
    }
    return (
      <div className="statusChanger">
        {Object.keys(StatusTypes).map((statusKey) => {
          return (
            <div
              key={statusKey}
              className="statusLabel"
              onClick={this.selectStatus.bind(this, statusKey)}
              style={{ backgroundColor: StatusTypes[statusKey].color }}
            >
              <span className="statusLabel_text">{StatusTypes[statusKey].text}</span>
            </div>
          );
        })}
      </div>
    );
  }

  renderComment(c) {
    return (
      <div className="comment">
        <p className="comment_text">{toParagraphed(c.Comment)}</p>
        <div className="comment_bottom">
          <ProfilePic className="comment_profilePic" size={25} image={c.User.profilePic} />
          <p className="comment_name">{c.User.displayName}</p>
          <p className="comment_time">{moment.utc(c.Timestamp).local().format('D MMM YYYY • h:mma')}</p>
        </div>
      </div>
    );
  }

  renderCommentSection() {
    if (this.state.loadingComments) {
      return null;
    }
    return (
      <div className="padding-60 paddingLeft-20">
        <div className="newTopBar paddingLeft-40">
          <p className="newTopBarTitle text-sectionTitle">COMMENTS</p>
          <div className="commentSection">
            {this.state.comments.map((c) => {
              return this.renderComment(c);
            })}
          </div>
          <div className="commentReply">
            <div
              className={`commentReply_button${!_.isEmpty(this.state.commentInput) ? ' commentReply_button-active' : ''}`}
              onClick={this.addComment.bind(this)}
            >
              <FontAwesome className="commentReply_icon" name="paper-plane-o" />
            </div>
            <Textarea
              id="commentInput"
              placeholder="Reply here..."
              type="text"
              className="commentReply_input"
              value={this.state.commentInput}
              onChange={(e) => this.handleChange(e)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderMain() {
    return (
      <div>
        <div className="padding-60 paddingVertical-40 bottomDivideBorder relative">
          <div className="newTopBar clearfix flex flex-reverse">
            {this.renderStatusLabel()}
            <p className="newTopBarTitle text-sectionTitle flex-1">SUPPORT TICKET</p>
          </div>
          {this.renderStatusChanger()}
          <div className="marginTop-24">
            <p className="fieldLabel" style={{ margin: 0 }}>
              {this.state.ticket.Timestamp
                ? `Submitted at ${moment.utc(this.state.ticket.Timestamp).local().format('D MMM YYYY h:mma')}`
                : ''}
            </p>
            <p className="pollTitle" style={{ margin: 0 }}>
              {this.state.ticket.Title}
            </p>
          </div>
          <div className="marginTop-24">
            <p className="fieldLabel" style={{ margin: 0 }}>
              Submitted by
            </p>
            <p className="fieldText">{this.state.ticket.User ? this.state.ticket.User.displayName : ''}</p>
          </div>
          {this.state.ticket.LastUpdated && (
            <div className="marginTop-24">
              <p className="fieldLabel" style={{ margin: 0 }}>
                Last updated at
              </p>
              <p className="fieldText">{moment.utc(this.state.ticket.LastUpdated).local().format('D MMM YYYY h:mma')}</p>
            </div>
          )}
          {!_.isEmpty(this.state.ticket.Text) && (
            <div className="marginTop-24">
              <p className="fieldLabel" style={{ margin: 0 }}>
                Description
              </p>
              <p className="fieldText">{toParagraphed(this.state.ticket.Text)}</p>
            </div>
          )}
        </div>
        {!_.isEmpty(this.state.ticket.Attachments) && (
          <div className="padding-60 paddingLeft-20">
            <div className="newTopBar clearfix paddingLeft-40">
              <p className="newTopBarTitle text-sectionTitle">IMAGES</p>
              <div className="supportImages">
                {this.state.ticket.Attachments.map((i) => {
                  // eslint-disable-next-line
                  return <a href={i} target="_blank" key={i} className="supportImage" style={{ backgroundImage: `url(${i})` }}></a>;
                })}
              </div>
            </div>
          </div>
        )}
        {this.renderCommentSection()}
      </div>
    );
  }

  renderHistoryEntry(e, i) {
    const entryToUse = e || {
      Timestamp: this.state.ticket.Timestamp,
      Status: 'open',
      User: this.state.ticket.User,
    };
    return (
      <div className="ticketHistoryEntry" key={i}>
        <p className="ticketHistoryEntry_timestamp">{moment.utc(entryToUse.Timestamp).local().format('D MMM YYYY h:mma')}</p>
        <div className="statusLabel statusLabel-large" style={{ backgroundColor: StatusTypes[entryToUse.Status].color }}>
          <span className="statusLabel_text">{e ? `Marked as ${StatusTypes[entryToUse.Status].text}` : 'Ticket opened'}</span>
        </div>
      </div>
    );
  }

  renderOverview() {
    if (!this.state.ticket.History) {
      return null;
    }
    return (
      <div className="padding-32 paddingVertical-40 bottomDivideBorder">
        <p className="newTopBarTitle text-sectionTitle">STATUS HISTORY</p>
        {this.renderHistoryEntry(null, -1)}
        {_.map(this.state.ticket.History, (e, i) => {
          return this.renderHistoryEntry(e, i);
        })}
      </div>
    );
  }

  renderSideForm() {
    return <OverlayPageSection className="pageSectionWrapper--newPopupSide">{this.renderOverview()}</OverlayPageSection>;
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--fixedPopupSize">{this.renderMain()}</OverlayPageSection>
          {this.renderSideForm()}
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderBack()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, jobs } = state;
  return { auth, tickets: jobs.tickets };
};

export default connect(mapStateToProps, {})(Ticket);
