import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, GenericInput } from '../../../components';
import { checkLoggedIn, isTheBest } from '../../../session';
import { authActions } from '../../../webapi';


/* 
    Example response
    goowoos: {
                "finishedSuccessfully": true,
                results: [
                    { "User node set to deleted": true },
                    { "Event Reps - Attendance Removed": true, "future event records removed": 0, "past or deleted records found": 0 },
                    { "Followers - Following records removed": true, "Records removed": 0 },
                    { "Followers - Followed by removed": true, "Records removed": 3 },
                    { "Personal Events - Removed from audiences": true },
                ]
            },
*/

class UserDisable extends Component {
    state = {
        goowoos: null,
        submitting: false,

        userName: '',
        showWarnings: false
    }

    UNSAFE_componentWillMount() {
        checkLoggedIn(this, this.props.auth);
    }

    componentDidMount() {
        if (!isTheBest(this.props.auth)) {
            this.props.history.push('/mastermenu');
        }
    }

    removeUser() {
        if (this.state.submitting || _.isEmpty(this.state.userName)) {
            this.setState({ showWarnings: true });
            return;
        }

        if (window.confirm(`Are you sure you want to disable this user?
        This will:
        Remove them from all future event slots they are assigned to.
        Remove all people from their contacts list.
        Remove this person from other people's contact list.
        Remove user from all personal events.
        Remove user from all group chats.`)) {

            var self = this;
            this.setState({ showWarnings: false, error: false, submitting: true, goowoos: null });
            authActions.disableUser(
                this.state.userName
            )
                .then((res) => {
                    console.log('gweg response')
                    console.log(res.data)
                    console.log('7777777777777777777')
                    self.setState({
                        submitting: false,
                        goowoos: res.data
                    });
                }).catch((error) => {
                    self.setState({
                        submitting: false
                    });
                });

        }
    }

    handleChange(event) {
        var stateChange = {};
        stateChange[event.target.getAttribute('id')] = event.target.value;
        this.setState(stateChange);
    }

    renderChild(ev) {
        return Object.keys(ev).forEach((key) => {
            return (
                <div style={{ height: 30, width: '100%', marginBottom: 10, backgroundColor: 'pink' }}>
                    {key}
                </div>
            );
        });
    }

    renderResponses() {
        if (this.state.goowoos == null || !this.state.goowoos.finishedSuccessfully) {
            return null;
        }

        return this.state.goowoos.results.map((ev) => {
            if (ev != null) {
                return (
                    <div style={{ padding: 10, marginBottom: 10, backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: 10 }}>
                        {_.map(Object.keys(ev), (key, index) => {
                            return (<div>
                                <span>
                                    {key}:
                                </span>
                                <span
                                    style={{
                                        marginLeft: 5,
                                        fontWeight: 800,
                                        color: typeof ev[key] === 'boolean' ? ev[key] ? 'green' : 'red' : 'black'
                                    }}
                                >
                                    {ev[key].toString()}
                                </span>
                            </div>);
                        })}
                    </div>
                )
            }
            return null;
        });


    }

    renderButton() {
        if (this.state.submitting) {
            return <Button buttonType='secondary'>Working...</Button>;
        }
        return (
            <Button
                buttonType='primary'
                onClick={() => this.removeUser()}
                isActive
            >
                Delete User
            </Button>
        )
    }

    render() {
        return (
            <div style={{ width: '100%', maxWidth: 600 }}>
                <GenericInput
                    id="userName"
                    type="text"
                    label="User Id"
                    placeholder="userName"
                    value={this.state.userName}
                    onChange={(e) => this.handleChange(e)}
                    isRequired
                    isValid={() => { return !_.isEmpty(this.state.userName) }}
                    showError={() => { return this.state.showWarnings && _.isEmpty(this.state.userName) }}
                />
                <div style={{ marginBottom: 30 }}>
                    {this.renderButton()}
                </div>
                {this.renderResponses()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
};

export default connect(mapStateToProps, {})(UserDisable);
