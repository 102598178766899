import { UPDATE_CUSTOM_USERFIELDS, UPDATE_STRINGS } from '../actions/types';
import { readStorageWithCookie, setLocalStorage } from '../helper';

const INITIAL_STATE = {
  config: readStorageWithCookie('constStrings', true) || {},
  userFields: readStorageWithCookie('customUserFields', true) || {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_STRINGS:
      setLocalStorage('constStrings', action.payload, true);
      return {
        ...state,
        config: action.payload,
      };
    case UPDATE_CUSTOM_USERFIELDS:
      setLocalStorage('customUserFields', action.payload, true);
      return {
        ...state,
        userFields: action.payload,
      };
    default:
      return state;
  }
};
