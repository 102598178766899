import _ from 'lodash';

import {
    OFFERS_LOADED,
    OFFER_REMOVED,
    OFFERS_PURGE
} from '../actions/types';

const INITIAL_STATE = {
    offers: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case OFFERS_LOADED:
            var result = _.unionWith(action.payload, state.offers, (v1, v2) => {
                return v1 != null && v2 != null && v1.Id === v2.Id;
            });
            return {
                ...state,
                offers: result
            };
        case OFFER_REMOVED:
            const index = _.findIndex(state.offers, (event) => { return event != null && event.Id === action.payload });
            if (index > -1) {
                const newEvents = [...state.offers];
                newEvents.splice(index, 1);
                return { ...state, offers: newEvents };
            }
            return state;
        case OFFERS_PURGE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
