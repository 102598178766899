import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { checkLoggedIn, validateAccess } from '../../../session';
import { userActions, typeActions } from '../../../webapi';
import { OverlayPage, OverlayPageContents, OverlayPageSection } from '../../../components';
import { TEXT_LIGHT } from '../../../js';
import UserList from './UserList';
import User from './User';

class LinkUsers extends Component {
  state = {
    loading: true,
    chosenUserId: null,
    chosenUser: {},

    users: [],
    types: [],
    sortColumn: 'displayName',
    userSearch: '',
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    this.setState({ loading: true, site: this.props.auth.site });
  }

  componentDidMount() {
    if (!validateAccess(this.props.auth.site, 'userManagement', this.props.auth, true)) {
      this.props.history.push('/mastermenu');
    } else {
      this.getUserTypes();
      this.fetchUsers();
    }
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  getUserTypes() {
    var self = this;
    typeActions
      .getUserTypes(this.props.auth.site)
      .then((res) => {
        self.setState({
          types: res.data,
        });
      })
      .catch((error) => {});
  }

  fetchUsers() {
    this.setState({ loading: true });
    userActions
      .fetchUsers(this.props.auth.site)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.userFetchFail) {
          return;
        }
        if (res.data != null && !_.isEmpty(res.data.results.Items) && res.data.results.Items[0].site === this.props.auth.site) {
          this.buildData(res.data.results.Items);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  }

  selectUser(user) {
    this.setState({
      chosenUserId: user != null ? user.Id : null,
      chosenUser: user != null ? user : {},
    });
  }

  buildData(users) {
    const source = [];
    Object.keys(users).forEach((userId) => {
      source.push({ ...users[userId], id: userId });
    });
    this.setState({ users: source });
  }

  renderMain() {
    if (this.state.chosenUserId == null && (_.isUndefined(this.state.chosenUser) || _.isEmpty(this.state.chosenUser))) {
      return (
        <UserList
          users={this.state.users}
          sortColumn={this.state.sortColumn}
          selectUser={this.selectUser.bind(this)}
          types={this.state.types}
          residentSearch
        />
      );
    }
    return (
      <User
        userId={this.state.chosenUserId}
        user={this.state.chosenUser}
        users={this.state.users}
        resetUserSelection={this.selectUser.bind(this)}
      />
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons>
          <OverlayPageSection style={{ minWidth: 665 }}>
            <div className="generalText" style={{ color: TEXT_LIGHT }}>
              Use this feature to link users to primary users, allowing unique following views within the app.
            </div>
            <div style={{ marginTop: 24 }}>{this.renderMain()}</div>
          </OverlayPageSection>
        </OverlayPageContents>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, {})(withRouter(LinkUsers));
