import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router';
import { Header } from '../../components';
import { dishesLoaded, removeDish } from '../../actions';
import { foodActions } from '../../webapi';
import moment from 'moment';
import { get300 } from '../../helper';

class FoodHub extends Component {
  constructor(props) {
    super(props);

    const selectedDay = moment();
    const startOfWeek = moment(selectedDay).startOf('w');

    this.state = {
      menuWeek: startOfWeek,
      selectedDay,
      days: [],
    };
  }

  componentDidMount() {
    this.loadDishes();
    this.setDaysOfTheWeek();
  }

  setDaysOfTheWeek(menuWeek) {
    const startOfWeek = menuWeek || this.state.menuWeek;
    const days = [];
    let daysLeft = 7;
    let dayToAdd = moment(startOfWeek);

    while (daysLeft > 0) {
      days.push(dayToAdd);
      dayToAdd = moment(dayToAdd).add(1, 'd');
      daysLeft--;
    }
    this.setState({
      menuWeek: startOfWeek,
      days,
    });
  }

  loadDishes() {
    this.setState({
      loadingDishes: true,
    });

    foodActions
      .getDishes(this.props.auth.site)
      .then((res) => {
        this.setState({
          loadingDishes: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.dishesLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loadingDishes: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  getTodayDishes() {
    const todayKey = this.state.selectedDay.format('DD-MM-YYYY');
    return _.filter(this.props.dishes, (d) => {
      if (!d.Availability.Limited) {
        return true;
      }
      return _.some(d.Availability.Dates, (date) => {
        return date.Key === todayKey;
      });
    });
  }

  changeWeek = (change) => {
    const newStartOfWeek = moment(this.state.menuWeek);
    const newSelectedDay = moment(this.state.selectedDay);
    newStartOfWeek.add(change, 'w');
    newSelectedDay.add(change, 'w');
    this.setState({
      menuWeek: newStartOfWeek,
      selectedDay: newSelectedDay,
    });
    this.setDaysOfTheWeek(newStartOfWeek);
  };

  selectDay = (d) => {
    this.setState({
      selectedDay: d,
    });

    foodActions.getDailyMenu(this.props.auth.site, d.format('DD-MM-YYYY'), true).then((res) => {
      console.log(res.data);
    });
  };

  openAddDish = () => {
    this.props.history.push('/food/dish');
  };

  openEditDish = (d) => {
    this.props.history.push(`/food/dish/${d.Id}`);
  };

  removeDish = (d, e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!window.confirm(`Are you sure you want to delete ${d.Title}?`)) {
      return;
    }
    foodActions.deleteDish(d.Site, d.Id).then((res) => {
      this.props.removeDish(d.Id);
    });
  };

  renderCategories() {
    //TODO
    return <div className="marginTop-20"></div>;
  }

  renderDish(d) {
    return (
      <div
        className="dishCard"
        onClick={() => {
          this.openEditDish(d);
        }}
      >
        <div className="dishCard_image" style={{ backgroundImage: `url(${get300(d.Image)})` }}>
          <div className="dishCard_action dishCard_action-remove">
            <FontAwesome
              className="dishCard_action_icon"
              name="trash"
              onClick={(e) => {
                this.removeDish(d, e);
              }}
            />
          </div>
          <div className="dishCard_action dishCard_action-edit">
            <FontAwesome className="dishCard_action_icon" name="pencil" />
          </div>
        </div>
        <div className="dishCard_bottom">
          <p className="dishCard_bottom_title">{d.Title}</p>
          <div className="dishCard_bottom_fill">
            <p className="dishCard_bottom_description">{d.Description}</p>
          </div>
        </div>
      </div>
    );
  }

  renderDay(d) {
    const isSelected = d.format('DD-MM-YYYY') === this.state.selectedDay.format('DD-MM-YYYY');
    return (
      <div
        className={`weekSelector_day ${isSelected ? 'weekSelector_day-selected' : ''}`}
        key={d.format('DD-MM-YYYY')}
        onClick={() => {
          this.selectDay(d);
        }}
      >
        {d.format('ddd • D MMM')}
      </div>
    );
  }

  renderWeekSelector() {
    return (
      <div className="weekSelector marginTop-20">
        <div
          className="weekSelector_changeWeek"
          onClick={() => {
            this.changeWeek(-1);
          }}
        >
          <FontAwesome name="angle-left" className="weekSelector_changeWeek_icon" />
          <p className="weekSelector_changeWeek_text">Prev Week</p>
        </div>
        {this.state.days.map((d) => {
          return this.renderDay(d);
        })}
        <div
          className="weekSelector_changeWeek weekSelector_changeWeek-next"
          onClick={() => {
            this.changeWeek(1);
          }}
        >
          <FontAwesome name="angle-right" className="weekSelector_changeWeek_icon" />
          <p className="weekSelector_changeWeek_text">Next Week</p>
        </div>
      </div>
    );
  }

  renderDishes() {
    return (
      <div className="topDivideBorder">
        <div className="flex flex-center-row flex-between marginTop-20">
          <p className="managingPageSubtitle">Daily Menus</p>
          <div className="managingAddButton" onClick={this.openAddDish}>
            <FontAwesome name="plus-circle" className="managingAddButton_icon" />
            <p className="managingAddButton_text">Add new dish</p>
          </div>
        </div>
        {this.renderWeekSelector()}
        <div>
          {this.getTodayDishes().map((d) => {
            return this.renderDish(d);
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="pageContainer relative flex flex-column">
        <Header />
        <div className="hub-contentWrapper">
          <div style={{ minWidth: '100%' }}>
            <p className="managingPageTitle">Restaurant Manager</p>
            {this.renderCategories()}
            {this.renderDishes()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { dishes } = state.food;
  const { auth } = state;
  return {
    auth,
    dishes,
  };
};

export default connect(mapStateToProps, { dishesLoaded, removeDish })(withRouter(FoodHub));
