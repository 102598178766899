import React, { Component } from 'react';
import _ from 'lodash';
import Textarea from 'react-textarea-autosize';

class GenericInput extends Component {
  componentDidUpdate(prevProps, prevState) {
    if (!_.isUndefined(this.props.autofocus) && this.props.autofocus) {
      this._input.focus();
    }
  }

  getAutoComplete() {
    if (!_.isUndefined(this.props.autoComplete)) {
      return this.props.autoComplete ? 'on' : 'off';
    }
    return 'off';
  }

  isValid() {
    if (!_.isUndefined(this.props.isValid) && typeof this.props.isValid === 'function') {
      return this.props.isValid();
    }
    return this.props.isValid;
  }

  showError() {
    return (
      !_.isUndefined(this.props.showError) &&
      ((typeof this.props.showError === 'function' && this.props.showError()) ||
        (typeof this.props.showError === 'boolean' && this.props.showError))
    );
  }

  getClassNames() {
    let string = `${this.props.className} genericInputContainer`;
    if (!_.isUndefined(this.props.disabled) && this.props.disabled) {
      string += ' genericInput-disabled';
    }
    if (this.props.large) {
      string += ' genericInput-large';
    }
    if (!_.isUndefined(this.props.type) && this.props.type === 'textarea') {
      string += ' genericInput-textarea';
    }
    if (this.isValid()) {
      return string + ' genericInput-valid';
    }
    if (this.showError()) {
      return string + ' genericInput-error';
    }
    return string;
  }

  getLabelStyle() {
    const style = {};
    if (_.isEmpty(this.props.value) && !this.props.alwaysShowLabel) {
      style.opacity = 0;
    }
    return style;
  }

  renderError() {
    if (this.showError() && !this.isValid()) {
      return <div className={'fieldLabel fieldLabel-warning'}>{this.props.errorMessage ? this.props.errorMessage : 'Required'}</div>;
    }
    return null;
  }
  renderHelp() {
    if (!_.isEmpty(this.props.help)) {
      return <div className="genericInput-help">{this.props.help}</div>;
    }
    return null;
  }

  renderInput() {
    if (!_.isUndefined(this.props.inputComponent)) {
      return (
        <div style={{ ...styles.row, alignItems: 'flex-start' }}>
          {this.props.isRequired && <div className="inputRequired " />}
          {this.props.inputComponent}
          {this.props.rightContent}
        </div>
      );
    }
    if (!_.isUndefined(this.props.type) && this.props.type === 'textarea') {
      // Render expandable text area
      return (
        <div style={{ ...styles.row, alignItems: 'flex-start' }}>
          {this.props.isRequired && <div className="inputRequired " />}
          <Textarea
            id={this.props.id}
            placeholder={!_.isUndefined(this.props.placeholder) ? this.props.placeholder : this.props.label}
            type={!_.isUndefined(this.props.type) ? this.props.type : 'text'}
            className={`genericInput ${this.props.inputClass}`}
            value={this.props.value || ''}
            onChange={this.props.onChange}
            onKeyPress={this.props.onEnter}
            style={{ ...this.props.inputStyle }}
            readOnly={!_.isUndefined(this.props.readOnly) ? this.props.readOnly : false}
            disabled={!_.isUndefined(this.props.disabled) ? this.props.disabled : false}
            autoComplete={this.getAutoComplete()}
            maxLength={this.props.maxLength}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
          />
          {this.props.rightContent}
        </div>
      );
    }
    // Render default input
    return (
      <div style={{ ...styles.row, ...this.props.textWrapperStyle }}>
        {this.props.isRequired && <div className="inputRequired " />}
        <input
          ref={(c) => (this._input = c)}
          id={this.props.id}
          placeholder={!_.isUndefined(this.props.placeholder) ? this.props.placeholder : this.props.label}
          type={!_.isUndefined(this.props.type) ? this.props.type : 'text'}
          className={`genericInput ${this.props.inputClass}`}
          value={this.props.value}
          onChange={this.props.onChange}
          onClick={this.props.onClick}
          onKeyPress={this.props.onEnter}
          style={{ ...this.props.inputStyle }}
          readOnly={!_.isUndefined(this.props.readOnly) ? this.props.readOnly : false}
          disabled={!_.isUndefined(this.props.disabled) ? this.props.disabled : false}
          autoComplete={this.getAutoComplete()}
          autoFocus={!_.isUndefined(this.props.autofocus) ? this.props.autofocus : false}
          maxLength={this.props.maxLength}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
        />
        {this.props.rightContent}
      </div>
    );
  }

  render() {
    return (
      <div className={this.getClassNames()} style={{ ...this.props.style }}>
        <div style={{ ...styles.row, marginBottom: 0, justifyContent: 'space-between' }}>
          {!_.isUndefined(this.props.label) && (
            <div className="fieldLabel" style={this.getLabelStyle()}>
              {this.props.label}
            </div>
          )}
          {this.renderError()}
        </div>
        {/* Render either generic input or large text area  */}
        {this.renderInput()}
        {/* Render bottom help info */}
        {this.renderHelp()}
      </div>
    );
  }
}

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    // marginBottom: 8,
    alignItems: 'center',
  },
};

export { GenericInput };
