import moment from 'moment';
import _ from 'lodash';

export const getEventTimes = (events) => {
  const result = [];
  events.forEach((event) => {
    event.RepeatedTimes.forEach((rep) => {
      const date = moment.utc(rep.Time).local();
      let endTime = null;
      if (!!rep.EndTime) {
        const endTimeMoment = moment.utc(rep.EndTime).local();
        endTime = endTimeMoment.format('mm') === '00' ? endTimeMoment.format('ha') : endTimeMoment.format('h:mma');
      }
      result.push({
        Id: event.RowId,
        RepId: rep.Id,
        EntryId: `${event.Id}_${rep.Id}`,
        DateKey: date.format('DD-MM-YYYY'),
        Location: rep.Location,
        TicketCount: rep.TicketCount || 0,
        Tickets: event.Tickets,
        AttendanceCount: rep.AttendanceCount || 0,
        AllDay: rep.AllDay,
        Time: date.format('mm') === '00' ? date.format('ha') : date.format('h:mma'),
        EndTime: endTime,
        Title: event.Title,
        TimeSort: date.valueOf(),
      });
    });
  });
  return _.sortBy(result, 'TimeSort');
};
