import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { CommentSection, OverlayPage, OverlayPageContents, OverlayPageSection, SVGIcon, Text } from '../../components';
import { reactionActions } from '../../webapi';
import { safeReadParams, getFirstName, getUrlParams } from '../../helper';
import { COLOUR_DUSK_LIGHT } from '../../js';

class Thread extends Component {
  constructor(props) {
    super(props);

    const threadId = safeReadParams(props, 'threadId');
    const threadSplit = threadId.split('_');
    const entityType = _.last(threadSplit);
    threadSplit.pop();
    const entityId = threadSplit.join('_');
    const parentId = getUrlParams().parentId;

    this.state = {
      comments: [],
      threadId,
      entityType,
      entityId,
      parentId,
      openedThread: false,
    };
  }

  componentDidMount() {
    this.connect();
  }

  componentWillUnmount() {
    this.disconnect();
  }

  connect = () => {
    this.getComments();

    this.commentsInterval = setInterval(this.getComments, 2000);
  };

  disconnect = () => {
    clearInterval(this.commentsInterval);
  };

  onBack = () => {
    window.history.back();
  };

  getEntityName = () => {
    if (_.isEmpty(this.state.comments)) return null;
    const commentWithName = _.find(this.state.comments, (c) => {
      return !_.isEmpty(c.EntityName);
    });
    if (!commentWithName) return null;
    return commentWithName.EntityName;
  };

  getSite = () => {
    if (_.isEmpty(this.state.comments)) return this.props.auth.site;
    const commentWithSite = _.find(this.state.comments, (c) => {
      return !_.isEmpty(c.Site);
    });
    if (!commentWithSite) return this.props.auth.site;
    return commentWithSite.Site;
  };

  onAddComment = (comment, parentId) => {
    reactionActions.addComment(
      this.state.entityId,
      this.state.entityType,
      this.getEntityName(),
      this.getSite(),
      comment,
      undefined,
      parentId,
    );
  };

  onAddCommentReply = (comment) => {
    this.onAddComment(comment, this.state.threadOpen.Id);
  };

  onOpenThread = (comment) => {
    this.setState({
      threadOpen: comment,
    });
  };

  onCloseThread = () => {
    this.setState({
      threadOpen: null,
    });
  };

  getComments = async () => {
    const minTime = _.isEmpty(this.state.comments) ? 0 : _.last(this.state.comments).Timestamp + 1;
    reactionActions.getComments(this.state.entityId, this.state.entityType, minTime).then((res) => {
      const comments = _.uniqBy([...this.state.comments, ...res.data], (c) => c.Id);
      this.setState({
        comments: _.orderBy(comments, 'Timestamp'),
      });
      if (this.state.parentId && !this.state.openedThread) {
        this.setState({
          openedThread: true,
        });

        this.onOpenThread(
          _.find(comments, (c) => {
            return c.Id === this.state.parentId;
          }),
        );
      }
    });
  };

  getCommentReplies = () => {
    return [
      this.state.threadOpen,
      ..._.filter(this.state.comments, (c) => {
        return c.ParentId === this.state.threadOpen.Id;
      }),
    ];
  };

  renderSidebar() {
    const { threadOpen } = this.state;
    if (!threadOpen) {
      return null;
    }
    return (
      <OverlayPageSection className="pageSectionWrapper--newPopupSide relative">
        <SVGIcon colour={COLOUR_DUSK_LIGHT} icon="close" className="popup_closeIcon" onClick={this.onCloseThread} />
        <div className="padding-60 paddingVertical-40">
          <Text type="formTitleMedium">{`Replies to ${getFirstName(threadOpen.User ? threadOpen.User.displayName : 'comment')}`}</Text>

          <CommentSection comments={this.getCommentReplies()} onAddComment={this.onAddCommentReply} />
        </div>
      </OverlayPageSection>
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons>
          <OverlayPageSection className="pageSectionWrapper--fixedPopupSize">
            <div className="padding-60 paddingVertical-40">
              <div className="flex flex-center">
                <FontAwesome className="featurePicker_back" name="angle-left" onClick={this.onBack} />
                <Text type="formTitleLarge" className="featurePicker_pageTitle">
                  {this.getEntityName()}
                </Text>
              </div>
              <CommentSection
                comments={this.state.comments}
                onAddComment={this.onAddComment}
                onOpenThread={this.onOpenThread}
                onlyParents
              />
            </div>
          </OverlayPageSection>
          {this.renderSidebar()}
        </OverlayPageContents>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(withRouter(Thread));
