import _ from 'lodash';
// import { mergeLists } from '../helper';
import { FEEDBACK_LOADED, FEEDBACK_REMOVED, POLLS_LOADED, POLL_REMOVED, POLL_UPDATED } from '../actions/types';

const INITIAL_STATE = {
  allFeedback: [],
  polls: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FEEDBACK_LOADED:
      const feedbackResult = _.unionWith(action.payload, state.allFeedback, (v1, v2) => {
        return v1 != null && v2 != null && v1.Id === v2.Id;
      });
      return {
        ...state,
        allFeedback: feedbackResult,
      };
    case FEEDBACK_REMOVED:
      const feedbackIndex = _.findIndex(state.allFeedback, event => {
        return event != null && event.Id === action.payload;
      });
      if (feedbackIndex > -1) {
        const newEventsSub = [...state.allFeedback];
        newEventsSub.splice(feedbackIndex, 1);
        return { ...state, allFeedback: newEventsSub };
      }
      return state;
    case POLLS_LOADED:
      const pollResult = _.unionWith(action.payload, state.polls, (v1, v2) => {
        return v1 != null && v2 != null && v1.Id === v2.Id;
      });
      return {
        ...state,
        polls: pollResult,
      };
    case POLL_REMOVED:
      const pollIndex = _.findIndex(state.polls, event => {
        return event != null && event.Id === action.payload;
      });
      if (pollIndex > -1) {
        const newEventsSub = [...state.polls];
        newEventsSub.splice(pollIndex, 1);
        return { ...state, polls: newEventsSub };
      }
      return state;
    case POLL_UPDATED:
      var result = _.unionWith([action.payload], state.polls, (v1, v2) => {
        return v1 != null && v2 != null && v1.Id === v2.Id;
      });
      return {
        ...state,
        polls: result,
      };
    default:
      return state;
  }
};
