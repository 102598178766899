import React, { Component } from 'react';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { INACTIVE_TEXT } from '../js';

class DropdownInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      open: false,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ open: false });
    }
  }

  getAutoComplete() {
    if (!_.isUndefined(this.props.autoComplete)) {
      return this.props.autoComplete ? 'on' : 'off';
    }
    return 'off';
  }

  getClassNames() {
    let string = '';
    if (this.props.className) {
      string += `${this.props.className} `;
    }
    if (!_.isUndefined(this.props.disabled) && this.props.disabled) {
      string = 'genericInput-disabled ';
    }
    if (this.props.large) {
      string += 'genericInput-large ';
    }
    if (!_.isUndefined(this.props.showError) && this.props.showError()) {
      return string + 'genericInput-error';
    }
    if (!_.isUndefined(this.props.isValid) && this.props.isValid()) {
      return string + 'genericInput-valid';
    }
    return string;
  }

  getLabelStyle() {
    const style = {};
    if (_.isEmpty(this.props.value) && !this.props.alwaysShowLabel) {
      style.opacity = 0;
    }
    if (!_.isUndefined(this.props.ignoreValue) && this.props.value === this.props.ignoreValue && !this.props.alwaysShowLabel) {
      style.opacity = 0;
    }
    // if (this.props.isRequired) {
    //     style.marginLeft = 12;
    // }
    return style;
  }

  renderError() {
    if (!_.isUndefined(this.props.showError) && this.props.showError()) {
      return <div className={'fieldLabel fieldLabel-warning'}>{this.props.errorMessage ? this.props.errorMessage : 'Required'}</div>;
    }
    return null;
  }

  renderHelp() {
    if (!_.isUndefined(this.props.help)) {
      return <div className="genericInput-help">{this.props.help}</div>;
    }
    return null;
  }

  open() {
    if (this.props.disabled) {
      return;
    }
    this.setState({ open: !this.state.open });
  }

  renderOption() {
    if (!this.state.open) {
      return null;
    }
    return (
      <div style={styles.dropdownItemsWrapper}>
        {_.values(this.props.options).map((cat) => {
          return (
            <div
              key={cat.Key}
              className="subtleHover fontRegular fontSize-16 text-dark"
              style={styles.dropDownOption}
              onClick={() => {
                this.props.onSelect(cat.Key);
              }}
            >
              {cat.Title}
            </div>
          );
        })}
      </div>
    );
  }

  getBottomBorder() {
    if (!_.isUndefined(this.props.disabled) && this.props.disabled) {
      return 'dropdownInput-bottomBorder--disabled';
    }
    if (!_.isUndefined(this.props.showError) && this.props.showError()) {
      return 'dropdownInput-bottomBorder--error';
    }
    return '';
  }

  renderInput() {
    return (
      <div style={styles.row}>
        {this.props.isRequired && <div className="inputRequired " />}
        <div
          onClick={this.open.bind(this)}
          className={`dropdownInput-bottomBorder ${this.getBottomBorder()} ${!this.props.disabled ? 'pointer' : ''}`}
        >
          <input
            id={this.props.id}
            placeholder={!_.isUndefined(this.props.placeholder) ? this.props.placeholder : this.props.label}
            type={!_.isUndefined(this.props.type) ? this.props.type : 'text'}
            className={`genericInput ${!this.props.disabled ? 'pointer' : ''}`}
            value={this.props.value}
            onChange={this.props.onChange}
            onKeyPress={this.props.onEnter}
            style={{ ...this.props.inputStyle }}
            disabled
            autoComplete="false"
          />
          <div style={styles.chevronWrapper}>
            <FontAwesome
              style={{
                ...styles.chevron,
                color: !_.isUndefined(this.props.disabled) && this.props.disabled ? 'transparent' : INACTIVE_TEXT,
              }}
              name={this.state.open ? 'chevron-up' : 'chevron-down'}
            />
          </div>
          {this.renderOption()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        ref={this.setWrapperRef}
        className={`dropdownInput ${this.getClassNames()}`}
        style={{ marginBottom: 16, position: 'relative', ...this.props.style }}
      >
        <div style={{ ...styles.row, marginBottom: 0, justifyContent: 'space-between' }}>
          {!_.isUndefined(this.props.label) && (
            <div className="fieldLabel" style={this.getLabelStyle()}>
              {this.props.label}
            </div>
          )}
          {this.renderError()}
        </div>
        {/* Render either generic input or large text area  */}
        {this.renderInput()}
        {/* Render bottom help info */}
        {this.renderHelp()}
      </div>
    );
  }
}

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chevronWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  chevron: {
    fontSize: 12,
    paddingBottom: 4,
  },
  dropdownItemsWrapper: {
    backgroundColor: '#fff',
    position: 'absolute',
    width: '100%',
    top: 35,
    boxShadow: '2px 2px 10px rgba(106, 163, 216, 0.7)',
    zIndex: 90,
    borderRadius: 4,
    paddingTop: 4,
    paddingBottom: 4,
  },
  dropDownOption: {
    padding: '4px 16px',
  },
};

export { DropdownInput };
