import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header } from '../../components';
import WelcomePages from './WelcomePages';

class WelcomePagesPage extends Component {

    render() {
        return (
            <div className="hub-wrapperContainer">
                <div className="hub-headerContentWrapper">
                    <Header />
                    <div className="hub-contentWrapper">
                        <WelcomePages />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
};

export default connect(mapStateToProps, {})(WelcomePagesPage);
