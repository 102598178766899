import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { defaultProfileImage } from '../../config';
import { formatDuration } from '../../helper';

class AlertUser extends Component {
  generateClassNames() {
    if (this.props.readList.indexOf(this.props.user.id) > -1) {
      return ' aCheck--active';
    }
    return '';
  }

  renderTimeToRead() {
    if (_.isEmpty(this.props.readTime)) {
      return null;
    }
    const readTime = _.find(this.props.readTime, (t) => {
      return t.userId === this.props.user.id;
    });
    if (!readTime) {
      return null;
    }
    return (
      <span style={{ fontSize: 13 }}>
        <br />
        {formatDuration(readTime.timestamp - this.props.created)}
      </span>
    );
  }

  render() {
    if (this.props.user) {
      return (
        <div
          style={{
            minHeight: 35,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 10,
            opacity: this.props.user && this.props.user.loading ? 0.5 : 1,
          }}
        >
          {/* User IMage and name */}
          <div key={this.props.user.id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <img
              style={{ height: 30, width: 30, borderRadius: 15, marginRight: 16 }}
              src={this.props.user.profilePic != null ? this.props.user.profilePic : defaultProfileImage}
              alt="user profilePic"
            />
            <div className={`fontMedium fontSize-16 ${this.props.searchUser ? 'text-dark' : 'text-white'}`}>
              {this.props.user.displayName}
              {this.renderTimeToRead()}
            </div>
          </div>
          {/* Action Buttons  */}
          {this.props.hasTick && (
            <div className={`aCheck ${this.generateClassNames()} aCheck--disbaled`}>
              <div className="aCheck-Tick">
                <FontAwesome className="aCheck-font" name={'check'} />
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(AlertUser);
