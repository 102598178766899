import _ from 'lodash';

import {
    KEYS_LOADED,
    KEY_REMOVED,
    KEYS_PURGE
} from '../actions/types';

const INITIAL_STATE = {
    keys: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case KEYS_LOADED:
            var result = _.unionWith(action.payload, state.keys, (v1, v2) => {
                return v1 != null && v2 != null && v1.RowId === v2.RowId;
            });
            return {
                ...state,
                keys: result
            };
        case KEY_REMOVED:
            const index = _.findIndex(state.keys, (event) => { return event != null && event.RowId === action.payload });
            if (index > -1) {
                const newEvents = [...state.keys];
                newEvents.splice(index, 1);
                return { ...state, keys: newEvents };
            }
            return state;
        case KEYS_PURGE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
