import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { GenericInput } from '../../../components';
import { COLOUR_BRANDING_MAIN } from '../../../js';
import { defaultProfileImage } from '../../../config';

class UserList extends Component {
    state = {
        userSearch: ''
    }

    handleChange(event) {
        var stateChange = {};
        stateChange[event.target.getAttribute('id')] = event.target.value;
        this.setState(stateChange);
    }

    getTypeTitle(user) {
        const typeObject = _.find(this.props.types, (t) => { return t.typeName === user.type; });
        if (typeObject) {
            return typeObject.displayName;
        }
        return '';
    }

    isResident(user) {
        const residentTypes = [];
        this.props.types.forEach(ev => {
            if (!_.isUndefined(ev.category) && ev.category === 'resident') {
                residentTypes.push(ev.typeName)
            }
        });
        return _.includes(residentTypes, user.type);
    }

    getProfilePic(user) {
        if (!_.isEmpty(user.profilePicThumb)) {
            return user.profilePicThumb;
        }
        if (!_.isEmpty(user.profilePic)) {
            return user.profilePic;
        }
        return defaultProfileImage;
    }

    renderUserList() {
        let source = _.sortBy(this.props.users, this.props.sortColumn);
        // If resident search - filter out all non-resident users.
        if (this.props.residentSearch) {
            source = _.filter(source, (ev) => {
                if (!ev) {
                    return false
                }
                return this.isResident(ev);
            });
        }
        // Filter out users based on search input
        source = _.filter(source, (ev) => {
            if (!ev) {
                return false
            }
            if (!_.isEmpty(this.state.userSearch)) {
                return (ev.displayName.toLowerCase().indexOf(this.state.userSearch.toLowerCase()) > -1)
            } else {
                return true;
            }
        });
        // Filter out users based on optional ignore list
        if (!_.isUndefined(this.props.ignore) && !_.isEmpty(this.props.ignore)) {
            source = _.filter(source, (ev) => {
                return _.includes(this.props.ignore, ev.Id) === false;
            });
        }

        return (
            <div style={{ ...styles.leftHandWrapper, ...this.props.style }}>
                <GenericInput
                    id="userSearch"
                    type="text"
                    placeholder="John Doe"
                    value={this.state.userSearch}
                    onChange={(e) => this.handleChange(e)}
                    style={{ marginBottom: 0, padding: '0 16px' }}
                />
                <div style={styles.userListWrapper}>
                    {source.map((ev, index) => {
                        if (ev != null) {
                            return (
                                <div
                                    key={ev.Id}
                                    className="subtleHover"
                                    onClick={this.props.selectUser.bind(this, ev)}
                                    style={styles.listUser}
                                >
                                    <div
                                        style={{
                                            ...styles.profilePic,
                                            backgroundImage: `url(${this.getProfilePic(ev)})`
                                        }}
                                    />
                                    <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'baseline', width: '80%' }}>
                                        <div className="truncate" style={{ flex: 1 }}>{ev.displayName}</div>
                                        {!this.props.residentSearch &&
                                            <div className="italics" style={{ fontSize: 14, color: COLOUR_BRANDING_MAIN }}>{this.getTypeTitle(ev)}</div>
                                        }
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        )
    }

    render() {
        return this.renderUserList();
    }
}

const styles = {
    leftHandWrapper: {
        width: '100%',
        maxWidth: 400,
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 8
    },
    userListWrapper: {
        maxHeight: '100%',
        overflow: 'auto',
    },
    listUser: {
        display: 'flex',
        alignItems: 'center',
        padding: 8,
        fontSize: 16,
        cursor: 'pointer'
    },
    profilePic: {
        height: 40,
        width: 40,
        borderRadius: 20,
        border: '1px solid #aaa',
        backgroundColor: '#eee',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        marginRight: 12
    }
};

const mapStateToProps = (state) => {
    return { auth: state.auth };
};

export default connect(mapStateToProps, {})(withRouter(UserList));