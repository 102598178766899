import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, GenericInput } from '../../components';
import { COLOUR_GREEN } from '../../js';
import { stringActions } from '../../webapi';
import { getSessionTokenAWS } from '../../session';

class EditTerms extends Component {
    state = {
        terms: '',
    }

    componentDidMount() {
        this.getData();
    }

    getData() {
        stringActions.getString(this.props.auth.site, 'termsofentry')
            .then((res) => {
                this.setState({
                    terms: res.data
                });
            });
    }

    handleChange(event) {
        var stateChange = {};
        stateChange[event.target.getAttribute('id')] = event.target.value;
        this.setState(stateChange);
    }

    handleSubmit() {
        if (!this.validateForm()) {
            return;
        }
        this.setState({ submitting: true });
        getSessionTokenAWS()
            .then((idToken) => {
                stringActions.setString(this.props.auth.site, 'termsofentry', this.state.terms, this.props.auth.user.uid, idToken)
                    .then((res) => {
                        this.setState({
                            success: true,
                            submitting: false
                        });
                    }).catch((res) => {
                        this.setState({ submitting: false });
                        alert("Something went wrong with the request. Please try again.")
                    });
            });
    }

    onSuccess() {
        this.setState({ submitting: false, success: true });
    }

    validateForm() {
        return !_.isEmpty(this.state.terms);
    }

    renderSubmit() {
        if (this.state.submitting) {
            return <Button buttonType='secondary'>Saving...</Button>;
        }
        return (
            <Button
                inline
                buttonType='primary'
                onClick={this.handleSubmit.bind(this)}
                isActive={this.validateForm()}
            >
                Save
             </Button>
        );
    }

    renderSuccess() {
        if (!this.state.success) {
            return null;
        }
        return (<span style={{ color: COLOUR_GREEN, fontSize: 14, lineHeight: '33px', marginLeft: 30 }}>Saved</span>)
    }


    render() {
        return (
            <div style={{ minWidth: '100%' }}>
                <GenericInput
                    id="terms"
                    label='Terms'
                    type="textarea"
                    placeholder="Enter terms..."
                    value={this.state.terms}
                    onChange={(e) => this.handleChange(e)}
                    inputStyle={{
                        height: 500
                    }}
                />
                {this.renderSubmit()}
                {this.renderSuccess()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
};

export default connect(mapStateToProps, {})(EditTerms);