import React, { Component } from 'react';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { CheckBox, Button } from './';
import { defaultProfileImage } from '../config';

class AddUserPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSearch: '',
      returnList: [],
      userList: [],
    };
  }

  componentDidMount() {
    this.updateProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    const stateChange = {};
    const preselected = props.selectionList && props.selectionList.length > 0;
    stateChange.userList = props.userList.map((user) => {
      const newUser = { ...user };
      if (preselected) newUser.isSelected = !_.isNil(props.selectionList.find((item) => item.Id === newUser.Id));
      return newUser;
    });
    this.setState(stateChange);
  }

  onClose = () => {
    this.props.onClose();
  };

  onToggleUser = (selectedUser) => {
    const stateChange = { userList: [...this.state.userList], returnList: [...this.state.returnList] };
    const userToUpdate = stateChange.userList.find((user) => user.Id === selectedUser.Id);
    if (userToUpdate) userToUpdate.isSelected = !userToUpdate.isSelected;
    this.setState(stateChange);
  };

  onSave = () => {
    if (this.props.onSave) {
      const selectedUsers = this.state.userList.filter((user) => user.isSelected);
      this.props.onSave(
        selectedUsers.map((user) => ({
          Id: user.Id,
          profilePic: user.profilePic,
          displayName: user.displayName,
        })),
      );
    }
    this.onClose();
  };

  onSearchChange = (event) => {
    const stateChange = { returnList: [] };
    stateChange[event.target.getAttribute('id')] = event.target.value;

    if (!_.isEmpty(event.target.value) && event.target.value.length > 1) {
      stateChange.returnList = _.filter(this.state.userList, (ev) => {
        return ev.displayName.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1;
      });
    }
    this.setState(stateChange);
  };

  renderSearchInput() {
    return (
      <div className="searchContainer">
        <FontAwesome className="searchIcon" name="search" />
        <input
          className="searchInput"
          id="userSearch"
          type="text"
          placeholder="Search"
          value={this.state.userSearch}
          onChange={(e) => this.onSearchChange(e)}
        />
      </div>
    );
  }

  renderSearchResults() {
    return (
      <div className="searchResultContainer">
        {this.state.returnList.map((user, index) => {
          return (
            <div onClick={() => this.onToggleUser(user)} key={user.Id} className="searchItemContainer pointer">
              <CheckBox style={{ marginBottom: 0 }} isActive={user.isSelected} />
              <img className="searchItemIcon" src={user.profilePic != null ? user.profilePic : defaultProfileImage} alt="user profilePic" />
              <div className="searchItemText">{user.displayName}</div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div className="addUserPopupBackground">
        <div className="addUserPopup">
          <div className="topSection">
            <FontAwesome className="closeButton" name="times" onClick={this.onClose} />
            <div className="heading">{this.props.label || 'Add User'}</div>
            {this.renderSearchInput()}
            {this.renderSearchResults()}
          </div>
          <div className="bottomSection">
            <Button className="saveButton" inline buttonType="primary" onClick={this.onSave} isActive>
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export { AddUserPopup };
