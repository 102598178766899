import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import _ from 'lodash';
import { Button } from './Button';
import { COLOUR_DUSK_LIGHT } from '../js';
import { SVGIcon } from './SVGIcon';

class Popup extends Component {
  renderClose() {
    if (!this.props.onClose) {
      return null;
    }
    return <SVGIcon colour={COLOUR_DUSK_LIGHT} icon="close" className="popup_closeIcon" onClick={this.props.onClose} />;
  }

  renderTitle() {
    if (_.isEmpty(this.props.title)) {
      return null;
    }
    return <p className="popup_title">{this.props.title}</p>;
  }

  renderSubtitle() {
    if (_.isEmpty(this.props.subtitle)) {
      return null;
    }
    return <p className="popup_subtitle">{this.props.subtitle}</p>;
  }

  renderButtons() {
    if (_.isEmpty(this.props.buttons)) {
      return null;
    }
    if (this.props.verticalButtonStack) {
      return (
        <div className={`flex flex-1 marginTop-20${this.props.verticalButtonStack ? ' flex-column flex-center' : ' flex-center-row'}`}>
          {this.props.buttons.map((b, index) => {
            return (
              <Button
                key={index}
                buttonType={b.type}
                style={{ marginLeft: 8, marginRight: 8, ...b.style }}
                className={b.className}
                onClick={b.onClick}
                isActive={b.isActive}
              >
                {b.loading ? <FontAwesome name="spinner fa-pulse fa-fw" /> : b.text}
              </Button>
            );
          })}
        </div>
      );
    }
    return (
      <div className="popup_buttonSection">
        {this.props.buttons.map((b, index) => {
          return (
            <Button
              key={index}
              buttonType={b.type}
              style={{ marginLeft: 16, ...b.style }}
              className={b.className}
              onClick={b.onClick}
              isActive={b.isActive}
            >
              {b.loading ? <FontAwesome name="spinner fa-pulse fa-fw" /> : b.text}
            </Button>
          );
        })}
      </div>
    );
  }

  render() {
    return (
      <div className={`popup ${this.props.className}`}>
        <div
          className={`popup_box ${this.props.boxClasses}`}
          style={{
            maxHeight: this.props.maxHeight ? this.props.maxHeight : 'auto',
            maxWidth: this.props.maxWidth ? this.props.maxWidth : 'auto',
            minHeight: this.props.minHeight ? this.props.minHeight : 'auto',
            minWidth: this.props.minWidth ? this.props.minWidth : 'auto',
          }}
        >
          <div className={`popup_box_inner ${this.props.hasPadding ? 'popup_box-hasPadding' : ''} ${this.props.innerClasses}`}>
            {this.renderClose()}
            {this.props.topContent}
            {this.renderTitle()}
            {this.renderSubtitle()}
            {this.props.children}
          </div>
          {this.renderButtons()}
        </div>
      </div>
    );
  }
}

export { Popup };
