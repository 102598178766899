import { FORMS_LOADED, FORM_REMOVED, FORM_UPDATED, FORM_SUBMISSIONS_LOADED } from './types';

export const formsLoaded = (forms) => {
  return {
    type: FORMS_LOADED,
    payload: forms,
  };
};

export const removeForm = (id) => {
  return {
    type: FORM_REMOVED,
    payload: id,
  };
};

export const updateForm = (form) => {
  return {
    type: FORM_UPDATED,
    payload: form,
  };
};

export const formSubmissionsLoaded = (submissions) => {
  return {
    type: FORM_SUBMISSIONS_LOADED,
    payload: submissions,
  };
};
