import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { eventActions } from '../../webapi';
import { eventsLoaded } from '../../actions';
import { Button, CheckBox, PageContainer, Header, InputGroup } from '../../components';

class EventReport extends Component {
  state = {
    filteredEvents: [],
    onlyFuture: true,
    location: '',
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
    if (Object.keys(this.props.events).length > 0) {
      var maxElem = _.maxBy(this.props.events, (event) => {
        if (!event) {
          return 0;
        }
        return event.Changed;
      });
      this.loadEvents(maxElem.Changed + 1);
    } else {
      this.loadEvents();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    this.filterEvents();
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleCheckChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.checked;
    this.setState(stateChange);
  }

  filterEvents() {
    this.setState({
      filteredEvents: _.filter(this.props.events, (ev) => {
        if (!_.isEmpty(this.state.location) && ev.Location.toLowerCase() !== this.state.location.toLowerCase()) {
          return false;
        }
        if (this.state.onlyFuture) {
          return (
            moment.utc(ev.StartTime).isSameOrAfter(this.state.now) ||
            (ev.EndTime != null && ev.EndTime !== '' && moment.utc(ev.EndTime).isSameOrAfter(this.state.now))
          );
        }
        return true;
      }),
    });
  }

  renderReport() {
    return (
      <div>
        <p>
          {this.state.filteredEvents.length} event{this.state.filteredEvents.length !== 1 ? 's' : ''} matched
        </p>
        <pre style={{ whiteSpace: 'normal' }}>{JSON.stringify(this.state.filteredEvents)}</pre>
      </div>
    );
  }

  loadEvents(time) {
    this.setState({
      loading: true,
    });

    eventActions
      .getEvents(this.props.auth.site, time, true)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.eventsLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loading: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  render() {
    return (
      <div>
        <Header title="Events" />
        <PageContainer maxWidth="100%">
          {/* location */}
          <InputGroup
            id="location"
            type="text"
            label="Filter by Location:"
            placeholder="Level 5"
            value={this.state.location}
            onChange={(e) => this.handleChange(e)}
          />
          <CheckBox
            id="onlyFuture"
            label="Only show future events"
            isActive={this.state.onlyFuture}
            onChange={() => this.setState({ onlyFuture: !this.state.onlyFuture })}
          />
          <Button type="button" onClick={this.filterEvents.bind(this)}>
            Apply Filter
          </Button>
          <div style={{ marginTop: 30 }}>{this.renderReport()}</div>
        </PageContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { events } = state.events;
  return {
    events,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { eventsLoaded })(EventReport);
