/**
 * ADAPTED FROM:
 *
 * Resize and rotate images by EXIF orientation on the client side during upload. This uses
 * the HTML Canvas element and HTML5 FileReader.
 *
 * This class requires the Javascript file from https://github.com/jseidelin/exif-js
 *
 * See the GitHub repo for examples: https://github.com/ajgarn/CanvasImageUploader.
 *
 * @class CanvasImageUploader
 * @author ajgarn
 * @see https://github.com/ajgarn/CanvasImageUploader
 */

function CanvasImageUploader(options) {
  options = options || {};
  if (typeof options.maxSize === 'undefined') options.maxSize = 1500;
  if (typeof options.jpegQuality === 'undefined') options.jpegQuality = 0.7;

  var image; // Image object (<img>)
  var imageData; // Image from canvas as byte array

  /**
   * Converts a base64 string to byte array.
   */
  function base64toBlob(base64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(base64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  function calculateSize(image, maxSize) {
    var size = { width: image.width, height: image.height };
    if (image.width > maxSize || image.height > maxSize) {
      var ratio = image.width / image.height;
      if (image.width >= image.height) {
        size.width = maxSize;
        size.height = maxSize / ratio;
      } else {
        size.height = maxSize;
        size.width = maxSize * ratio;
      }
    }
    return size;
  }

  function setDimensions($canvas, size) {
    $canvas.attr('width', size.width);
    $canvas.attr('height', size.height);
  }

  function drawOnCanvas(image, $canvas, maxSize) {
    var canvas = $canvas[0];
    var ctx = canvas.getContext('2d');

    var size = calculateSize(image, maxSize);
    setDimensions($canvas, size);

    // Clear canvas
    ctx.clearRect(0, 0, $canvas.width(), $canvas.height());

    ctx.drawImage(
      image,
      0,
      0,
      image.width,
      image.height, // Source rectangle
      0,
      0,
      size.width,
      size.height,
    ); // Destination rectangle
  }

  function readImageToCanvasOnLoad(image, $canvas, callback) {
    drawOnCanvas(image, $canvas, options.maxSize);
    if (callback) callback();
    else console.warn('No callback for readImageToCanvas');
  }

  return {
    /**
     * Run to initialize CanvasImageUploader.
     */
    newImage: function () {
      imageData = null;
      image = new Image();
    },

    /**
     * Returns the image data if any file has been read.
     * @returns {Blob|null}
     */
    getImageData: function () {
      return imageData;
    },

    /**
     * Draw an image (<img>) or contents of a canvas to another canvas. The destination
     * canvas is resized properly.
     * @param source The image or source canvas to draw on a new canvas.
     * @param $destination The destination canvas to draw onto.
     * @param maxSize Maximum width or height of the destination canvas.
     */
    copyToCanvas: function (source, $destination, maxSize) {
      var size = calculateSize(source, maxSize);
      setDimensions($destination, size, 1);
      var destCtx = $destination[0].getContext('2d');
      destCtx.drawImage(source, 0, 0, source.width, source.height, 0, 0, size.width, size.height);
    },

    /**
     * Draw an image from a file on a canvas.
     * @param file The uploaded file.
     * @param $canvas The canvas (jQuery) object to draw on.
     * @param callback Function that is called when the operation has finished.
     */
    readImageToCanvas: function (file, $canvas, callback) {
      this.newImage();
      if (!file) return;

      var reader = new FileReader();
      reader.onload = function (fileReaderEvent) {
        image.onload = function () {
          readImageToCanvasOnLoad(this, $canvas, callback);
        };
        image.src = fileReaderEvent.target.result; // The URL from FileReader
      };
      reader.readAsDataURL(file);
    },

    /**
     * Read the canvas data and save it as a binary byte array to image data variable.
     * Get this data using the method getImageData().
     * @param canvas
     */
    saveCanvasToImageData: function (canvas) {
      var base64 = canvas.toDataURL('image/jpeg', options.jpegQuality).replace(/^data:image\/(png|jpeg|jpg|gif);base64,/, '');
      imageData = base64toBlob(base64, 'image/jpeg'); // Byte array
    },
  };
}

if (typeof module !== 'undefined') {
  module.exports = CanvasImageUploader;
}
