import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Popup } from '../Popup';
import { setNavData } from '../../actions';

class SignUpSuccess extends Component {
  onClose = () => {
    this.props.setNavData({
      signUpSuccess: false,
    });
  };

  onStartTour = () => {
    this.props.setNavData({
      signUpSuccess: false,
      onboardingStep: 'intro_dash_1',
    });
  };

  render() {
    if (!this.props.signUpSuccess) {
      return null;
    }
    return (
      <Popup
        className="popup-signUpSuccess"
        title="Sign up successful!"
        subtitle="You are now on the Community Manager."
        noDotTitle
        hasPadding
        onClose={this.onClose}
        topContent={
          <div className="successPopup_tick">
            <FontAwesome className="successPopup_tick_icon" name="check" />
          </div>
        }
        verticalButtonStack
        buttons={[
          {
            type: 'primaryAction',
            onClick: this.onStartTour,
            isActive: true,
            text: 'Start exploring',
            className: 'button--signup',
          },
          {
            type: 'text',
            onClick: this.onClose,
            isActive: true,
            text: 'Skip tour',
            className: 'button--signup',
          },
        ]}
      ></Popup>
    );
  }
}

const mapStateToProps = (state) => {
  const { nav } = state;
  return { signUpSuccess: nav.signUpSuccess };
};

const signUpSuccess = connect(mapStateToProps, { setNavData })(SignUpSuccess);
export { signUpSuccess as SignUpSuccess };
