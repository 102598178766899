import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

class Login extends Component {
    initialState = {
        authKey: '',
        submitting: false
    }

    state = { ...this.initialState };

    UNSAFE_componentWillMount() {
        const self = this;
        self.props.history.push('/login');
    }

    render() {
        return (
            <div className={'relative pageContainer'}>
                <div className={'poweredByPluss'} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
};

export default connect(mapStateToProps, {})(withRouter(Login));
