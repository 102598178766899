import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, GenericInput, Header, ImageInput, Text } from '../../components';
import { stringActions } from '../../webapi';
import { get300 } from '../../helper';
import FontAwesome from 'react-fontawesome';

class LoginCustomiser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedType: 'plain',
      squaresText: '',
      squaresImage1: '',
      squaresImage2: '',
      squaresImage3: '',
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    stringActions.getString('plussSpace', 'loginPicker').then((res) => {
      const newState = { selectedType: res.data.Type };
      if (newState.selectedType === 'squares') {
        newState.squaresText = res.data.Data.Text;
        newState.squaresImage1 = res.data.Data.Image1;
        newState.squaresImage2 = res.data.Data.Image2;
        newState.squaresImage3 = res.data.Data.Image3;
        this.checkSetImage('squaresImage1', newState.squaresImage1);
        this.checkSetImage('squaresImage2', newState.squaresImage2);
        this.checkSetImage('squaresImage3', newState.squaresImage3);
      }
      this.setState(newState);
    });
  }

  checkSetImage(key, url) {
    if (this[key]) {
      this[key].getWrappedInstance().setValue(url);
    } else {
      setTimeout(() => {
        this.checkSetImage(key, url);
      }, 100);
    }
  }

  onHandleTextChange = (e) => {
    var stateChange = {};
    stateChange[e.target.getAttribute('id')] = e.target.value;
    this.setState(stateChange);
  };

  onImageUpdated = (id, url) => {
    this.setState({
      [id]: url,
    });
  };

  save = () => {
    if (!this.validateForm()) {
      this.setState({
        showWarnings: true,
        success: false,
      });
      return;
    }
    this.setState({
      showWarnings: false,
      submitting: true,
      success: false,
    });
    const data = { Type: this.state.selectedType };
    if (this.state.selectedType === 'squares') {
      data.Data = {
        Text: this.state.squaresText,
        Image1: get300(this.state.squaresImage1),
        Image2: get300(this.state.squaresImage2),
        Image3: get300(this.state.squaresImage3),
      };
    }
    stringActions.setString('plussSpace', 'loginPicker', data).then((res) => {
      this.setState({
        submitting: false,
        success: true,
      });
    });
  };

  selectType = (type) => {
    this.setState({
      selectedType: type,
    });
  };

  validateSquaresText = () => {
    return !_.isEmpty(this.state.squaresText);
  };

  validateImage = (key) => {
    return !_.isEmpty(this.state[key]);
  };

  validateForm = () => {
    switch (this.state.selectedType) {
      case 'plain':
        return true;
      case 'squares':
        return (
          this.validateSquaresText() &&
          this.validateImage('squaresImage1') &&
          this.validateImage('squaresImage2') &&
          this.validateImage('squaresImage3')
        );
      default:
        return false;
    }
  };

  renderOptions() {
    return (
      <div>
        <div className="marginTop-40" style={{ display: this.state.selectedType === 'squares' ? 'block' : 'none' }}>
          <Text type="formTitleSmall" className="marginBottom-20">
            Customise the look
          </Text>
          <GenericInput
            id="squaresText"
            label="Label"
            placeholder="Enter label text"
            value={this.state.squaresText}
            onChange={(e) => this.onHandleTextChange(e)}
            isRequired
            isValid={() => {
              return this.validateSquaresText();
            }}
            showError={() => {
              return this.state.showWarnings && !this.validateSquaresText();
            }}
            alwaysShowLabel
          />
          <Text type="formLabel" className="marginTop-20">
            Images
          </Text>
          <div className="flex marginTop-10">
            <ImageInput
              ref={(r) => {
                this.squaresImage1 = r;
              }}
              isRequired
              showError={() => {
                return this.state.showWarnings && !this.validateImage('squaresImage1');
              }}
              refreshCallback={this.onImageUpdated.bind(this, 'squaresImage1')}
            />
            <ImageInput
              ref={(r) => {
                this.squaresImage2 = r;
              }}
              isRequired
              showError={() => {
                return this.state.showWarnings && !this.validateImage('squaresImage2');
              }}
              refreshCallback={this.onImageUpdated.bind(this, 'squaresImage2')}
              containerStyle={{ marginLeft: 40 }}
            />
            <ImageInput
              ref={(r) => {
                this.squaresImage3 = r;
              }}
              isRequired
              showError={() => {
                return this.state.showWarnings && !this.validateImage('squaresImage3');
              }}
              refreshCallback={this.onImageUpdated.bind(this, 'squaresImage3')}
              containerStyle={{ marginLeft: 40 }}
            />
          </div>
        </div>
      </div>
    );
  }

  renderTypePicker() {
    return (
      <div>
        <Text type="formTitleSmall" className="marginBottom-20">
          Choose a style
        </Text>
        <div className={`loginPicker_types${!!this.state.selectedType ? ' loginPicker_types-hasSelection' : ''}`}>
          <div
            className={`loginPicker_typeButton${this.state.selectedType === 'plain' ? ' loginPicker_typeButton-selected' : ''}`}
            onClick={() => {
              this.selectType('plain');
            }}
          >
            <div className="flex">
              <FontAwesome name="check" className="loginPicker_typeButton_check" />
              <Text type="formTitleSmall" className="loginPicker_typeButton_text">
                Plain
              </Text>
            </div>
          </div>
          <div
            className={`loginPicker_typeButton${this.state.selectedType === 'squares' ? ' loginPicker_typeButton-selected' : ''}`}
            onClick={() => {
              this.selectType('squares');
            }}
          >
            <div className="flex">
              <FontAwesome name="check" className="loginPicker_typeButton_check" />
              <Text type="formTitleSmall" className="loginPicker_typeButton_text">
                Squares
              </Text>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return (
      <Text type="formSaved" className="marginLeft-40">
        Saved
      </Text>
    );
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <Button inline buttonType="primary" onClick={() => this.save()} isActive={this.validateForm()}>
        Save
      </Button>
    );
  }

  render() {
    return (
      <div className="pageWithHeader">
        <Header />
        <div className="pageContainer-full paddingVertical-20 paddingHorizontal-40">
          <Text type="h1" className="marginBottom-40">
            Login Customiser
          </Text>
          {this.renderTypePicker()}
          {this.renderOptions()}
          <div className="marginTop-40 flex flex-center">
            {this.renderSubmit()}
            {this.renderSuccess()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(LoginCustomiser);
