import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { v4 } from 'uuid';
import { Button, GenericInput } from '../../../components';
import { COLOUR_GREEN } from '../../../js';
import { infoActions, stringActions } from '../../../webapi';

class SetWeatherCity extends Component {
  state = {
    weatherId: '',
    weatherCityInput: '',
    citySearch: '',
    loaded: false,
    showWarnings: false,
    useNew: true,
    searchToken: v4(),
    searchCount: 0,
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    stringActions.getString(this.props.auth.site, 'weatherId', true).then((res) => {
      this.setState({
        weatherId: res.data,
      });
      if (res.data) {
        this.getWeather();
      } else {
        this.setState({
          loaded: true,
        });
      }
    });
    stringActions.getString(this.props.auth.site, 'weatherCity', true).then((res) => {
      this.setState({
        weatherCityInput: res.data,
      });
    });
  }

  getWeather() {
    infoActions.getCurrentWeather(this.props.auth.site).then((res) => {
      this.setState({
        weather: res.data,
        loaded: true,
      });
    });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleSearchChange(event) {
    var stateChange = {
      searchCount: this.state.searchCount + 1,
      citySearch: event.target.value,
    };
    if (stateChange.searchCount === 2) {
      stateChange.searchCount = 0;
      infoActions.placeAutocomplete(stateChange.citySearch, this.state.searchToken).then((res) => {
        console.log('got autocomplete');
        console.log(res.data);
        this.setState({
          placePredictions: res.data,
        });
      });
    }
    this.setState(stateChange);
  }

  selectWeatherPlace(placeId) {
    if (!this.validateSearchForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    const searchToken = this.state.searchToken;
    this.setState({
      submittingSearch: true,
      showWarnings: false,
      success: false,
      searchToken: v4(),
      placePredictions: [],
      weather: null,
      searchCount: 0,
      citySearch: '',
    });
    infoActions
      .getWeatherCityFromGooglePlace(placeId, searchToken)
      .then((res) => {
        this.setState({ submittingSearch: false, weather: res.data, weatherCityInput: '' });
      })
      .catch((err) => {
        this.setState({ submittingSearch: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  handleSearchSubmit() {
    if (!this.validateSearchForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submittingSearch: true, showWarnings: false, success: false });
    infoActions
      .getWeatherCity(this.state.citySearch)
      .then((res) => {
        this.setState({ submittingSearch: false, weather: res.data, weatherCityInput: '' });
      })
      .catch((err) => {
        this.setState({ submittingSearch: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true, success: false });
      return;
    }
    this.setState({ submitting: true, showWarnings: false, success: false });
    const promises = [];
    promises.push(
      stringActions.setString(
        this.props.auth.site,
        'weatherCity',
        _.isEmpty(this.state.weatherCityInput) && !_.isEmpty(this.state.weather.Name)
          ? this.state.weather.Name
          : this.state.weatherCityInput,
      ),
    );
    const weatherId = this.state.useNew ? this.state.weather.CityId : this.state.weatherId;
    if (weatherId) {
      promises.push(stringActions.setString(this.props.auth.site, 'weatherId', weatherId));
    }

    Promise.all(promises)
      .then((res) => {
        this.setState({
          success: true,
          submitting: false,
        });
      })
      .catch((res) => {
        this.setState({ submitting: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  onSuccess() {
    this.setState({ submitting: false, success: true });
  }

  validateForm() {
    if (this.state.useNew) {
      return !_.isEmpty(this.state.weather);
    }
    return !_.isEmpty(this.state.weatherId);
  }

  validateSearchForm() {
    return !_.isEmpty(this.state.citySearch);
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateForm()}>
        Save
      </Button>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return <span style={{ color: COLOUR_GREEN, fontSize: 14, lineHeight: '33px', marginLeft: 30 }}>Saved</span>;
  }

  renderEmpty() {
    if (_.isEmpty(this.state.weatherId) && this.state.loaded) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
          <div className="emptyState" />
          <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
            <span className="fontRegular fontSize-13">
              Go to{' '}
              <a href="https://openweathermap.org/find" target="_blank" rel="noopener noreferrer">
                OpenWeatherMap
              </a>{' '}
              to search for your city. Once found, click on it and the website should be in the format of{' '}
              <b>https://openweathermap.org/city/6943577</b>. Enter the number in the input - in this case: 6943577.
            </span>
          </div>
        </div>
      );
    }
  }

  renderSearchSubmit() {
    if (this.state.submittingSearch) {
      return <Button buttonType="secondary">Searching...</Button>;
    }
    return (
      <Button inline buttonType="primary" onClick={this.handleSearchSubmit.bind(this)} isActive={this.validateSearchForm()}>
        Search
      </Button>
    );
  }

  renderWeather() {
    if (!this.state.weather) {
      return null;
    }
    const weatherColour = this.state.weather && this.state.weather.Weather.icon.substr(-1) === 'n' ? '#003159' : '#89c4f4';
    return (
      <div className="weatherbox">
        <h6 className="weathertitle">Weather</h6>
        <div
          className="weathercontent"
          style={{
            backgroundColor: weatherColour,
          }}
        >
          <img
            className="weathericon"
            alt=""
            src={`https://pluss60-dev-media.s3-ap-southeast-2.amazonaws.com/pluss/weather/${this.state.weather.Weather.icon}.png`}
          ></img>
        </div>

        <h4 className="weathercity">
          <b>{_.isEmpty(this.state.weatherCityInput) ? this.state.weather.Name : this.state.weatherCityInput}</b>
        </h4>
        <p className="weatherinfo">{`${Math.round(this.state.weather.Temps.temp)}°C • ${this.state.weather.Weather.main}`}</p>
      </div>
    );
  }

  renderSearchResults() {
    if (_.isEmpty(this.state.placePredictions)) {
      return null;
    }
    return this.state.placePredictions.map((place) => {
      return (
        <p
          className="weatherCityPrediction"
          key={place.place_id}
          onClick={() => {
            this.selectWeatherPlace(place.place_id);
          }}
        >
          {place.description}
        </p>
      );
    });
  }

  renderNew() {
    return (
      <div style={{ width: '100%', maxWidth: 600 }}>
        <GenericInput
          id="citySearch"
          label="Search for City"
          type="text"
          placeholder="Enter city"
          value={this.state.citySearch}
          onChange={(e) => this.handleSearchChange(e)}
          isRequired
          isValid={() => {
            return !_.isEmpty(this.state.citySearch);
          }}
          showError={() => {
            return this.state.showWarnings && _.isEmpty(this.state.citySearch);
          }}
        />
        {this.renderSearchResults()}
        {/* {this.renderSearchSubmit()} */}

        {this.renderWeather()}

        {this.state.weather && (
          <GenericInput
            id="weatherCityInput"
            label="Weather City Name"
            type="text"
            placeholder="Enter city name"
            value={this.state.weatherCityInput}
            onChange={(e) => this.handleChange(e)}
            help="Use this to manually replace the city name of the weather source. This allows you to display weather from Brisbane, but have it show up as Spring Hill on the TV/Kiosk."
            style={{ marginTop: 20 }}
          />
        )}

        {this.state.weather && this.renderSubmit()}
        {this.renderSuccess()}
      </div>
    );
  }

  render() {
    if (this.state.useNew) {
      return this.renderNew();
    }
    return (
      <div style={{ width: '100%', maxWidth: 600 }}>
        <GenericInput
          id="weatherId"
          label="Weather City Id"
          type="textarea"
          placeholder="Enter city id"
          value={this.state.weatherId}
          onChange={(e) => this.handleChange(e)}
          inputStyle={{
            height: 500,
          }}
          isRequired
          isValid={() => {
            return !_.isEmpty(this.state.weatherId);
          }}
          showError={() => {
            return this.state.showWarnings && _.isEmpty(this.state.weatherId);
          }}
          help={
            <div>
              Go to{' '}
              <a href="https://openweathermap.org/find" target="_blank" rel="noopener noreferrer">
                OpenWeatherMap
              </a>{' '}
              to search for your city. Once found, click on it and the website should be in the format of{' '}
              <span className="text-dark">https://openweathermap.org/city/6943577</span>. Enter the number in the input - in this case:{' '}
              <span className="text-dark">6943577</span>.
            </div>
          }
        />
        <GenericInput
          id="weatherCityInput"
          label="Weather City Name"
          type="text"
          placeholder="Enter city name"
          value={this.state.weatherCityInput}
          onChange={(e) => this.handleChange(e)}
          help="Use this to manually replace the city name of the weather source. This allows you to display weather from Brisbane, but have it show up as Spring Hill on the TV/Kiosk."
        />
        {this.renderSubmit()}
        {this.renderEmpty()}
        {this.renderSuccess()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(SetWeatherCity);
