import { authedFunction, unauthedFunction } from '../session';
import _ from 'lodash';
import { getUrl } from './helper';

export const visitorActions = {
  getVisitors: (site, minTime, maxTime) => {
    const query = {};
    if (!_.isUndefined(site)) {
      query.site = site;
    }
    if (!_.isUndefined(minTime)) {
      query.minTime = minTime;
    }
    if (!_.isUndefined(maxTime)) {
      query.maxTime = maxTime;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('visitors', 'getVisitors', query),
    });
  },
  getContractors: (site, minTime, maxTime) => {
    const query = {};
    if (!_.isUndefined(site)) {
      query.site = site;
    }
    if (!_.isUndefined(minTime)) {
      query.minTime = minTime;
    }
    if (!_.isUndefined(maxTime)) {
      query.maxTime = maxTime;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('visitors', 'getContractors', query),
    });
  },
  saveVisitorEntry: (entry, site) => {
    return unauthedFunction({
      method: 'POST',
      url: getUrl('visitors', 'addVisitor'),
      data: {
        entry,
        site,
      },
    });
  },
  saveContractorEntry: (entry, site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('visitors', 'addContractor'),
      data: {
        entry,
        site,
      },
    });
  },
  getFormLockoutLog: (site, minTime, maxTime, limit) => {
    const query = { site };
    if (!_.isUndefined(minTime)) {
      query.minTime = minTime;
    }
    if (!_.isUndefined(maxTime)) {
      query.maxTime = maxTime;
    }
    if (!_.isUndefined(limit)) {
      query.limit = limit;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('visitors', 'formlockout/get', query),
    });
  },
  logFormLockout: (site, answers) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('visitors', 'formlockout/log'),
      data: {
        site,
        answers,
      },
    });
  },
};
