import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { AddButton, GenericInput, ImageInput, Popup } from '../../../components';
import { COLOUR_BRANDING_OFF } from '../../../js';
import { typeActions } from '../../../webapi';

class ManageSubdomains extends Component {
  state = {
    subdomains: [],
    editSubdomain: null,
    backgroundImage: null,
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    this.setState({
      loading: true,
    });
    typeActions.getSubdomains().then((res) => {
      this.setState({
        subdomains: _.sortBy(res.data, 'Key'),
        loading: false,
      });
    });
  }

  onImageUpdated(url) {
    this.setState({
      backgroundImage: url,
    });
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  handleSubmit = () => {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true, showWarnings: false });
    typeActions
      .updateSubdomain(this.state.showNew ? this.state.subdomainInput : this.state.editSubdomain.Key, this.state.backgroundImage)
      .then((res) => {
        this.getData();
        this.hidePopup();
      });
  };

  hidePopup = () => {
    this.setState({
      submitting: false,
      editSubdomain: null,
      backgroundImage: null,
      showNew: false,
    });
  };

  openEditSubdomain = (subdomain) => {
    this.setState({
      editSubdomain: subdomain,
      backgroundImage: subdomain.Background,
      showNew: false,
    });
  };

  openAddNew = () => {
    this.setState({
      editSubdomain: null,
      backgroundImage: null,
      showNew: true,
    });
  };

  onSuccess() {
    this.setState({ submitting: false, success: true });
  }

  validateForm() {
    if (this.state.showNew) {
      return !_.isEmpty(this.state.subdomainInput) && !_.isEmpty(this.state.backgroundImage);
    }
    return true;
  }

  renderSubdomainPopup() {
    if (this.state.submitting) {
      return (
        <Popup title="Saving" maxWidth={600} hasPadding>
          <FontAwesome style={{ fontSize: 20, color: COLOUR_BRANDING_OFF }} name="spinner fa-pulse fa-fw" />
        </Popup>
      );
    }
    if (this.state.showNew) {
      return (
        <Popup
          title={`Add new subdomain configuration`}
          maxWidth={600}
          hasPadding
          buttons={[
            {
              type: 'outlinedAction',
              onClick: this.hidePopup,
              isActive: true,
              text: 'Cancel',
              className: 'button--signup',
            },
            {
              type: 'primaryAction',
              onClick: this.handleSubmit,
              isActive: this.validateForm(),
              text: 'Save',
              className: 'button--signup',
            },
          ]}
        >
          <div className="flex flex-column flex-center">
            <GenericInput
              id="subdomainInput"
              type="text"
              label="Subdomain"
              placeholder="admin"
              help="Only enter the subdomain. I.e. for admin.plusscommunities.com, enter admin"
              value={this.state.subdomainInput}
              onChange={(e) => this.handleChange(e)}
              inputClass="signUp_siteNameInput"
            />
            <p className="signUp_inputLabel signUp_inputLabel-widthAuto marginTop-20">Community Manager Background</p>
            <ImageInput
              ref="imageInput"
              style={{ width: 384, height: 216 }}
              containerStyle={{ width: 384, height: 216 }}
              noMenu
              refreshCallback={this.onImageUpdated.bind(this)}
              hasDefault={this.state.backgroundImage}
              simpleStyle
              maxSize={1920}
              noDownload
            />
          </div>
        </Popup>
      );
    }
    if (this.state.editSubdomain) {
      return (
        <Popup
          title={`Edit ${this.state.editSubdomain.Key}`}
          noDotTitle
          maxWidth={600}
          hasPadding
          buttons={[
            {
              type: 'outlinedAction',
              onClick: this.hidePopup,
              isActive: true,
              text: 'Cancel',
              className: 'button--signup',
            },
            {
              type: 'primaryAction',
              onClick: this.handleSubmit,
              isActive: this.validateForm(),
              text: 'Save',
              className: 'button--signup',
            },
          ]}
        >
          <p className="signUp_inputLabel signUp_inputLabel-widthAuto marginTop-20">Community Manager Background</p>
          <ImageInput
            ref="imageInput"
            style={{ width: 384, height: 216 }}
            containerStyle={{ width: 384, height: 216 }}
            noMenu
            refreshCallback={this.onImageUpdated.bind(this)}
            hasDefault={this.state.backgroundImage}
            simpleStyle
            maxSize={1920}
            noDownload
          />
        </Popup>
      );
    }
    return null;
  }

  renderContent() {
    if (this.state.loading) {
      return <FontAwesome style={{ fontSize: 20, color: COLOUR_BRANDING_OFF }} name="spinner fa-pulse fa-fw" />;
    }
    return (
      <div className="hub-contentWrapper">
        <Table className="plussTable" striped bordered condensed hover>
          <thead>
            <tr>
              <th>Subdomain</th>
              <th>Background Image</th>
            </tr>
          </thead>
          <tbody>
            {this.state.subdomains.map((subdomain) => {
              return (
                <tr key={subdomain.Key}>
                  <td className="table-TitleColumn">{subdomain.Key}</td>
                  <td>
                    <div className="bgCover" style={{ width: 192, height: 108, backgroundImage: `url(${subdomain.Background})` }}></div>
                  </td>
                  <td className="table-options">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <a
                        onClick={() => {
                          this.openEditSubdomain(subdomain);
                        }}
                      >
                        <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="pencil" />
                      </a>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <div className="flex flex-between flex-center">
          <p className="fontMedium fontSize-36 text-dark">Manage subdomains</p>
          <AddButton onClick={this.openAddNew} text="NEW SUBDOMAIN" />
        </div>
        {this.renderContent()}
        {this.renderSubdomainPopup()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(ManageSubdomains);
