import React, { Component } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import UserList from './UserList';
import { Button, SuccessPopup, DropdownInput } from '../../../components';
import { userActions, linkedUserActions } from '../../../webapi';
import Relationships from '../../../json/relationships.json';

class LinkUserPopup extends Component {
  state = {
    types: [],

    loading: true,
    loadingLinks: true,
    updatingLinks: false,
    links: [],

    viewCreator: false,
    viewNewCreator: false,

    newLinkUser: null,
    relationships: [],
    userRelationship: '',
    linkedRelationships: [],
    linkedUserRelationship: '',
    submitting: false,

    usersToFind: [],
    users: [],
  };

  componentDidMount() {
    this.loadRelationships();
    this.fetchLinkedUsers();
    this.fetchUsers();
    this.createData();
  }

  createData() {
    const ignore = [];
    ignore.push(this.props.user.Id);
    this.state.links.forEach(u => {
      ignore.push(u.id);
    });
    this.setState({ ignore });
  }

  selectUser(user) {
    this.setState({
      chosenUserId: user != null ? user.Id : null,
      chosenUser: user != null ? user : {},
    });
  }

  buildData(users) {
    const source = [];
    users.forEach(user => {
      if (user.category === 'resident') {
        source.push(user);
      }
    });
    this.setState({ users: source });
  }

  fetchUsers() {
    this.setState({ loading: true });
    userActions
      .fetchUsers(this.props.auth.site)
      .then(res => {
        this.setState({
          loading: false,
        });
        if (res.userFetchFail) {
          return;
        }
        if (res.data != null && !_.isEmpty(res.data.results.Items) && res.data.results.Items[0].site === this.props.auth.site) {
          this.buildData(res.data.results.Items);
          this.createData();
        }
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  }

  fetchLinkedUsers() {
    linkedUserActions
      .getLinkedToUsers(this.props.user.Id)
      .then(res => {
        this.setState({
          loadingLinks: false,
          updatingLinks: false,
          links: res.data,
        });
        this.createData();
      })
      .catch(error => {
        console.log('error response - fetching');
        console.log(error);
        this.setState({
          loadingLinks: false,
          updatingLinks: false,
        });
      });
  }

  validateNewLink() {
    if (this.state.submitting) {
      return false;
    }
    if (this.state.newLinkUser == null) {
      return false;
    }
    if (this.state.userRelationship.length < 2) {
      return false;
    }
    if (this.state.linkedUserRelationship.length < 2) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    if (!this.validateNewLink()) {
      return;
    }
    this.setState({
      submitting: true,
    });

    linkedUserActions
      .addLink(
        this.props.user.Id,
        this.state.userRelationship,
        {
          id: this.state.newLinkUser.Id,
          displayName: this.state.newLinkUser.displayName,
          profilePic: !_.isUndefined(this.state.newLinkUser.profilePicThumb)
            ? this.state.newLinkUser.profilePicThumb
            : this.state.newLinkUser.profilePic,
        },
        this.state.linkedUserRelationship,
      )
      .then(res => {
        this.props.onSuccess();
      })
      .catch(error => {
        console.log('error response - fetching');
        console.log(error);
        this.setState({
          loadingLinks: false,
          updatingLinks: false,
        });
      });
  }

  selectNewLink(user) {
    this.setState({ newLinkUser: user });
  }

  loadRelationships() {
    this.setState({ relationships: _.values(Relationships) });
  }

  selectUserRelationship(key, e) {
    const selected = _.find(this.state.relationships, { Key: key });
    this.setState({ userRelationship: selected.Title, linkedRelationships: selected.Options, linkedUserRelationship: '' });
  }

  selectLinkedUserRelationship(key, e) {
    const selected = _.find(this.state.linkedRelationships, { Key: key });
    this.setState({ linkedUserRelationship: selected.Title });
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="primary">Saving...</Button>;
    }
    return (
      <Button buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.validateNewLink()}>
        Save
      </Button>
    );
  }

  render() {
    return (
      <SuccessPopup noIcon>
        <div style={{ marginTop: 16 }}>
          <div className="text-sectionTitle" style={{ padding: 16, paddingTop: 8, paddingBottom: 0 }}>
            NEW LINK
          </div>

          {this.state.newLinkUser == null && (
            <div>
              <div style={styles.flexColumnWrapper}>
                <UserList
                  style={{ maxHeight: 350, maxWidth: 'auto' }}
                  users={this.state.users}
                  sortColumn="displayName"
                  ignore={this.state.ignore}
                  selectUser={this.selectNewLink.bind(this)}
                />
              </div>

              <div style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 16, paddingBottom: 16 }}>
                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <Button buttonType="outlined" onClick={this.props.onClose} isActive>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          )}
          {this.state.newLinkUser != null && (
            <div>
              <div style={styles.flexRowWrapper}>
                <div style={{ width: 200 }}>
                  <div
                    style={{
                      ...styles.profilePic,
                      marginTop: 8,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      backgroundImage: `url(${
                        !_.isUndefined(this.props.user.profilePicThumb) ? this.props.user.profilePicThumb : this.props.user.profilePic
                      })`,
                    }}
                  />
                  <div className="fontMedium text-dark" style={{ fontSize: 18, textAlign: 'center', marginBottom: 16 }}>
                    {this.props.user.displayName}
                  </div>
                  <DropdownInput
                    id="userRelationship"
                    label="Relationship"
                    value={this.state.userRelationship}
                    options={this.state.relationships}
                    onSelect={this.selectUserRelationship.bind(this)}
                    disabled={this.state.submitting}
                  />
                </div>
                <div style={{ width: 200 }}>
                  <div
                    style={{
                      ...styles.profilePic,
                      marginTop: 8,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      backgroundImage: `url(${
                        !_.isUndefined(this.state.newLinkUser.profilePicThumb)
                          ? this.state.newLinkUser.profilePicThumb
                          : this.state.newLinkUser.profilePic
                      })`,
                    }}
                  />
                  <div className="fontMedium text-dark" style={{ fontSize: 18, textAlign: 'center', marginBottom: 16 }}>
                    {this.state.newLinkUser.displayName}
                  </div>
                  <DropdownInput
                    id="linkedUserRelationship"
                    label="Relationship"
                    value={this.state.linkedUserRelationship}
                    options={this.state.linkedRelationships}
                    onSelect={this.selectLinkedUserRelationship.bind(this)}
                    disabled={this.state.submitting}
                  />
                </div>
              </div>
              <div style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 16, paddingBottom: 16 }}>
                <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: 15 }}>
                  <Button buttonType="outlined" onClick={this.props.onClose} isActive style={{ marginRight: 16 }}>
                    Cancel
                  </Button>
                  {this.renderSubmit()}
                </div>
              </div>
            </div>
          )}
        </div>
      </SuccessPopup>
    );
  }
}

const styles = {
  flexColumnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  flexRowWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  profilePic: {
    height: 50,
    width: 50,
    borderRadius: 25,
    border: '1px solid #aaa',
    backgroundColor: '#eee',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginBottom: 8,
  },
};

const mapStateToProps = state => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, {})(withRouter(LinkUserPopup));
