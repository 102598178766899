import _ from 'lodash';

import {
    ALERT_REMOVED,
    ALERTS_LOADED,
    ALERTS_UPDATED,
    ALERTS_DASHBOARD_LOADING,
    NEWS_PURGE
} from '../actions/types';

const INITIAL_STATE = {
    alerts: [],
    loading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ALERTS_DASHBOARD_LOADING:
            return {
                ...state,
                loading: true
            };
        // case NEWS_SUBMISSIONS_DASHBOARD_LOADING:
        //     return {
        //         ...state,
        //         submissionsLoading: true
        //     };
        case ALERT_REMOVED:
            const indexF = _.findIndex(state.alerts, (event) => { return event != null && event.RowId === action.payload });
            if (indexF > -1) {
                const newEventsF = [...state.alerts];
                newEventsF.splice(indexF, 1);
                return { ...state, alerts: newEventsF };
            }
            return state;
        case ALERTS_LOADED:
            var resultn = _.filter(action.payload, (ev) => { return !ev.Deleted; });
            return {
                ...state,
                alerts: resultn,
                loading: false
            };
        case ALERTS_UPDATED:
            var result = _.unionWith(action.payload, state.alerts, (v1, v2) => {
                return v1 != null && v2 != null && v1.RowId === v2.RowId;
            });
            result = _.filter(result, (ev) => { return !ev.Deleted; });
            return {
                ...state,
                alerts: result,
                loading: false
            };
        case NEWS_PURGE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
