import _ from 'lodash';

import {
  USERS_LOADED,
  USER_REMOVED,
  USERS_PURGE,
  USERS_LOADING,
  USERS_RESIDENT_COUNT_SET,
  INVITE_CODES_LOADED,
  INVITE_CODE_UPDATED,
  INVITE_CODE_REMOVED,
} from '../actions/types';

const INITIAL_STATE = {
  users: [],
  allUsers: [],
  residentCount: 0,
  loading: false,
  inviteCodes: [],
};

export default (state = INITIAL_STATE, action) => {
  let newInviteCodes;
  switch (action.type) {
    case USERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USERS_RESIDENT_COUNT_SET:
      return {
        ...state,
        residentCount: action.payload,
        loading: false,
      };
    case USERS_LOADED:
      var result = _.unionWith(action.payload, state.users, (v1, v2) => {
        return v1 != null && v2 != null && v1.Id === v2.Id;
      });
      var filtResults = _.filter(result, (ev) => {
        if (!ev || ev.Deleted) {
          return false;
        }
        return true;
      });
      return {
        ...state,
        users: filtResults,
        allUsers: result,
        loading: false,
      };
    case USER_REMOVED:
      const index = _.findIndex(state.users, (event) => {
        return event != null && event.Id === action.payload;
      });
      if (index > -1) {
        const newEvents = [...state.users];
        newEvents.splice(index, 1);
        return { ...state, users: newEvents };
      }
      return state;
    case INVITE_CODES_LOADED:
      return { ...state, inviteCodes: action.payload };
    case INVITE_CODE_UPDATED:
      newInviteCodes = _.unionWith([action.payload], state.inviteCodes, (v1, v2) => {
        return v1 != null && v2 != null && v1.Code === v2.Code;
      });
      return { ...state, inviteCodes: newInviteCodes };
    case INVITE_CODE_REMOVED:
      newInviteCodes = state.inviteCodes.filter((i) => i.Code !== action.payload.Code);
      return { ...state, inviteCodes: newInviteCodes };
    case USERS_PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
