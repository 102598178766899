import _ from 'lodash';
import { SET_LOCAL, ADD_RECENTLY_CREATED, LOGOUT } from '../actions/types';
import { clearLocalStorage, readStorageWithCookie, setLocalStorage } from '../helper';

const INITIAL_STATE = {
  recentlyCreated: [],
  ...readStorageWithCookie('local', true),
};

export default (state = INITIAL_STATE, action) => {
  let newState;
  switch (action.type) {
    case SET_LOCAL:
      newState = { ...state, ...action.payload };
      setLocalStorage('local', newState, true);
      return newState;
    case ADD_RECENTLY_CREATED:
      console.log(state);
      newState = {
        ...state,
        recentlyCreated: [
          action.payload,
          ..._.filter(state.recentlyCreated, (r) => {
            return r !== action.payload;
          }),
        ],
      };
      setLocalStorage('local', newState, true);
      return newState;
    case LOGOUT:
      clearLocalStorage('local');
      return {};
    default:
      return state;
  }
};
