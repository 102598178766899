import { authedFunction } from '../session';
import { getUrl } from './helper';

export const mapActions = {
  getMaps: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('maps', 'get', { site }),
    });
  },
  getMap: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('maps', `get/${id}`),
    });
  },
  addMap: (site, title, source, shouldNotify) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('maps', 'add'),
      data: {
        site,
        title,
        source,
        shouldNotify,
      },
    });
  },
  editMap: (site, id, title, source) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('maps', 'edit'),
      data: {
        site,
        id,
        title,
        source,
      },
    });
  },
  deleteMap: (site, id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('maps', 'remove'),
      data: {
        site,
        id,
      },
    });
  },
};
