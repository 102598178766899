import _ from 'lodash';
import { DISHES_LOADED, UPDATE_DISH, DISH_REMOVED } from '../actions/types';

const INITIAL_STATE = {
  dishes: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DISHES_LOADED:
      return {
        ...state,
        dishes: _.unionWith(action.payload, state.dishes, (v1, v2) => {
          return v1 != null && v2 != null && v1.Id === v2.Id;
        }),
      };
    case UPDATE_DISH:
      return {
        ...state,
        dishes: _.unionWith([action.payload], state.dishes, (v1, v2) => {
          return v1 != null && v2 != null && v1.Id === v2.Id;
        }),
      };
    case DISH_REMOVED:
      return {
        ...state,
        dishes: _.filter(state.dishes, (dish) => {
          return dish != null && dish.Id !== action.payload;
        }),
      };
    default:
      return state;
  }
};
