import React, { Component } from 'react';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import qs from 'qs';
import moment from 'moment';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { userActions, linkedUserActions } from '../../../webapi';
import { getFirstName } from '../../../helper';
import { Button, DropdownInput } from '../../../components';
import { COLOUR_BRANDING_MAIN, COLOUR_BRANDING_LIGHT, COLOUR_BRANDING_ACTION } from '../../../js';
import UserList from './UserList';
import NewUserLink from './NewUserLink';
import { validateAccess } from '../../../session';
import Relationships from '../../../json/relationships.json';

class User extends Component {
  state = {
    types: [],

    loading: true,
    loadingLinks: true,
    updatingLinks: false,
    links: [],

    hoverOn: null,

    viewCreator: false,
    viewNewCreator: false,

    newLinkUser: null,
    relationships: [],
    userRelationship: '',
    linkedRelationships: [],
    linkedUserRelationship: '',
    submitting: false,

    usersToFind: [],
    users: [],
  };

  componentDidMount() {
    setTimeout(() => {
      this.loadRelationships();
      this.fetchLinkedUsers();
      this.createData();
      this.getInviteCode();
    }, 2000);

    console.log(qs.parse(this.props.location.search));

    if (
      this.props.isAResident &&
      !_.isUndefined(qs.parse(this.props.location.search)['?launchNewLink']) &&
      qs.parse(this.props.location.search)['?launchNewLink'] === 'true'
    ) {
      this.setState({ viewNewCreator: true });
    }
  }

  getInviteCode() {
    if (validateAccess(this.props.auth.site, 'inviteCode', this.props.auth)) {
      userActions.getInviteCode(this.props.userId, 'family', this.props.auth.site).then((res) => {
        console.log(res.data);
        this.setState({
          inviteCode: res.data,
        });
      });
    }
  }

  generateNewCode() {
    this.setState({
      generatingCode: true,
    });
    userActions.generateInviteCode(this.props.userId, 'family', this.props.auth.site).then((res) => {
      console.log(res.data);
      this.setState({
        generatingCode: false,
        inviteCode: res.data,
      });
    });
  }

  createData() {
    const ignore = [];
    ignore.push(this.props.userId);
    this.state.links.forEach((u) => {
      ignore.push(u.id);
    });
    this.setState({ ignore });
  }

  selectUser(user) {
    this.setState({
      chosenUserId: user != null ? user.Id : null,
      chosenUser: user != null ? user : {},
    });
  }

  buildData(users) {
    const source = [];
    Object.keys(users).forEach((userId) => {
      source.push({ ...users[userId], id: userId });
    });
    this.setState({ users: source });
  }

  fetchUsers() {
    this.setState({ loading: true });
    userActions
      .fetchUsers(this.props.auth.site)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.userFetchFail) {
          return;
        }
        if (res.data != null && !_.isEmpty(res.data.results.Items) && res.data.results.Items[0].site === this.props.auth.site) {
          this.buildData(res.data.results.Items);
          this.createData();
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  }

  fetchLinkedUsers() {
    if (this.props.isAResident(this.props.user.type)) {
      linkedUserActions
        .getLinkedUsers(this.props.userId)
        .then((res) => {
          this.setState({
            loadingLinks: false,
            updatingLinks: false,
            links: res.data.Items,
          });
          this.createData();
        })
        .catch((error) => {
          console.log('error response - fetching');
          console.log(error);
          this.setState({
            loadingLinks: false,
            updatingLinks: false,
          });
        });
    } else {
      this.fetchLinkedTo();
    }
  }

  fetchLinkedTo() {
    linkedUserActions
      .getLinkedToUsers(this.props.userId)
      .then((res) => {
        this.setState({
          loadingLinks: false,
          updatingLinks: false,
          links: res.data,
        });
        this.createData();
      })
      .catch((error) => {
        console.log('error response - fetching');
        console.log(error);
        this.setState({
          loadingLinks: false,
          updatingLinks: false,
        });
      });
  }

  openNewPopup() {
    if (this.state.loadingLinks || this.state.updatingLinks || this.state.viewNewCreator || this.state.viewCreator) {
      return;
    }
    this.setState({ viewNewCreator: true });
  }

  openPopup() {
    if (this.state.loadingLinks || this.state.updatingLinks || this.state.viewNewCreator || this.state.viewCreator) {
      return;
    }
    this.fetchUsers();
    this.setState({ viewCreator: true });
  }

  closePopup() {
    this.setState({ viewNewCreator: false, viewCreator: false, newLinkUser: null });
  }

  onHover(id) {
    this.setState({ hoverOn: id });
  }

  onNoHover(id) {
    this.setState({ hoverOn: null });
  }

  removeLink(user) {
    if (window.confirm(`Are you sure you want to remove this user link?`)) {
      this.setState({
        loadingLinks: true,
        updatingLinks: true,
      });
      linkedUserActions
        .removeLink(
          this.props.isAResident(this.props.user.type) ? user.id : this.props.userId,
          this.props.isAResident(this.props.user.type) ? this.props.userId : user.id,
        )
        .then((res) => {
          this.fetchLinkedUsers();
        })
        .catch((error) => {
          console.log('error response - removing');
          console.log(error);
          this.setState({
            loadingLinks: false,
            updatingLinks: false,
          });
        });
    }
  }

  validateNewLink() {
    if (this.state.submitting) {
      return false;
    }
    if (this.state.newLinkUser == null) {
      return false;
    }
    if (this.state.userRelationship.length < 2) {
      return false;
    }
    if (this.state.linkedUserRelationship.length < 2) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    if (!this.validateNewLink()) {
      return;
    }
    this.setState({
      loadingLinks: true,
      updatingLinks: true,
      viewCreator: false,
    });

    linkedUserActions
      .addLink(
        this.state.newLinkUser.Id,
        this.state.userRelationship,
        {
          id: this.props.userId,
          displayName: this.props.user.displayName,
          profilePic: !_.isUndefined(this.props.user.profilePicThumb) ? this.props.user.profilePicThumb : this.props.user.profilePic,
        },
        this.state.linkedUserRelationship,
      )
      .then((res) => {
        this.setState({
          newLinkUser: null,
          userRelationship: '',
          linkedUserRelationship: '',
        });
        this.fetchLinkedUsers();
      })
      .catch((error) => {
        console.log('error response - fetching');
        console.log(error);
        this.setState({
          loadingLinks: false,
          updatingLinks: false,
        });
      });
  }

  selectNewLink(user) {
    this.setState({ newLinkUser: user });
  }

  loadRelationships() {
    this.setState({ relationships: _.values(Relationships) });
  }

  selectUserRelationship(key, e) {
    const selected = _.find(this.state.relationships, { Key: key });
    this.setState({ userRelationship: selected.Title, linkedRelationships: selected.Options, linkedUserRelationship: '' });
  }

  selectLinkedUserRelationship(key, e) {
    const selected = _.find(this.state.linkedRelationships, { Key: key });
    this.setState({ linkedUserRelationship: selected.Title });
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="primary">Saving...</Button>;
    }
    return (
      <Button buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.validateNewLink()}>
        Save
      </Button>
    );
  }

  renderCurrentLinks() {
    if (this.state.loadingLinks) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    let source = _.sortBy(this.state.links, 'displayName');

    return source.map((ev, index) => {
      if (ev != null) {
        return (
          <div
            key={ev.id}
            className="subtleHover animateMargin"
            style={{ ...styles.listUser }}
            // onMouseEnter={this.onHover.bind(this, ev.id)}
            // onMouseLeave={this.onNoHover.bind(this)}
          >
            <div style={{ ...styles.userInner, overflow: 'hidden' }}>
              <div
                style={{
                  ...styles.profilePic,
                  height: 40,
                  width: 40,
                  borderRadius: 20,
                  marginRight: 8,
                  marginBottom: 0,
                  backgroundImage: `url(${!_.isUndefined(ev.profilePicThumb) ? ev.profilePicThumb : ev.profilePic})`,
                }}
              />
              <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'baseline', width: '80%' }}>
                <div className="truncate" style={{ flex: 1 }}>
                  <a href={`/usershub/user/${ev.id}`}>{ev.displayName}</a>
                </div>
              </div>
            </div>
            <div style={styles.userInner}>
              <div style={{ fontSize: 14, marginLeft: 16, marginRight: 16, color: COLOUR_BRANDING_MAIN }}>{ev.relationship}</div>
              <div className={`aCheck  aCheck--active`} onClick={this.removeLink.bind(this, ev)}>
                <div className="aCheck-Tick">
                  <FontAwesome className="aCheck-font" name={'times'} />
                </div>
              </div>
            </div>
          </div>
        );
      }
      return null;
    });
  }

  renderCreateNewUserLink() {
    if (!this.state.viewNewCreator) {
      return false;
    }
    return (
      <NewUserLink
        types={this.props.types}
        close={this.closePopup.bind(this)}
        user={this.props.user}
        userId={this.props.userId}
        onSuccess={this.fetchLinkedUsers.bind(this)}
      />
    );
  }

  renderAddNewLink() {
    return (
      <div style={{ marginTop: 16, marginBottom: 32, backgroundColor: '#fff', borderRadius: 4 }}>
        <div className="text-sectionTitle" style={{ padding: 16, paddingTop: 8, paddingBottom: 0 }}>
          NEW LINK
        </div>

        {this.state.newLinkUser == null && (
          <div style={styles.flexColumnWrapper}>
            <UserList
              style={{ maxHeight: 350 }}
              users={this.state.users}
              sortColumn="displayName"
              ignore={this.state.ignore}
              selectUser={this.selectNewLink.bind(this)}
            />
          </div>
        )}
        {this.state.newLinkUser != null && (
          <div>
            <div style={styles.flexColumnWrapper}>
              <div
                style={{
                  ...styles.profilePic,
                  marginTop: 8,
                  backgroundImage: `url(${
                    !_.isUndefined(this.state.newLinkUser.profilePicThumb)
                      ? this.state.newLinkUser.profilePicThumb
                      : this.state.newLinkUser.profilePic
                  })`,
                }}
              />
              <div className="fontMedium text-dark" style={{ fontSize: 18 }}>
                {this.state.newLinkUser.displayName}
              </div>
            </div>
            <div style={{ paddingLeft: 24, paddingRight: 24, paddingTop: 16, paddingBottom: 16 }}>
              <DropdownInput
                id="userRelationship"
                label={`Relationship to ${getFirstName(this.props.user.displayName)}`}
                value={this.state.userRelationship}
                options={this.state.relationships}
                onSelect={this.selectUserRelationship.bind(this)}
                disabled={this.state.submitting}
              />
              <DropdownInput
                id="linkedUserRelationship"
                label={`${getFirstName(this.props.user.displayName)}'s relationship to ${getFirstName(this.state.newLinkUser.displayName)}`}
                value={this.state.linkedUserRelationship}
                options={this.state.linkedRelationships}
                onSelect={this.selectLinkedUserRelationship.bind(this)}
                disabled={this.state.submitting}
              />
              <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', paddingTop: 15 }}>
                <Button
                  buttonType="outlined"
                  onClick={() => {
                    this.closePopup();
                  }}
                  isActive
                  style={{ marginRight: 16 }}
                >
                  Cancel
                </Button>
                {this.renderSubmit()}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderResidentLinking() {
    if (this.props.isAResident(this.props.user.type)) {
      return (
        <div>
          <div className="flex flex-between flex-center marginBottom-8">
            <Button
              buttonType="outlined"
              isActive={!this.state.loadingLinks && !this.state.viewCreator && !this.state.viewNewCreator}
              style={{ marginBottom: 0 }}
              onClick={() => {
                this.openNewPopup();
              }}
            >
              Create and link a new user
            </Button>
            {this.state.viewNewCreator && (
              <Button
                buttonType="tertiary"
                isActive
                style={{ marginBottom: 0 }}
                onClick={() => {
                  this.closePopup();
                }}
              >
                Cancel
              </Button>
            )}
          </div>
          <div className="flex flex-between flex-center">
            <Button
              buttonType="outlined"
              isActive={!this.state.loadingLinks && !this.state.viewCreator}
              style={{ marginBottom: 0 }}
              onClick={() => {
                this.openPopup();
              }}
            >
              Find and link an existing user
            </Button>
            {this.state.viewCreator && (
              <Button
                buttonType="tertiary"
                isActive
                style={{ marginBottom: 0 }}
                onClick={() => {
                  this.closePopup();
                }}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      );
    }
    return null;
  }

  renderInviteCode() {
    if (!this.state.inviteCode || !this.props.isAResident(this.props.user.type)) {
      return null;
    }
    if (this.state.generatingCode) {
      return (
        <div className="marginBottom-16 marginTop-16">
          <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />
        </div>
      );
    }
    return (
      <div className="marginBottom-16 marginTop-16">
        <div className="text-sectionTitle">INVITE CODE</div>
        <div className="genericInput-help" style={{ marginTop: 4 }}>
          Anyone who signs up using this code will be created
          <br />
          as a linked user to this primary user.
        </div>
        <div className="inviteCode_container">
          <p className="inviteCode_code">{this.state.inviteCode.Code}</p>
        </div>
        <p className="inviteCode_expiry">Expires {moment.utc(this.state.inviteCode.Expiry).local().fromNow()}</p>
        <p className="inviteCode_generateCode" onClick={this.generateNewCode.bind(this)}>
          Generate new code
        </p>
      </div>
    );
  }

  render() {
    if (_.isUndefined(this.props.user)) {
      return null;
    }
    return (
      <div>
        <div className="padding-32 paddingVertical-40 bottomDivideBorder">
          <div className="text-sectionTitle marginBottom-16">
            {this.props.isAResident(this.props.user.type) ? 'USERS LINKED TO THIS PERSON' : 'LINKED TO'}
          </div>
          {this.renderResidentLinking()}
          {this.state.viewCreator && this.renderAddNewLink()}
          <div className="marginTop-24">{this.renderCurrentLinks()}</div>
          {this.renderCreateNewUserLink()}
          {this.renderInviteCode()}
        </div>
      </div>
    );
  }
}

const styles = {
  spinner: {
    color: COLOUR_BRANDING_ACTION,
    fontSize: 18,
  },
  popUpOuter: {
    zIndex: 300,
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.7)',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 30,
    overflow: 'auto',
    flex: '0 1 auto',
    top: 0,
    left: 0,
    //alignItems: 'center'
  },
  whiteBox: {
    maxWidth: '80%',
    //maxHeight: '90%',
    //overflow: 'auto',
  },
  flexColumnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  removeButton: {
    height: '100%',
    backgroundColor: COLOUR_BRANDING_LIGHT,
    width: 0,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  userListWrapper: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  listUser: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid lightgrey',
    borderRadius: 5,
    marginTop: 8,
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: '#fff',
  },
  userInner: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    fontSize: 16,
  },
  profilePic: {
    height: 50,
    width: 50,
    borderRadius: 25,
    border: '1px solid #aaa',
    backgroundColor: '#eee',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    marginBottom: 8,
  },
  profilePicSmall: {
    height: 40,
    width: 40,
    borderRadius: 20,
  },
};

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, {})(withRouter(User));
