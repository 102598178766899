import { authedFunction } from '../session';
import { getUrl } from './helper'

export const alertActions = {
    getAlert: (site, id) => {
        const query = {
            site
        };
        return authedFunction({
            method: 'GET',
            url: getUrl('alerts', `get/${id}`, query)
        });
    },
    getAlerts: (site) => {
        return authedFunction({
            method: 'GET',
            url: getUrl('alerts', 'get', { site })
        });
    },
    getTVAlerts: (site) => {
        return authedFunction({
            method: 'GET',
            url: getUrl('alerts', 'tv', { site })
        });
    },
    addAlert: (obj) => {
        return authedFunction({
            method: 'POST',
            url: getUrl('alerts', 'add'),
            data: {
                site: obj.Site,
                obj
            }
        });
    }
}