import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { checkLoggedIn } from '../../session';
import StatusTypes from '../../json/supportStatus.json';

class Tickets extends Component {
  state = {
    sortColumn: 'Status', // column to sort by
    sortDesc: true, // if true, sort descending rather than ascending
    now: moment.utc().valueOf(),
  };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  sortByCol(col) {
    if (this.state.sortColumn === col) {
      this.setState({
        sortDesc: !this.state.sortDesc,
      });
    } else {
      this.setState({
        sortColumn: col,
        sortDesc: false,
      });
    }
  }

  renderStatusLabel(ticket) {
    return (
      <div className="statusLabel" style={{ backgroundColor: StatusTypes[ticket.Status].color }}>
        <span className="statusLabel_text">{StatusTypes[ticket.Status].text}</span>
      </div>
    );
  }

  renderTickets(source) {
    return source.map((ticket) => {
      if (ticket != null) {
        return (
          <tr key={ticket.Id}>
            <td className="table-TitleColumn">
              <Link to={`/support/ticket/${ticket.Id}`}>{ticket.Title}</Link>
            </td>
            <td>{ticket.User.displayName}</td>
            <td>{moment.utc(ticket.Timestamp).local().format('D MMM YYYY h:mma')}</td>
            <td>{ticket.LastUpdated ? moment.utc(ticket.EndTime).local().format('D MMM YYYY h:mma') : ''}</td>
            <td>{this.renderStatusLabel(ticket)}</td>
            <td className="table-options">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to={`/support/ticket/${ticket.Id}`}>
                  <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8 }} name="external-link" />
                </Link>
              </div>
            </td>
          </tr>
        );
      }
      return null;
    });
  }

  renderSort(col) {
    if (col !== this.state.sortColumn) {
      return null;
    }
    return <FontAwesome style={{ marginLeft: 5 }} name={this.state.sortDesc ? 'chevron-up' : 'chevron-down'} />;
  }

  sortIsActive(col) {
    if (col !== this.state.sortColumn) {
      return '';
    }
    return ' table--columnActive';
  }

  renderView(source) {
    return (
      <Table className="plussTable" striped bordered condensed hover>
        <thead>
          <tr>
            <th
              className={`${this.sortIsActive('Title')}`}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.sortByCol('Title');
              }}
            >
              Title{this.renderSort('Title')}
            </th>
            <th
              className={`${this.sortIsActive('User')}`}
              style={{ cursor: 'pointer', width: 120 }}
              onClick={() => {
                this.sortByCol('User');
              }}
            >
              Submitted By{this.renderSort('User')}
            </th>
            <th
              className={`${this.sortIsActive('Timestamp')}`}
              style={{ cursor: 'pointer', width: 120 }}
              onClick={() => {
                this.sortByCol('Timestamp');
              }}
            >
              Submitted at{this.renderSort('Timestamp')}
            </th>
            <th
              className={`${this.sortIsActive('LastUpdated')}`}
              style={{ cursor: 'pointer', width: 120 }}
              onClick={() => {
                this.sortByCol('LastUpdated');
              }}
            >
              Last updated{this.renderSort('LastUpdated')}
            </th>
            <th
              className={`${this.sortIsActive('Status')}`}
              style={{ cursor: 'pointer', width: 120 }}
              onClick={() => {
                this.sortByCol('Status');
              }}
            >
              Status{this.renderSort('Status')}
            </th>
            <th style={{ width: 70 }} />
          </tr>
        </thead>
        <tbody>{this.renderTickets(source)}</tbody>
      </Table>
    );
  }

  renderContent() {
    let source = _.sortBy(this.props.tickets, this.state.sortColumn);
    if (this.state.sortDesc) {
      source.reverse();
    }
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });

    if (this.props.filterOption === 'current') {
      source = _.filter(source, (ev) => {
        return this.state.now > ev.StartTime && this.state.now < ev.EndTime;
      });
    } else if (this.props.filterOption === 'past') {
      source = _.filter(source, (ev) => {
        return this.state.now > ev.EndTime;
      });
    }
    return this.renderView(source);
  }

  render() {
    return <div style={{ minWidth: '100%' }}>{this.renderContent()}</div>;
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return {
    auth,
  };
};

export default connect(mapStateToProps, {})(Tickets);
