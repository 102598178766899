import React, { Component } from 'react';

class Text extends Component {
  getClassName() {
    if (this.props.className) {
      return ` ${this.props.className}`;
    }
    return '';
  }

  render() {
    return (
      <p className={`text-${this.props.type}${this.getClassName()}`} style={this.props.style}>
        {this.props.children}
      </p>
    );
  }
}

export { Text };
