import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';

class Spinner extends Component {
  render() {
    return <FontAwesome name="spinner fa-pulse fa-fw" />;
  }
}

export { Spinner };
