import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { eventsLoaded } from '../../actions';
import { PageContainer, Header } from '../../components';
import { safeReadParams } from '../../helper';
import { eventActions } from '../../webapi';

class EventFeedback extends Component {
  state = {
    eventId: safeReadParams(this.props, 'eventId') ? parseInt(this.props.match.params.eventId, 10) : null,
    feedback: {},
    selectedRep: -1,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.onNewProps(nextProps);
  }

  componentDidMount() {
    if (Object.keys(this.props.events).length > 0) {
      var maxElem = _.maxBy(this.props.events, (event) => {
        if (!event) {
          return 0;
        }
        return event.Changed;
      });
      this.loadEvents(maxElem.Changed + 1);
    } else {
      this.loadEvents();
    }
  }

  UNSAFE_componentWillMount() {
    this.onNewProps(this.props);
  }

  onNewProps(props) {
    const eventId = parseInt(safeReadParams(props, 'eventId'), 10);
    const event = _.find(props.events, (ev) => {
      return ev != null && ev.Id === eventId;
    });
    this.setState({
      event,
    });
  }

  selectRep(repId) {
    this.setState({
      selectedRep: repId,
    });
  }

  loadEvents(time) {
    this.setState({
      loading: true,
    });

    eventActions
      .getEvents(this.props.auth.site, time, true)
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data != null && !_.isEmpty(res.data) && res.data[0].Site === this.props.auth.site) {
          this.props.eventsLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loading: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  renderRep(rep) {
    return (
      <tr key={rep.Id}>
        <td style={{ width: 100 }}>{moment.utc(rep.Time).local().format('D MMM YY - h:mma')}</td>
        <td style={{ width: 100 }}>{_.values(rep.feedback).length} feedback submitted</td>
        <td
          style={{ width: 100, cursor: 'pointer' }}
          onClick={() => {
            this.selectRep(rep.Id);
          }}
        >
          View feedback
        </td>
      </tr>
    );
  }

  renderEventReps() {
    if (!this.state.event || !this.state.event.RepeatedTimes) {
      return null;
    }
    if (this.state.selectedRep > -1) {
      return null;
    }
    const repsToRender = [];
    _.forEach(this.state.feedback, (rep, key) => {
      const repData = _.find(this.state.event.RepeatedTimes, (ev) => {
        return ev.Id === key;
      });
      repsToRender.push({ ...repData, feedback: rep });
    });
    return (
      <Table striped bordered condensed hover>
        <tbody>
          {repsToRender.map((rep) => {
            return this.renderRep(rep);
          })}
        </tbody>
      </Table>
    );
  }

  renderFeedbackEntry(feedback) {
    return (
      <tr key={feedback.User.id}>
        <td style={{ width: 100 }}>{moment.unix(feedback.Timestamp).local().format('D MMM YY - h:mma')}</td>
        <td style={{ width: 100 }}>{feedback.User.displayName}</td>
        <td style={{ width: 100 }}>{feedback.Rating > 0 ? `${feedback.Rating}/5` : null}</td>
        <td>{feedback.Feedback}</td>
      </tr>
    );
  }

  renderFeedback() {
    if (this.state.selectedRep === -1) {
      return null;
    }
    return (
      <Table striped bordered condensed hover>
        <tbody>
          <tr>
            <th style={{ width: 100 }}>Time</th>
            <th style={{ width: 100 }}>Name</th>
            <th style={{ width: 100 }}>Rating</th>
            <th>Feedback</th>
          </tr>
          {_.map(this.state.feedback[this.state.selectedRep], (feedback) => {
            return this.renderFeedbackEntry(feedback);
          })}
        </tbody>
      </Table>
    );
  }

  render() {
    return (
      <div>
        <Header title="Event Feedback" />
        <PageContainer maxWidth="100%">
          {this.renderEventReps()}
          {this.renderFeedback()}
        </PageContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authKey: state.auth.code,
    events: state.events.events,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { eventsLoaded })(EventFeedback);
