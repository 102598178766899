import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { Header, AddButton } from '../../components';
import { ticketsLoaded } from '../../actions';
import { maintenanceActions } from '../../webapi';
import { COLOUR_BRANDING_OFF } from '../../js';
import Tickets from './Tickets';

class SupportHub extends Component {
  state = {
    selectedSection: 'open',
    loadingTickets: false,

    openTickets: [],
    closedTickets: [],
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
    this.loadTickets();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  loadTickets() {
    this.setState({
      loadingTickets: true,
    });

    maintenanceActions
      .getTickets(this.props.auth.site)
      .then((res) => {
        this.setState({
          loadingTickets: false,
        });
        if (res.data != null && !_.isEmpty(res.data)) {
          this.props.ticketsLoaded(res.data);
        }
      })
      .catch((res) => {
        this.setState({ loadingTickets: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  updateProps(props) {
    const openTickets = _.filter(props.tickets, (ev) => {
      return ev.Status !== 'closed';
    });

    const closedTickets = _.filter(props.tickets, (ev) => {
      return ev.Status === 'closed';
    });

    this.setState({ openTickets, closedTickets });
  }

  addNew() {
    this.props.history.push(`/support/ticket`);
  }

  getSideBarSectionColour(id) {
    return this.state.selectedSection === id ? { backgroundColor: '#fff' } : {};
  }

  getSource() {
    if (this.state.selectedSection === 'open') {
      return this.state.openTickets;
    }
    if (this.state.selectedSection === 'closed') {
      return this.state.closedTickets;
    }
    return this.props.tickets;
  }

  renderStats(component, loading) {
    if (loading) {
      return <FontAwesome style={styles.spinner} name="spinner fa-pulse fa-fw" />;
    }
    return component;
  }

  renderLeftBar() {
    return (
      <div className="hub-sideContent">
        {/* Top Add Button */}
        <div className="hub-sideContent-topButton" />
        <div style={{ paddingLeft: 15, width: '100%' }}>
          {/* Title */}
          <div className="fontMedium fontSize-36 text-dark" style={styles.sideBarTitleSection}>
            Tickets
          </div>
          {/* Content */}
          {/* All Tickets */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'all' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('all')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.props.tickets.length, this.state.loadingTickets)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">All Tickets</div>
          </div>
          {/* Open Tickets */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'open' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('open')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.openTickets.length, this.state.loadingTickets)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Open Tickets</div>
          </div>
          {/* Closed Tickets */}
          <div
            onClick={() => {
              this.setState({ selectedSection: 'closed' });
            }}
            className="sideBarSection"
            style={this.getSideBarSectionColour('closed')}
          >
            <div className="fontMedium fontSize-36 text-dark" style={{ lineHeight: '50px' }}>
              {this.renderStats(this.state.closedTickets.length, this.state.loadingTickets)}
            </div>
            <div className="fontRegular fontSize-16 text-light lineHeight-22">Closed Tickets</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="hub-wrapperContainer">
        {this.renderLeftBar()}
        <div className="hub-headerContentWrapper">
          <Header>
            <AddButton onClick={this.addNew.bind(this)} text="NEW TICKET" />
          </Header>
          <div className="hub-contentWrapper">
            <Tickets tickets={this.getSource()} />
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  sideBarTitleSection: {
    lineHeight: '50px',
    marginTop: 30,
    marginBottom: 30,
    paddingLeft: 24,
  },
  sideBarSection: {
    weight: '100%',
    minWidth: 200,
    padding: 32,
    paddingLeft: 24,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  spinner: {
    fontSize: 32,
    color: COLOUR_BRANDING_OFF,
  },
};

const mapStateToProps = (state) => {
  const { tickets } = state.jobs;
  const { auth } = state;
  return {
    tickets,
    auth,
  };
};

export default connect(mapStateToProps, { ticketsLoaded })(SupportHub);
