import {
    MAPS_LOADED,
    MAP_REMOVED,
    MAPS_PURGE
} from './types';
import { mapActions } from '../webapi';

export const mapsUpdate = (site) => {
    return (dispatch) => {
        mapActions.getMaps(site).then(
            (res) => {
                if (res.data != null) {
                    dispatch({
                        type: MAPS_LOADED,
                        payload: res.data
                    })
                }
            }
        );
    }
}

export const mapsLoaded = (events) => {
    return {
        type: MAPS_LOADED,
        payload: events
    };
};

export const removeMap = (id) => {
    return {
        type: MAP_REMOVED,
        payload: id
    };
};

export const clearMaps = () => {
    return {
        type: MAPS_PURGE
    };
};