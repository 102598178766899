import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, OverlayPage, OverlayPageContents, OverlayPageSection, OverlayPageBottomButtons } from '../../components';
import { analyticsActions, facilityActions } from '../../webapi';
import { checkLoggedIn, validateAccess } from '../../session';
import { safeReadParams, getPluralS } from '../../helper';
import FontAwesome from 'react-fontawesome';
import { Text } from '../../components/text';
import { CONST_STRINGS } from '../../config';

class FacilityAnalytics extends Component {
  initialState = {
    infoId: safeReadParams(this.props, 'infoId'),
    facility: {},
    PageView: 0,
    ReturningPageView: 0,
    UniquePageView: 0,
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!validateAccess(this.props.auth.site, 'facilities', this.props.auth, true)) {
        window.history.back();
      } else {
        this.getFacility();
        this.getStats();
      }
    }, 500);
  }

  getFacility() {
    const idSplit = this.state.infoId.split('_');
    facilityActions
      .getFacility(idSplit[0], idSplit[1])
      .then((res) => {
        this.setState({
          facility: res.data,
        });
      })
      .catch((error) => {
        console.log('getFacility', error);
      });
  }

  getStats() {
    analyticsActions.getEntityStats(this.state.infoId, 'facility').then((res) => {
      console.log(res.data);
      this.setState({ ...res.data, statsLoaded: true });
    });
  }

  renderOverview() {
    if (!this.state.statsLoaded) {
      return null;
    }
    return (
      <div>
        <Text type="formTitleSmall" className="marginBottom-16">
          Page Views
        </Text>
        <div className="polloverview">
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="eye" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.PageView}</p>
              <p className="polloverview_text">Page View{getPluralS(this.state.PageView)}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="user" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.UniquePageView}</p>
              <p className="polloverview_text">Unique Visitor{getPluralS(this.state.UniquePageView)}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="repeat" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.ReturningPageView}</p>
              <p className="polloverview_text">Returning Visitor{getPluralS(this.state.ReturningPageView)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBack() {
    return (
      <div>
        <Button
          inline
          buttonType="primary"
          onClick={() => {
            window.history.back();
          }}
          isActive
        >
          Back to {CONST_STRINGS.FACILITIES}
        </Button>
      </div>
    );
  }

  renderMain() {
    return (
      <div className="padding-60 paddingBottom-40">
        <Text type="formTitleLarge" className="marginBottom-24">
          {this.state.facility.Title}
        </Text>
        {this.renderOverview()}
      </div>
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents id="EventContainer" noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--fixedPopupSize">{this.renderMain()}</OverlayPageSection>
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderBack()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(FacilityAnalytics);
