import React, { Component } from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import _ from 'lodash';
import { eventActions } from '../../webapi';
import { Button, PageContainer, Header, SuccessPopup } from '../../components';

class AddEventsByJson extends Component {
    state = {
        jsonInput: '',
        submitting: false
    }

    handleChange(event) {
        var stateChange = {};
        stateChange[event.target.getAttribute('id')] = event.target.value;
        this.setState(stateChange);
    }

    validateForm() {
        return !_.isEmpty(this.state.jsonInput) && !this.state.submitting;
    }

    handleSubmit() {
        if (!this.validateForm()) {
            return;
        }
        this.setState({ submitting: true });
        eventActions.addEventsByJson(this.state.jsonInput)
            .then((res) => {
                this.setState({
                    success: true,
                    submitting: false
                });
            }).catch((res) => {
                this.setState({ submitting: false });
                alert("Something went wrong with the request. Please try again.")
            });
    }

    renderSuccess() {
        if (!this.state.success) {
            return null;
        }
        return (<SuccessPopup
            text='Your events have been imported'
            buttons={[
                {
                    type: 'outlined',
                    onClick: () => { window.history.back() },
                    text: 'Go to home'
                }
            ]}
        />);
    }

    renderSubmit() {
        if (this.state.success) {
            return null;
        }
        if (this.state.submitting) {
            return (
                <Button type="button" disabled>Saving...</Button>
            );
        }
        return (
            <Button type="button" bsStyle={this.validateForm() ? 'success' : null} onClick={() => this.handleSubmit()}>Save</Button>
        );
    }

    renderForm() {
        if (this.state.success) {
            return null;
        }
        return (
            <FormGroup controlId="jsonInput" >
                <ControlLabel>JSON</ControlLabel>
                <FormControl
                    id="jsonInput"
                    componentClass="textarea"
                    placeholder="Paste the JSON export here"
                    value={this.state.eventDescription}
                    onChange={(e) => this.handleChange(e)}
                    style={{
                        height: '70vh'
                    }}
                />
            </FormGroup>
        );
    }

    render() {
        return (
            <div>
                <Header title='Events' />
                <PageContainer maxWidth='100%'>
                    {this.renderForm()}
                    {this.renderSubmit()}
                    {this.renderSuccess()}
                </PageContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, {})(AddEventsByJson);