import React, { Component } from 'react';
import _ from 'lodash';

class P60Icon extends Component {
  state = {
    doublePaths: ['add-image', 'add-circle', 'facility', 'news'],
    triplePaths: [],
  };

  renderIconPaths() {
    if (_.includes(this.state.doublePaths, this.props.icon)) {
      return (
        <span>
          <span className="path1" />
          <span className={`path2${this.props.icon !== 'add-image' ? ' text-white' : ''}`} />
        </span>
      );
    }
    if (_.includes(this.state.triplePaths, this.props.icon)) {
      return (
        <span>
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
        </span>
      );
    }
    return null;
  }

  render() {
    return (
      <div {...this.props} className={`${this.props.className} p60icon-${this.props.icon}`}>
        {this.renderIconPaths()}
      </div>
    );
  }
}

export { P60Icon };
