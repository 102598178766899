import { SET_NAV_DATA } from '../actions/types';

const INITIAL_STATE = {
  signUpSuccess: false,
  introTourStep: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_NAV_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
