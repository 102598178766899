import { getUrl } from './helper';
import { authedFunction } from '../session';

export const maintenanceActions = {
  getJobs: (site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('maintenance', 'getJobs'),
      data: {
        site,
      },
    });
  },
  addTicket: (site, title, text, attachments) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('maintenance', `tickets/add`),
      data: {
        site,
        title,
        text,
        attachments,
      },
    });
  },
  editTicketStatus: (id, status) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('maintenance', `tickets/status`),
      data: {
        id,
        status,
      },
    });
  },
  getTicket: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('maintenance', `tickets/get/${id}`),
    });
  },
  getTickets: (site, status) => {
    const query = { site };
    if (status) {
      query.status = status;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('maintenance', 'tickets/get', query),
    });
  },
};
