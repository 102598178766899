import _ from 'lodash';

import {
    MAPS_LOADED,
    MAP_REMOVED,
    MAPS_PURGE
} from '../actions/types';

const INITIAL_STATE = {
    maps: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MAPS_LOADED:
            var result = _.unionWith(action.payload, state.maps, (v1, v2) => {
                return v1 != null && v2 != null && v1.RowId === v2.RowId;
            });
            return {
                ...state,
                maps: result
            };
        case MAP_REMOVED:
            const index = _.findIndex(state.maps, (event) => { return event != null && event.RowId === action.payload });
            if (index > -1) {
                const newEvents = [...state.maps];
                newEvents.splice(index, 1);
                return { ...state, maps: newEvents };
            }
            return state;
        case MAPS_PURGE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
