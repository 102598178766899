import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { GenericInput, Button } from '../../components';
import { isEmail } from '../../helper';
import { authActions } from '../../webapi';
import { setAuthUser } from '../../actions';
import { COLOUR_BRANDING_MAIN } from '../../js';

class Forgot extends Component {
    initialState = {
        forgotStep: 1,

        forgot: '',
        forgotType: 'email',
        showCodeEntry: false,

        forgotUser: null,
        forgotUserType: null,

        code: '',
        password: '',
        confirm: ''
    }

    state = { ...this.initialState };

    handleChange(event) {
        var stateChange = {};
        stateChange[event.target.getAttribute('id')] = event.target.value;
        this.setState(stateChange);
    }

    handleEnter(event) {
        if (!_.isUndefined(event.charCode) && event.charCode === 13) {
            if (this.state.forgotStep === 1) {
                this.handleForgot();
            }
            if (this.state.forgotStep === 2) {
                this.handleCodeSubmit();
            }
            if (this.state.forgotStep === 3) {
                this.handleSubmit();
            }
        }
    }

    handleForgot() {
        if (!isEmail(this.state.forgot)) {
            this.setState({ showWarnings: true });
            return;
        }
        this.setState({ showWarnings: false, submitting: true, gooch: null });
        authActions.fetchForgotPasswordId(this.state.forgot.toLowerCase()).then((res) => {
            if (res.data.userFound) {
                console.log('User Found');
                this.setState({ userId: res.data.stuff.userId });

                Auth.forgotPassword(res.data.stuff.userId)
                    .then(data => {
                        console.log('yea yea yea dawgggg');
                        this.setState({
                            forgotStep: 2,
                            forgotUser: res.data.stuff.userId,
                            forgotUserType: data.CodeDeliveryDetails.DeliveryMedium,
                            forgotUserPreview: data.CodeDeliveryDetails.Destination,
                            submitting: false,
                            forgotSuccess: true,
                            showCodeEntry: true,
                            forgotType: data.CodeDeliveryDetails.AttributeName
                        });
                    })
                    .catch(err => {
                        console.log('Get reset password error')
                        this.setState({ submitting: false, gooch: err.message });
                    });
            } else {
                console.log('no user found')
                this.setState({ gooch: 'No user found matching that input.', submitting: false })
            }
            // //this.props.loginUserAWS(res.data, userId);
        }).catch((err) => {
            console.log('get user signup fail');
            this.setState({ submitting: false, gooch: err });
        });
    }

    handleCodeSubmit() {
        if (this.state.code.length !== 6) {
            this.setState({ showWarnings: true });
            return;
        }

        this.setState({ forgotStep: 3, showWarnings: false });
    }

    handleSubmit() {
        if (!this.validateForm()) {
            this.setState({ showWarnings: true });
            return;
        }

        this.setState({ submitting: true, showWarnings: false });

        Auth.forgotPasswordSubmit(
            this.state.forgotUser,
            this.state.code,
            this.state.password)
            .then(data => {
                console.log('Password changeddddddddddddd');
                this.setState({ submitting: false });
                this.props.goBack(false, true)
            })
            .catch(err => {
                console.log('no password change -- fail');
                this.setState({ submitting: false, gooch: err.message, forgotStep: 2 });
            });

    }

    toggleForgot(gweg) {
        if (this.state.submitting) {
            return;
        }
        this.setState({ showForgot: gweg });
    }
    validateForgotForm() {
        return !_.isEmpty(this.state.forgot);
    }

    validateForm() {
        /* if (this.state.code.length != 6) {
            return false;
        } */
        if (this.state.password.length < 6) {
            return false;
        }
        if (this.state.confirm !== this.state.password) {
            return false;
        }
        return true;
    }

    renderForgotSubmit() {
        if (this.state.submitting) {
            return <Button buttonType='secondary'>Submitting...</Button>;
        }
        return (
            <div>
                <Button
                    inline
                    buttonType='outlined'
                    onClick={() => this.props.goBack(false)}
                    isActive
                    style={{ marginRight: 16 }}
                >
                    Cancel
                </Button>
                <Button
                    inline
                    buttonType='primary'
                    onClick={() => this.handleForgot()}
                    isActive={this.state.forgot.length > 0}
                >
                    Submit
                 </Button>
            </div>
        );
    }

    renderPasswordSubmit() {
        if (this.state.submitting) {
            return <Button buttonType='secondary'>Submitting...</Button>;
        }
        return (
            <div>
                <Button
                    inline
                    buttonType='outlined'
                    onClick={() => this.setState({ forgotStep: 1, showWarnings: false })}
                    isActive
                    style={{ marginRight: 16 }}
                >
                    Back
                </Button>
                <Button
                    inline
                    buttonType='primary'
                    onClick={() => this.handleSubmit()}
                    isActive={this.validateForm()}
                >
                    Submit new password
                 </Button>
            </div>
        );
    }

    renderCodeSubmit() {
        if (this.state.submitting) {
            return <Button buttonType='secondary'>Submitting...</Button>;
        }
        return (
            <div>
                <Button
                    inline
                    buttonType='outlined'
                    onClick={() => this.setState({ forgotStep: 1, showWarnings: false })}
                    isActive
                    style={{ marginRight: 16 }}
                >
                    Back
                </Button>
                <Button
                    inline
                    buttonType='primary'
                    onClick={() => this.handleCodeSubmit()}
                    isActive={this.state.code.length > 5}
                >
                    Submit Code
                 </Button>
            </div>
        );
    }

    renderForm() {
        if (this.state.forgotStep === 3) {
            return (
                <div>
                    <GenericInput
                        id="password"
                        type="password"
                        isRequired
                        label="New Password"
                        placeholder="Enter your new password"
                        value={this.state.password}
                        onChange={(e) => this.handleChange(e)}
                        showError={() => { return this.state.showWarnings && this.state.password.length < 6; }}
                        isValid={() => { return this.state.password.length > 5; }}
                        errorMessage='Password must be 6 characters'
                    />
                    <GenericInput
                        id="confirm"
                        type="password"
                        isRequired
                        label="Confirm New Password"
                        placeholder="Confirm new password"
                        value={this.state.confirm}
                        onChange={(e) => this.handleChange(e)}
                        onEnter={(e) => this.handleEnter(e)}
                        showError={() => { return this.state.showWarnings && this.state.confirm !== this.state.password; }}
                        isValid={() => { return this.state.confirm.length > 0 && this.state.confirm === this.state.password; }}
                        errorMessage='Passwords must match'
                        style={{ marginBottom: 32 }}
                    />
                    {this.renderPasswordSubmit()}
                </div >
            )
        }
        if (this.state.forgotStep === 2) {
            return (
                <div>
                    <GenericInput
                        id="code"
                        isRequired
                        label="Verification Code"
                        placeholder="Enter verification Code"
                        value={this.state.code}
                        onChange={(e) => this.handleChange(e)}
                        onEnter={(e) => this.handleEnter(e)}
                        showError={() => { return this.state.showWarnings && this.state.code.length !== 6; }}
                        isValid={() => { return this.state.code.length === 6; }}
                        errorMessage='Code must be 6 characters'
                        style={{ marginBottom: 32 }}
                    />
                    {this.renderCodeSubmit()}
                </div >
            )
        }
        if (this.state.forgotStep === 1) {
            return (
                <div>
                    <GenericInput
                        id="forgot"
                        isRequired
                        label='Email'
                        placeholder="Enter your email address"
                        value={this.state.forgot}
                        onChange={(e) => this.handleChange(e)}
                        onEnter={(e) => this.handleEnter(e)}
                        showError={() => { return this.state.showWarnings && !isEmail(this.state.forgot); }}
                        isValid={() => { return isEmail(this.state.forgot); }}
                        style={{ marginBottom: 32 }}
                    />
                    {this.renderForgotSubmit()}
                </div >
            );
        }
        return null;
        /*   return (<div>
             
              < InputGroupNew
                  id="email"
                  type="text"
                  label="Email"
                  placeholder="Enter your email"
                  value={this.state.email}
                  onChange={(e) => this.handleChange(e)}
                  isRequired
              />
              <InputGroupNew
                  id="password"
                  type="password"
                  label="Password"
                  placeholder="Enter your password"
                  value={this.state.password}
                  onChange={(e) => this.handleChange(e)}
                  isRequired
                  style={{ marginBottom: 12 }}
              />
              <div className={'inputGroup-label text-plussBlue pointer'} style={{ marginBottom: 16 }} onClick={() => this.toggleForgot(true)}>
                  Forgot password
              </div>
              {this.renderSubmit()}
          </div >); */
    }

    renderTop() {
        if (this.state.forgotStep === 1) {
            return (
                <div>
                    <div className={'marginTop-55 merri-bold fontSize-26 text-plussBlue'}>
                        Forgot your password
                    </div>
                    <div className={'marginTop-16 fontRegular fontSize-16'}>
                        Enter your email address below and we will send you a code to reset your password.
                    </div>
                </div>
            )
        }
        if (this.state.forgotStep === 2) {
            return (
                <div>
                    <div className={'marginTop-55 merri-bold fontSize-26 text-plussBlue'}>
                        Enter Reset Code
                    </div>
                    <div className={'marginTop-16 fontRegular fontSize-16'}>
                        To reset your password enter the validation code that was sent via <span style={{ color: COLOUR_BRANDING_MAIN }}>{this.state.forgotUserType}</span> to
                    </div>
                    <div className={'marginTop-16 fontRegular fontSize-16'} style={{ color: COLOUR_BRANDING_MAIN }}>
                        {this.state.forgotUserPreview}
                    </div>
                </div>
            )
        }
        if (this.state.forgotStep === 3) {
            return (
                <div>
                    <div className={'marginTop-55 merri-bold fontSize-26 text-plussBlue'}>
                        New Password
                    </div>
                    <div className={'marginTop-16 fontRegular fontSize-16'}>
                        Set your new password.
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                {this.renderTop()}
                <div
                    className={'text-plussRed fontSize-16 sf-semibold'}
                    style={styles.errorContainer}
                >
                    {this.state.gooch}
                </div>
                {this.renderForm()}
            </div>
        );
    }
}

const styles = {
    errorContainer: {
        minHeight: 32,
        padding: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
};

export default connect(mapStateToProps, { setAuthUser })(withRouter(Forgot));
