import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import upvoty from './upvoty';

const UpvotyWidget = ({ boardHash, ssoToken, baseUrl, startPage, fixedLayout }) => {
  useEffect(() => {
    upvoty.init('render', { boardHash, ssoToken, baseUrl, startPage, fixedLayout });
    return () => {
      upvoty.destroy();
    };
  }, []);
  return <div data-upvoty />;
};

UpvotyWidget.defaultProps = {
  ssoToken: null,
  boardHash: undefined,
  startPage: null,
  fixedLayout: undefined,
};

UpvotyWidget.propTypes = {
  boardHash: PropTypes.string,
  ssoToken: PropTypes.string,
  baseUrl: PropTypes.string.isRequired,
  startPage: PropTypes.string,
  fixedLayout: PropTypes.string,
};

export default UpvotyWidget;
