import { OFFERS_LOADED, OFFER_REMOVED, OFFERS_PURGE } from './types';
import { marketActions } from '../webapi';

export const offersUpdate = site => {
  return dispatch => {
    marketActions.getOffers(site).then(res => {
      if (res.data != null) {
        dispatch({
          type: OFFERS_LOADED,
          payload: res.data,
        });
      }
    });
  };
};

export const offersLoaded = events => {
  return {
    type: OFFERS_LOADED,
    payload: events,
  };
};

export const removeOffer = id => {
  return {
    type: OFFER_REMOVED,
    payload: id,
  };
};

export const clearOffers = () => {
  return {
    type: OFFERS_PURGE,
  };
};
