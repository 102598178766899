import React, { Component } from 'react';
import _ from 'lodash';
import { testflightLink, clientName, baseStage } from '../../../config';
import { PageContainer } from '../../../components';

class TestflightDownload extends Component {
    componentDidMount() {
        setTimeout(() => {
            if (!_.isUndefined(testflightLink) && !_.isEmpty(testflightLink)) {
                console.log('redirecting to android download');
                window.location.href = testflightLink;
            }
        }, 1500);
    }

    renderIOS() {
        if (!_.isUndefined(testflightLink) && !_.isEmpty(testflightLink)) {
            return (
                <div>
                    Downloading iOS app for {clientName}-{baseStage}
                </div>
            )
        }
        return (
            <div>
                No iOS demo app for {clientName}-{baseStage}.
            </div>
        )
    }

    render() {
        return (
            <div>
                <PageContainer maxWidth={400}>
                    <div style={{ marginTop: 40, textAlign: 'center' }}>
                        <div className="sf-semibold" style={{ fontSize: 16, lineHeight: '40px' }}>
                            {clientName}-{baseStage} iOS
                        </div>
                        <div className="fontRegular " style={{ fontSize: 14, lineHeight: '20px' }}>
                            {this.renderIOS()}
                        </div>
                    </div>
                </PageContainer>
            </div>
        );
    }
}

export default TestflightDownload;
