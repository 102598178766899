import React, { Component } from 'react';
import FontAwesome from 'react-fontawesome';
import { SVGIcon } from './SVGIcon';

class Button extends Component {
  getClasses() {
    let string = '';
    if (this.props.inline) string = string + 'buttonWrapper--inline ';
    if (this.props.isActive) string = string + 'button--active ';
    if (this.props.buttonType) string = string + `button--${this.props.buttonType}`;
    if (this.props.compact) string = string + ' buttonWrapper--compact';
    if (this.props.narrow) string = string + ' buttonWrapper--narrow';
    if (this.props.noHoverHighlight) string = string + ' button--noHoverHighlight';
    if (this.props.className) string = string + ' ' + this.props.className;
    return string;
  }

  getButtonClasses() {
    if (this.props.buttonClassName) {
      return `button ${this.props.buttonClassName}`;
    }
    return 'button';
  }

  getButtonTextClasses() {
    let result = 'buttonText';
    if (this.props.leftIcon) {
      result = result + ` buttonTextWithIcon`;
    }
    if (this.props.buttonTextClassName) {
      result = result + ` ${this.props.buttonTextClassName}`;
    }
    return result;
  }

  buttonClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  renderLeftIcon() {
    if (!this.props.leftIcon) {
      return null;
    }
    if (this.props.leftIconType === 'svg') {
      return <SVGIcon icon={this.props.leftIcon} className="buttonTextWithIcon_icon" colour={this.props.leftIconColour} />;
    }
    return <FontAwesome name={this.props.leftIcon} className="buttonTextWithIcon_icon" />;
  }

  render() {
    return (
      <div className={`buttonWrapper ${this.getClasses()}`} style={{ ...this.props.buttonStyle }}>
        <div onClick={() => this.buttonClick()} className={this.getButtonClasses()} style={{ ...this.props.style }}>
          <div className={this.getButtonTextClasses()} style={{ ...this.props.textStyle }}>
            {this.renderLeftIcon()}
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export { Button };
