import _ from 'lodash';

import {
    INFOPAGES_LOADED,
    INFOPAGE_REMOVED,
    INFOPAGES_PURGE,
    WELCOMEPAGES_LOADED,
    WELCOMEPAGE_REMOVED,
    WELCOMEPAGES_PURGE
} from '../actions/types';

const INITIAL_STATE = {
    infoPages: [],
    welcomePages: []
};

export default (state = INITIAL_STATE, action) => {
    var result;
    var index;
    var newEvents;
    switch (action.type) {
        case INFOPAGES_LOADED:
            result = _.unionWith(action.payload, state.infoPages, (v1, v2) => {
                return v1 != null && v2 != null && v1.RowId === v2.RowId;
            });
            return {
                ...state,
                infoPages: result
            };
        case INFOPAGE_REMOVED:
            index = _.findIndex(state.infoPages, (event) => { return event != null && event.RowId === action.payload });
            if (index > -1) {
                newEvents = [...state.infoPages];
                newEvents.splice(index, 1);
                return { ...state, infoPages: newEvents };
            }
            return state;
        case INFOPAGES_PURGE:
            return INITIAL_STATE;
        case WELCOMEPAGES_LOADED:
            result = _.unionWith(action.payload, state.welcomePages, (v1, v2) => {
                return v1 != null && v2 != null && v1.Id === v2.Id;
            });
            return {
                ...state,
                welcomePages: result
            };
        case WELCOMEPAGE_REMOVED:
            index = _.findIndex(state.welcomePages, (event) => { return event != null && event.Id === action.payload });
            if (index > -1) {
                newEvents = [...state.welcomePages];
                newEvents.splice(index, 1);
                return { ...state, welcomePages: newEvents };
            }
            return state;
        case WELCOMEPAGES_PURGE:
            return INITIAL_STATE;
        default:
            return state;
    }
};
