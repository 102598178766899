import _ from 'lodash';
import { getUrl } from './helper';
import { authedFunction } from '../session';

export const newsletterActions = {
  getRecent: (site, limit, maxTime, noFuture, tvMode) => {
    const query = { site, limit, noFuture, tvMode };
    if (maxTime) {
      query.maxTime = maxTime;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('newsletter', 'recent', query),
    });
  },
  getNewsletterEntries: (site, skip, count) => {
    const query = { site, noHQ: true, noFilter: true };
    if (skip) {
      query.skip = skip;
    }
    if (count) {
      query.count = count;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('newsletter', 'get', query),
    });
  },
  recursiveGetNews: (site, results) => {
    return new Promise((resolve, reject) => {
      const resultsToUse = results || [];
      newsletterActions.getNewsletterEntries(site, resultsToUse.length, 500).then((res) => {
        const finalResult = _.concat(resultsToUse, res.data.items);
        if (finalResult.length < res.data.totalCount) {
          return resolve(newsletterActions.recursiveGetNews(site, finalResult));
        }
        return resolve(finalResult);
      });
    });
  },
  getNewsletterEntry: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('newsletter', `get/${id}`),
    });
  },
  addOrEditNewsletterEntry: (data, site) => {
    let url = getUrl('newsletter', 'add');
    if (data.RowId != null) {
      url = getUrl('newsletter', 'edit');
    }
    return authedFunction({
      method: 'POST',
      url,
      data: {
        site,
        update: data,
      },
    });
  },
  deleteNewsletterEntry: (site, id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('newsletter', 'remove'),
      data: {
        site,
        id,
      },
    });
  },
  getPublishedEntries: (ids) => {
    const query = {};
    if (Array.isArray(ids)) {
      query.ids = ids.join(',');
    } else {
      query.ids = ids;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('newsletter', 'content/specific', query),
    });
  },
  getNewsletterSubmissions: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('newsletter', 'submissions/get', { site }),
    });
  },
  getNewsletterSubmission: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('newsletter', `submissions/get/${id}`),
    });
  },
  handleNewsletterSubmission: (site, id, action) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('newsletter', 'submissions/handle'),
      data: {
        site,
        id,
        action,
      },
    });
  },
  editNewsletterSubmission: (site, id, update) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('newsletter', 'editSubmission'),
      data: {
        site,
        id,
        update,
      },
    });
  },
  getSubmissionCount: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('newsletter', `submissions/count`, { site }),
    });
  },
  getAvailableNews: (minTime, maxTime, count) => {
    const query = {};
    if (minTime) {
      query.minTime = minTime;
    }
    if (maxTime) {
      query.maxTime = maxTime;
    }
    if (count) {
      query.count = count;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('newsletter', 'content/get', query),
    });
  },
  getPublishedAvailableNews: (site, sourceId) => {
    const query = {};
    if (site) {
      query.site = site;
    }
    if (sourceId) {
      query.sourceId = sourceId;
    }
    return authedFunction({
      method: 'GET',
      url: getUrl('newsletter', 'content/published', query),
    });
  },
};
