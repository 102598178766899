import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Header } from '../../../components';
import { androidLink, iOSLink } from '../../../config';

class SiteSignUpComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  viewWhiteLabel = () => {};

  goToPanel = () => {
    window.location.replace('/');
  };

  render() {
    return (
      <div className="pageContainer signUpContainer">
        <div className="background"></div>
        <Header fixed simple />
        <div className="deco-pluss45deg"></div>
        <div className="deco-pluss"></div>
        <div className="deco-circle"></div>
        <div className="flex flex-center-row paddingTop-header paddingBottom-footer relative">
          <div className="container1200 paddingVertical-60 flex-between flex flex-center">
            <div className="signUpSuccess">
              <p className="signUp_title marginBottom-20">Your app is ready to launch</p>
              <div className="flex">
                {this.props.preview}
                <div className="flex flex-1 flex-column flex-center-row">
                  <p className="signUpSuccess_seeItText">
                    See it live in
                    <br />
                    Pluss Communities App
                  </p>
                  <div className="flex flex-center marginTop-20">
                    <img
                      className="signUpSuccess_plussLogo"
                      src="https://pluss-prd-media.s3-ap-southeast-2.amazonaws.com/assets/pluss-communities.svg"
                      alt="logo"
                    />
                    <p className="signUpSuccess_plussLogoText">
                      Download the Pluss Communities app in App Store and Play Store to see it live
                    </p>
                  </div>
                  <div className="flex flex-center marginTop-20">
                    <a className="marginRight-10" href={iOSLink} target="_blank">
                      <img
                        src="https://pluss-prd-media.s3-ap-southeast-2.amazonaws.com/assets/app-store.svg"
                        className="signUpSuccess_appStoreImage"
                        alt="App Store"
                      />
                    </a>
                    <a href={androidLink} target="_blank">
                      <img
                        src="https://pluss-prd-media.s3-ap-southeast-2.amazonaws.com/assets/google-play.svg"
                        className="signUpSuccess_appStoreImage"
                        alt="Play Store"
                      />
                    </a>
                  </div>
                  <div className="hrline"></div>
                  <p className="signUpSuccess_whiteLabelTitle">Publish as White Label App</p>
                  <div className="flex flex-center marginTop-20">
                    <div className="signUpSuccess_clientLogo" style={{ backgroundImage: `url('${this.props.logo}')` }}></div>
                    <div className="flex flex-1 flex-column flex-between height-100p">
                      <p className="signUpSuccess_plussLogoText">Publish your standalone app exclusively under your community branding</p>
                      <Button buttonType="primaryAction" className="button--signup" onClick={this.viewWhiteLabel} isActive={true}>
                        View Details
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="signUpSuccess_right">
              <div className="signUpSuccess_rightBox"></div>
              <div className="signUpSuccess_rightBox"></div>
            </div>
          </div>
        </div>
        <div className="signUpSuccess_footer flex flex-center-row">
          <div className="container1200 flex-between flex flex-center height-100p">
            <p className="signUpSuccess_footer_text">Start managing your site</p>
            <Button buttonType="signupFooter" onClick={this.goToPanel} isActive={true}>
              Go to Community Manager
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, {})(SiteSignUpComplete);
