import React, { Component } from 'react';

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editKey: null,
    };
  }

  getTabClass(tab) {
    let classes = 'tab';
    if (this.props.selectedTab === tab.value) {
      classes += ' tab-selected';
    }
    if (tab.strikethrough) {
      classes += ' tab-strikethrough';
    }
    if (tab.isInvalid) {
      classes += ' tab-invalid';
    }
    return classes;
  }

  renderTab(tab) {
    return (
      <div
        key={tab.value}
        onClick={() => {
          this.props.onSelectTab(tab.value);
        }}
        className={this.getTabClass(tab)}
        id={tab.value}
      >
        <p className="tab_text" id={`tab_${tab.value}`}>
          {tab.text}
        </p>
      </div>
    );
  }

  render() {
    return (
      <div className={`tabContainer ${this.props.className || ''}`} style={this.props.containerStyle}>
        {this.props.tabs.map((t) => {
          return this.renderTab(t);
        })}
        {this.props.children}
      </div>
    );
  }
}

export { Tabs };
