import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header } from '../../../components';
import EditPermissions from './EditPermissions';

class PermissionsPage extends Component {
  render() {
    return (
      <div className="hub-wrapperContainer">
        <div className="hub-headerContentWrapper">
          <Header />
          <div className="hub-contentWrapper" style={{ position: 'relative' }}>
            <EditPermissions />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(PermissionsPage);
