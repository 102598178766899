import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, GenericInput } from '../../../components';
import { checkLoggedIn, isTheBest, getSessionTokenAWS } from '../../../session';
import { stringActions } from '../../../webapi';

class AppStoreVersions extends Component {
    state = {
        ios: '',
        android: '',
        showWarnings: false
    }

    UNSAFE_componentWillMount() {
        checkLoggedIn(this, this.props.auth);
    }

    componentDidMount() {
        if (!isTheBest(this.props.auth)) {
            this.props.history.push('/mastermenu');
        }
        this.getVersions();
    }

    getVersions() {
        stringActions.getString('plussSpace', 'iosversion')
            .then((res) => {
                this.setState({
                    ios: res.data
                });
            }).catch((error) => {

            });
        stringActions.getString('plussSpace', 'androidversion')
            .then((res) => {
                this.setState({
                    android: res.data
                });
            }).catch((error) => {

            });
    }

    handleChange(event) {
        var stateChange = {};
        stateChange[event.target.getAttribute('id')] = event.target.value;
        this.setState(stateChange);
    }

    validateForm() {
        if (this.state.submitting) {
            return false;
        }
        if (_.isEmpty(this.state.ios)) {
            return false;
        }
        if (_.isEmpty(this.state.android)) {
            return false;
        }
        return true;
    }

    handleSubmit() {
        if (!this.validateForm()) {
            this.setState({ showWarnings: true });
            return;
        }
        this.setState({
            submitting: true,
            showWarnings: false
        });
        getSessionTokenAWS()
            .then((idToken) => {
                const promises = [];
                promises.push(stringActions.setString('plussSpace', 'iosversion', this.state.ios, this.props.auth.user.uid, idToken));
                promises.push(stringActions.setString('plussSpace', 'androidversion', this.state.android, this.props.auth.user.uid, idToken));

                Promise.all(promises).then((res) => {
                    console.log('success');
                    this.setState({
                        submitting: false
                    });
                });
            });
    }

    renderSubmit() {
        if (this.state.submitting) {
            return <Button buttonType='secondary'>Saving...</Button>;
        }
        return (
            <Button
                buttonType='primary'
                onClick={this.handleSubmit.bind(this)}
                isActive={this.validateForm()}
            >
                Save
            </Button>
        );
    }

    render() {
        return (
            <div style={{ width: '100%', maxWidth: 600 }}>
                <GenericInput
                    id="android"
                    type="text"
                    label="Android Play Store Version"
                    placeholder="1.0.0"
                    value={this.state.android}
                    onChange={(e) => this.handleChange(e)}
                    isRequired
                    isValid={() => { return !_.isEmpty(this.state.android) }}
                    showError={() => { return this.state.showWarnings && _.isEmpty(this.state.android) }}
                />
                <GenericInput
                    id="ios"
                    type="text"
                    label="iOS AppStore Version"
                    placeholder="1.0.0"
                    value={this.state.ios}
                    onChange={(e) => this.handleChange(e)}
                    isRequired
                    isValid={() => { return !_.isEmpty(this.state.ios) }}
                    showError={() => { return this.state.showWarnings && _.isEmpty(this.state.ios) }}
                />
                {this.renderSubmit()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth };
};

export default connect(mapStateToProps, {})(AppStoreVersions);
