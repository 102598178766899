import { authedFunction } from '../session';
import { getUrl } from './helper';

export const profileActions = {
  addEC: (userId, name, phone, email, relationship) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'emergencycontacts/add'),
      data: { userId, name, phone, email, relationship },
    });
  },
  editEC: (id, userId, name, phone, email, relationship) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'emergencycontacts/edit'),
      data: { id, userId, name, phone, email, relationship },
    });
  },
  deleteEC: (id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'emergencycontacts/delete'),
      data: { id },
    });
  },
  getECs: (userId) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('profile', 'emergencycontacts/user', { userId }),
    });
  },
  addPD: (userId, name, url, isPublic) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'personaldocuments/add'),
      data: { userId, name, url, isPublic },
    });
  },
  editPD: (id, userId, name, url, isPublic) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'personaldocuments/edit'),
      data: { id, userId, name, url, isPublic },
    });
  },
  deletePD: (id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'personaldocuments/delete'),
      data: { id },
    });
  },
  getPDs: (userId) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('profile', 'personaldocuments/user', { userId }),
    });
  },
  addPN: (userId, title, text, attachments) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'personalnotes/add'),
      data: { userId, title, text, attachments },
    });
  },
  editPN: (id, title, text, attachments) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'personalnotes/edit'),
      data: { id, title, text, attachments },
    });
  },
  deletePN: (id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'personalnotes/delete'),
      data: { id },
    });
  },
  getPNs: (userId) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('profile', 'personalnotes/user', { userId }),
    });
  },
  addUserTag: (site, title) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'usertags/add'),
      data: { site, title },
    });
  },
  editUserTag: (id, title) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'usertags/edit'),
      data: { id, title },
    });
  },
  deleteUserTag: (id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'usertags/delete'),
      data: { id },
    });
  },
  attachUserTag: (tagId, userId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'usertags/attach'),
      data: { tagId, userId },
    });
  },
  detachUserTag: (tagId, userId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('profile', 'usertags/detach'),
      data: { tagId, userId },
    });
  },
  getUserTagsByUser: (userId) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('profile', 'usertags/user', { userId }),
    });
  },
  getUserTagsBySite: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('profile', 'usertags/site', { site }),
    });
  },
};
