import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, OverlayPage, OverlayPageContents, OverlayPageSection, OverlayPageBottomButtons } from '../../components';
import { serviceActions, analyticsActions } from '../../webapi';
import { checkLoggedIn, validateAccess } from '../../session';
import { safeReadParams, toParagraphed, getPluralS, getPercentage, getPluralOptions } from '../../helper';
import FontAwesome from 'react-fontawesome';
import { ProfilePic } from '../../components/ProfilePic';
import { Text } from '../../components/text';

class ServiceAnalytics extends Component {
  initialState = {
    infoId: safeReadParams(this.props, 'infoId'),
    service: {},
    BookingCompleted: 0,
    BookingStarted: 0,
    PageView: 0,
    ReturningPageView: 0,
    UniquePageView: 0,
    bookings: [],
  };

  state = { ...this.initialState };

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    setTimeout(() => {
      if (!validateAccess(this.props.auth.site, 'services', this.props.auth)) {
        window.history.back();
      } else {
        this.getService();
        this.getStats();
      }
    }, 500);
  }

  getService() {
    serviceActions
      .getService(this.state.infoId)
      .then((res) => {
        this.setState({
          service: res.data,
        });
      })
      .catch((error) => {});
  }

  getStats() {
    analyticsActions.getEntityStats(this.state.infoId, 'service').then((res) => {
      console.log(res.data);
      this.setState({ ...res.data, statsLoaded: true });
    });
    serviceActions.getBookings(this.state.infoId).then((res) => {
      this.setState({ bookings: _.sortBy(res.data, 'bookingReceivedTime').reverse() });
    });
  }

  renderOverview() {
    if (!this.state.statsLoaded) {
      return null;
    }
    return (
      <div className="padding-32 paddingVertical-40 bottomDivideBorder">
        <Text type="formTitleSmall" className="marginBottom-16">
          Page Views
        </Text>
        <div className="polloverview">
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="eye" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.PageView}</p>
              <p className="polloverview_text">Page View{getPluralS(this.state.PageView)}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="user" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.UniquePageView}</p>
              <p className="polloverview_text">Unique Visitor{getPluralS(this.state.UniquePageView)}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="repeat" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.ReturningPageView}</p>
              <p className="polloverview_text">Returning Visitor{getPluralS(this.state.ReturningPageView)}</p>
            </div>
          </div>
        </div>
        <Text type="formTitleSmall" className="marginTop-32 marginBottom-16">
          Enquiries
        </Text>
        <div className="polloverview">
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="ticket" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.BookingCompleted}</p>
              <p className="polloverview_text">{getPluralOptions(this.state.BookingCompleted, 'Enquiry', 'Enquiries')}</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="line-chart" />
            <div className="polloverview_right">
              <p className="polloverview_count">{getPercentage(this.state.BookingCompleted, this.state.PageView)}</p>
              <p className="polloverview_text">Conversion Rate</p>
            </div>
          </div>
          <div className="polloverview_section">
            <FontAwesome className="polloverview_icon" name="exclamation-triangle" />
            <div className="polloverview_right">
              <p className="polloverview_count">{this.state.BookingStarted - this.state.BookingCompleted}</p>
              <p className="polloverview_text">
                Partially Completed Enquiry Form{getPluralS(this.state.BookingStarted - this.state.BookingCompleted)}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBack() {
    return (
      <div>
        <Button
          inline
          buttonType="primary"
          onClick={() => {
            window.history.back();
          }}
          isActive
        >
          Back to Services
        </Button>
      </div>
    );
  }

  renderBooking(b, index) {
    console.log(b);
    return (
      <div className="servicebooking" key={index}>
        <div className="servicebooking_top">
          <div className="servicebooking_top_side">
            <p className="servicebooking_enquiryAbout">Enquiry about</p>
            <p className="servicebooking_time">{b.dateText}</p>
            {b.timeText && <p className="servicebooking_time">{b.timeText}</p>}
          </div>
          <div className="servicebooking_top_side">
            {!_.isEmpty(b.description) && <p className="servicebooking_text">{toParagraphed(b.description)}</p>}
            {b.contactNumber && <p className="servicebooking_text servicebooking_text--secondary">Contact number: {b.contactNumber}</p>}
            {b.email && <p className="servicebooking_text servicebooking_text--secondary">Email: {b.email}</p>}
            {b.user && b.user.phoneNumber && b.user.phoneNumber !== b.contactNumber && (
              <p className="servicebooking_text servicebooking_text--secondary">User's phone number: {b.user.phoneNumber}</p>
            )}
            {b.user && b.user.email && b.user.email !== b.email && (
              <p className="servicebooking_text servicebooking_text--secondary">User's email: {b.user.email}</p>
            )}
          </div>
        </div>
        <div className="servicebooking_bottom">
          <div className="servicebooking_user">
            {b.user && <ProfilePic className="servicebooking_pic" size={24} image={b.user.profilePic}></ProfilePic>}
            <div className="servicebooking_name">
              {b.user ? b.user.displayName : b.name}
              {b.user && b.user.displayName !== b.name && (
                <span className="servicebooking_behalf">
                  {' '}
                  • on behalf of <span className="servicebooking_behalfname">{b.name}</span>
                </span>
              )}
            </div>
          </div>
          {b.bookingReceivedTime && (
            <span className="servicebooking_timestamp">
              Enquiry received at {moment.utc(b.bookingReceivedTime).local().format('D MMM YYYY • h:mma')}
            </span>
          )}
        </div>
      </div>
    );
  }

  renderMain() {
    return (
      <div>
        <div className="padding-60 paddingVertical-40">
          <Text type="formTitleLarge" className="marginBottom-24">
            {this.state.service.Title}
          </Text>
          <Text type="formTitleSmall" className="marginBottom-16">
            Enquiries
          </Text>
          {_.map(this.state.bookings, (b, i) => {
            return this.renderBooking(b, i);
          })}
        </div>
      </div>
    );
  }

  renderSideForm() {
    return <OverlayPageSection className="pageSectionWrapper--newPopupSideLarge">{this.renderOverview()}</OverlayPageSection>;
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents id="ServiceContainer" noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--fixedPopupSize">{this.renderMain()}</OverlayPageSection>
          {this.renderSideForm()}
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderBack()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(ServiceAnalytics);
