import React, { Component } from 'react';

class PageTitle extends Component {
  render() {
    return (
      <p className="managingPageTitle" {...this.props}>
        {this.props.children}
      </p>
    );
  }
}

export { PageTitle };
