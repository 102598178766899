import { authedFunction } from '../session';
import { getUrl } from './helper';

export const serviceActions = {
  getService: (RowId) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('services', 'getservice'),
      data: {
        RowId,
      },
    });
  },
  getServices: (Site) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('services', 'getservices'),
      data: {
        Site,
        noHQ: true,
        includeInactive: true,
      },
    });
  },
  getBookings: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('services', 'getbookings', { serviceId: id }),
    });
  },
  addService: (site, obj) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('services', 'addservice'),
      data: {
        site,
        obj,
      },
    });
  },
  manageStatus: (site, id, action) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('services', 'status'),
      data: {
        site,
        id,
        action,
      },
    });
  },
};
