import React from 'react';
import { FormGroup, FormControl, ControlLabel, HelpBlock } from 'react-bootstrap';
import { COLOUR_RED } from '../js';

function InputGroup({ id, label, help, showError, required, ...props }) {
    return (
        <FormGroup controlId={id} validationState={showError ? 'error' : null}>
            <ControlLabel><span>{label}</span>{required && <span style={{ color: COLOUR_RED, marginLeft: 5 }}>*</span>}</ControlLabel>
            <FormControl {...props} />
            {help && <HelpBlock>{help}</HelpBlock>}
        </FormGroup>
    );
}

export { InputGroup };