import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { contactsUpdate, addRecentlyCreated } from '../../actions';
import {
  Button,
  GenericInput,
  OverlayPage,
  OverlayPageContents,
  OverlayPageSection,
  OverlayPageBottomButtons,
  SuccessPopup,
  ImageInput,
  RadioButton,
} from '../../components';
import { contactActions } from '../../webapi';
import { safeReadParams, isEmail, get1400 } from '../../helper';
import { checkLoggedIn } from '../../session';
import { Text } from '../../components/text';

class AddImportantContact extends Component {
  initialState = {
    infoId: safeReadParams(this.props, 'infoId'),
    titleInput: '',
    emailInput: '',
    phoneInput: '',
    textInput: '',
    shouldNotify: false,
    showWarnings: false,
    success: false,
    submitting: false,
  };

  state = { ...this.initialState };

  UNSAFE_componentWillReceiveProps(nextProps) {}

  UNSAFE_componentWillMount() {
    checkLoggedIn(this, this.props.auth);
    if (this.state.infoId) {
      this.getData();
    }
  }

  componentDidMount() {
    this.props.addRecentlyCreated('importantContacts');
  }

  getData() {
    contactActions.getContact(this.state.infoId).then((res) => {
      this.parseUpdate(res.data);
    });
  }

  parseUpdate(update) {
    this.setState({
      titleInput: update.title,
      emailInput: update.email,
      phoneInput: update.phone,
      textInput: update.text,
    });
    if (!_.isEmpty(update.image)) {
      this.checkSetImage(update.image);
    }
  }

  checkSetImage(url) {
    if (this.refs.imageInput) {
      this.refs.imageInput.getWrappedInstance().setValue(url);
    } else {
      setTimeout(() => {
        this.checkSetImage(url);
      }, 100);
    }
  }

  isEmpty(text) {
    return text === '';
  }

  handleChange(event) {
    var stateChange = {};
    stateChange[event.target.getAttribute('id')] = event.target.value;
    this.setState(stateChange);
  }

  validateLoading() {
    if (this.state.submitting) {
      return false;
    }
    return true;
  }

  validateEmail() {
    if (!_.isUndefined(this.state.emailInput) && !_.isEmpty(this.state.emailInput) && !isEmail(this.state.emailInput)) {
      return false;
    }
    return true;
  }

  validateCompulsoryText() {
    if (this.isEmpty(this.state.titleInput)) {
      return false;
    }
    if (!this.validateEmail()) {
      return false;
    }
    return true;
  }

  validateForm() {
    if (!this.validateCompulsoryText()) {
      return false;
    }
    if (!this.validateLoading()) {
      return false;
    }
    return true;
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true });
      return;
    }
    this.setState({ submitting: true });

    if (this.state.infoId) {
      contactActions
        .editContact(
          this.props.auth.site,
          this.state.infoId,
          this.state.titleInput,
          this.state.emailInput,
          this.state.phoneInput,
          _.isEmpty(this.refs.imageInput.getWrappedInstance().getValue())
            ? null
            : get1400(this.refs.imageInput.getWrappedInstance().getValue()),
          this.state.textInput,
        )
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          this.props.contactsUpdate(this.props.auth.site);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    } else {
      contactActions
        .addContact(
          this.props.auth.site,
          this.state.titleInput,
          this.state.emailInput,
          this.state.phoneInput,
          _.isEmpty(this.refs.imageInput.getWrappedInstance().getValue())
            ? null
            : get1400(this.refs.imageInput.getWrappedInstance().getValue()),
          this.state.textInput,
          this.state.shouldNotify,
        )
        .then((res) => {
          this.setState({
            success: true,
            submitting: false,
          });
          this.props.contactsUpdate(this.props.auth.site);
        })
        .catch((res) => {
          this.setState({ submitting: false });
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  clearForm() {
    this.setState(this.initialState);
  }

  clearSuccess() {
    this.setState({
      success: false,
      submitting: false,
      showWarnings: false,
    });
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    const buttons = [];
    if (this.state.infoId == null) {
      buttons.push({
        type: 'outlined',
        onClick: () => this.clearForm(),
        text: 'Clear form',
      });
    }
    buttons.push({
      type: 'outlined',
      onClick: () => {
        window.history.back();
      },
      text: 'Go to home',
    });
    return <SuccessPopup text={`Contact has been ${this.state.infoId != null ? 'edited' : 'added'}`} buttons={buttons} />;
  }

  inputsDisabled() {
    if (this.state.submitting) {
      return true;
    }
    return false;
  }

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <div>
        <Button
          inline
          buttonType="tertiary"
          onClick={() => {
            window.history.back();
          }}
          isActive
          style={{ marginRight: 16 }}
        >
          {!this.inputsDisabled() ? 'Cancel' : 'Back'}
        </Button>
        {!this.inputsDisabled() && (
          <Button inline buttonType="primary" onClick={() => this.handleSubmit()} isActive={this.validateForm()}>
            Save
          </Button>
        )}
      </div>
    );
  }

  renderSideForm() {
    if (this.state.success || this.state.infoId) {
      return null;
    }
    return (
      <OverlayPageSection className="pageSectionWrapper--newPopupSide">
        <div>
          <div className="padding-60 paddingVertical-40 bottomDivideBorder ">
            <Text type="formTitleSmall" className="marginBottom-16">
              Notification
            </Text>

            <RadioButton
              label="Do you want to send a notification with this contact?"
              isActive={this.state.shouldNotify}
              options={[
                { Label: 'Yes', Value: true, onChange: () => this.setState({ shouldNotify: true }) },
                { Label: 'No', Value: false, onChange: () => this.setState({ shouldNotify: false }) },
              ]}
            />
            <div className="genericInput-help" style={{ marginTop: 4 }}>
              This will send an alert to all users who have enabled push notifications.
            </div>
          </div>
        </div>
      </OverlayPageSection>
    );
  }

  renderForm() {
    if (this.state.success) {
      return null;
    }
    return (
      <div className="padding-60 paddingVertical-40">
        <Text type="formTitleLarge" className="marginBottom-24">
          {this.state.infoId == null ? 'New' : 'Edit'} Contact
        </Text>
        {/* title */}
        <GenericInput
          id="titleInput"
          type="text"
          label="Title"
          placeholder="Building Manager"
          showError={() => {
            return this.state.showWarnings && this.isEmpty(this.state.titleInput);
          }}
          value={this.state.titleInput}
          onChange={(e) => this.handleChange(e)}
          isRequired
          isValid={() => {
            return !this.isEmpty(this.state.titleInput);
          }}
          alwaysShowLabel
        />
        {/* email */}
        <GenericInput
          id="emailInput"
          type="text"
          label="Email"
          placeholder="example@site.com"
          showError={() => {
            return this.state.showWarnings && !this.validateEmail();
          }}
          value={this.state.emailInput}
          onChange={(e) => this.handleChange(e)}
          alwaysShowLabel
        />
        {/* phone */}
        <GenericInput
          id="phoneInput"
          type="text"
          label="Phone"
          placeholder="(07) 1234 5678"
          value={this.state.phoneInput}
          onChange={(e) => this.handleChange(e)}
          alwaysShowLabel
        />
        {/* text */}
        <GenericInput
          id="textInput"
          label="Text"
          type="textarea"
          placeholder="Enter additional text here such as address or comments (optional)"
          componentClass="textarea"
          value={this.state.textInput}
          onChange={(e) => this.handleChange(e)}
          inputStyle={{
            height: 120,
          }}
          alwaysShowLabel
        />
        <Text type="formLabel" className="marginBottom-8">
          Photo
        </Text>
        <ImageInput ref="imageInput" noMenu />
      </div>
    );
  }

  render() {
    return (
      <OverlayPage>
        <OverlayPageContents noBottomButtons={this.state.success}>
          <OverlayPageSection className="pageSectionWrapper--newPopup">
            {this.renderForm()}
            {this.renderSuccess()}
          </OverlayPageSection>
          {this.renderSideForm()}
        </OverlayPageContents>
        <OverlayPageBottomButtons>{this.renderSubmit()}</OverlayPageBottomButtons>
      </OverlayPage>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { contactsUpdate, addRecentlyCreated })(AddImportantContact);
