import { DISHES_LOADED, UPDATE_DISH, DISH_REMOVED } from './types';

export const dishesLoaded = (dishes) => {
  return {
    type: DISHES_LOADED,
    payload: dishes,
  };
};

export const updateDish = (dish) => {
  return {
    type: UPDATE_DISH,
    payload: dish,
  };
};

export const removeDish = (id) => {
  return {
    type: DISH_REMOVED,
    payload: id,
  };
};
