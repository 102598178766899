import React, { Component } from 'react';
import { Table, DropdownButton, MenuItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import { withRouter } from 'react-router';
import { welcomePagesLoaded, removeWelcomePage } from '../../actions';
import { COLOUR_BRANDING_OFF } from '../../js';
import { checkLoggedIn, validateAccess } from '../../session';
import { infoActions, typeActions } from '../../webapi';
import { AddButton, SuccessPopup } from '../../components';
import { Text } from '../../components/text';

class WelcomePages extends Component {
  state = {
    welcomePageList: [],
    showCompleted: false,
    sortColumn: 'Order',
    sortDesc: false,
    savingOrder: false,
    loading: true,
    types: {},
  };

  UNSAFE_componentWillMount() {
    this.updateProps(this.props);
    checkLoggedIn(this, this.props.auth);
  }

  componentDidMount() {
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps);
  }

  updateProps(props) {
    this.setState({
      welcomePageList: props.welcomePages,
    });
  }

  moveDown(ev) {
    if (this.state.savingOrder) {
      return;
    }
    let source = _.sortBy(this.state.welcomePageList, 'Order');
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });
    const index = source.indexOf(ev);
    if (index === source.length - 1) {
      return;
    }
    source.splice(index, 1);
    source.splice(index + 1, 0, ev);

    this.saveOrder(source);
  }

  moveUp(ev) {
    if (this.state.savingOrder) {
      return;
    }
    let source = _.sortBy(this.state.welcomePageList, 'Order');
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });
    const index = source.indexOf(ev);
    if (index === 0) {
      return;
    }
    source.splice(index, 1);
    source.splice(index - 1, 0, ev);

    this.saveOrder(source);
  }

  saveOrder(source) {
    const changes = [];
    source.forEach((item, index) => {
      if (item.Order !== index) {
        changes.push({
          id: item.Id,
          order: index,
        });
        item.Order = index;
      }
    });
    this.setState({
      savingOrder: true,
    });
    infoActions.changeWelcomePageOrder(this.props.auth.site, changes).then((res) => {
      console.log('success');
      this.setState({
        savingOrder: false,
      });
    });
  }

  getData() {
    this.getPages();
    infoActions.getWelcomePages('default').then((res) => {
      if (res.data != null) {
        console.log(res.data);
        this.setState({
          defaultWelcomePages: res.data,
        });
      }
      this.setState({ loading: false });
    });

    typeActions
      .getUserTypes(this.props.auth.site)
      .then((res) => {
        const result = {};
        res.data.forEach((t) => {
          result[t.typeName] = t.displayName;
        });
        this.setState({
          types: result,
        });
      })
      .catch((error) => {});
  }

  getPages() {
    infoActions.getWelcomePages(this.props.auth.site).then((res) => {
      if (res.data != null) {
        this.props.welcomePagesLoaded(res.data);
      }
      this.setState({ loading: false });
    });
  }

  getUpStyle(ev) {
    if (this.state.savingOrder || ev.Order === 0) {
      return { visibility: 'hidden' };
    }
    return null;
  }

  getDownStyle(ev) {
    if (this.state.savingOrder || ev.Order === this.state.welcomePageList.length - 1) {
      return { visibility: 'hidden' };
    }
    return null;
  }

  getAudienceText(ev) {
    if (ev.AudienceType === 'All') {
      return 'All users';
    }
    if (ev.AudienceType === 'Category') {
      const types = {
        resident: 'Primary users',
        staff: 'Staff users',
        family: 'Linked users',
      };

      return types[ev.AudienceTypeSelection];
    }
    if (ev.AudienceType === 'UserType') {
      if (this.state.types[ev.AudienceTypeSelection]) {
        return `${this.state.types[ev.AudienceTypeSelection]} users`;
      }
      return `${ev.AudienceTypeSelection} users`;
    }
    return '';
  }

  removeWelcomePage(ev) {
    if (window.confirm(`Are you sure you want to delete ${ev.Title}?`)) {
      infoActions
        .deleteWelcomePage(this.props.auth.site, ev.Id, this.props.auth.user.uid)
        .then((res) => {
          this.getData();
          // action
          console.log('success');
          this.props.removeWelcomePage(ev.Id);
        })
        .catch((res) => {
          alert('Something went wrong with the request. Please try again.');
        });
    }
  }

  addNew() {
    this.props.history.push(`/info/welcome`);
  }

  openCopyPopup() {
    this.setState({
      copyOpen: true,
    });
  }

  closeCopyPopup() {
    this.setState({
      copyOpen: false,
    });
  }

  onSubmitCopy() {
    this.setState({
      copying: true,
    });
    infoActions.copyWelcomePage(this.props.auth.site, this.state.selectedDefault.Id).then((res) => {
      this.getPages();

      this.setState({
        copying: false,
        loading: true,
        copyOpen: false,
      });
    });
  }

  getSelectedDefault() {
    if (!this.state.selectedDefault) {
      return 'Select a page to copy';
    }
    return this.state.selectedDefault.Title;
  }

  renderDefaultOptions() {
    return [...this.state.defaultWelcomePages, { Id: 'all', Title: 'All of the above pages' }].map((ev) => {
      return (
        <MenuItem key={ev.Id} eventKey={ev.Id} active={this.state.selectedDefault && this.state.selectedDefault.Id === ev.Id}>
          {ev.Title}
        </MenuItem>
      );
    });
  }

  selectDefault(id) {
    if (id === 'all') {
      this.setState({
        selectedDefault: { Id: 'all', Title: 'All of the above pages' },
      });
      return;
    }
    this.setState({
      selectedDefault: _.find(this.state.defaultWelcomePages, (p) => {
        return p.Id === id;
      }),
    });
  }

  renderCopyPopup() {
    if (!this.state.copyOpen) {
      return null;
    }
    if (this.state.copying) {
      return (
        <SuccessPopup
          noIcon
          text="Copy one or all of the Welcome Pages"
          buttons={[
            {
              type: 'primary',
              onClick: null,
              text: `Copying`,
              inactive: true,
            },
          ]}
        ></SuccessPopup>
      );
    }
    return (
      <SuccessPopup
        noIcon
        text="Copy one or all of the Welcome Pages"
        buttons={[
          {
            type: 'primary',
            onClick: this.onSubmitCopy.bind(this),
            text: `Copy`,
            inactive: this.state.selectedDefault == null,
          },
          {
            type: 'outlined',
            onClick: this.closeCopyPopup.bind(this),
            text: `Cancel`,
          },
        ]}
      >
        <div className="dropdown--copyPopup">
          <DropdownButton
            id="secondLevelSort"
            bsSize="xsmall"
            className="siteSelector__button"
            title={this.getSelectedDefault()}
            onSelect={this.selectDefault.bind(this)}
          >
            {this.renderDefaultOptions()}
          </DropdownButton>
        </div>
      </SuccessPopup>
    );
  }

  renderList() {
    if (_.isEmpty(this.state.welcomePageList)) {
      return null;
    }
    let source = _.sortBy(this.state.welcomePageList, this.state.sortColumn);
    source = _.filter(source, (ev) => {
      if (!ev) {
        return false;
      }
      return true;
    });

    return source.map((ev, index) => {
      if (ev != null) {
        return (
          <tr key={index}>
            <td className="table-TitleColumn">
              <Link to={`/info/welcome/${ev.Id}`}>{ev.Title}</Link>
            </td>
            <td>{this.getAudienceText(ev)}</td>
            {validateAccess(this.props.auth.site, 'welcomePages', this.props.auth) && (
              <td className="table-options">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <a
                    onClick={() => {
                      this.moveUp(ev);
                    }}
                    style={this.getUpStyle(ev)}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="arrow-up" />
                  </a>
                  <a
                    onClick={() => {
                      this.moveDown(ev);
                    }}
                    style={this.getDownStyle(ev)}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="arrow-down" />
                  </a>
                  <Link to={`/info/welcome/${ev.Id}`}>
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 12, cursor: 'pointer' }} name="pencil" />
                  </Link>
                  <a
                    onClick={() => {
                      this.removeWelcomePage(ev);
                    }}
                  >
                    <FontAwesome style={{ fontSize: 20, padding: 5, marginLeft: 8, cursor: 'pointer' }} name="minus-circle" />
                  </a>
                </div>
              </td>
            )}
          </tr>
        );
      }
      return null;
    });
  }

  renderView() {
    return (
      <Table className="plussTable" striped bordered condensed hover style={{ minWidth: '100%' }}>
        <thead>
          <tr>
            <th>Title</th>
            <th style={{ width: 140 }}>Audience</th>
            <th style={{ width: 140 }} />
          </tr>
        </thead>
        <tbody>{this.renderList()}</tbody>
      </Table>
    );
  }

  renderEmpty() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center', alignItems: 'center', marginTop: 32 }}>
        <div className="emptyState" />
        <div className="marginTop-32" style={{ maxWidth: 500, textAlign: 'center' }}>
          <Text type="h3">Welcome Information is the content you can use to greet your Users into the app.</Text>
        </div>
      </div>
    );
  }

  renderContent() {
    if (_.isEmpty(this.state.welcomePageList)) {
      return this.renderEmpty();
    }
    return this.renderView();
  }

  render() {
    if (this.state.welcomePageList.length === 0 && this.state.loading) {
      return (
        <div style={{ minWidth: '100%' }}>
          <div className="padding-60 paddingVertical-40" style={{ textAlign: 'center' }}>
            <FontAwesome style={{ fontSize: 30, color: COLOUR_BRANDING_OFF }} name="spinner fa-pulse fa-fw" />
          </div>
        </div>
      );
    }
    return (
      <div style={{ minWidth: '100%' }}>
        <div style={{ marginBottom: 32, height: 30 }}>
          <AddButton onClick={this.addNew.bind(this)} text="NEW WELCOME PAGE" style={{ float: 'left' }} />
          {!_.isEmpty(this.state.defaultWelcomePages) && this.props.auth.site !== 'default' && (
            <AddButton onClick={this.openCopyPopup.bind(this)} text="COPY DEFAULT WELCOME PAGE" style={{ float: 'left', marginLeft: 32 }} />
          )}
        </div>
        {this.renderContent()}
        {this.renderCopyPopup()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { welcomePages } = state.infoPages;
  const { auth } = state;
  return { welcomePages, auth };
};

export default connect(mapStateToProps, { welcomePagesLoaded, removeWelcomePage })(withRouter(WelcomePages));
