import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, DropdownInput, GenericInput } from '../../../components';
import { COLOUR_GREEN } from '../../../js';
import { stringActions } from '../../../webapi';

class SetTimezone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timezone: 'Australia/Brisbane',
    };

    this.timezones = [
      'Australia/Adelaide',
      'Australia/Brisbane',
      'Australia/Darwin',
      'Australia/Hobart',
      'Australia/Melbourne',
      'Australia/Perth',
      'Australia/Sydney',
      'Pacific/Auckland',
    ];
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    stringActions.getString(this.props.auth.site, 'timezone', true).then((res) => {
      this.setState({
        timezone: res.data,
      });
    });
  }

  handleSubmit() {
    if (!this.validateForm()) {
      this.setState({ showWarnings: true, success: false });
      return;
    }
    this.setState({ submitting: true, showWarnings: false, success: false });

    stringActions
      .setString(this.props.auth.site, 'timezone', this.state.timezone)
      .then((res) => {
        this.setState({
          success: true,
          submitting: false,
        });
      })
      .catch((res) => {
        this.setState({ submitting: false });
        alert('Something went wrong with the request. Please try again.');
      });
  }

  validateForm = () => {
    if (this.state.submitting) {
      return false;
    }
    return this.timezones.includes(this.state.timezone);
  };

  onSuccess() {
    this.setState({ submitting: false, success: true });
  }

  getTZOptions = () => {
    return this.timezones.map((v) => {
      return { Key: v, Value: v, Title: v };
    });
  };

  selectTimezone = (tz) => {
    console.log(tz);
    this.setState({
      timezone: tz,
    });
  };

  renderSubmit() {
    if (this.state.submitting) {
      return <Button buttonType="secondary">Saving...</Button>;
    }
    return (
      <Button inline buttonType="primary" onClick={this.handleSubmit.bind(this)} isActive={this.validateForm()}>
        Save
      </Button>
    );
  }

  renderSuccess() {
    if (!this.state.success) {
      return null;
    }
    return <span style={{ color: COLOUR_GREEN, fontSize: 14, lineHeight: '33px', marginLeft: 30 }}>Saved</span>;
  }

  render() {
    return (
      <div style={{ width: '100%', maxWidth: 600 }}>
        <GenericInput
          label="Select Timezone"
          alwaysShowLabel
          inputComponent={
            <DropdownInput
              id="timezone"
              placeholder="Select timezone"
              value={this.state.timezone}
              options={this.getTZOptions()}
              onSelect={this.selectTimezone}
              className="marginRight-20"
              style={{ marginBottom: 0, width: 300 }}
            />
          }
        />
        {this.renderSubmit()}
        {this.renderSuccess()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, {})(SetTimezone);
