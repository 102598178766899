import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import SignatureCanvas from 'react-signature-canvas';
import { isEmail, readJSONFromStorage, setLocalStorage, toParagraphed } from '../../helper';
import { setAuthUser } from '../../actions';
import { fileActions, formActions } from '../../webapi';
import { Button, GenericInput, Popup, RadioButton, CheckBox } from '../../components';

class SingleSignInForm extends Component {
  constructor(props) {
    super(props);

    let answers = {};

    if (this.props.form && !this.props.isPreview && !this.props.disableAutoFill) {
      answers = readJSONFromStorage(window.localStorage, `signin_${this.props.form.RowId}`, {});
    }

    this.cachedAnswerTypes = ['phone', 'email', 'text', 'checkbox'];

    this.state = {
      forms: [],
      failureText: '',
      submittingSignIn: false,
      answers,
      formPage: 0,
      formPages: [],
      message: '',
      emptySignatures: {},
    };
  }

  componentDidMount() {
    this.setFormPages();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.form !== this.props.form) {
      this.setFormPages();
    }
  }

  getFieldId(pageIndex, fieldIndex) {
    return `${pageIndex}_${fieldIndex}`;
  }

  isFirstPage() {
    return this.state.formPage === 0;
  }

  isLastPage() {
    return this.state.formPage === this.state.formPages.length - 1;
  }

  getCurrentPage() {
    return this.state.formPages[this.state.formPage] || [];
  }

  setFormPages() {
    this.setState({
      formPages: _.sortBy(_.values(_.groupBy(this.props.form.Fields, 'page')), (fields) => {
        return fields[0].page;
      }),
    });
  }

  clearSignature = (fieldId) => {
    if (this.state.submittingSignIn) {
      return;
    }
    this[`sigPad_${fieldId}`].clear();
    const eSigs = { ...this.state.emptySignatures };
    eSigs[fieldId] = false;
    const newAnswers = { ...this.state.answers };
    newAnswers[fieldId] = null;
    this.setState({
      emptySignatures: eSigs,
      answers: newAnswers,
    });
  };

  updateEmptySignature = (fieldId) => {
    setTimeout(() => {
      const eSigs = { ...this.state.emptySignatures };
      eSigs[fieldId] = !this[`sigPad_${fieldId}`].isEmpty();
      this.setState({
        emptySignatures: eSigs,
      });
    }, 100);
  };

  uploadSignature = async (fieldId) => {
    this.setState({
      message: 'uploading signature',
    });
    const sigRes = await fileActions.putCanvas(this[`sigPad_${fieldId}`], 'signature');
    return { fieldId, url: sigRes.key };
  };

  uploadSignatures = () => {
    return new Promise((resolve, reject) => {
      const promises = [];
      this.getCurrentPage().forEach((f, i) => {
        if (f.type === 'signature') {
          promises.push(this.uploadSignature(this.getFieldId(this.state.formPage, i)));
        }
      });
      Promise.all(promises).then((responses) => {
        const newAnswers = { ...this.state.answers };
        responses.forEach((r) => {
          newAnswers[r.fieldId] = r.url;
        });
        this.setState(
          {
            answers: newAnswers,
            message: null,
          },
          () => {
            return resolve();
          },
        );
      });
    });
  };

  onChangeAnswer = (qId, answer) => {
    if (this.props.isPreview) return;
    const newAnswers = { ...this.state.answers };
    newAnswers[qId] = answer;
    this.setState({
      answers: newAnswers,
    });
  };

  onChangeCheckboxAnswer = (qId, answer) => {
    if (this.props.isPreview) return;
    const newAnswers = { ...this.state.answers };
    const newList = _.xor(newAnswers[qId] || [], [answer]);
    newAnswers[qId] = newList;
    this.setState({
      answers: newAnswers,
    });
  };

  isReadyToSubmitPage = () => {
    if (this.props.isPreview) return true;
    return !_.some(this.getCurrentPage(), (f, i) => {
      return !this.isFieldValid(f, i);
    });
  };

  checkValidation = () => {
    if (this.props.isPreview) return true;
    let failedStep = null;
    let failedOption = null;
    this.getCurrentPage().forEach((f, i) => {
      if (!f.validation) {
        return true;
      }
      f.validation.forEach((v) => {
        if (v.type === 'invalidValue') {
          if (this.state.answers[this.getFieldId(this.state.formPage, i)] === v.value) {
            // the value is invalid
            failedStep = f;
            failedOption = v;
          }
        }
      });
    });
    if (!failedStep) {
      return true;
    }
    const failure = {
      action: failedOption.action || failedStep.failedValidation.action,
      message: failedOption.message || failedStep.failedValidation.message,
    };
    switch (failure.action) {
      case 'stop':
        this.setState({
          failure: true,
          failureText: failure.message,
        });

        // wait a minute and reset form
        setTimeout(() => {
          this.props.resetForm();
        }, 30000);
        break;
      default:
        break;
    }
    return false;
  };

  submitAnswers = (validated = false) => {
    this.setState({ submittingSignIn: true, message: 'Signing in...' }, async () => {
      try {
        const { formPages, answers } = this.state;
        const { form } = this.props;
        const { Options } = form;
        const submission = {
          FormId: form.RowId,
          FormName: form.Name,
          Version: form.Version,
          Site: form.Site,
          Type: form.Type,
          Answers: [],
          Extras: {
            valid: validated,
            signOutCode: '',
            phone: '',
            email: '',
            requireSignature: Options && Options.RequireSignOutSignature,
          },
        };
        const cookieAnswers = {};
        for (const [key, value] of Object.entries(answers)) {
          const qIndex = key.split('_');
          const page = qIndex[0];
          const position = qIndex[1];
          const field = formPages[page][position];
          if (field.type === 'phone') {
            submission.Extras.phone = value;
          } else if (field.type === 'email') {
            submission.Extras.email = value;
          }
          if (_.includes(this.cachedAnswerTypes, field.type)) {
            cookieAnswers[key] = value;
          }
          submission.Answers.push({
            page,
            position,
            question: field.label,
            answer: value,
            type: field.type,
          });
        }

        setLocalStorage(`signin_${form.RowId}`, JSON.stringify(cookieAnswers));

        const res = await formActions.submitForm(submission);
        console.log('submitForm', res.data.submission.Extras);
        this.props.onSignInSuccess(res.data.submission.Extras.signOutCode);
      } catch (error) {
        console.log('submitForm error', error);
        window.alert('Something went wrong. Please try again.');
        // this.props.onSignInSuccess('');
        this.setState({
          submittingSignIn: false,
        });
      }
    });
  };

  onCancelPage = () => {
    if (!this.isFirstPage()) {
      this.setState({
        formPage: this.state.formPage - 1,
      });
      return;
    }
    this.props.resetForm();
  };

  onSubmitPage = async () => {
    if (!this.isReadyToSubmitPage()) {
      // button should inactive
      return;
    }
    if (!this.checkValidation()) {
      // locked out due to invalid answer
      this.submitAnswers();
      return;
    }
    // upload signatures
    await this.uploadSignatures();
    console.log('signatures uploaded. answers set.');
    console.log(JSON.stringify(this.state.answers));
    if (!this.isLastPage()) {
      this.setState({
        formPage: this.state.formPage + 1,
      });
      return;
    }
    if (this.props.isPreview) return this.props.resetForm();
    console.log('now submitting');
    this.submitAnswers(true);
  };

  isFieldValid = (f, i) => {
    const fieldId = this.getFieldId(this.state.formPage, i);
    if (f.mandatory) {
      switch (f.type) {
        case 'yn':
          return !_.isUndefined(this.state.answers[fieldId]);
        case 'multichoice':
          return !_.isUndefined(this.state.answers[fieldId]);
        case 'text':
          return !_.isEmpty(this.state.answers[fieldId]);
        case 'phone':
          return !_.isEmpty(this.state.answers[fieldId]);
        case 'checkbox':
          return !_.isEmpty(this.state.answers[fieldId]);
        case 'email':
          return isEmail(this.state.answers[fieldId]);
        case 'signature':
          return this.state.emptySignatures[fieldId];
        default:
          break;
      }
    }
    return true;
  };

  renderFailure() {
    if (!this.state.failure) {
      return null;
    }
    return (
      <Popup
        // title={`We are very sorry, but you cannot enter this facility.`}
        subtitle={toParagraphed(this.state.failureText)}
        maxWidth="90%"
        maxHeight="90%"
        hasPadding
        buttons={[
          {
            type: 'tertiary',
            onClick: this.props.resetForm,
            isActive: true,
            text: 'Close',
          },
        ]}
      />
    );
  }

  renderSignInSubmit() {
    const { submittingSignIn, message } = this.state;
    const isReady = !submittingSignIn && this.isReadyToSubmitPage();
    if (!_.isEmpty(message)) {
      return (
        <div className="flex flex-center-row">
          <p>{message}</p>
        </div>
      );
    }

    return (
      <div className="flex flex-center-row marginTop-20">
        <Button
          buttonType="primary"
          onClick={this.onCancelPage}
          isActive={!submittingSignIn}
          style={{
            width: '110px',
            marginRight: '10px',
            backgroundColor: '#fff',
            ...(!submittingSignIn ? { borderColor: this.props.colour } : null),
          }}
          textStyle={!submittingSignIn ? { color: this.props.colour } : null}
          noHoverHighlight
        >
          {this.isFirstPage() ? 'Cancel' : 'Back'}
        </Button>
        <Button
          buttonType="primary"
          onClick={this.onSubmitPage}
          isActive={isReady}
          style={{
            width: '110px',
            marginLeft: '10px',
            ...{ borderColor: this.props.colour, backgroundColor: this.props.colour },
          }}
          textStyle={{ color: '#fff' }}
          noHoverHighlight
        >
          {this.isLastPage() ? (this.props.isPreview ? 'Close' : 'Submit') : 'Continue'}
        </Button>
      </div>
    );
  }

  renderField(field, i) {
    const fieldId = this.getFieldId(this.state.formPage, i);
    switch (field.type) {
      case 'yn':
        return (
          <div className="visitorSignIn_question" key={fieldId}>
            <RadioButton
              label={field.label}
              labelStyle={{ color: this.props.colour }}
              isActive={this.state.answers[fieldId]}
              noHoverHighlight
              highlightColour={this.props.colour}
              options={[
                { Label: 'Yes', Value: true, onChange: this.onChangeAnswer.bind(this, fieldId, true) },
                { Label: 'No', Value: false, onChange: this.onChangeAnswer.bind(this, fieldId, false) },
              ]}
            />
          </div>
        );
      case 'multichoice':
        return (
          <div className="visitorSignIn_question" key={fieldId}>
            <RadioButton
              label={field.label}
              labelStyle={{ color: this.props.colour }}
              isActive={this.state.answers[fieldId]}
              noHoverHighlight
              highlightColour={this.props.colour}
              options={field.values.map((o) => {
                return { Label: o, Value: o, onChange: this.onChangeAnswer.bind(this, fieldId, o) };
              })}
              rowStyle={{ flexDirection: 'column' }}
              buttonStyle={{ marginTop: '5px', marginBottom: '5px' }}
            />
          </div>
        );
      case 'checkbox':
        return (
          <div className="visitorSignIn_question" key={fieldId}>
            <div className="fieldLabel" style={{ marginBottom: '5px', color: this.props.colour }}>
              {field.label}
            </div>
            {field.values.map((option, optionIndex) => {
              return (
                <CheckBox
                  key={optionIndex}
                  label={option}
                  isActive={this.state.answers[fieldId] && this.state.answers[fieldId].includes(option)}
                  highlightColour={this.props.colour}
                  noHoverHighlight
                  onChange={() => this.onChangeCheckboxAnswer(fieldId, option)}
                />
              );
            })}
          </div>
        );
      case 'text':
      case 'email':
      case 'phone':
        return (
          <div className="paddingLeft-10" key={fieldId}>
            <GenericInput
              id={fieldId}
              type="text"
              label={field.label}
              placeholder={field.placeHolder}
              value={this.state.answers[fieldId]}
              onChange={(e) => this.onChangeAnswer(fieldId, e.target.value)}
              isRequired={field.mandatory}
              isValid={() => {
                return this.isFieldValid(field, i);
              }}
              alwaysShowLabel
            />
          </div>
        );
      case 'staticTitle':
        return (
          <p className="visitorSignIn_text-staticTitle" style={{ color: this.props.colour }} key={fieldId}>
            {field.label}
          </p>
        );
      case 'staticText':
        return (
          <p className="visitorSignIn_text-staticText" key={fieldId}>
            {toParagraphed(field.label, { marginTop: 10 })}
          </p>
        );
      case 'signature':
        return (
          <div className="visitorSignIn_question" key={fieldId}>
            <p className="actionText">{field.label}</p>
            <div className="signature">
              <SignatureCanvas
                penColor="black"
                canvasProps={{ width: 600, className: 'signature_canvas' }}
                // backgroundColor="#fff"
                ref={(ref) => {
                  this[`sigPad_${fieldId}`] = ref;
                }}
                onEnd={() => this.updateEmptySignature(fieldId)}
              />
            </div>
            <Button style={{ marginTop: 5 }} isActive buttonType="secondary" onClick={() => this.clearSignature(fieldId)}>
              Clear Signature
            </Button>
          </div>
        );
      default:
        return null;
    }
  }

  renderHeader() {
    return (
      <div className="visitorSignIn_header">
        <img className="visitorSignIn_header_logo" src={this.props.logo} alt="logo" />
      </div>
    );
  }

  render() {
    return (
      <div className="visitorSignIn">
        {this.renderFailure()}
        {this.renderHeader()}
        <div className="visitorSignIn_form">
          {this.getCurrentPage().map((field, i) => {
            return this.renderField(field, i);
          })}
          {this.renderSignInSubmit()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  return { auth };
};

export default connect(mapStateToProps, { setAuthUser })(SingleSignInForm);
