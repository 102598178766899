import React, { Component } from 'react';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';

class SelectedEventDate extends Component {
    
    getAttendance(ev) {
        if (ev.TicketCount === 0) {
            return `${ev.AttendanceCount}`;
        }
        return `${ev.AttendanceCount}/${ev.TicketCount}`;
    }

    render() {
        return (<div className={`selectedEventDate ${this.props.date ? 'selectedEventDate--shown' : ''}`}>
            <div className='selectedEventDate__close' onClick={this.props.onClose}>
                <FontAwesome name="remove" />
            </div>
            <div className='selectedEventDate__scroll'>
                {this.props.date &&
                    <div>
                        <p className='selectedEventDate__day'>{moment(this.props.date.dateKey, 'DD-MM-YYYY').format('dddd')}</p>
                        <p className='selectedEventDate__date'>{moment(this.props.date.dateKey, 'DD-MM-YYYY').format('D MMMM')}</p>
                        <p className='selectedEventDate__events'>Events</p>
                        {this.props.date.events.map((ev, i) => {
                            return (
                                <div className='selectedEventDate__event' key={i}>
                                    <Link className='noUnderline selectedEventDate__edit' to={`/events/event/${ev.Id}`}>
                                        <FontAwesome name="pencil" />
                                    </Link>
                                    <p className='selectedEventDate__eventTitle'>{ev.Title}</p>
                                    <p className='selectedEventDate__eventTime'>{ev.Time}</p>
                                    <p className='selectedEventDate__eventLocation'>{ev.Location}</p>
                                    <div className='selectedEventDate__eventAttendanceContainer clearfix'>
                                        <p className='selectedEventDate__eventAttendance'>
                                            <FontAwesome name="male" />
                                            {this.getAttendance(ev)}
                                        </p>
                                        {ev.TicketCount > 0 && ev.TicketCount === ev.AttendanceCount && (
                                            <p className='selectedEventDate__eventAttendanceFull'>FULL</p>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                }
            </div>
        </div>
        );
    }
}

export default SelectedEventDate;