import {
    INFOPAGES_LOADED,
    INFOPAGE_REMOVED,
    INFOPAGES_PURGE,
    WELCOMEPAGES_LOADED,
    WELCOMEPAGE_REMOVED,
    WELCOMEPAGES_PURGE
} from './types';
import { infoActions } from '../webapi';

export const infoPagesUpdate = (site) => {
    return (dispatch) => {
        infoActions.getInfoPages(site).then(
            (res) => {
                if (res.data != null) {
                    dispatch({
                        type: INFOPAGES_LOADED,
                        payload: res.data
                    })
                }
            }
        );
    }
}

export const infoPagesLoaded = (events) => {
    return {
        type: INFOPAGES_LOADED,
        payload: events
    };
};

export const removeInfoPage = (id) => {
    return {
        type: INFOPAGE_REMOVED,
        payload: id
    };
};

export const clearInfoPages = () => {
    return {
        type: INFOPAGES_PURGE
    };
};

export const welcomePagesUpdate = (site) => {
    return (dispatch) => {
        infoActions.getWelcomePages(site).then(
            (res) => {
                if (res.data != null) {
                    dispatch({
                        type: WELCOMEPAGES_LOADED,
                        payload: res.data
                    })
                }
            }
        );
    }
}

export const welcomePagesLoaded = (events) => {
    return {
        type: WELCOMEPAGES_LOADED,
        payload: events
    };
};

export const removeWelcomePage = (id) => {
    return {
        type: WELCOMEPAGE_REMOVED,
        payload: id
    };
};

export const clearWelcomePages = () => {
    return {
        type: WELCOMEPAGES_PURGE
    };
};