import { getUrl } from './helper';
import { authedFunction } from '../session';

export const foodActions = {
  addOrEditDish: (data) => {
    let url = getUrl('food', 'dishes/add');
    if (data.id != null) {
      url = getUrl('food', 'dishes/edit');
    }
    return authedFunction({
      method: 'POST',
      url,
      data,
    });
  },
  deleteDish: (site, id) => {
    return authedFunction({
      method: 'POST',
      url: getUrl('food', 'dishes/remove'),
      data: {
        site,
        id,
      },
    });
  },
  getDishes: (site) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('food', 'dishes/get', { site }),
    });
  },
  getDish: (id) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('food', `dishes/get/${id}`),
    });
  },
  getDailyMenu: (site, dateKey, includeDaily) => {
    return authedFunction({
      method: 'GET',
      url: getUrl('food', `menus/get`, { site, dateKey, includeDaily }),
    });
  },
};
