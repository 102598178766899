import React, { Component } from 'react';
import _ from 'lodash';

class ToggleButton extends Component {
    getClasses(e, index) {
        let string = '';
        if (this.props.isActive === e.Value) {
            string = string + 'button--active ';
        }
        if (index === 0) {
            string = string + 'toggleFirst ';
        }
        if (index === (this.props.options.length - 1)) {
            string = string + 'toggleLast';
        }
        return string;
    }

    renderOptions() {
        if (_.isUndefined(this.props.options) || _.isEmpty(this.props.options)) {
            return null;
        }
        return (
            this.props.options.map((e, index) => {
                return (
                    <div
                        key={e.Label}
                        className={`buttonWrapper buttonWrapper--inline button--toggle ${this.getClasses(e, index)}`}
                        onClick={e.onChange}
                    >
                        <div
                            className={'button'}
                            style={{ ...this.props.style }}
                        >
                            <div className={'buttonText'}>
                                {e.Label}
                            </div>
                        </div>
                    </div>
                )
            })
        )
    }

    render() {
        return (
            <div>
                <div style={styles.row}>
                    {this.renderOptions()}
                </div>
            </div>
        );
    }
}

const styles = {
    row: {
        display: 'flex',
        flexDirection: 'row'
    }
};

export { ToggleButton };
