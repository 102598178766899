import {
    KEYS_LOADED,
    KEY_REMOVED,
    KEYS_PURGE
} from './types';
import { keyActions } from '../webapi';

export const keysUpdate = (site) => {
    return (dispatch) => {
        keyActions.getKeys(site).then(
            (res) => {
                if (res.data != null) {
                    dispatch({
                        type: KEYS_LOADED,
                        payload: res.data
                    })
                }
            }
        );
    }
};

export const keysLoaded = (events) => {
    return {
        type: KEYS_LOADED,
        payload: events
    };
};

export const removeKey = (id) => {
    return {
        type: KEY_REMOVED,
        payload: id
    };
};

export const clearKeys = () => {
    return {
        type: KEYS_PURGE
    };
};