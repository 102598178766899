import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { isEmail } from '../../helper';
import { Button, GenericInput } from '../../components';
import { checkLoggedIn, isAdmin } from '../../session';
import { feedbackActions } from '../../webapi';

class SetFeedbackEmail extends Component {
    state = {
        email: '',
        emailStandin: 'Enter receiving email',
        showWarnings: false,
        fetching: true,
        loadFail: false,
        submitting: false,
        showSuccess: false
    }

    UNSAFE_componentWillMount() {
        checkLoggedIn(this, this.props.auth);
    }

    componentDidMount() {
        if (!isAdmin(this.props.auth)) {
            this.props.history.push('/mastermenu');
        } else {
            this.getFbEmail();
        }
    }

    getFbEmail() {
        var self = this;
        feedbackActions.getFeedbackEmail(this.props.auth.site)
            .then((res) => {
                if (res.data.emailFetchFail) {
                    self.setState({ fetching: false });
                } else {
                    self.setState({ fetching: false, email: res.data });
                }
            }).catch((error) => {

            });
    }

    handleChange(event) {
        var stateChange = {};
        stateChange[event.target.getAttribute('id')] = event.target.value;
        this.setState(stateChange);
    }

    validateEmail() {
        return !_.isEmpty(this.state.email) && isEmail(this.state.email);
    }

    handleSubmit() {
        if (!this.validateEmail()) {
            this.setState({ showWarnings: true });
            return;
        }

        this.setState({ showWarnings: false, submitting: true, showSuccess: false });

        feedbackActions.setFeedbackEmail(this.props.auth.site, this.state.email)
            .then((res) => {
                this.setState({ submitting: false, showSuccess: true });
                this.getFbEmail();
            }).catch((res) => {
                this.setState({ submitting: false });
                alert("Something went wrong with the request. Please try again.")
            });
    }

    onSuccess() {
        this.setState({ submitting: false, showSuccess: true });
    }

    renderSubmit() {
        if (this.state.submitting) {
            return <Button buttonType='secondary'>Saving...</Button>;
        }
        return (
            <Button
                buttonType='primary'
                onClick={this.handleSubmit.bind(this)}
                isActive={this.validateEmail()}
            >
                Save
            </Button>
        );
    }

    renderSuccess() {
        if (!this.state.showSuccess) {
            return null;
        }
        return (<span className='fontHeavy marginLeft-16 text-teal'>Saved</span>)
    }

    render() {
        return (
            <div className='marginBottom-16 bottomDivideBorder' style={{ padding: '24px 32px' }}>
                <p className="text-sectionTitle">
                    CONFIGURE FEEDBACK EMAIL
                </p>
                <div className='marginTop-16'>
                    <GenericInput
                        id="email"
                        type="text"
                        label="Email"
                        placeholder={this.state.emailStandin}
                        value={this.state.email}
                        onChange={(e) => this.handleChange(e)}
                        isRequired
                        isValid={() => { return this.validateEmail() }}
                        showError={() => { return this.state.showWarnings && !this.validateEmail() }}
                        help='When feedback is posted via the app, the entered email will also recieve an emailed copy of the feedback.'
                    />
                </div>
                <div className='flex flex-center'>
                    {this.renderSubmit()}
                    {this.state.fetching &&
                        <div className='fontMedium marginLeft-16 text-tang'>
                            fetching saved email...
                        </div>
                    }
                    {this.renderSuccess()}
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    const { auth } = state;
    return { auth, site: auth.site };
};

export default connect(mapStateToProps, {})(SetFeedbackEmail);