import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { TEXT_DARK, TEXT_LIGHT } from '../../../js';

class ColourDisplay2 extends Component {
    render() {
        return (
            <div style={styles.card}>
                <div
                    style={{
                        ...styles.eventTop,
                        // borderBottom: '1px solid #ccc',
                        paddingBottom: 0,
                        overflow: 'hidden',
                        backgroundColor: this.props.colour4 ? this.props.colour4 : '#fff'
                    }}
                    className='flex flex-column flex-between'
                >
                    <div className='flex flex-between '>
                        <div>
                            <div className='fontHeavy text-dark fontSize-16' style={{ color: this.props.colour4 ? this.props.colour1 : TEXT_DARK }} >
                                Hello Robert
                            </div>
                            <div className='fontRegular text-dark fontSize-13' style={{ color: this.props.colour4 ? this.props.colour1 : TEXT_DARK }} >
                                Let's see what's on
                            </div>
                        </div>
                        <div className='flex' style={{ paddingTop: 2 }}>
                            <div style={{ ...styles.backButton, backgroundColor: this.props.colour1 }} />
                            <FontAwesome
                                style={{ fontSize: 16, lineHeight: '20px', marginLeft: 8, color: this.props.colour4 ? this.props.colour1 : TEXT_DARK }}
                                name={'bell-o'}
                            />
                        </div>
                    </div>
                    <div className='flex' style={{ justifyContent: 'space-evenly' }}>
                        <div className='fontHeavy text-dark fontSize-13' style={{ ...styles.cat, color: this.props.colour1, borderBottom: `3px solid ${this.props.colour1}` }} >
                            News & Events
                        </div>
                        <div className='fontHeavy text-light fontSize-13' style={{ ...styles.cat, color: this.props.colour4 ? this.props.colour1 : TEXT_LIGHT, opacity: this.props.colour4 ? 0.5 : 1 }} >
                            Services
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ ...styles.surveyFeild, backgroundColor: this.props.colour1 }}>
                        <div className='fontHeavy' style={{ color: '#fff', fontSize: 14 }}>
                            Generic text about new survey
                        </div>
                    </div>
                    <div style={styles.eventCard}>
                        <div>
                            <div className='fontHeavy text-dark fontSize-13' style={{ paddingBottom: 2 }}>
                                Wednesday 17th April
                            </div>
                            <div />
                        </div>
                        <div className='flex flex-between flex-center'>
                            <div>
                                <div className='fontHeavy text-mid fontSize-13' >
                                    6:09pm
                                </div>
                                <div className='fontHeavy text-dark fontSize-16' style={{}}>
                                    Example Event Title
                                </div>
                            </div>
                            <div className='fontMedium' style={{ ...styles.button, ...styles.buttonTag, backgroundColor: this.props.colour1 }}>
                                View
                            </div>
                        </div>
                        <div style={{
                            ...styles.eventCardImage,
                            backgroundImage: `url('https://plusscdn.azureedge.net/uploads1400/461-1/636491722666798942483background3.jpg')`
                        }} />
                    </div>
                    <div style={styles.eventCard}>
                        <div>
                            <div className='fontHeavy text-dark fontSize-13' style={{ paddingBottom: 2 }}>
                                Thursday 18th April
                            </div>
                            <div />
                        </div>
                        <div className='flex flex-between flex-center'>
                            <div>
                                <div className='fontHeavy text-mid fontSize-13' >
                                    4:30pm
                                </div>
                                <div className='fontHeavy text-dark fontSize-16' style={{}}>
                                    Example Event Title 2
                                </div>
                            </div>
                            <div className='fontMedium' style={{ ...styles.button, ...styles.buttonTag, backgroundColor: this.props.colour1 }}>
                                View
                            </div>
                        </div>
                        <div style={{
                            ...styles.eventCardImage,
                            backgroundImage: `url( 'https://plusscdn.azureedge.net/uploads1400/461-1/636491722650861034737background2.jpg')`
                        }} />
                    </div>
                </div>
            </div>
        );
    }
}

const styles = {
    card: {
        width: 250,
        height: 370,
        backgroundColor: '#ebebeb',
        overflow: 'hidden',
        borderRadius: 3
    },
    eventTop: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: 8,
        paddingLeft: 12,
        height: 75
    },
    backButton: {
        height: 20,
        width: 20,
        textAlign: 'center',
        borderRadius: 20,
        color: '#fff',
        fontSize: 10,
        lineHeight: '20px',
        backgroundColor: '#ccc'
    },
    button: {
        padding: '6px 12px',
        borderRadius: '4px',
        width: 'max-content',
        color: '#fff'
    },
    buttonTag: {
        fontSize: 12,
        marginRight: 8,
        padding: '5px 10px',
        height: 'max-content'
    },
    cat: {
        paddingLeft: 4,
        paddingRight: 4,
        borderBottom: '3px solid transparent'
    },
    eventCard: {
        marginTop: 8,
        backgroundColor: '#fff',
        width: '100%',
        padding: 12,
        paddingBottom: 8,
        paddingTop: 6,
        borderBottom: '1px solid #ccc',
        borderTop: '1px solid #eee'
    },
    eventCardImage: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 8,
        width: '100%',
        height: 90,
        backgroundColor: '#ccc',
        marginTop: 4
    },
    surveyFeild: {
        padding: '6px 12px',
        color: '#fff'
    }
}

export default connect(null, {})(ColourDisplay2);
