import React, { Component } from 'react';
import { connect } from 'react-redux';
import SingleSignInForm from './SingleSignInForm';
import { typeActions } from '../../webapi';
import { COLOUR_BRANDING_APP } from '../../js';
import { logo } from '../../config';
import { readJSONFromStorage } from '../../helper';

class SignInFormPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingBranding: true,
      form: readJSONFromStorage(window.sessionStorage, 'signinpreview'),
    };
  }

  componentDidMount() {
    if (!this.state.form) return;
    this.getSiteBranding(this.state.form.Site);
  }

  getSiteBranding(site) {
    typeActions.getSiteBranding(site).then((res) => {
      this.setState({
        loadingBranding: false,
        siteName: res.data.Name,
        colour: res.data.MainBrandingColour || COLOUR_BRANDING_APP,
        logo: res.data.Logo || logo,
      });
    });
  }

  resetForm() {
    window.close();
  }

  render() {
    if (this.state.loadingBranding) return null;
    return (
      <div className="visitorSignInPreviewContainer">
        <p className="managingPageTitle marginBottom-20">Preview of {this.state.form.Name} Sign In</p>
        <div className="visitorSignInPreviewContainer_inner">
          <SingleSignInForm form={this.state.form} isPreview colour={this.state.colour} logo={this.state.logo} resetForm={this.resetForm} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {})(SignInFormPreview);
